import React, { useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import { useQuery } from 'react-query'
import '../../../css/painelDispositivos/dspSimcard/dspAcoes.css'
import { SolicitationsService } from '../../../services/http/solicitations.service'
import { useGlobal } from '../../../stores/useGlobal.js'
import PaginacaoTabela from '../../../utils/paginacaoTabela'
import LineSkeleton from '../../Mantine/Skeleton/line'
import * as S from './styles'
import { GlobalStyles } from './styles'

let paginaAntiga = 0
let valorLimite = 5
let totalItens = 58
let contador = 0
let totalPaginas = 0

let auxDados = []
const TableprimaryKey = 'ICCID'

export default function DetalhamentoLinha({ cdSolicitacao = null }) {
  const { isLoadingGlobal, setIsLoadingGlobal } = useGlobal()
  const { getDetails } = SolicitationsService
  const [paginaAtual, setPaginaAtual] = useState(1)
  const [dados, setDados] = useState([])

  const columns = [
    {
      dataField: 'CdWhitechip',
      text: 'CdWhitechip',
      headerClasses: 'nao-selecionavel',
      sort: true,
      hidden: true
    },
    {
      dataField: 'ICCID',
      text: 'ICCID',
      headerClasses: 'nao-selecionavel',
      sort: true
    },
    {
      dataField: 'MSISDN',
      text: 'MSISDN',
      headerClasses: 'nao-selecionavel',
      sort: true
    },
    {
      dataField: 'Operadora',
      text: 'Operadora',
      headerClasses: 'nao-selecionavel',
      sort: true
    },
    {
      dataField: 'Plano',
      text: 'Plano',
      headerClasses: 'nao-selecionavel',
      sort: true
    },
    {
      dataField: 'Status',
      text: 'Status',
      headerClasses: 'nao-selecionavel',
      sort: true,
      formatter: (cell) => {
        return (
          <S.StatusWrapper>
            <S.StatusElipse status={cell} />
            {cell}
          </S.StatusWrapper>
        )
      }
    }
  ]

  const { data, refetch } = useQuery(
    [`${cdSolicitacao}/solicitations`, paginaAtual, valorLimite, true],
    async () => {
      try {
        setIsLoadingGlobal(true)

        return await getDetails(cdSolicitacao, paginaAtual, valorLimite, true)
      } catch (err) {
        console.error(err)
      } finally {
        setIsLoadingGlobal(false)
      }
    },
    {
      onSuccess: () => {
        if (!data?.devices) refetch()
        contador = (paginaAtual - 1) * valorLimite
        totalItens = data?.item_count
        totalPaginas = Math.ceil(totalItens / valorLimite)

        const novosItens = [...auxDados]
        data?.devices?.forEach((CdWhitechip, index) => {
          novosItens[contador + index] = CdWhitechip
        })
        setDados(novosItens)
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false
    }
  )

  function trocarPagina(novaPagina) {
    paginaAntiga = paginaAtual
    setPaginaAtual(novaPagina)
  }

  const getLimiteNaPagina = () =>
    contador + valorLimite <= totalItens ? contador + valorLimite : totalItens

  const opcoesPaginacao = {
    page: paginaAtual,
    showTotal: true,
    sizePerPage: valorLimite,
    firstPageTitle: 'Primeira',
    lastPageTitle: 'Última',
    nextPageTitle: 'Proxima',
    prePageTitle: 'Anterior',
    custom: true,
    onPageChange: (page) => {
      totalItens ? setPaginaAtual(page) : setPaginaAtual(1)
    }
  }

  return (
    <>
      <GlobalStyles />
      <S.detalhamnetoLinhaWrapper>
        {isLoadingGlobal && (
          <S.SkeletonContainer>
            <LineSkeleton height={75} visible={isLoadingGlobal} />
          </S.SkeletonContainer>
        )}

        {!isLoadingGlobal && (
          <div>
            <PaginationProvider pagination={Paginador(opcoesPaginacao)}>
              {({ paginationProps, paginationTableProps }) => (
                <>
                  <BootstrapTable
                    classes={'tabela'}
                    condensed={true}
                    keyField={TableprimaryKey}
                    data={dados}
                    columns={columns}
                    bootstrap4={true}
                    bordered={false}
                    noDataIndication={'Nenhum item encontrado'}
                    {...paginationTableProps}
                  />

                  <div className="pagination-table">
                    <PaginacaoTabela
                      preview={true}
                      setPaginaAtual={trocarPagina}
                      totalPaginas={totalPaginas}
                      totalItens={totalItens}
                      valorLimite={valorLimite}
                      paginaAtual={paginaAtual}
                      page={paginationProps.page}
                      onPageChange={paginationProps.onPageChange}
                    />
                  </div>
                </>
              )}
            </PaginationProvider>
            <div className="pagination-description">
              Mostrando
              <span className="label-tabela-itens-exibidos">
                {contador + 1}
              </span>
              -
              <span className="label-tabela-itens-exibidos">
                {getLimiteNaPagina()}
              </span>
              de
              <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </div>
          </div>
        )}
      </S.detalhamnetoLinhaWrapper>
    </>
  )
}
