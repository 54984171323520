import React, { useEffect, useState } from 'react'

import Botao from '../../Mantine/Botao'
import Group from '../../Mantine/Group'

import * as S from './styles'

export default function Stepper({
  steps = [],
  disabledNextStep = [],
  allowNextStepsSelect = false,
  prev = 'Cancelar',
  next = 'Próximo',
  finish = 'Confirmar',
  onSubmit,
  close = null
}) {
  const [active, setActive] = useState(0)
  const [finalStep, setFinalStep] = useState(false)
  const nextStep = () =>
    setActive((current) => (current < 3 ? current + 1 : current))
  const prevStep = () => {
    setActive((current) => {
      if (current > 0) {
        return current - 1
      } else {
        if (close) close()
        return current
      }
    })
  }

  const finishStep = () => onSubmit()

  steps = steps.map((step) => ({
    ...step,
    disableValidation: !step.disableValidation ? true : false
  }))

  useEffect(() => {
    setFinalStep(steps.length === active + 1)
  }, [active, steps])

  return (
    <>
      <S.Stepper
        active={active}
        onStepClick={setActive}
        iconSize={25}
        size="md"
        allowNextStepsSelect={allowNextStepsSelect}
      >
        {steps.map((step) => (
          <S.Stepper.Step key={step.label} label={step.label}>
            <div className="content-subtitle">{step.subtitle}</div>

            <div className="content">{step.content}</div>
          </S.Stepper.Step>
        ))}
      </S.Stepper>

      <Group position="right" mt="xl">
        <Botao
          label={!active ? prev : 'Voltar'}
          variant="outline"
          onClick={prevStep}
        />
        <Botao
          disabled={
            disabledNextStep[active] && steps[active]?.disableValidation
          }
          label={finalStep ? finish : next}
          variant="primary"
          onClick={finalStep ? finishStep : nextStep}
        />
      </Group>
    </>
  )
}
