import styled from 'styled-components'

export const SimcardsWrapper = styled.div`
  position: absolute;
  margin-left: 248px;
  padding-left: 16px;
  width: calc(100% - 264px);

  .pagination-table,
  .grupo-botao-paginacao-tabela-4 {
    margin-right: 20px;
  }

  .pagination-description {
    margin-top: 0.16em;
    margin-left: 215px;
    font-family: RobotoRegular !important;
    font-size: 0.875em !important;
  }

  table {
    width: 100% !important;
  }

  td {
    font-family: RobotoRegular !important;
    font-size: 16px !important;
    line-height: 1.5em !important;
    color: ${({ theme }) => theme.cor9}!important;
  }

  .container-simcards-erb,
  .container-table-simcards-erb {
    overflow: hidden !important;
    overflow-y: auto !important;
    overflow-x: auto !important;
  }
`

export const ElipseStatusWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  text-transform: capitalize;
`

export const ElipseStatus = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${({ theme, status }) =>
    status === 'online' ? theme.cor11 : '#F44336'};
  border-radius: 50px;
`
