export const limiteSolicitacoes = 10000

/* export const verificaTrucksLinhasOperadoraVivo = async({ modoSelecaoSimcard, itens }) => {
    let mostraSwitch = false;

    if(!modoSelecaoSimcard && itens.length > 0) { 
        mostraSwitch = !itens.find(item => item.DS_OPERADORA !== 'VIVO'); // && item.DS_OPERADORA !==undefined
    }

    return mostraSwitch;
} */
