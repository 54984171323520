//IMPORTAÇÕES
import moment from 'moment'
import 'moment-timezone'
import React, { useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  filtrosArrayBarra,
  mudarFiltros
} from '../../../../redux/actions/index'
import { useGlobal } from '../../../../stores/useGlobal'
import { formatarMilhar } from '../../../../utils/formatarMilhar'
import PaginacaoTabela from '../../../../utils/paginacaoTabela'
//ICONES
import {
  iconeAntenaAtivado,
  iconeLocalizacaoAtivado
} from '../../../../utils/alternanciaIcones'
//COMPONENTES

export default function ExtratoConexao(props) {
  const { setFiltros, setRevelarBarraFiltro } = useGlobal()
  const history = useHistory()
  //HOOKS TABELA
  const [maisInfo, setMaisInfo] = useState([])
  const despacho = useDispatch()
  let renderizar = props.renderizar
  let contador = props.contador
  const permissions = props.permissions

  const colunas = [
    {
      dataField: 'dataInicio',
      text: 'Início',
      sort: true,
      headerClasses: 'nao-selecionavel',
      formatter: (cell, row) => {
        const dataBr = moment(cell).format('DD/MM/YYYY - HH:mm')
        return <p className="fonte-cor-1 label-tabela-1">{dataBr}</p>
      }
    },

    {
      dataField: 'dataFim',
      text: 'Fim',
      sort: true,
      headerClasses: 'nao-selecionavel',
      formatter: (cell, row) => {
        if (cell & (cell != '-')) {
          cell = cell.replace('T', ' ').split('.')
          if (cell.length > 0) {
            cell = cell[0]
          }
        }

        return (
          <p className="fonte-cor-1 label-tabela-1" key={row.CD_SIMCARD}>
            {' '}
            {cell === '-' ? '-' : moment(cell).format('DD/MM/YYYY - HH:mm')}
          </p>
        )
      }
    },

    {
      dataField: 'LAT_LONG_OPERADORA',
      text: 'Rede',
      sort: true,
      headerClasses: 'nao-selecionavel',
      formatter: (cell, row) => {
        return <p className="fonte-cor-1 label-tabela-1">{cell}</p>
      }
    },
    {
      dataField: 'duracao',
      text: 'Duração',
      sort: true,
      headerClasses: 'nao-selecionavel',
      formatter: (cell, row) => {
        return <p className="fonte-cor-1 label-tabela-1">{cell}</p>
      }
    },
    {
      dataField: 'trafegoKb',
      text: 'Tráfego KB',
      sort: true,
      headerClasses: 'nao-selecionavel',
      formatter: (cell, row) => {
        return <p className="fonte-cor-1 label-tabela-1">{cell}</p>
      }
    },
    {
      dataField: 'trafegoMb',
      text: 'Tráfego MB',
      sort: true,
      headerClasses: 'nao-selecionavel',
      formatter: (cell, row) => {
        return <p className="fonte-cor-1 label-tabela-1">{cell}</p>
      }
    },
    {
      dataField: 'uploadKb',
      text: 'Upload KB',
      sort: true,
      headerClasses: 'nao-selecionavel',
      formatter: (cell, row) => {
        return <p className="fonte-cor-1 label-tabela-1">{cell}</p>
      }
    },
    {
      dataField: 'downloadKb',
      text: 'Download KB',
      sort: true,
      headerClasses: 'nao-selecionavel',
      formatter: (cell, row) => {
        return <p className="fonte-cor-1 label-tabela-1">{cell}</p>
      }
    },
    {
      dataField: 'pacotesRecebidos',
      text: 'Pacotes recebidos',
      sort: true,
      headerClasses: 'nao-selecionavel',
      formatter: (cell, row) => {
        return (
          <p className="fonte-cor-1 label-tabela-1">
            {cell === '-' ? '-' : formatarMilhar(parseInt(cell))}
          </p>
        )
      }
    },
    {
      dataField: 'pacotesEnviados',
      text: 'Pacotes enviados',
      sort: true,
      headerClasses: 'nao-selecionavel',
      formatter: (cell, row) => {
        return (
          <p className="fonte-cor-1 label-tabela-1">
            {cell === '-' ? '-' : formatarMilhar(parseInt(cell))}
          </p>
        )
      }
    },
    {
      dataField: 'apn',
      text: 'APN',
      sort: true,
      headerClasses: 'nao-selecionavel',
      formatter: (cell, row) => {
        return <p className="fonte-cor-1 label-tabela-1">{cell}</p>
      }
    },
    {
      dataField: 'tecnologia',
      text: 'Tecnologia',
      sort: true,
      headerClasses: 'nao-selecionavel',
      formatter: (cell, row) => {
        return <p className="fonte-cor-1 label-tabela-1">{cell}</p>
      }
    },
    {
      dataField: 'ip',
      text: 'IP',
      sort: true,
      headerClasses: 'nao-selecionavel',
      formatter: (cell, row) => {
        return <p className="fonte-cor-1 label-tabela-1">{cell}</p>
      }
    },
    {
      dataField: 'imei',
      text: 'IMEI',
      headerClasses: 'nao-selecionavel',
      formatter: (cell, row) => {
        return <p className="fonte-cor-1 label-tabela-1">{cell}</p>
      }
    },
    {
      dataField: 'terminoConexao',
      text: 'Término conexão',
      sort: true,
      headerClasses: 'nao-selecionavel',
      formatter: (cell, row) => {
        return <p className="fonte-cor-1 label-tabela-1">{cell}</p>
      }
    },
    {
      dataField: 'geolocalizacao',
      text: 'Geo',
      sort: true,
      headerClasses: 'nao-selecionavel',
      formatter: (cell, row) => {
        return (
          <p className="container-button-table">
            <a
              className="fonte-cor-1 label-tabela-1"
              href={cell}
              target="_blank"
            >
              <img src={iconeLocalizacaoAtivado()} />
            </a>
          </p>
        )
      }
    }
  ]

  if (permissions.portadoras) {
    colunas.push({
      dataField: 'portadora',
      text: 'Mostrar em Portadoras',
      headerClasses: 'nao-selecionavel',
      formatter: (cell, row) => {
        const handleGeolocation = () => {
          history.push('/veye/portadoras')
          despacho(
            mudarFiltros({
              GEOLOCATION: `'${row.cellid}'`
            })
          )
          setFiltros({
            GEOLOCATION: `'${row.cellid}'`
          })
          despacho(filtrosArrayBarra(['Geolocalização?GEOLOCATION']))
          setRevelarBarraFiltro(true)
        }
        return (
          <p className="container-button-table">
            <a
              className="fonte-cor-1 label-tabela-1"
              href={cell}
              target="_blank"
              onClick={(e) => {
                e.preventDefault()
                handleGeolocation()
              }}
            >
              <img src={iconeAntenaAtivado()} />
            </a>
          </p>
        )
      }
    })
  }

  const eventosLinhas = {
    onClick: (e, row, rowIndex) => {
      let maisInfo = {}
      maisInfo.ip = row.ip
      maisInfo.token = row.token
      setMaisInfo(maisInfo)
    }
  }

  const opcoesPaginacao = {
    page: props.paginaAtual,
    showTotal: true,
    sizePerPage: props.valorLimite,
    firstPageTitle: 'Primeira',
    lastPageTitle: 'Última',
    nextPageTitle: 'Proxima',
    prePageTitle: 'Anterior',
    custom: true,
    onPageChange: (page) => {
      props.setPaginaAtual(page)
    }
  }

  return (
    <div className="container-extrato">
      <PaginationProvider pagination={Paginador(opcoesPaginacao)}>
        {({ paginationProps, paginationTableProps }) => (
          <div className="container-tabela-extrato-conectividade">
            <BootstrapTable
              hover={true}
              condensed={true}
              data={props.itens}
              keyField={'extrato_conexao'}
              columns={colunas}
              rowEvents={eventosLinhas}
              bootstrap4={true}
              bordered={false}
              noDataIndication={!props.spinAtivo && 'Nenhum item encontrado'}
              {...paginationTableProps}
            />
            <PaginacaoTabela
              extrato={true}
              setPaginaAtual={props.setPaginaAtual}
              totalPaginas={props.totalPaginas}
              totalItens={props.totalItens}
              valorLimite={props.valorLimite}
              paginaAtual={props.paginaAtual}
              page={paginationProps.page}
              onPageChange={paginationProps.onPageChange}
            />
          </div>
        )}
      </PaginationProvider>
      <text className="itens-exibidos-tabela-extrato-conectividade fonte-cor-1">
        Mostrando
        <span className="label-tabela-itens-exibidos">{contador + 1}</span>-
        <span className="label-tabela-itens-exibidos">
          {contador + props.valorLimite <= props.totalItens
            ? contador + props.valorLimite
            : props.totalItens}
        </span>
        de
        <span className="label-tabela-itens-exibidos">{props.totalItens}</span>
      </text>
    </div>
  )
}
