import React, { useState } from 'react'

import { setaDropdown } from '../../../utils/alternanciaIcones'
import Checkbox from '../../Mantine/Checkbox'

import * as S from './styles'
import { GlobalStyles } from './styles'


export default function MultiSelect({
  label = '',
  options = [],
  onChange,
  width,
  value,
  disabled
}) {
  const [isOpen, setIsOpen] = useState(false)

  const filterData = (query, selected, data) => {
    if (!query) return data
    return data.label.toLowerCase().includes(query.toLowerCase())
  }

  return (
    <>
      <GlobalStyles />
      <S.MultiSelect
        width={width}
        onChange={onChange}
        disabled={disabled}
        radius="xl"
        searchable={false}
        zIndex={9999}
        nothingFound="Nenhum item encontrado"
        withinPortal
        disableSelectedItemFiltering
        value={value}
        filter={filterData}
        placeholder="Selecione"
        data={options}
        label={label}
        onDropdownOpen={() => setIsOpen(true)}
        onDropdownClose={() => setIsOpen(false)}
        rightSection={
          <S.Img isOpen={isOpen} alt="seta-dropdown" src={setaDropdown()} />
        }
        itemComponent={(props) => <CustomItem {...props} />}
        valueComponent={(props) => (
          <Value
            key={`${label}-${props.index}`}
            allSelected={value}
            options={options || []}
            {...props}
          />
        )}
      />
    </>
  )
}

function Value({ label, options, onRemove, allSelected, ...others }) {
  if (others.index > 0) return null

  const moreThanOne = allSelected.length > 1

  const formattedLabel = (
    <S.Label key="more">
      {moreThanOne ? `${allSelected.length} Selecionados` : '1 Selecionado'}
    </S.Label>
  )

  return <S.LabelWrapper {...others}>{formattedLabel}</S.LabelWrapper>
}

function CustomItem({ label, ...props }) {
  return (
    <S.CustomItemWrapper {...props}>
      <Checkbox checked={props.selected} readOnly />
      <S.CustomItemSpan>{label}</S.CustomItemSpan>
    </S.CustomItemWrapper>
  )
}
