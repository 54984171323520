import routers from '../../statics/routers.json'

export function getTitulosPaginas(dados) {
  const menuNivel1 = dados[1] // módulo (se não tiver filho, então é uma "tela")
  const menuNivel2 = dados[2] // tela
  // const menuNivel3 = dados[3]; // elemento

  let titulosPaginas = []

  menuNivel1.map((modulo) => {
    if (modulo.ativo) {
      if (modulo.filho === 0) {
        // é uma página
        const { cdMenu, label, rota } = modulo
        titulosPaginas.push({
          cdMenu,
          label,
          /*tituloPagina: label,*/ rota: '/' + rota
        })
      } else {
        // não é uma página e possui submenus
        const { cdMenu, label, rota, filho } = modulo

        const objMenu = { cdMenu, label, rota, filho }

        const subPaginas = menuNivel2.filter((tela) => tela.menuPai === cdMenu)
        const labelPai = modulo.label

        subPaginas.map((tela) => {
          const { cdMenu, menuPai, label, rota } = tela
          titulosPaginas.push({
            cdMenu,
            menuPai,
            labelPai,
            label,
            rota: '/' + rota
          })
          return null
        })
        titulosPaginas.push(objMenu)
      }
    }
    return null
  })

  return titulosPaginas
}

export function getItemBreadCrumbDados(breadCrumbsObj, locatinPathname) {
  let breadCrumbItem

  const breadCrumbFound = breadCrumbsObj.find(
    (tela) => tela.rota === locatinPathname
  )

  if (breadCrumbFound) {
    const { label, rota, labelPai } = breadCrumbFound

    breadCrumbItem = { tituloPagina: label, rota, label }

    if (locatinPathname === '/dashboard/dispositivos') {
      return [
        { label: 'Dashboard' },
        {
          tituloPagina: 'Dashboard Dispositivos',
          label: 'Dispositivos',
          rota
        }
      ]
    }

    if (labelPai) {
      return [{ label: labelPai }, breadCrumbItem]
    } else {
      return [breadCrumbItem]
    }
  } else {
    const rota = locatinPathname
    const arrVirtuAcoes = [
      { label: 'Virtueyes' },
      { label: 'Central de Ações' }
    ]
    const arrVirtuMigracaoPlano = [
      { label: 'Virtueyes' },
      { label: 'Migração de Plano' }
    ]

    const getBreadcrumArrVirtuAcoes = (label, rota) => [
      ...arrVirtuAcoes,
      { label, rota }
    ]
    const getBreadcrumArrVirtuMigracaoPlano = (label, rota) => [
      ...arrVirtuMigracaoPlano,
      { label, rota }
    ]

    switch (rota) {
      case routers.dashboardSMS:
        return [
          { label: 'Dashboard' },
          { tituloPagina: 'Dashboard SMS', label: 'SMS', rota }
        ]

      case routers.dashboardFaturas:
        return [
          { label: 'Dashboard' },
          { tituloPagina: 'Dashboard Faturas', label: 'Faturas', rota }
        ]

      case routers.virtueyesBloqueioAcoes:
        return getBreadcrumArrVirtuAcoes('Bloqueio', rota)

      case routers.virtueyesClientesAcoes:
        return getBreadcrumArrVirtuAcoes('Clientes', rota)

      case routers.virtueyesOperadoraAcoes:
        return getBreadcrumArrVirtuAcoes('Operadora', rota)

      case routers.virtueyesMigracaoPlanoGerenciamento:
        return getBreadcrumArrVirtuMigracaoPlano('Gerenciamento', rota)

      case routers.virtueyesMigracaoPlanoDashboard:
        return getBreadcrumArrVirtuMigracaoPlano('Dashboard', rota)

      default:
        return [
          {
            tituloPagina: rota,
            label: rota,
            rota
          }
        ]
    }
  }
}

export function getTituloPagina(breadCrumbArr) {
  if (breadCrumbArr?.length > 0) {
    const lastIdx = breadCrumbArr?.length - 1
    const item = breadCrumbArr[lastIdx]
    const titulo = item.tituloPagina || item.label || ''

    return tratarTitulo(titulo)
  } else {
    return null
  }
}

function tratarTitulo(titulo) {
  switch (titulo) {
    case 'mensagens':
      return 'Mensagens'

    case 'Papéis':
      return 'Papéis de permissionamento'

    default:
      return titulo
  }
}
