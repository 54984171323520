import { DatePickerInput as DatePickerInputMantine } from '@mantine/dates'
import styled, { createGlobalStyle, css } from 'styled-components'

export const DatePickerInput = styled(DatePickerInputMantine)`
  .mantine-DatePickerInput-icon {
    margin: 0 12px;
  }

  .mantine-DatePickerInput-label {
    color: ${({ theme }) => theme.cor9} !important;
    font-size: 20px !important;
    background-color: transparent !important;
  }

  .mantine-Input-rightSection {
    margin-right: 8px;
  }

  .mantine-DatePickerInput-placeholder {
    color: ${({ theme }) => theme.cor39}!important;
    font-weight: 400 !important;
  }

  .mantine-DatePickerInput-input {
    border: none !important;
    color: ${({ theme }) => theme.cor9}!important;
    background-color: ${({ theme }) => theme.cor27}!important;
    padding-left: ${({ $hasPrefixo }) =>
      $hasPrefixo ? '52px' : '16px'} !important;
    font-size: 16px !important;
  }
`

export const GlobalStyles = createGlobalStyle`
  .mantine-Popover-dropdown {
    border: none !important;
    color: ${({ theme }) => theme.cor9}!important;
    background-color: ${({ theme }) => theme.cor27}!important;
  }
  
  .mantine-DatePickerInput-calendarHeaderControl {
    :hover{
      background-color: transparent !important;
    }
  }

  .mantine-DatePickerInput-calendarHeaderControlIcon {
    color: ${({ theme }) => theme.cor9} !important;
    width: 24px !important;
    height: 24px !important;
  }

  .mantine-DatePickerInput-calendarHeaderLevel,
  .mantine-DatePickerInput-pickerControl {
    color: ${({ theme }) => theme.cor9}!important;
    font-size: 16px !important;
    font-weight: 700!important;

    :hover{
      background-color: transparent !important;
    }
  }

  .mantine-DatePickerInput-pickerControl{
    :hover{
      background-color: ${({ theme }) => theme.cor57}!important;
    }
  }

  .mantine-DatePickerInput-weekday{
    color: ${({ theme }) => theme.cor11}!important;
  }

  .mantine-DatePickerInput-day {
    color: ${({ theme }) => theme.cor9}!important;
    font-weight: 500;

    :hover{
      background-color: ${({ theme }) => theme.cor57}!important;
    }
  }

  .mantine-DatePickerInput-day[data-outside], .mantine-DatePickerInput-day[data-disabled] {
    color: ${({ theme }) => theme.cor7}!important;
    font-weight: 500;

    :hover{
      background-color: ${({ theme }) => theme.cor57}!important;
    }
  }
  
  .mantine-DatePickerInput-day[data-selected],
  .mantine-DatePickerInput-day[data-in-range] {
    color: ${({ theme }) => theme.cor9}!important;
    font-weight: 500;
    background-color: ${({ theme }) => theme.cor57}!important;
  }

  .mantine-DatePickerInput-day[data-selected]{
    ${({ type }) =>
      type === 'default' &&
      css`
        border-radius: 30px;
        background-color: ${({ theme }) => theme.cor11}!important;
      `}
  }

  .mantine-DatePickerInput-day[data-first-in-range]  {
    background-color: ${({ theme }) => theme.cor11}!important;
    border-top-left-radius: 30px!important;
    border-bottom-left-radius: 30px!important;
  }

  .mantine-DatePickerInput-day[data-last-in-range]  {
    background-color: ${({ theme }) => theme.cor11}!important;
    border-top-right-radius: 30px!important;
    border-bottom-right-radius: 30px!important;
  }

`

export const Img = styled.img``

export const Prefixo = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.cor9}!important;
`
