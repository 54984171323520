import { IconButton } from '@mui/material'
import React, { useState } from 'react'

import { setaParaEsquerda } from '../../../../../../utils/alternanciaIcones'
import { selecionarCicloEstilosCoresTema as estilosCoresTema } from '../../../cores'
import { obterCiclo } from '../funcoes'
import { isTemaClaro } from '../utils'
import { getEstilosCalendarMonthByNewClasses } from './cores'
import './OpcoesMeses.css'

const months = [
  'jan',
  'fev',
  'mar',
  'abr',
  'mai',
  'jun',
  'jul',
  'ago',
  'set',
  'out',
  'nov',
  'dez'
]

function SelecionarCiclo(props) {
  const estilosCores = isTemaClaro()
    ? estilosCoresTema.claro
    : estilosCoresTema.escuro

  // ---------------------------------------------------------
  const [selectedMonth, setSelectedMonth] = useState(null)
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())

  const getDadosMeses = () => {
    const currentDate = new Date()
    const sixMonthsAgo = new Date()
    sixMonthsAgo.setMonth(currentDate.getMonth() - 6)

    return { currentDate, sixMonthsAgo }
  }

  const { currentDate, sixMonthsAgo } = getDadosMeses()
  const isPreviousYearAvailable = new Date(selectedYear - 1, 11) >= sixMonthsAgo
  const isNextYearAvailable = new Date(selectedYear + 1, 0) <= currentDate

  const handleYearChange = (newYear) => {
    setSelectedYear(newYear)
    setSelectedMonth(null) // Reseta o mês selecionado
  }

  const handleMonthClick = (monthIndex) => {
    const selectedDate = new Date(selectedYear, monthIndex)
    const { currentDate, sixMonthsAgo } = getDadosMeses()

    if (selectedDate >= sixMonthsAgo && selectedDate <= currentDate) {
      setSelectedMonth(monthIndex)
      handleMonthSelect(monthIndex, selectedYear)
    }
  }

  const handleMonthSelect = (monthIndex, year) => {
    const cicloEscolhido = obterCiclo(monthIndex, year)
    props.alterarCiclo(cicloEscolhido)
  }
  // ---------------------------------------------------------

  const ChevronLeft = () => (
    <img className="seta-esquerda-opcoes" src={setaParaEsquerda()} />
  )
  const ChevronRight = () => (
    <img className="seta-direita-opcoes" src={setaParaEsquerda()} />
  )

  return (
    <div className="calendar-container" style={estilosCores.calendarContainer}>
      <div
        className="calendar-header"
        style={{
          justifyContent: isPreviousYearAvailable
            ? 'start'
            : isNextYearAvailable
              ? 'end'
              : 'center'
        }}
      >
        {isPreviousYearAvailable && (
          <IconButton onClick={() => handleYearChange(selectedYear - 1)}>
            <ChevronLeft />
          </IconButton>
        )}
        <span>{selectedYear}</span>
        {isNextYearAvailable && (
          <IconButton onClick={() => handleYearChange(selectedYear + 1)}>
            <ChevronRight />
          </IconButton>
        )}
      </div>

      <div className="calendar-grid">
        {months.map((month, index) => {
          const selectedDate = new Date(selectedYear, index)
          const isDisabled =
            selectedDate < sixMonthsAgo || selectedDate > currentDate
          const isCurrentMonth =
            selectedDate.getMonth() === currentDate.getMonth() &&
            selectedDate.getFullYear() === currentDate.getFullYear()

          const selectedCurrent = selectedMonth === index

          const getEsilosCalendarMonth = () => {
            const arrNewClasses = []
            if (isCurrentMonth) arrNewClasses.push('current')
            if (selectedCurrent) arrNewClasses.push('selected')
            if (isDisabled) arrNewClasses.push('disabled')

            return getEstilosCalendarMonthByNewClasses(arrNewClasses)
          }

          return (
            <div
              key={index}
              className={`calendar-month ${isCurrentMonth ? 'current' : ''} 
            ${selectedCurrent ? 'selected' : ''} 
            ${isDisabled ? 'disabled' : ''}`}
              style={getEsilosCalendarMonth()}
              onClick={() => !isDisabled && handleMonthClick(index)}
            >
              {month}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SelecionarCiclo
