import React, { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { PortadorasService } from '../../../services/http/portadoras.service'
import { useGlobal } from '../../../stores/useGlobal'
import MapaGoogle from '../../googleMap/googleMap'
import MapaGoogleHeatmap from '../../googleMap/googleMapHeatmap'
import Botao from '../../Mantine/Botao'
import Modal from '../../Mantine/Modal'
import * as S from './styles'

const ALERTAS = {
  padrao: {
    open: false,
    mensagem: ''
  },
  maximo: {
    titulo: 'Atenção!',
    open: true,
    mensagem:
      'O máximo permitido para essa ação é de até 100 portadoras selecionadas.'
  },
  semLoc: {
    open: true,
    mensagem: 'Não foram encontrados dados para a localização da antena'
  },
  locParcial: {
    titulo: 'Atenção!',
    type: 'parcial',
    open: true,
    mensagem:
      'Uma ou mais portadoras não possuem dados para a localização da antena. As opções selecionadas restantes serão exibidas no mapa.'
  }
}

export default function Mapa({
  opened,
  close,
  title,
  selecionados,
  tipoMapa = 'normal'
}) {
  const { selecionarParqueInteiro, filtros, setIsLoadingGlobal } = useGlobal()
  const { getMapInformation } = PortadorasService

  const [abrirMapa, setAbrirMapa] = useState(true)
  const [simcards, setSimcards] = useState(selecionados)
  const [alerta, setAlerta] = useState(ALERTAS.padrao)

  useQuery(
    ['/api/v2/carriers/retrieveMapInformation', selecionarParqueInteiro],
    async () => {
      try {
        setIsLoadingGlobal(true)
        return await getMapInformation(filtros)
      } catch (err) {
        console.error(err)
      } finally {
        setIsLoadingGlobal(false)
      }
    },
    {
      onSuccess: async (data) => {
        verificarLimite(data.list?.length)

        setSimcards(data?.list)
      },
      enabled: selecionarParqueInteiro,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false
    }
  )

  const verificarLimite = (simcardsLength) => {
    if (simcardsLength > 100) {
      setAlerta(ALERTAS.maximo)
      setAbrirMapa(false)

      return
    }
  }

  const atualizarSimcards = useCallback(() => {
    const simcardsComLatLong = simcards.filter(
      (item) => item.latitude && item.longitude
    )

    verificarLimite(simcardsComLatLong?.length)

    if (simcardsComLatLong.length === 0) {
      setAlerta(ALERTAS.semLoc)
      setAbrirMapa(false)
      return
    }

    if (simcardsComLatLong.length < simcards.length) {
      setAlerta(ALERTAS.locParcial)
      setAbrirMapa(false)
      setSimcards(simcardsComLatLong)
    }
  }, [simcards])

  useEffect(() => {
    if (simcards.length > 0) {
      atualizarSimcards()
    }
  }, [simcards, atualizarSimcards])

  const handleFecharMensagem = () => {
    if (alerta?.type === 'parcial') {
      setAbrirMapa(true)
      return
    }

    close()
    setAbrirMapa(false)
  }

  const MapComponent = tipoMapa === 'calor' ? MapaGoogleHeatmap : MapaGoogle

  return (
    <>
      {abrirMapa && (
        <Modal
          titulo={title}
          opened={opened}
          close={close}
          showActionsModal={false}
        >
          <S.MapaWrapper>
            <MapComponent array={simcards} />
          </S.MapaWrapper>
        </Modal>
      )}

      {alerta.open && (
        <Modal
          opened={alerta.open}
          width="445px"
          minHeight={alerta.titulo ? '180px' : '130px'}
          type="alerta"
        >
          <S.ContainerAlerta>
            {alerta.titulo && <S.TituloAlerta>{alerta.titulo}</S.TituloAlerta>}
            <S.MensagemAlerta>{alerta.mensagem}</S.MensagemAlerta>
            <Botao label="Fechar" onClick={handleFecharMensagem} />
          </S.ContainerAlerta>
        </Modal>
      )}
    </>
  )
}
