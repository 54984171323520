//IMPORTAÇÕES
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Button, ButtonToolbar, Col, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import SpinerVeye from '../../components/spinnerVeye/spinnerVeye.js'
import { api } from '../../conexoes/api'
import { mudarManejador } from '../../redux/actions/index'
import { elipseVerde, setaDropdown } from '../../utils/alternanciaIcones'
import Historico from '../calendario/calendario'

//REDUX
//MODAIS
import ColetaClickstream from './clickstreamColeta.js'
import SelectLogin from './modais/selecLogin'
import SelectUrl from './modais/selectUrl'

import '../../css/clickstreamAnalise/css/style.css'

export default function ClickstreamAnalise() {
  //HOOKS VALIDAÇÂO
  const { handleSubmit } = useForm()
  //HOOKS MODAIS
  const [revelarSelectLogin, setRevelarSelectLogin] = useState(false)
  const [revelarSelectUrl, setRevelarSelectUrl] = useState(false)
  //HOOKS PRINCIPAIS
  const [dsLogin, setDsLogin] = useState('Selecione')
  const [phLogin, setPhLogin] = useState('Selecione')
  const [cdUrl, setCdUrl] = useState(-1)
  const [dsUrl, setDsUrl] = useState('Selecione')
  const [phUrl, setPhUrl] = useState('Selecione')

  const [desativarSelectLogin, setDesativarSelectLogin] = useState(true)
  const [desativarBotao, setDesativarBotao] = useState(true)
  const [spinAtivo, setSpinAtivo] = useState(true)

  const [vetorLogins, setVetorLogins] = useState([])
  const [vetorUrls, setVetorUrls] = useState([])

  const [dataInicio, setDataInicio] = useState()
  const [dataFinal, setDataFinal] = useState()
  const [dataMin, setDataMin] = useState(moment().subtract(30, 'days')._d)
  const [dataMax, setDataMax] = useState(new Date())

  const despacho = useDispatch()
  // USABILIDADE
  const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

  useEffect(() => {
    if (coletaUsabilidade) {
      ColetaClickstream(151) // 'clickstreamAnalise'
      setColetaUsabilidade(false)
    }
  }, [])

  useEffect(() => {
    despacho(mudarManejador('clickstream'))

    setSpinAtivo(true)
    setDesativarSelectLogin(true) // Desativa o select do login para a carga dos dados
    async function carregarVetorLogins() {
      const resultado = await api.get(
        '/m1/servicos/clickstream/consultarclickstream?opcao=' + 'email'
      )

      setVetorLogins(resultado.data.dados)
    }
    carregarVetorLogins()
    setSpinAtivo(false)
  }, [])

  useEffect(() => {
    async function carregarVetorUrls() {
      const resultado = await api.get(
        '/m1/servicos/clickstream/consultarclickstream?opcao=' +
          'url' +
          '&email=' +
          dsLogin
      )
      setVetorUrls(resultado.data.dados)
    }
    if (dsLogin != 'Selecione') {
      setDsUrl('Selecione')
      carregarVetorUrls()
    }
  }, [dsLogin])

  useEffect(() => {
    async function carregarVetorTempo() {
      const resultado = await api.get(
        '/m1/servicos/clickstream/consultarclickstream?opcao=' +
          'tempo' +
          '&email=' +
          dsLogin +
          '&url=' +
          dsUrl
      )
      setDataInicio(moment(resultado.data.dados[0])._d)
      setDataFinal(moment(resultado.data.dados[1])._d)
    }
    if (dsUrl != 'Selecione') {
      carregarVetorTempo()
    } else {
      setDataInicio()
      setDataFinal()
    }
  }, [dsUrl])

  async function handleImprimirClickstream() {
    if (dsLogin != 'Selecione' && dsUrl != 'Selecione') {
      const periodoInicio = new Date(dataInicio).getTime()
      const periodoFim = new Date(dataFinal).getTime()
      const dadosMapa = {
        email: dsLogin,
        url: dsUrl,
        dataInicio: periodoInicio < periodoFim ? periodoInicio : periodoFim,
        dataFinal: periodoFim >= periodoInicio ? periodoFim : periodoInicio
      }
      localStorage.setItem('dadosdeheatmap', JSON.stringify(dadosMapa))
      const url = dsUrl + '?data=heatmapPrint' // Cria a url
      window.open(url, '_blank') // Abre a url
    }
  }

  return (
    <Form onSubmit={handleSubmit(() => handleImprimirClickstream())}>
      <div className="modal-clickstream-analise fundo-cor-6">
        <Form.Row>
          <Col>
            <img
              className="icone-label-janela"
              src={elipseVerde()}
              alt="elipse"
            />
            <Form.Label>
              <div className="fonte-cor-1 label-janela">Clickstream</div>
            </Form.Label>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label>
                <div className="fonte-cor-1 label-campo">Login</div>
              </Form.Label>
              {revelarSelectLogin === false && (
                <Button
                  name="cdLogin"
                  className="campo-texto-cor-3 label-elipses campo-select"
                  onClick={() => setRevelarSelectLogin(!revelarSelectLogin)}
                >
                  <div className="fonte-cor-1 label-campo">{dsLogin}</div>
                  <img
                    className="campo-select-icone-desativado nao-selecionavel"
                    src={setaDropdown()}
                    alt="drop"
                  />
                </Button>
              )}
              {revelarSelectLogin === true && (
                <SelectLogin
                  setRevelarSelectLogin={setRevelarSelectLogin} //Hook para fechar a modal
                  setDsLogin={setDsLogin} //Nome do login
                  vetorLogins={vetorLogins} // Vetor com as opões de logins
                  setSpinAtivo={setSpinAtivo} // para fechar o spinner
                  phLogin={phLogin} //Placeholder para mostrar o valor original em caso de edição
                  dsLogin={dsLogin}
                />
              )}
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label>
                <div className="fonte-cor-1 label-campo">Url</div>
              </Form.Label>
              {revelarSelectUrl === false && (
                <Button
                  name="cdUrl"
                  className="campo-texto-cor-3 label-elipses campo-select"
                  onClick={() => setRevelarSelectUrl(!revelarSelectUrl)}
                >
                  <div className="fonte-cor-1 label-campo">{dsUrl}</div>
                  <img
                    className="campo-select-icone-desativado nao-selecionavel"
                    src={setaDropdown()}
                    alt="drop"
                  />
                </Button>
              )}
              {revelarSelectUrl === true && (
                <SelectUrl
                  setRevelarSelectUrl={setRevelarSelectUrl} //Hook para fechar a modal
                  vetorUrls={vetorUrls} //Login selecionado
                  setSpinAtivo={setSpinAtivo} // para fechar o spinner
                  setDsUrl={setDsUrl} //Nome do url
                  phUrl={phUrl} //Placeholder para mostrar o valor original em caso de edição
                  dsUrl={dsUrl}
                />
              )}
            </Form.Group>
          </Col>
        </Form.Row>

        <>
          <div className="container-calendario-detalhemento-linha">
            <Historico
              imputCustomisado="De:"
              data={dataInicio}
              setData={setDataInicio}
              dataMax={dataMax}
              dataMin={dataMin}
            />
          </div>
          <div className="container-calendario-detalhemento-linha">
            <Historico
              imputCustomisado="Até:"
              data={dataFinal}
              setData={setDataFinal}
              dataMax={dataMax}
              dataMin={dataMin}
            />
          </div>
        </>

        <ButtonToolbar className="bt-barra-clickstream">
          <Button
            disable={desativarBotao}
            type="submit"
            className="botao-confirmar botao-confirmar-clickstream"
            variant="success"
            size="sm"
          >
            <div className="bg-color">Imprimir Clickstream</div>
          </Button>
        </ButtonToolbar>

        {spinAtivo && (
          <div className="componente-spinner-overlay">
            <div className="componente-spinner-container">
              <SpinerVeye />
            </div>
          </div>
        )}

        {/* <Graph
                    id="graph-id" // id is mandatory, if no id is defined rd3g will throw an error
                    data={graficoRotas}
                    config={myConfig} 
                />*/}
      </div>
    </Form>
  )
}

// https://www.npmjs.com/package/react-d3-graph

// https://dzone.com/articles/getting-started-with-regraph-the-graph-visualizati
// https://cambridge-intelligence.com/regraph/

/*const [graficoRotas, setGraficoRotas] = useState({
        nodes: [{ id: "Harry" }, { id: "Sally" }, { id: "Alice" }],
        links: [{ source: "Harry", target: "Sally" }, { source: "Harry", target: "Alice" }],
    })
    // the graph configuration, you only need to pass down properties
    // that you want to override, otherwise default ones will be used
    const myConfig = {
        "directed": true,
        nodeHighlightBehavior: true,
        node: {
            color: "lightgreen",
            size: 120,
            highlightStrokeColor: "blue",
        },
        link: {
            highlightColor: "lightblue",
        },
    };*/

/*
            const clickstreamDados = resultado.data.dados
            let arrayAuxiliarLogin = clickstreamDados.map(function(item) {return item.email}) // cria um array de emails
            // TRABALHO DOS DADOS PARA SEREM SALVOS NO LOCALSOTRAGE 
            // Organização dos dados de login
            let listaLogins = []
            listaLogins[0] = arrayAuxiliarLogin
            let j = 0
            let cont = 0
            for (j = 0; j < arrayAuxiliarLogin.length; j++) { // Cria um array sem email repetidos
                if(listaLogins.indexOf(arrayAuxiliarLogin[j]) === -1){
                    listaLogins[cont++] = arrayAuxiliarLogin[j]
                }
            }
            // ******** 
            // organização dos dados de URL, rotas e hora criada
            let dadosClick = listaLogins.map(item => ({ login: item })); // Passa o array de emails para o array de objetos
            let urlS = []
            let rotasVetor = [] // MAPA
            let horaVetor = [] // MAPA
            cont = 0
            let aux = 0
            let auxiliar = []
            j = 0
            for (let i = 0; i < dadosClick.length; i++) {
                for(j = 0; j < clickstreamDados.length; j++) {
                    if(dadosClick[i].login === clickstreamDados[j].email) {
                        rotasVetor[cont] = clickstreamDados[j].rota // MAPA
                        horaVetor[cont] = clickstreamDados[j].createdAt // MAPA
                        urlS[cont++] = clickstreamDados[j].url
                    }
                }
                auxiliar = []
                aux = 0
                auxiliar[0] = urlS[0]
                for (j = 0; j < urlS.length; j++) { 
                    if(auxiliar.indexOf(urlS[j]) == -1){
                        auxiliar[++aux] = urlS[j] // Cria um array sem urlS repetidos
                    }
                }
                dadosClick[i].URLs = auxiliar
                urlS = []
                cont = 0
            }

            // Criação dos nodos do mapa
            aux = 0
            let nodesVetor = []
            nodesVetor[0] = rotasVetor[0]
            for (aux = 1; aux < rotasVetor.length; aux++) { // Cria um array sem rotas repetidas
                if(rotasVetor[aux - 1] != rotasVetor[aux]){
                    nodesVetor[++cont] = rotasVetor[aux]
                }
            }
            
            let nodes = []
            let source = ''
            let target = ''
            let links = []
            nodes = nodesVetor.map(item => ({ id: item })); // Cria a variável dos nodos do mapa
            for (aux = 0; aux < nodesVetor.length - 1; aux++) { // Loop para criar a cariável de links do mapa
                source = nodesVetor[aux]
                target = nodesVetor[aux+1]
                links[aux] = {source, target}
                
            }
            let dadosGraficoRotas = { nodes, links}
            setGraficoRotas(dadosGraficoRotas)
            */
