export default function ConversorBytes(size, fromUnit, toUnit) {
  // ex de chamada: ConversorBytes(10000, 'MB', 'GB') -> '9.77'
  const units = ['B', 'KB', 'MB', 'GB', 'TB']
  const from = units.indexOf(fromUnit.toUpperCase())
  const to = units.indexOf(toUnit.toUpperCase())
  const BASE_SIZE = 1000
  let result = 0

  if (from < 0 || to < 0) {
    return (result = 'Erro no tamanho dos arquivos!')
  }

  result =
    from < to
      ? size / BASE_SIZE ** (to - from)
      : size * BASE_SIZE ** (from - to)
  return result.toFixed(2)
}
