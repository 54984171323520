const cor = {
  preto: '#000000',
  preto2: '#1C1E1D',
  branco: '#FFFFFF',
  verde: '#00A650',
  verdeClaro: '#36D896',
  cinza: '#BCC1BF',
  cinzaEscuro: '#505050',
  cinza3: '#ECF0EE',
  amarelo: '#FDCB62',
  laranja: '#FC5F0E',
  vermelho: '#CD2D37',
  azul: '#1F8EFA',
  vermelho2: '#FF5F58',
  roxo: '#640EDF',
  ciano: '#1FDFD9',
  violeta: '#B947DB',
  rosa: '#F02E7A'
}

const DashArrayCoresDefault = [
  cor.amarelo,
  cor.laranja,
  cor.vermelho,
  cor.azul,
  cor.vermelho2,
  cor.roxo,
  cor.ciano,
  cor.violeta,
  cor.rosa
]

const handleGetColorByIdx = (arrayCores, idx) => {
  let i = idx
  const len = arrayCores.length
  if (i >= len) i = i % len
  return arrayCores[i]
}

export { cor as corDash, DashArrayCoresDefault, handleGetColorByIdx }

