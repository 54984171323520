import { createGlobalStyle } from "styled-components";
import {
  scroll,
  setaPassarCalendarioDireita,
  setaPassarCalendarioEsquerda,
} from "../utils/alternanciaIcones";

export const EstilosGlobais = createGlobalStyle`
    .rc-tooltip-arrow {
        border-bottom-color: #5b605d !important;
        top: 4px !important;
    }

    .grecaptcha-badge { visibility: hidden; } 

    .label-verde-campo-demonstrativo{
        color:  ${({ theme }) => theme.cor11}!important;
    }

    .container-tabela-modal {
        max-height: 48vh!important;
    }

    .container-modal-cadastro{
        background-color: ${({ theme }) => theme.cor23}!important;
    }

    .titulo-modal-cadastro{
        color:  ${({ theme }) => theme.cor9}!important;
    }


    .label-campo-modal-cadastro{
        color:  ${({ theme }) => theme.cor9}!important;
    }

    .campo-modal-cadastro{
        color:  ${({ theme }) => theme.cor9}!important;
        background-color: ${({ theme }) => theme.cor27}!important;
    }

    .campo-modal-cadastro:hover, .campo-modal-cadastro:active, .campo-modal-cadastro:focus{
        color:  ${({ theme }) => theme.cor9}!important;
        background-color: ${({ theme }) => theme.cor27}!important;
    }

    .campo-modal-cadastro:disabled{
        color:  ${({ theme }) => theme.cor7}!important;
        background-color: ${({ theme }) => theme.cor27}!important;
    }

    .componente-calendario-campo{
        color:  ${({ theme }) => theme.cor9}!important;
        background-color: ${({ theme }) => theme.cor27}!important;
    }

    .componente-calendario-campo:hover, .componente-calendario-campo:active, .componente-calendario-campo:focus{
        color:  ${({ theme }) => theme.cor9}!important;
        background-color: ${({ theme }) => theme.cor27}!important;
    }

    .componente-calendario-campo:disabled{
        color:  ${({ theme }) => theme.cor7}!important;
        background-color: ${({ theme }) => theme.cor27}!important;
    }

    .icone-cabecalho-modal-cadastro{
        background-color: ${({ theme }) => theme.cor11}!important;
    }

    .icone-botao-fechar-modal-cadastro path{
        fill: ${({ theme }) => theme.cor12}!important;
    }

    .icone-botao-fechar-modal-cadastro:hover path{
        fill: ${({ theme }) => theme.cor12}!important;
    }

    .icone-botao-fechar-modal-cadastro:active path{
        fill: ${({ theme }) => theme.cor12}!important;
    }

    .componente-calendario-icone path{
        fill: ${({ theme }) => theme.cor11}!important;
    }

    .componente-calendario-icone:hover path{
        fill: ${({ theme }) => theme.cor11}!important;
    }

    .componente-calendario-icone:active path{
        fill: ${({ theme }) => theme.cor11}!important;
    }

    .cadastro-importar-linhas-botao-adicionar-servico{
        border: 0.256em ${({ theme }) => theme.cor11} solid;
    }

    .cadastro-importar-linhas-botao-adicionar-servico path{
        fill: ${({ theme }) => theme.cor11};
    }

    .cadastro-importar-linhas-botao-adicionar-servico:hover path{
        fill: ${({ theme }) => theme.cor11};
    }

    .cadastro-importar-linhas-botao-adicionar-servico:active path{
        fill: ${({ theme }) => theme.cor11};
    }

    .cadastro-importar-linhas-botao-adicionar-servico-desativado{
        border: 0.256em ${({ theme }) => theme.cor3} solid;
    }
        
    .cadastro-importar-linhas-botao-adicionar-servico-desativado path{
        fill: ${({ theme }) => theme.cor3};
    }

    .icon-ativo path{
        fill:  ${({ theme }) => theme.cor11}!important;
    }
    
    .icon-normal path{
        fill:  ${({ theme }) => theme.cor9}!important;
    }

    .icon-alerta path{
        fill:  ${({ theme }) => theme.cor12}!important;
    }

    .campo-demonstrativo{
        color:  ${({ theme }) => theme.cor9}!important;
        background-color: ${({ theme }) => theme.cor3}!important;
    }

    .label-erro-senha-erro{
        color: #CD2D37;
    }

    .botao-confirmar-login-veye{
        background-color: ${({ theme }) => theme.cor11};
        border: ${({ theme }) => theme.cor11} 0.1em solid;
    }

    .botao-confirmar-login-veye:hover{
        background-color: ${({ theme }) => theme.cor11};
        border: ${({ theme }) => theme.cor11} 0.1em solid;
    }

    .botao-confirmar-login-veye:active{
        background-color: ${({ theme }) => theme.cor11};
        border: ${({ theme }) => theme.cor11} 0.1em solid;
    }

    .label-recuperacao-senha-login-veye{
        color: ${({ theme }) => theme.cor11};
    }

    .label-recuperacao-senha-login-veye:hover{
        border-bottom: 0.1em solid ${({ theme }) => theme.cor11};
        color: ${({ theme }) => theme.cor11};
    }

    .label-recuperacao-senha-erro{
        color: ${({ theme }) => theme.cor12};
    }

    .titulo-conteudo-corpo-login-veye{
        color: ${({ theme }) => theme.cor11};
    }

    .titulo-conteudo-corpo-login-veye-erro{
        color: ${({ theme }) => theme.cor12};
    }

/******************** FUNDO DE MENUS E TELAS ********************/
    .titulo-modal-cadastro{
        color:  ${({ theme }) => theme.cor9}!important;
    }

    .label-campo-modal-cadastro{
        color:  ${({ theme }) => theme.cor9}!important;
    }

    .campo-modal-cadastro{
        color:  ${({ theme }) => theme.cor9}!important;
        background-color: ${({ theme }) => theme.cor3}!important;
    }

    .campo-modal-cadastro:hover, .campo-modal-cadastro:active, .campo-modal-cadastro:focus{
        color:  ${({ theme }) => theme.cor9}!important;
        background-color: ${({ theme }) => theme.cor3}!important;
    }

    .campo-modal-cadastro:disabled{
        color:  ${({ theme }) => theme.cor7}!important;
        background-color: ${({ theme }) => theme.cor3}!important;
    }

    .icone-cabecalho-modal-cadastro{
        background-color: ${({ theme }) => theme.cor11}!important;
    }

    .icone-botao-fechar-modal-cadastro path{
        fill: ${({ theme }) => theme.cor12}!important;
    }

    .icone-botao-fechar-modal-cadastro:hover path{
        fill: ${({ theme }) => theme.cor12}!important;
    }

    .icone-botao-fechar-modal-cadastro:active path{
        fill: ${({ theme }) => theme.cor12}!important;
    }

    .cadastro-fornecedor-botao-cancelar-cidade path{
        fill: ${({ theme }) => theme.cor12}!important;
    }

    .cadastro-fornecedor-botao-cancelar-cidade:hover path{
        fill: ${({ theme }) => theme.cor12}!important;
    }

    .cadastro-fornecedor-botao-cancelar-cidade:active path{
        fill: ${({ theme }) => theme.cor12}!important;
    }

    .cadastro-fornecedor-botao-adicionar-cidade path{
        fill: ${({ theme }) => theme.cor11}!important;
    }

    .cadastro-fornecedor-botao-adicionar-cidade:hover path{
        fill: ${({ theme }) => theme.cor11}!important;
    }

    .cadastro-fornecedor-botao-adicionar-cidade:active path{
        fill: ${({ theme }) => theme.cor11}!important;
    }

    .botao-secundario-sistema{
        background-color: transparent;
        color: ${({ theme }) => theme.cor11};
        border: 0.2em ${({ theme }) => theme.cor11} solid;
        transition: 0.5s;
    }

    .botao-primario-sistema{
        background-color: ${({ theme }) => theme.cor11};
        color: ${({ theme }) => theme.cor19};
        border: 0.2em ${({ theme }) => theme.cor11} solid;
        transition: 0.5s;
    }

    .botao-secundario-sistema:hover{
        background-color: transparent;
        color: ${({ theme }) => theme.cor11};
        border: 0.2em ${({ theme }) => theme.cor11} solid;
        transition: 0.5s;
    }

    .botao-primario-sistema:hover{
        background-color: ${({ theme }) => theme.cor11};
        color: ${({ theme }) => theme.cor19};
        border: 0.2em ${({ theme }) => theme.cor11} solid;
        transition: 0.5s;
    }

    .botao-secundario-sistema:active{
        background-color: transparent;
        color: ${({ theme }) => theme.cor11};
        border: 0.2em ${({ theme }) => theme.cor11} solid;
        transition: 0.5s;
    }

    .botao-primario-sistema:active{
        background-color: ${({ theme }) => theme.cor11};
        color: ${({ theme }) => theme.cor9};
        border: 0.2em ${({ theme }) => theme.cor11} solid;
        transition: 0.5s;
    }

    .botao-primario-sistema-desativado{
        background-color: ${({ theme }) => theme.cor8};
        color: ${({ theme }) => theme.cor9};
        border: 0.2em ${({ theme }) => theme.cor8} solid;
    }

    .select-search-input{
        color:  ${({ theme }) => theme.cor9}!important;
        background-color: ${({ theme }) => theme.cor3}!important;        
    }

    #container-tela{
        background-color: ${({ theme }) => theme.cor1}!important;
    }

    .fundo-cor-1{
        background-color: ${({ theme }) => theme.cor1}!important;
    }

    .fundo-cor-2{
        background-color: ${({ theme }) => theme.cor7}!important;
    }

    .fundo-cor-3{
        background-color: ${({ theme }) => theme.cor3}!important;
    }

    .fundo-cor-4{
        background-color: ${({ theme }) => theme.cor5}!important;
    }
    
    .fundo-cor-5{
        background-color: ${({ theme }) => theme.cor17}!important;
    }

    .fundo-cor-6{
        background-color: ${({ theme }) => theme.cor23}!important;
    }

    .fundo-cor-7{
        background-color: ${({ theme }) => theme.cor26}!important;
    }

    .fundo-cor-8{
        background-color: ${({ theme }) => theme.cor30}!important;
    }

    .fundo-cor-9{
        background-color: ${({ theme }) => theme.cor25}!important;
    }

    .fundo-cor-10{
        background-color: ${({ theme }) => theme.cor27}!important;
    }

    .fundo-cor-11{
        background-color: ${({ theme }) => theme.cor31}!important;
    }

    .fundo-cor-12{
        background-color: ${({ theme }) => theme.cor22}!important;
    }

    .fundo-cor-13{
        background-color: ${({ theme }) => theme.cor42}!important;
    }
    .fundo-cor-14{
        background-color: ${({ theme }) => theme.cor40}!important;
    }
    .fundo-cor-busca-verde{
        background-color: ${({ theme }) => theme.cor11}!important;
    }
    .fundo-cor-busca-verde:disabled{
        background-color: ${({ theme }) => theme.cor30}!important;
    }

/******************** FONTES E TEXTOS ********************/

.mais-filtros{
    border: 1px solid ${({ theme }) => theme.cor17}!important;
    background: ${({ theme }) => theme.cor17}!important;
}

    .fonte-cor-1{
        color:  ${({ theme }) => theme.cor9}!important;
    }

    .fonte-cor-2{
        color:  ${({ theme }) => theme.cor11}!important;
    }

    .fonte-cor-3{
        color:  ${({ theme }) => theme.cor12}!important;
    }

    .fonte-cor-4{
        color:  ${({ theme }) => theme.cor14}!important;
    }

    .fonte-cor-5{
        color:  ${({ theme }) => theme.cor19}!important;
    }

    .fonte-cor-6{
        color:  ${({ theme }) => theme.cor8}!important;
    }

    .fonte-cor-7{
        color:  ${({ theme }) => theme.cor35}!important;
    }

    .fonte-cor-8{
        color:  ${({ theme }) => theme.cor29}!important;
    }

    .fonte-cor-9{
        color:  ${({ theme }) => theme.cor35}!important;
    }

    .fonte-cor-10{
        color:  ${({ theme }) => theme.cor36}!important;
    }

    .fonte-cor-11{
        color:  ${({ theme }) => theme.cor37}!important;
    }

    .fonte-cor-12{
        color:  ${({ theme }) => theme.cor38}!important;
    }

    .fonte-cor-13{
        color:  ${({ theme }) => theme.cor39}!important;
    }

    .fonte-cor-14{
        color:  ${({ theme }) => theme.cor40}!important;
    }

    .fonte-cor-15{
        color:  ${({ theme }) => theme.cor9}!important;
    }

    .fonte-cor-16{
        color:  ${({ theme }) => theme.cor41}!important;
    }

    .fonte-cor-17{
        color:  ${({ theme }) => theme.cor48}!important;
    }

    .fonte-cor-18{
        color:  ${({ theme }) => theme.cor49}!important;
    }

    .fonte-cor-19{
        color:  ${({ theme }) => theme.cor51}!important;
    }

    .fonte-cor-20{
      color:  ${({ theme }) => theme.cor54}!important;
    }
    

    .texto-input-customizado-calendario{
        color:  ${({ theme }) => theme.cor9}!important;
    }

    .texto-input-customizado-calendario-b{
        color:  ${({ theme }) => theme.cor9}!important;
    }

    .texto-input-customizado-calendario-solicitacao{
        color:  ${({ theme }) => theme.cor9}!important;
    }

    .fonte-barra-filtro{
        color:  ${({ theme }) => theme.cor46}!important;
        height: 18px;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.16px;
        flex: none;
        order: 0;
        flex-grow: 0;
    }

.filtrado-por{
    color:  ${({ theme }) => theme.cor46}!important; 
   }

   .barra-filtragem-caixa-fechar{
    color:  ${({ theme }) => theme.cor46}!important; 
   }
   
    .filtragem-caixa{
        border: 1px solid ${({ theme }) => theme.cor47}!important;
    }
    
/******************** CAMPOS DE INPUT ********************/
    .campo-texto-cor-1{
        color:  ${({ theme }) => theme.cor9}!important;
        background-color: ${({ theme }) => theme.cor3}!important;
    }

    .campo-texto-cor-1:hover, .campo-texto-cor-1:active, .campo-texto-cor-1:focus{
        color:  ${({ theme }) => theme.cor9}!important;
        background-color: ${({ theme }) => theme.cor3}!important;
    }

    .campo-texto-cor-1:disabled{
        color:  ${({ theme }) => theme.cor7}!important;
        background-color: ${({ theme }) => theme.cor3}!important;
    }

    .campo-texto-cor-2{
        color:  ${({ theme }) => theme.cor9}!important;
        background-color: ${({ theme }) => theme.cor5}!important;
    }

    .campo-texto-cor-2:hover, .campo-texto-cor-2:active, .campo-texto-cor-2:focus{
        color:  ${({ theme }) => theme.cor9}!important;
        background-color: ${({ theme }) => theme.cor5}!important;
    }

    .campo-texto-cor-2:disabled{
        color:  ${({ theme }) => theme.cor7}!important;
        background-color: ${({ theme }) => theme.cor5}!important;
        border-radius: 35px!important;
    }
    .campo-texto-disabled:disabled{
        color:  ${({ theme }) => theme.cor9}!important;
        background-color: ${({ theme }) => theme.cor27}!important;
        border-radius: 35px!important;
    }
    
    .sun-editor {
        border: 1px solid ${({ theme }) => theme.cor23}!important;
        background-color: ${({ theme }) => theme.cor27}!important;
        color: ${({ theme }) => theme.cor9}!important;
    }

    .sun-editor .se-toolbar{
        background-color: ${({ theme }) => theme.cor23}!important;
        outline: ${({ theme }) => theme.cor1}!important;
    }

    .sun-editor .se-wrapper .se-wrapper-wysiwyg {
        background-color: ${({ theme }) => theme.cor27}!important;
        color: ${({ theme }) => theme.cor9}!important;
        border-radius: 15px!important;
    }

    .sun-editor .se-resizing-bar {
        visibility: hidden!important
    }

    .sun-editor .se-btn-module-border {
        border: 1px solid transparent!important;
        border-radius: 35px!important;
    }

    .sun-editor .se-btn:enabled:focus, .sun-editor .se-btn:enabled:hover {
        background-color: ${({ theme }) => theme.cor7}!important;
        border-color: transparent!important;
        color: ${({ theme }) => theme.cor9}!important;
        outline: 0 none;
    }

    .sun-editor button {
        color: ${({ theme }) => theme.cor9}!important;
        background-color: ${({ theme }) => theme.cor27};
        border-radius: 35px!important;
    }

    .sun-editor .se-container{
        background-color: ${({ theme }) => theme.cor23}!important;
    }

    /******************** CAMPOS SELECT COM PESQUISA ********************/
    .css-3zoqlz-control{
        color:  ${({ theme }) => theme.cor9}!important;
        background-color: ${({ theme }) => theme.cor27}!important;
    }

    .css-yk16xz-control{
        color:  ${({ theme }) => theme.cor9}!important;
        background-color: ${({ theme }) => theme.cor27}!important;
    }

    .css-yk16xz-control:hover, .css-yk16xz-control:active, .css-yk16xz-control:focus{
        color:  ${({ theme }) => theme.cor9}!important;
    }

    .css-yk16xz-control:disabled{
        color:  ${({ theme }) => theme.cor7}!important;
        background-color: ${({ theme }) => theme.cor3}!important;
    }

    .css-1pahdxg-control{
        color:  ${({ theme }) => theme.cor9}!important;
        background-color: ${({ theme }) => theme.cor27}!important;
    }

    .css-1pahdxg-control:hover, .css-1pahdxg-control:active, .css-1pahdxg-control:focus{
        color:  ${({ theme }) => theme.cor9}!important;
    }

    .css-1pahdxg-control:disabled{
        color:  ${({ theme }) => theme.cor7}!important;
        background-color: ${({ theme }) => theme.cor3}!important;
    }
    
    .css-1wa3eu0-placeholder{
        color:  ${({ theme }) => theme.cor9}!important;
    }

    .css-1uccc91-singleValue{
        color:  ${({ theme }) => theme.cor9}!important;
    }

    .Selecione__value-container{
        color:  ${({ theme }) => theme.cor9}!important;
    }

    .Selecione__value-container--has-value{
         color:  ${({ theme }) => theme.cor9}!important;
    }

    .css-g1d714-ValueContainer{
        color:  ${({ theme }) => theme.cor9}!important; 
    }

    .css-b8ldur-Input{
        color:  ${({ theme }) => theme.cor9}!important; 
    }
    
    .Selecione__menu{
        background-color: ${({ theme }) => theme.cor3}!important;
        color:  ${({ theme }) => theme.cor9}!important;
    }

    .Selecione__option:hover, .Selecione__option:active, .Selecione__option:focus{
        background-color: ${({ theme }) => theme.cor27}!important;
        color:  ${({ theme }) => theme.cor9}!important;
    }

    .css-9gakcf-option{
        color:  ${({ theme }) => theme.cor9}!important;
        background-color: ${({ theme }) => theme.cor3}!important;
    }

    .css-1n7v3ny-option{
        background-color: ${({ theme }) => theme.cor3}!important;
    }

    .css-gg45go-NoOptionsMessage{
        color:  ${({ theme }) => theme.cor27}!important;
    }

/******************** CAMPOS SELECT ********************/

    #novoNomeGrupo{
        -webkit-text-fill-color: ${({ theme }) => theme.cor9} !important;
        -webkit-box-shadow: 0 0 0 30px ${({ theme }) =>
          theme.cor26} inset !important;
    }

    input:-webkit-autofill,
    textarea:-webkit-autofill,
    select:-webkit-autofill {
        -webkit-text-fill-color: ${({ theme }) => theme.cor9} !important;
        -webkit-box-shadow: 0 0 0 30px ${({ theme }) =>
          theme.cor27} inset !important;
        -webkit-background-clip: text;
        caret-color: ${({ theme }) => theme.cor9} !important;
    }

    .drop-filtros-menu:hover{
        background-color: ${({ theme }) => theme.cor30}!important;
    }

    .campo-texto-cor-personalizado-transparente{
        color:  ${({ theme }) => theme.cor9}!important;
    }
    
    .campo-texto-placeholder{
      color: ${({ theme }) => theme.placeholder}!important;
      background-color: ${({ theme }) => theme.cor27}!important;
    }

    .campo-texto-cor-3{
        color:  ${({ theme }) => theme.cor9}!important;
        background-color: ${({ theme }) => theme.cor27}!important;
    }

    .campo-texto-cor-3:hover, .campo-texto-cor-3:active, .campo-texto-cor-3:focus{
        color:  ${({ theme }) => theme.cor9}!important;
    }

    .campo-texto-cor-3:disabled{
        color:  ${({ theme }) => theme.cor7}!important;
        background-color: ${({ theme }) => theme.cor27}!important;
    }

    .campo-select-triangulo-cor-1{
        border-bottom: 0.9375em solid ${({ theme }) => theme.cor27}!important;
    }

    .campo-texto-cor-5{
        color:  ${({ theme }) => theme.cor14}!important;
        background-color: ${({ theme }) => theme.cor43}!important;
    }
    

    .campo-texto-cor-5:hover, .campo-texto-cor-5:active, .campo-texto-cor-5:focus{
        color:  ${({ theme }) => theme.cor14}!important;
    }

    .campo-texto-cor-5:hover, .campo-texto-cor-5:active, .campo-texto-cor-5:focus{
        color:  ${({ theme }) => theme.cor14}!important;
    }

    .campo-select-triangulo-cor-2{
        border-bottom: 0.6em solid ${({ theme }) => theme.cor26}!important;
    }

    .campo-select-triangulo-menor-cor-2-novo{
        border-bottom: 0.6em solid ${({ theme }) => theme.cor26}!important;
    }

    .campo-select-triangulo-menor-cor-2{
      border-bottom: 0.6em solid ${({ theme }) => theme.cor26}!important;
    }

    .campo-select-triangulo-menor-cor-2-simcard{
        border-bottom: 0.6em solid ${({ theme }) => theme.cor26}!important;
    }
    
    .campo-select-triangulo-menor-cor-3{
        border-bottom: 0.6em solid ${({ theme }) => theme.cor27}!important;
    }

    .campo-select-triangulo-cor-3{
        border-bottom: 0.9375em solid ${({ theme }) => theme.cor17}!important;
    }

    .campo-select-triangulo-cor-4{
        border-bottom: 0.9375em solid ${({ theme }) => theme.cor11}!important;
    }


    .campo-select-divisor-cor-1{
        background-color: ${({ theme }) => theme.cor13}!important;
    }
    
    .campo-select-divisor-cor-3{
        background-color: ${({ theme }) => theme.cor59} !important;
    }

    .campo-select-opcao-1:hover{
        background-color: ${({ theme }) => theme.cor30}!important;
    }

    .campo-select-opcao-1:active{
        background-color: ${({ theme }) => theme.cor6}!important;
    }

    .campo-select-cor-2{
        color:  ${({ theme }) => theme.cor9}!important;
        background-color: ${({ theme }) => theme.cor5}!important;
    }

    .campo-select-cor-2:hover, .campo-select-cor-2:active, .campo-select-cor-2:focus{
        color:  ${({ theme }) => theme.cor9}!important;
        background-color: ${({ theme }) => theme.cor5}!important;
    }

    .campo-select-cor-2:disabled{
        color:  ${({ theme }) => theme.cor7}!important;
        background-color: ${({ theme }) => theme.cor5}!important;
    }
    
    .campo-select-triangulo-cor-5{
        border-bottom: 0.9375em solid ${({ theme }) => theme.cor17}!important;
    }

    .campo-select-corpo-cor-2{
        color:  ${({ theme }) => theme.cor9}!important;
        background-color: ${({ theme }) => theme.cor5}!important;
    }

    .campo-select-divisor-cor-2{
        background-color: ${({ theme }) => theme.cor7}!important;
    }

    .campo-select-opcao-2:hover{
        background-color: ${({ theme }) => theme.cor4}!important;
    }

    .campo-select-opcao-2:active{
        background-color: ${({ theme }) => theme.cor6}!important;
    }
    
    .campo-select-opcao-3:hover{
        background-color: ${({ theme }) => theme.cor4}!important;
    }

    .campo-select-opcao-3:active{
        background-color: ${({ theme }) => theme.cor6}!important;
    }
    
    .campo-select-opcao-4:hover{
        background-color: ${({ theme }) => theme.cor20}!important;
    }

    .campo-select-opcao-4:active{
        background-color: ${({ theme }) => theme.cor20}!important;
    }
    
    .campo-texto-faturas-contas-disabled:disabled{
        color:  ${({ theme }) => theme.cor9}!important;
    }

    .campo-texto-cor-4{
        border: 1px solid ${({ theme }) => theme.cor11}!important;
        color:  ${({ theme }) => theme.cor36}!important;
    }

    .campo-texto-cor-4:hover, .campo-texto-cor-4:active, .campo-texto-cor-4:focus{
        color:  ${({ theme }) => theme.cor36}!important;
    }

    .container-botao-revelar-detalhamento-valores{
        background-color: ${({ theme }) => theme.cor27}!important;
    }
    
    .container-detalhamento-valores-dropdown{
        background-color: ${({ theme }) => theme.cor27}!important;
    }

/************************ TABELAS ************************/
    .tabela-generica th {
        background-color: ${({ theme }) => theme.cor4};
        color: ${({ theme }) => theme.cor9};
    }

    .tabela-generica tr:nth-child(even) {
        background-color: ${({ theme }) => theme.cor15};
        color: ${({ theme }) => theme.cor9};
    }

    .tabela-generica tr:nth-child(odd) {
        background-color: ${({ theme }) => theme.cor16};
        color: ${({ theme }) => theme.cor9};
    }

    .tabela-generica tr:focus {
        background-color: ${({ theme }) => theme.cor10};
        color: ${({ theme }) => theme.cor9};
    }

    .tabela-generica tr:hover {
        background-color: ${({ theme }) => theme.cor7};
        color: ${({ theme }) => theme.cor9};
    }

    .tabela-generica tr.tabela-generica-linha-selecionada:nth-child(even) {
        background-color: ${({ theme }) => theme.cor10}!important;
        color: ${({ theme }) => theme.cor37};
    }

    .tabela-generica tr.tabela-generica-linha-selecionada:nth-child(odd) {
        background-color: ${({ theme }) => theme.cor38}!important;
        color: ${({ theme }) => theme.cor37};
    }

    .checkmark-checkbox-tabela-generica-selecao-parque {
        background-color: ${({ theme }) => theme.cor1}!important;
    }

    .container-checkbox-tabela-generica-selecao-parque:hover input ~ .checkmark-checkbox-tabela-generica-selecao-parque {
        background-color: ${({ theme }) => theme.cor1}!important;
    }

    .container-checkbox-tabela-generica-selecao-parque input:checked ~ .checkmark-checkbox-tabela-generica-selecao-parque {
        background-color: ${({ theme }) => theme.cor1}!important;
    }

    .checkmark-checkbox-tabela-generica-selecao-pagina {
        background-color: ${({ theme }) => theme.cor1}!important;
    }

    .container-checkbox-tabela-generica-selecao-pagina:hover input ~ .checkmark-checkbox-tabela-generica-selecao-pagina {
        background-color: ${({ theme }) => theme.cor1}!important;
    }

    .container-checkbox-tabela-generica-selecao-pagina input:checked ~ .checkmark-checkbox-tabela-generica-selecao-pagina {
        background-color: ${({ theme }) => theme.cor1}!important;
    }

    .checkmark-checkbox-tabela-generica-selecao-linha {
        background-color: ${({ theme }) => theme.cor1}!important;
        border: solid 0.125em ${({ theme }) => theme.cor1}!important;
    }

    .container-checkbox-tabela-generica-selecao-linha:hover input ~ .checkmark-checkbox-tabela-generica-selecao-linha {
        background-color: ${({ theme }) => theme.cor1}!important;
    }

    .container-checkbox-tabela-generica-selecao-linha input:checked ~ .checkmark-checkbox-tabela-generica-selecao-linha {
        background-color: ${({ theme }) => theme.cor1}!important;
    }

    .checkmark-radio-tabela-generica-selecao-linha {
        background-color: ${({ theme }) => theme.cor1}!important;
    }

    .container-radio-tabela-generica-selecao-linha:hover input ~ .checkmark-radio-tabela-generica-selecao-linha {
        background-color: ${({ theme }) => theme.cor1}!important;
    }

    .container-radio-tabela-generica-selecao-linha input:checked ~ .checkmark-radio-tabela-generica-selecao-linha {
        background-color: ${({ theme }) => theme.cor1}!important;
    }
    
    .tabela tr:nth-child(even) {
        background-color: ${({ theme }) => theme.cor15}!important;
    }

    .tabela tr:nth-child(odd) {
        background-color: ${({ theme }) => theme.cor16}!important;
    }

    .tabela tr:focus {
        background-color: ${({ theme }) => theme.cor10}!important;
    }

    .tabela tr:hover{
        background-color: ${({ theme }) => theme.cor7}!important;
    }

    .tabela th{
        background-color: ${({ theme }) => theme.cor4};
        color: ${({ theme }) => theme.cor9}!important;
    }
    //TABELA NOVA
        
    .tabela-nova tr:nth-child(even) {
        background-color: ${({ theme }) => theme.cor52}!important;
    }

    .tabela-nova tr:nth-child(odd) {
        background-color: ${({ theme }) => theme.cor53}!important;
    }

    .tabela-nova tr:focus {
        background-color: ${({ theme }) => theme.cor10}!important;
    }

    .tabela-nova tr:hover{
        background-color: ${({ theme }) => theme.cor7}!important;
    }

    .tabela-nova th{
        background-color: ${({ theme }) => theme.cor52};
        color: ${({ theme }) => theme.cor54}!important;
        font-weight:600 !important;
        font-size: 14px;
    }

    .tabela-financeiro-bloqueado tr:nth-child(even) {
        background-color: ${({ theme }) => theme.cor32}!important;
    }

    .tabela-financeiro-bloqueado tr:nth-child(odd) {
        background-color: ${({ theme }) => theme.cor33}!important;
    }

    .tabela-financeiro-bloqueado tr:focus {
        background-color: ${({ theme }) => theme.cor10}!important;
    }

    .tabela-financeiro-bloqueado tr:hover{
        background-color: ${({ theme }) => theme.cor7}!important;
    }

    .tabela-financeiro-bloqueado th{
        background-color: ${({ theme }) => theme.cor34};
        color: ${({ theme }) => theme.cor9}!important;
    }

    .tabela-transparente-modais tr:nth-child(even) {
        background-color: ${({ theme }) => theme.cor26}!important;
        border-bottom: 1px solid ${({ theme }) => theme.cor1}!important;
    }

    .tabela-transparente-modais tr:nth-child(odd) {
         background-color: ${({ theme }) => theme.cor26}!important;
        border-bottom: 1px solid ${({ theme }) => theme.cor1}!important;
    }

    .tabela-transparente-modais tr:focus {
         background-color: ${({ theme }) => theme.cor26}!important;
    }

    .tabela-transparente-modais tr:hover{
         background-color: ${({ theme }) => theme.cor26}!important;
    }

    .tabela-transparente-modais th{
        background-color: ${({ theme }) => theme.cor26}!important;
        color: ${({ theme }) => theme.cor9}!important;  
    }

    .react-bootstrap-table .tabela-transparente-modais thead tr th:first-child{
        border-radius: 0!important;
    }

    .tabela-transparente tr:nth-child(even) {
        background-color: transparent!important;
    }

    .tabela-transparente tr:nth-child(odd) {
        background-color: transparent!important;
    }

    .tabela-transparente tr:focus {
        background-color: transparent!important;
    }

    .tabela-transparente tr:hover{
        background-color: transparent!important;
    }

    .tabela-transparente th{
        background-color: transparent!important;
        color: ${({ theme }) => theme.cor9}!important;
    }

    .tabela-transparente-header-fixo tr:nth-child(even) {
        background-color: ${({ theme }) => theme.cor26}!important;
    }

    .tabela-transparente-header-fixo tr:nth-child(odd) {
        background-color: ${({ theme }) => theme.cor26}!important;
    }

    .tabela-transparente-header-fixo tr:focus {
        background-color: ${({ theme }) => theme.cor26}!important;
    }

    .tabela-transparente-header-fixo tr:hover{
        background-color: ${({ theme }) => theme.cor26}!important;
    }

    .tabela-transparente-header-fixo th{
        background-color: ${({ theme }) => theme.cor26}!important;
        color: ${({ theme }) => theme.cor9}!important;
    }

    .tabela-transparente-header-fixo-solicitacao tr:nth-child(even) {
        background-color: ${({ theme }) => theme.cor26}!important;
    }

    .tabela-transparente-header-fixo-solicitacao tr:nth-child(odd) {
        background-color: ${({ theme }) => theme.cor26}!important;
    }

    .tabela-transparente-header-fixo-solicitacao tr:focus {
        background-color: ${({ theme }) => theme.cor26}!important;
    }

    .tabela-transparente-header-fixo-solicitacao tr:hover{
        background-color: ${({ theme }) => theme.cor26}!important;
    }

    .tabela-transparente-header-fixo-solicitacao th{
        background-color: ${({ theme }) => theme.cor26}!important;
        color: ${({ theme }) => theme.cor9}!important;
    }

    .botao-paginacao-primeira-ultima-tabela{
        background-color: transparent!important;
        color: ${({ theme }) => theme.cor9};
    }

    .botao-navegacao-tabelas-dispostivos-ativo{
        background-color: ${({ theme }) => theme.cor17}!important;
    }
    
/************************ SWITCHES ************************/
    .flipswitch-cor-1, .flipswitch-cor-1:before, flipswitch-cor-1:after{
        background-color: ${({ theme }) => theme.cor27}!important;  
    }

    .flipswitch-cor-1-cmp, .flipswitch-cor-1-cmp:before, flipswitch-cor-1:after{
        background-color: ${({ theme }) => theme.cor50}!important;  
    }

    .flipswitch-cor-1-cmp-habilitado, .flipswitch-cor-1-cmp-habilitado:before, flipswitch-cor-1:after{
        background-color: #8fe1b3!important;  
    }

    .flipswitch-cor-1-cmp-desabilitado, .flipswitch-cor-1-cmp-desabilitado:before, flipswitch-cor-1:after{
        background-color: ${({ theme }) => theme.cor51}!important;  
    }

    .flipswitch-cor-2, .flipswitch-cor-2:before, flipswitch-cor-2:after{
        background-color: ${({ theme }) => theme.cor26}!important;  
    }

    .flipswitch-cor-3, .flipswitch-cor-3:before, flipswitch-cor-3:after{
        background-color: ${({ theme }) => theme.cor1}!important;  
    }

    .flipswitch-medium-inner, .flipswitch-medium-inner:after, .flipswitch-medium-inner:before{
        background-color: ${({ theme }) => theme.cor1}!important;
    }

    .flipswitch-tiny-label{
        background-color: ${({ theme }) => theme.cor1}!important;
    }

/************************ RADIO BUTTONS ************************/
    .radio-button-cor-1{
        background-color: ${({ theme }) => theme.cor1} !important;
    }

    .radio-button-cor-2{
        background-color: ${({ theme }) => theme.cor5} !important;
    }

/************************ CHECKBOXES ************************/

    .label-checkbox-filtros-operadoras input:checked ~ .checkbox-filtros-operadoras{
        background-color: ${({ theme }) => theme.cor27};
    }

    .label-checkbox-filtros-operadoras-2 input:checked ~ .checkbox-filtros-operadoras-2, 
    .label-checkbox-filtros-status_migracao_plano input:checked ~ .checkbox-filtros-status_migracao_plano {
        background-color: ${({ theme }) => theme.cor43};
    }

    .checkbox-filtros-operadoras-2, .checkbox-filtros-status_migracao_plano {
        background-color: ${({ theme }) => theme.cor44};
        border-color: ${({ theme }) => theme.cor45}
    }


    .label-checkbox-simcard{
        background-color: ${({ theme }) => theme.cor1};
    }

    input[type=checkbox].input-checkbox-simcard:checked + label.label-checkbox-simcard{
        background-image: ${({ theme }) => theme.checkedImage};
    }

    .label-checkbox-ativacao{
        background-color: ${({ theme }) => theme.cor1}!important;
        border: 0.0625em solid ${({ theme }) => theme.cor11}!important;
    }

    .label-checkbox-header-simcard{
        background-color: ${({ theme }) => theme.cor1}!important;
        border: 0.0625em solid ${({ theme }) => theme.cor11}!important;
    }

    .chekmark-checkbox-item-select{
        background-color: ${({ theme }) => theme.cor1}!important;
    }
    
    .container-checkbox-item-select:hover input ~ .chekmark-checkbox-item-select {
        background-color: ${({ theme }) => theme.cor1}!important;
    }
    
    .container-checkbox-item-select input:checked ~ .chekmark-checkbox-item-select {
        background-color: ${({ theme }) => theme.cor1}!important;
    }

    .container-checkbox-item-select .chekmark-checkbox-item-select:after {
        left: 7px!important;
        top: 2px!important;
        width: 7px!important;
        height: 12px!important;
        border: solid ${({ theme }) => theme.cor9}!important;
        border-width: 0 3px 3px 0!important;
        -webkit-transform: rotate(45deg)!important;
        -ms-transform: rotate(45deg)!important;
        transform: rotate(45deg)!important;
    }

    .label-checkbox-sms-unchecked {
        border: 2px solid ${({ theme }) => theme.cor9}!important;
        background-color: ${({ theme }) => theme.cor23}!important;
    }

/************************ CALENDARIOS ************************/
    .react-datepicker__header{
        background-color: ${({ theme }) => theme.cor27}!important;
    }

    .react-datepicker{
        background-color: ${({ theme }) => theme.cor27}!important;
    }

    .react-datepicker__current-month, .react-datepicker__day-name, .react-datepicker-time__caption{
        color: ${({ theme }) => theme.cor9}!important;
    }

    .react-datepicker__day{
        color: ${({ theme }) => theme.cor9}!important;
    }

    .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before{
        border-top-color: ${({ theme }) => theme.cor27}!important;
        border-bottom-color: ${({ theme }) => theme.cor27}!important;
    }

    .react-datepicker__day-name{
        color: ${({ theme }) => theme.cor11}!important;
    }

    .react-datepicker-time__input{
        background-color: ${({ theme }) => theme.cor23}!important;
        color:  ${({ theme }) => theme.cor9}!important;
    }

    .react-datepicker__day--disabled{
        color:  ${({ theme }) => theme.cor7}!important;
    }

    .react-datepicker__navigation--next{
        background: url(${setaPassarCalendarioDireita})no-repeat!important;
    }

    .react-datepicker__navigation--previous{
        background: url(${setaPassarCalendarioEsquerda})no-repeat!important;
    }

    .calendario-btn-mostrar:hover{
        background-color: ${({ theme }) => theme.cor5}!important; 
    }

    .componente-calendario-campo{
        background-color: ${({ theme }) => theme.cor27}!important;
        color:  ${({ theme }) => theme.cor9}!important;
    }

    .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
        border-bottom-color: ${({ theme }) => theme.cor55}!important;
    }

    .react-datepicker__month-container {
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12)!important;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14)!important;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2)!important
    }

    .react-datepicker__month react-datepicker__monthPicker {
        background: red!important;
    }
    
    .react-datepicker-year-header,
    .react-datepicker__month-text {
        // background-color: ${({ theme }) => theme.cor55}!important;
        color:  ${({ theme }) => theme.cor54}!important;
    }

    .react-datepicker__month-text {
        font-weight: 700;
        font-size: 14px;
    }

    .react-datepicker__month--disabled {
        color: ${({ theme }) => theme.cor56}!important;
        font-weight: normal;
    }

    .react-datepicker__month-text:hover {
        color: black!important;
    }

    .react-datepicker__day--today{
        color: ${({ theme }) => theme.cor58}!important;
    }

    .react-datepicker__month--selected,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range {
        background-color: ${({ theme }) => theme.cor57}!important;
        color: ${({ theme }) => theme.cor9}!important;
    } 

/************************ TIMELINE ************************/
    /* .timeline-barras-espaco-de-tempo-cor{
        border-top: 1px solid ${({ theme }) => theme.cor21};
        border-left: 1px solid ${({ theme }) => theme.cor21};
    }
    
    .timeline-barras-espaco-de-tempo-cor-ultimo{
        border-top: 1px solid ${({ theme }) => theme.cor21};
        border-left: 1px solid ${({ theme }) => theme.cor21};
        border-right: 1px solid ${({ theme }) => theme.cor21};
    } */

    .timeline-tipo-espaco-de-tempo-cor{
        border-left: 1px solid ${({ theme }) => theme.cor21};
        border-bottom: 1px solid ${({ theme }) => theme.cor21};
    }

    .timeline-tipo-espaco-de-tempo-cor-ultimo{
        border-left: 1px solid ${({ theme }) => theme.cor21};
        border-right: 1px solid ${({ theme }) => theme.cor21};
        border-bottom: 1px solid ${({ theme }) => theme.cor21};
    }

    /* .timeline-tipo-espaco-de-tempo-cor-zoom{
        border-left: 1px solid ${({ theme }) => theme.cor21};
    }
    .timeline-tipo-espaco-de-tempo-cor-zoom-ultimo{
        border-left: 1px solid ${({ theme }) => theme.cor21};
        border-right: 1px solid ${({ theme }) => theme.cor21};
    } */

    .timeline-tooltip-triangulo-cor{
        border-right: 0.7em solid  ${({ theme }) => theme.cor3}!important;
    }

/************************ RANGE SLIDER ************************/
    input[type=range]::-webkit-slider-thumb,
    input[type=range]::-webkit-slider-thumb:focus,
    input[type=range]::-webkit-slider-thumb:active,
    input[type=range]::-webkit-slider-thumb:not(:focus) {
        box-shadow: 350px 0px 0px 340px ${({ theme }) =>
          theme.cor27} !important;
    }

    input[type=range]::-webkit-slider-thumb,
    input[type=range]::-webkit-slider-thumb:focus,
    input[type=range]::-webkit-slider-thumb:active,
    input[type=range]::-webkit-slider-thumb:not(:focus){
        background-color: ${({ theme }) => theme.cor3} !important;
        border: 2px solid;
        border-color:${({ theme }) => theme.cor18} !important;
    }
    
    input[type=range]::-moz-slider-thumb,
    input[type=range]::-moz-slider-thumb:focus,
    input[type=range]::-moz-slider-thumb:active,
    input[type=range]::-moz-slider-thumb:not(:focus) {
        box-shadow: 350px 0px 0px 340px ${({ theme }) =>
          theme.cor15} !important;
    }

    input[type=range]::-moz-slider-thumb,
    input[type=range]::-moz-slider-thumb:focus,
    input[type=range]::-moz-slider-thumb:active,
    input[type=range]::-moz-slider-thumb:not(:focus){
        background-color: ${({ theme }) => theme.cor3} !important;
        border: 2px solid;
        border-color:${({ theme }) => theme.cor18} !important;
    }
    
    input[type=range]::-ms-slider-thumb,
    input[type=range]::-ms-slider-thumb:focus,
    input[type=range]::-ms-slider-thumb:active,
    input[type=range]::-ms-slider-thumb:not(:focus) {
        box-shadow: 350px 0px 0px 340px ${({ theme }) =>
          theme.cor15} !important;
    }

    input[type=range]::-ms-slider-thumb,
    input[type=range]::-ms-slider-thumb:focus,
    input[type=range]::-ms-slider-thumb:active,
    input[type=range]::-ms-slider-thumb:not(:focus){
        background-color: ${({ theme }) => theme.cor3} !important;
        border: 2px solid;
        border-color:${({ theme }) => theme.cor18} !important;
    }

    ::-webkit-slider-thumb{
        background-image: url(${scroll}) !important;
        background-color: ${({ theme }) => theme.cor22};
    }

    ::-webkit-slider-thumb {
        background-image: url(${scroll}) !important;
        background-color: ${({ theme }) => theme.cor22};
    }

/************************ SCROLLBAR ************************/
    ::-webkit-scrollbar-thumb{
        background-image: url(${scroll});
        background-color: ${({ theme }) => theme.cor22};
    }

    ::-webkit-scrollbar-thumb:window-inactive {
        background-color: ${({ theme }) => theme.cor22};
    }

    ::-ms-thumb{
        background-image: url(${scroll}) !important;
        background-color: ${({ theme }) => theme.cor22};
    }

    ::-webkit-scrollbar-corner {
        background: rgba(0,0,0,0)!important;
    }

/************************ DROP ACOES ************************/
    .item-drop-acoes-hover:hover, .item-drop-acoes-hover:active, .item-drop-acoes-hover:focus{
        color:  ${({ theme }) => theme.cor9}!important;
        background-color: ${({ theme }) => theme.cor30}!important;
    }

    .item-drop-acoes-hover:disabled{
        color:  ${({ theme }) => theme.cor7}!important;
        background-color: ${({ theme }) => theme.cor5}!important;
    }

    .drop-formato-preview-hover:hover, drop-formato-preview-hover:active, drop-formato-preview-hover:focus{
        color:  ${({ theme }) => theme.cor9}!important;
        background-color: ${({ theme }) => theme.cor26}!important;
    }
    
/************************ DROP CONCILIACAO ************************/

    .drop-relatorio-conciliacao{
        background-color: ${({ theme }) => theme.cor27}!important;
    }    
    .drop-relatorio-conciliacao-hover:hover, drop-relatorio-conciliacao-hover:active, drop-relatorio-conciliacao-hover:focus{
        color:  ${({ theme }) => theme.cor9}!important;
        background-color: ${({ theme }) => theme.cor26}!important;
    } 

/************************ OUTROS ************************/
    .triangulo-mensagem-recebida-cor{
        border-left: 0.9375em ${({ theme }) => theme.cor17} solid!important;
    }

    .barra-progresso-container{
        background-color: ${({ theme }) => theme.cor1}!important;
    }

/************************ MAPAS ************************/
    .leaflet-popup-content-wrapper{
        background-color: ${({ theme }) => theme.cor16}!important;
    }
    
    .leaflet-popup-tip{
        background-color: ${({ theme }) => theme.cor16}!important;
    }
  
    .gm-style .gm-style-iw-c, .gm-style-iw-tc::after{
        background-color: ${({ theme }) => theme.cor16}!important;
        color: ${({ theme }) => theme.cor9};
    }
    
    button.gm-ui-hover-effect{
        width: 20px !important;
        height: 30px !important;

        span{
            margin: 0 !important;
        }
    }

    .gm-style .gm-style-iw-t::after {
        background: ${({ theme }) => theme.cor16}!important;
    }

    .triangulo-dropdown-configuracao-cabecalho-sistema{
        border-bottom: 15px solid ${({ theme }) => theme.cor27}!important;
    }

    .select-pav-triangulo-grupo{
        border-bottom: 12px solid ${({ theme }) => theme.cor3}!important;
    }   
    
    .bt-dropdown-mapa-cabecalho{
        background-color: ${({ theme }) => theme.cor3};
    }

    .bt-barra-pesquisa-pesquisar{
        background-color: ${({ theme }) => theme.cor3}!important;  
    }

    .bt-barra-pesquisa-limpar{
        background-color: ${({ theme }) => theme.cor3}!important; 
    }

    .bt-barra-pesquisa-dropdown{
        background-color: ${({ theme }) => theme.cor3}!important; 
    }

    .bt-dropdown-conta{
        background-color: ${({ theme }) => theme.cor3};
    }

    .dropdown-ativado-cor{
        background-color: ${({ theme }) => theme.cor3}!important;
        color:  ${({ theme }) => theme.cor11}!important;
    }

    .menu-expandido-item:hover{
        background-color: ${({ theme }) => theme.cor3}!important;
    }

    .menu-expandido-item-filho{
        background-color: ${({ theme }) => theme.cor3}!important;
    }

    .menu-expandido-item-filho-2{
        background-color: ${({ theme }) => theme.cor24}!important;
    }

    .menu-expandido-item-filho-2:hover{
        background-color: ${({ theme }) => theme.cor25}!important;
    }

    .dropdown-ativado-tela{
        background-color: ${({ theme }) => theme.cor25}!important;
    }

    .dropdown-ativado-tela:hover{
        background-color: ${({ theme }) => theme.cor25}!important;
    }

    .menu-expandido-item-filho-3{
        background-color: ${({ theme }) => theme.cor25}!important;
        -webkit-box-shadow: inset 1.6em 0px 0px 0px ${({ theme }) =>
          theme.cor11}!important;
        -moz-box-shadow: inset 1.6em 0px 0px 0px ${({ theme }) =>
          theme.cor11}!important;
        box-shadow: inset 1.6em 0px 0px 0px ${({ theme }) =>
          theme.cor11}!important;
    }

    .menu-expandido-item-filho-3:hover{
        background-color: ${({ theme }) => theme.cor24}!important;
    }

    .menu-expandido-item-filho-borda{
        -webkit-box-shadow: inset 1.6em 0px 0px 0px ${({ theme }) =>
          theme.cor11}!important;
        -moz-box-shadow: inset 1.6em 0px 0px 0px ${({ theme }) =>
          theme.cor11}!important;
        box-shadow: inset 1.6em 0px 0px 0px ${({ theme }) =>
          theme.cor11}!important;
    }

    .container-switches-exibir-colunas-2:hover{
        background-color: ${({ theme }) => theme.cor30}!important;
    }
    
    .detalhamento-hover:hover{
        background-color: ${({ theme }) => theme.cor3}!important;
    }

    .divisoria-switch-exibir-colunas{
        border-bottom: 1px solid ${({ theme }) => theme.cor1}!important;
    }

    .sun-editor .se-dialog .se-dialog-inner .se-dialog-content{
        background-color: ${({ theme }) => theme.cor23}!important;
        font-family: RobotoRegular!important;
        border-radius: 0.65em;
    }

    .sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-input-form {
        display: block;
        height: 2.3em!important;
        width: 100%!important;
        font-size: 0.875em!important;
        font-family: RobotoRegular!important;
        line-height: 1.7em;
        border: 0em!important;
        box-shadow: none!important;
        border-radius: 5em!important;
        display: -webkit-inline-box!important;
        color:  ${({ theme }) => theme.cor9}!important;
        background-color: ${({ theme }) => theme.cor27}!important;
    }

    .sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-dialog-form-files .se-dialog-files-edge-button {
        flex: auto;
        opacity: .8;
        border: none;
    }

    .sun-editor .se-dialog .se-dialog-inner .se-dialog-btn-revert {
        border: none;
    }

    .sun-editor .se-dialog-tabs button {
        border: none;
        font-family: RobotoRegular!important;
        float: left;
        outline: none;
        padding: 2px 13px;
        transition: .3s;
        width: 7em;
        margin-left: 1em;
        height: 2em!important;
    }

    .sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-input-control {
        display: inline-block;
        font-family: RobotoRegular!important;
        width: 70px;
        height: 34px;
        font-size: 14px;
        text-align: center;
        line-height: 1.42857143;
        border-radius: 5em;
    }

    .sun-editor .se-dialog button, .sun-editor .se-dialog input, .sun-editor .se-dialog label{
        color:  ${({ theme }) => theme.cor9}!important;
    }

    input.se-input-form._se_image_alt{
        color:  ${({ theme }) => theme.cor9}!important;
        background-color: ${({ theme }) => theme.cor27}!important;
    }

    input.se-input-form.se-input-url {
        color:  ${({ theme }) => theme.cor9}!important;
        background-color: ${({ theme }) => theme.cor27}!important;
    }

    input.se-input-control._se_image_size_x{
        color:  ${({ theme }) => theme.cor9}!important;
        background-color: ${({ theme }) => theme.cor27}!important;
    }
    
    input.se-input-control._se_image_size_y{
        color:  ${({ theme }) => theme.cor9}!important;
        background-color: ${({ theme }) => theme.cor27}!important;
    }

    .imagem-fatura-fundo{
        border-color: ${({ theme }) => theme.cor9}!important;
    }

    .cor-texto-imagem-fatura{
        color: ${({ theme }) => theme.cor9}!important;
    }

    .codigo-barras-imagem-fatura-cor{
        background-color: ${({ theme }) => theme.cor9}!important;
    }

    .tituloLista-importar-arquivo{
        color: ${({ theme }) => theme.cor9}!important;
    }
    
    .listaNome-importar-arquivo{
        color: ${({ theme }) => theme.cor9}!important;
    }

    .lista-importar-arquivo {
        background-color: ${({ theme }) => theme.cor27}!important;
    }

/******************** FIM REFACTOR ********************//******************** FIM REFACTOR ********************/
/******************** FIM REFACTOR ********************//******************** FIM REFACTOR ********************/









    /*INPUT*/
    .campo-texto-falha-operadora, .campo-texto-falha-operadora:focus{
        background-color: ${({ theme }) => theme.menuPrincipal};
        color:  ${({ theme }) => theme.fonteCabecalho};
    }
    .campo-texto-cinza, .campo-texto-cinza:focus{
        background-color: ${({ theme }) => theme.campoTextoCinza}!important;
        color:  ${({ theme }) => theme.fonteCabecalho}!important;
    }
    .campo-texto-cinza::placeholder{
        color:  ${({ theme }) => theme.fonteCabecalho}!important;
    }
    .campo-texto-branco{
        background-color: ${({ theme }) => theme.bg};
        color:  ${({ theme }) => theme.fonteCabecalho};
        font-family: RobotoRegular;
        font-size: 14px;
        line-height: 16px;
        height: 32px!important;
        border: 0px!important;
        border-radius: 100px!important;
        box-shadow: none !important;
        text-align: center
    }
    .campo-texto-area, .campo-texto-area:focus{
        color: ${({ theme }) => theme.fonteCabecalho};
        background-color: ${({ theme }) =>
          theme.buscaFiltroAcoesScrollPaginacao};
    }
    .campo-texto-area::placeholder{
        color:  ${({ theme }) => theme.fonteCabecalho};
    }

    .campo-texto-mb{
        background-color: ${({ theme }) =>
          theme.buscaFiltroAcoesScrollPaginacao};
        color:  ${({ theme }) => theme.fonteCabecalho};
    }
    .campo-texto-mb:hover, .campo-texto-mb:focus, .campo-texto-mb:active{
        background-color: ${({ theme }) =>
          theme.buscaFiltroAcoesScrollPaginacao};
        color:  ${({ theme }) => theme.fonteCabecalho};
    }

    .cabecalho-backgroud{
        background-color: ${({ theme }) => theme.cabecalhoBackgroud};
    }
    .tela-background{
        background-color: ${({ theme }) => theme.backgroud};
    }
    .cabecalho-border{
        margin-top: 14px !important;
        margin-left: 15px !important;
        width: 145px !important;
        border-top: none !important;
        border: 0.5px solid ${({ theme }) => theme.backgroud} !important;
    }
    .bg-color{
        color: ${({ theme }) => theme.bg};
    }
    .fonte-icone-menu-selecionado::-moz-selection, .fonte-icone-menu-selecionado::selection{
        background-color: ${({ theme }) => theme.selecionadoFonteIconeMenu};
        color: ${({ theme }) => theme.selecionadoFonteIconeMenu};
    }
    .fonte-icone-menu-selecionado{
        background-color: ${({ theme }) => theme.selecionadoFonteIconeMenu};
    }
    .fonte-icone-menu{
        color: ${({ theme }) => theme.selecionadoFonteIconeMenu};
    }
    .bg-menu-selecionado::-moz-selection, bg-menu-selecionado::selection{
        background-color: ${({ theme }) => theme.cor3};
    }
    .busca-filtros-acoes-scroll-paginacao{
        background-color: ${({ theme }) =>
          theme.buscaFiltroAcoesScrollPaginacao};
    }
    .cabecalho-tabela{
        background-color: ${({ theme }) => theme.cabecalhoTabela};
    }
    .linha1-tabela{
        background-color: ${({ theme }) => theme.linha1Table};
    }
    .linha2-tabela{
        background-color: ${({ theme }) => theme.linha2Table};
    }
    .linha-selecionada::-moz-selection, linha-selecionada::selection{
        background-color: ${({ theme }) => theme.selecionadaLinha};
    }
    .mouse-hover:hover{
        background-color: ${({ theme }) => theme.cor7} !important;
    }
    .fonte-linha{
        color: ${({ theme }) => theme.fonteLinha};
    }
    .fonte-linha-hover:hover{
        color: ${({ theme }) => theme.hoverFonteLinha};
    }
    .fonte-linha-selecionada::-moz-selection,fonte-linha-selecionada::selection{
        color: ${({ theme }) => theme.selecionadoFonteLinha};
    }
    .barra-cosumo1{
        background-color: ${({ theme }) => theme.barraConsumo1};
    }
    .barra-cosumo2{
        background-color: ${({ theme }) => theme.barraConsumo2};
    }

    
    /*cabecalho*/
    .menu-dropdown-filtro{
        background-color: ${({ theme }) => theme.cinzaEscuroForte};
    }
    .bt-dropdown-mapa-cabecalho:hover, .bt-dropdown-mapa-cabecalho:focus{
        background-color: ${({ theme }) => theme.hoverMouse}!important;
    }
    .bt-dropdown-conta:hover, .bt-dropdown-conta:focus{
        background-color: ${({ theme }) => theme.hoverMouse}!important;
    }

    /*Menu lateral*/


    .item-dropdown-acoes-cabecalho-sistema:hover{
        background-color: ${({ theme }) =>
          theme.selecionadoFonteIconeMenu}!important;
    }

    .menu-texto{
        color: ${({ theme }) => theme.fonteCabecalho}!important;
    }
    .menu-texto:active{
        color: ${({ theme }) => theme.selecionadoFonteIconeMenu}!important;
    }

    /*TABLE TABELA*/
    
    .react-bootstrap-table .react-bs-table-no-data{
        color: ${({ theme }) => theme.cor9}!important;
    }

    .switchPerfil:checked + label:before {
        background-color: ${({ theme }) => theme.backgroud};
    }
    
    .switchPerfil + label:before {
        background-color: ${({ theme }) => theme.backgroud};
    }

    .campo-select-modal, .campo-select-modal:hover, .campo-select-modal:focus{
        background-color: ${({ theme }) =>
          theme.buscaFiltroAcoesScrollPaginacao};
        color:  ${({ theme }) => theme.fonteCabecalho};
        font-family: RobotoRegular;
        font-size: 14px;
        line-height: 16px;
        height: 32px!important;
        border: 0px!important;
        border-radius: 100px!important;
        box-shadow: none !important;
    }
    .campo-select-modal:disabled{
        background-color: ${({ theme }) => theme.menuPrincipal};
        color:  ${({ theme }) => theme.fonteCabecalho};
        font-family: RobotoRegular;
        font-size: 14px;
        line-height: 16px;
        height: 32px!important;
        border: 0px!important;
        border-radius: 100px!important;
        box-shadow: none !important;
    }
    .campo-select-modal:active{
        background-color: ${({ theme }) =>
          theme.buscaFiltroAcoesScrollPaginacao};
        color:  ${({ theme }) => theme.fonteCabecalho};
        font-family: RobotoRegular;
        font-size: 14px;
        line-height: 16px;
        height: 32px!important;
        border: 0px!important;
        border-radius: 100px!important;
        box-shadow: none !important;
    }

    
    .campo-select-modal-escuro, .campo-select-modal-escuro:hover, .campo-select-modal-escuro:focus{
        background-color: ${({ theme }) => theme.cinzaEscuroForte};
        color:  ${({ theme }) => theme.fonteCabecalho};
        font-family: RobotoRegular;
        font-size: 14px;
        line-height: 16px;
        height: 32px!important;
        border: 0px!important;
        border-radius: 100px!important;
        box-shadow: none !important;
    }
    .campo-select-modal-escuro:disabled{
        background-color: ${({ theme }) => theme.menuPrincipal};
        color:  ${({ theme }) => theme.fonteCabecalho};
        font-family: RobotoRegular;
        font-size: 14px;
        line-height: 16px;
        height: 32px!important;
        border: 0px!important;
        border-radius: 100px!important;
        box-shadow: none !important;
    }
    .campo-select-modal-escuro:active{
        background-color: ${({ theme }) => theme.cinzaEscuroForte};
        color:  ${({ theme }) => theme.fonteCabecalho};
        font-family: RobotoRegular;
        font-size: 14px;
        line-height: 16px;
        height: 32px!important;
        border: 0px!important;
        border-radius: 100px!important;
        box-shadow: none !important;
    }

    .campo-select-filtro, .campo-select-filtro:hover, .campo-select-filtro:focus{
        background-color: ${({ theme }) => theme.cinzaEscuroForte};
        color:  ${({ theme }) => theme.fonteCabecalho};
        font-family: RobotoRegular;
        font-size: 14px;
        line-height: 16px;
        height: 31px!important;
        border: 0px!important;
        border-radius: 100px!important;
        box-shadow: none !important;
    }
    .campo-select-filtro-b, .campo-select-filtro-b:hover, .campo-select-filtro-b:focus{
        background-color: ${({ theme }) => theme.cinzaEscuroForte};
        color:  ${({ theme }) => theme.fonteCabecalho};
        font-family: RobotoRegular;
        font-size: 14px;
        line-height: 16px;
        height: 31px!important;
        border: 0px!important;
        border-radius: 100px!important;
        box-shadow: none !important;
        margin-left: 55px;
    }
    .campo-select-filtro:active{
        background-color: ${({ theme }) => theme.cinzaEscuroForte};
        color:  ${({ theme }) => theme.fonteCabecalho};
        font-family: RobotoRegular;
        font-size: 14px;
        line-height: 16px;
        height: 32px!important;
        border: 0px!important;
        border-radius: 100px!important;
        box-shadow: none !important;
    }

    .item-modal-select{
        background-color: ${({ theme }) => theme.cor3};
    }
    .item-modal-select:hover, .item-modal-select:focus{
        background-color: ${({ theme }) => theme.hoverMouse}!important;
    }
    .item-filtro-select{
        background-color: ${({ theme }) => theme.cinzaEscuroForte};
    }
    .item-filtro-select:hover, .item-filtro-select:focus{
        background-color: ${({ theme }) => theme.hoverMouse}!important;
    }

    .triangulo-modal-select{
        border-bottom: 15px solid ${({ theme }) => theme.cor3}!important;
    }
    .triangulo-filtro-select{
        border-bottom: 15px solid ${({ theme }) =>
          theme.cinzaEscuroForte}!important;
    }
    .triangulo-modal-select-ciclo{
        border-bottom: 15px solid ${({ theme }) =>
          theme.cabecalhoBackgroud}!important;
    }
    .campo-select-acoes, .campo-select-acoes:hover, .campo-select-acoes:focus{
        background-color: ${({ theme }) =>
          theme.buscaFiltroAcoesScrollPaginacao};
        color:  ${({ theme }) => theme.fonteCabecalho};
    }
    .campo-select-acoes:active{
        background-color: ${({ theme }) =>
          theme.buscaFiltroAcoesScrollPaginacao};
        color:  ${({ theme }) => theme.fonteCabecalho};
    }
    .campo-select-descicao-cliente, .campo-select-descicao-cliente:hover, .campo-select-descicao-cliente:focus{
        background-color: ${({ theme }) => theme.campoTextoCinza};
        color:  ${({ theme }) => theme.fonteCabecalho};
    }
    .campo-select-descicao-cliente:active{
        background-color: ${({ theme }) => theme.campoTextoCinza};
        color:  ${({ theme }) => theme.fonteCabecalho};
    }
    .botaoTemplate{
        background-color: ${({ theme }) => theme.botaoTemplate}!important;  
    }
    .botaoTemplate-importar-arquivo{
        background-color: ${({ theme }) => theme.botaoTemplate}!important;  
    }
    .fonte-branca-tema-branco{
        color: ${({ theme }) => theme.backgroud}!important;  
    }
    .iconeImportar{
        color: ${({ theme }) => theme.backgroud}!important;
    }
    .visualizar-grupo:focus{
        background-color: ${({ theme }) => theme.cor26}!important;  
    }
    .background-cadastro{
        border-radius: 8px;
        background-color: ${({ theme }) => theme.bg}!important;  
        border: solid 2px ${({ theme }) => theme.permissao}!important
    }
    .triangulo-detalhamento-linha{
        border-bottom: 15px solid ${({ theme }) =>
          theme.cabecalhoBackgroud}!important;
    }
    .cor-fundo-sistema{
        background-color: ${({ theme }) => theme.corFundo}!important;  
    }

    .triangulo-personalizar{
        border-bottom: 0.7em solid ${({ theme }) => theme.cor30}!important;
    }

    

    /*CALENDARIO*/



    .item-navegacao:hover{
        background-color: ${({ theme }) => theme.cor3}!important;
    }
    .hr-sistema{
        color: ${({ theme }) => theme.hr}!important;
        background-color: ${({ theme }) => theme.hr}!important;
    }
    
    .divisoria-acordeao-clara{
        border: 0.5px solid ${({ theme }) => theme.slider}!important;
    }
    
    
    // ALGAR E VEYE
    .icone-usuario-logado-1{
        border: 0.375em solid ${({ theme }) => theme.cor11}!important;
    }

    .icone-usuario-logado-2{
        border: 0.375em solid ${({ theme }) => theme.cor11}!important;
    }

    .botao-expandir-infos-usuario{
        background-color: ${({ theme }) => theme.cor11}!important;
    }

    .botao-expandir-infos-usuario:focus, .botao-expandir-infos-usuario:active, .botao-expandir-infos-usuario:hover{
        background-color: ${({ theme }) => theme.cor11}!important;
    }

    .botao-relatorio:focus, .botao-relatorio:active, .botao-relatorio:hover{
        background-color: ${({ theme }) => theme.cor24}!important;
    }

    .botao-atualizar:focus, .botao-atualizar:active, .botao-atualizar:hover{
        background-color: ${({ theme }) => theme.cor24}!important;
    }

    .usuario-logado-dados-email-1{
        color: ${({ theme }) => theme.cor11}!important;
    }

    .label-ver-todos-dropdown-cabecalho-sistema{
        color: ${({ theme }) => theme.cor11}!important;
    }

    .flipswitch-tiny-cb:checked + .flipswitch-tiny-label .flipswitch-tiny-switch{
        background-color: ${({ theme }) => theme.cor11}!important;
    }

    .mensagem-botao-confirmar{
        border: 0.09375em solid ${({ theme }) => theme.cor11}!important;
        background-color: ${({ theme }) => theme.cor11}!important;
    }

    .mensagem-botao-cancelar{
        border: 0.09375em solid ${({ theme }) => theme.cor11}!important;
    }

    .label-checkbox-cliente-virtueyes .checkbox-cliente-virtueyes:after{
        background-color: ${({ theme }) => theme.cor11}!important;
    }
    
    .modal-footer-submit-button-enabled{
        background-color: ${({ theme }) => theme.cor11}!important;
        border: 0.09375em solid ${({ theme }) => theme.cor11} !important;
    }
    
    .modal-footer-cancel-button{
        border: 0.09375em solid ${({ theme }) => theme.cor11}!important;
    }

    .botao-confirmar{
        background-color: ${({ theme }) => theme.cor11}!important;
        border: 0.09375em solid ${({ theme }) => theme.cor11} !important;
    }

    .botao-confirmar-branco{ /* obs: botão da mesma cor para os 2 temas (aprovado por UI/UX) */
        background-color: #fff!important;
        color: #000!important;
        border: 0.09375em solid #fff!important;
    }

    .botao-cancelar{
        border: 0.09375em solid ${({ theme }) => theme.cor11}!important;
    }

    .cor-texto-botao-verde {
        color: ${({ theme }) => theme.cor1}!important;
    }

    .botao-confirmar-faturamento{
        background-color: ${({ theme }) => theme.cor11}!important;
        border: 0.09375em solid ${({ theme }) => theme.cor11} !important;
    }
    
    .botao-cancelar-faturamento{
        border: 0.09375em solid ${({ theme }) => theme.cor11}!important;
    }

    .botao-aplicar-filtro-habilitado{
        background-color: ${({ theme }) => theme.cor11}!important;
    }

    .botao-aplicar-filtro-simcard-habilitado{
        background-color: ${({ theme }) => theme.cor11}!important;
    }

    .botao-limpar-filtro-habilitado{
        border: 0.09375em solid ${({ theme }) => theme.cor11}!important;
    }


    .botao-fechar-filtro{
        border: 0.09375em solid ${({ theme }) => theme.cor11}!important;
    }

    .botao-mostrar-filtro-arquivo{
        border: 0.09375em solid ${({ theme }) => theme.cor39}!important;
    }

    .label-radio-filtros .checkbox-filtros:after{
        background-color: ${({ theme }) => theme.cor11}!important;
    }

    .label-radio-filtros-simcard .checkbox-status:after{
        background-color: ${({ theme }) => theme.cor11}!important;
    }

    .label-radio-filtros-conexao .checkbox-filtros:after{
        background-color: ${({ theme }) => theme.cor11}!important;
    }

    .label-radio-filtros-pacote .checkbox-filtros:after{
        background-color: ${({ theme }) => theme.cor11}!important;
    }

    .label-radio-filtros-tempo .checkbox-filtros:after{
        background-color: ${({ theme }) => theme.cor11}!important;
    }

    .label-radio-filtros-tempo-b .checkbox-filtros:after{
        background-color: ${({ theme }) => theme.cor11}!important;
    }

    .label-radio-filtros-pacote-b .checkbox-filtros:after{
        background-color: ${({ theme }) => theme.cor11}!important;
    }

    .label-radio-filtros-trafego .checkbox-filtros:after{
        background-color: ${({ theme }) => theme.cor11}!important;
    }
    .label-radio-filtros-trafego-2 .checkbox-filtros:after{
        background-color: ${({ theme }) => theme.cor11}!important;
    }
    .label-radio-filtros-status .checkbox-filtros:after{
        background-color: ${({ theme }) => theme.cor11}!important;
    }
    .label-radio-filtros-status-b .checkbox-filtros:after{
        background-color: ${({ theme }) => theme.cor11}!important;
    }
    .label-tabela-itens-exibidos{
        color: ${({ theme }) => theme.cor11}!important;
    }

    .fonte-paginacao-verde{
        color: ${({ theme }) => theme.cor11}!important;
    }

    .icone-subitem-navegacao-detalhamento-linha-ativado{
        background-color: ${({ theme }) => theme.cor11}!important;
    }
    
    .container-subitem-navegacao-detalhamento-linha-2{
        box-shadow: inset 0.25em 0px 0px 0px ${({ theme }) =>
          theme.cor11}!important;
    }

    .icone-subitem-navegacao-detalhamento-linha-desativado{
        border: 0.0625em solid ${({ theme }) => theme.cor11}!important;
    }

    .botao-confirmar-filtro-detalhamento-linha{
        border: 0.09375em solid ${({ theme }) => theme.cor11} !important;
        background-color: ${({ theme }) => theme.cor11}!important;
    }

    .container-radio-button-extrato .radio-button-extrato:after{
        background-color: ${({ theme }) => theme.cor11}!important;
    }
    
    .custom-range{
        background-color: ${({ theme }) => theme.cor11}!important;
    }

    input[type=range]::-webkit-slider-runnable-track{
        background-color: ${({ theme }) => theme.cor11}!important;
    }

    input[type=range]::-moz-slider-runnable-track {
        background-color: ${({ theme }) => theme.cor11}!important;
    }

    input[type=range]::-ms-slider-runnable-track {
        background-color: ${({ theme }) => theme.cor11}!important;
    }

    .botao-editar-pedidos-novo-simcard{
        border: 0.093em solid ${({ theme }) => theme.cor11}!important;
    }

    .flipswitch-cb:checked + .flipswitch-label .flipswitch-switch{
        background-color: ${({ theme }) => theme.cor11}!important;
    }

    .flipswitch-cmp:checked + .flipswitch-label .flipswitch-switch{
        background-color: ${({ theme }) => theme.cor11}!important;
    }

    .container-conteudo-resumo-faturas{
        border-left: 0.3125em solid ${({ theme }) => theme.cor11}!important;
    }

    .container-conteudo-Percentual-gastos{
        border-left: 0.3125em solid ${({ theme }) => theme.cor11}!important;
    }
    
    .flipswitch-medium-cb:checked + .flipswitch-medium-label .flipswitch-medium-switch{
        background-color: ${({ theme }) => theme.cor11}!important;
    }

    .botaoImportar{
        background-color: ${({ theme }) => theme.cor11}!important;
    }

    .botao-importar{
        background-color: ${({ theme }) => theme.cor11}!important;
    }

    .btn-importar-febraban{
        border: 0.09375em solid ${({ theme }) => theme.cor11}!important;
        background-color: ${({ theme }) => theme.cor11}!important;
    }

    .botao-editar-titulo-personalizado{
        border: 0.093em solid ${({ theme }) => theme.cor11}!important;
    }

    .tab-elementos-permissoes-cadastro-usuario-cliente-ativado{
        border-bottom: 0.2em solid ${({ theme }) => theme.cor11}!important;
    }

    .btn-filtro-arquivo{
        border: 0.09375em solid ${({ theme }) => theme.cor11}!important;
        background-color: ${({ theme }) => theme.cor11}!important;
    }

    .barra-dados-caixa-titulo{
        background-color: ${({ theme }) => theme.cor11}!important;
    }

    .corpo-infos-usuario-algar{
        background-color: ${({ theme }) => theme.cor11}!important;
    }

    .item-menu-info-usuario-ultimo{
        background-color: ${({ theme }) => theme.cor11}!important;
    }
    
    .triangulo-infos-usuario{
        border-bottom: 0.75em solid ${({ theme }) => theme.cor11}!important;
    }

    .menu-dropdown-formatos-preview{
        background-color: ${({ theme }) => theme.cor27}!important;
    }
    

`;
