import { Popover as PopoverMantine } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef
} from 'react'

import ActionIcon from '../ActionIcon'

import * as S from './styles.js'

const Popover = forwardRef(
  ({ children, icon, title, width = 350, onOpen }, ref) => {
    const [opened, { open, close }] = useDisclosure(false)
    const popoverRef = useRef(null)

    // Expor a função close para o pai via ref
    useImperativeHandle(ref, () => ({
      closePopover: close
    }))

    const handleClick = () => {
      if (onOpen) onOpen()
      open()
    }

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (popoverRef.current && !popoverRef.current.contains(event.target)) {
          close()
        }
      }

      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [close])

    return (
      <div style={{ position: 'relative' }} ref={popoverRef}>
        <S.GlobalStyles />
        <PopoverMantine
          zIndex={999}
          style={{ border: 'none' }}
          width={width}
          position="right-start"
          withArrow
          opened={opened}
        >
          <PopoverMantine.Target>
            <ActionIcon
              alt="editar"
              variant="transparent"
              icon={icon}
              onClick={() => handleClick()}
              tooltip={{
                label: title
              }}
            />
          </PopoverMantine.Target>
          <PopoverMantine.Dropdown>
            <S.PopoverContainer>
              {title && <S.Title>{title}:</S.Title>}
              {children}
            </S.PopoverContainer>
          </PopoverMantine.Dropdown>
        </PopoverMantine>
      </div>
    )
  }
)



export default Popover
