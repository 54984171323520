import { ActionIcon as ActionIconMantine } from "@mantine/core";
import styled from "styled-components";

export const ActionIcon = styled(ActionIconMantine)`
  img {
    width: 13px;
    height: 24px;
    margin-left: 10px;
    scale: 1.5;
  }
`;
