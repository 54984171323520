import React, { useEffect, useState } from "react";
import * as S from "./styles";
import Group from "../../../Mantine/Group";
import Botao from "../../../Mantine/Botao";
import DatePicker from "../../../Mantine/DatePicker";
import Grafico from "./Grafico";

const buttons = [
  { value: "24h", label: "Últimas 24h" },
  { value: "48h", label: "Últimas 48h" },
  { value: "7d", label: "Últimos 7 dias" },
  { value: "15d", label: "Últimos 15 dias" },
  { value: "30d", label: "Últimos 30 dias" },
  { value: "periodo", label: "Por período" },
];
const DEFAULT_DATA = { start: null, end: null };

export default function Historico() {
  const [selectedButton, setSelectedButton] = useState(null);
  const [selectedDate, setSelectedDate] = useState(DEFAULT_DATA);
  const [visibleDate, setVisibleDate] = useState(false);

  const handleButtonFilter = (value) => {
    if (value !== "periodo") {
      setVisibleDate(true);
      setSelectedDate(DEFAULT_DATA);
    } else setVisibleDate(true);
    setSelectedButton(value);
  };

  const handleSelectedDate = (field, value) => {
    setSelectedDate((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <S.HistoricoWrapper>
      <Group>
        {buttons.map((button) => (
          <>
            <Botao
              key={button.value}
              label={button.label}
              onClick={() => handleButtonFilter(button.value)}
              variant={button.value === selectedButton ? "selected" : "select"}
            />
            {visibleDate && button.value === "periodo" && (
              <div
                className={`transition-container ${
                  selectedButton === "periodo" ? "visible" : ""
                }`}
              >
                <DatePicker
                  prefixo="De:"
                  value={selectedDate.start}
                  onChange={(date) => handleSelectedDate("start", date)}
                />
                <DatePicker
                  prefixo="Até:"
                  minDate={selectedDate.start}
                  value={selectedDate.end}
                  onChange={(date) => handleSelectedDate("end", date)}
                />
              </div>
            )}
          </>
        ))}
      </Group>

      <Grafico />
    </S.HistoricoWrapper>
  );
}
