/* colunas padrões ao logar

OBS: quando adicionar nova coluna aqui, deve adicionar também em;
    sveye-front\src\components\personalizarColunas\dados.js
     OU 
    src\components\personalizarColunasFaturas\dados.js

    ----------------
    ATENÇÃO! Não apagar as COLUNAS comentadas. 
              Pois se mudarem de ideia, outro dev pode demorar dias para restaurá-las.
*/

// carrega ao fazer login
export const colunasTodosNiveisClientes = [
  {
    nome: 'Linha',
    valor: true,
    id: 'CALLERID',
    nivel_acesso: 3
  },
  {
    nome: 'ICCID',
    valor: true,
    id: 'ICCID',
    nivel_acesso: 3
  },
  {
    nome: 'Operadora',
    valor: true,
    id: 'OPERADORA',
    nivel_acesso: 3
  },
  {
    nome: 'Status da conexão', // OU 'Status'
    valor: true,
    id: 'ON_OFF',
    nivel_acesso: 3
  },
  {
    nome: 'Último Evento',
    valor: true,
    id: 'ULTIMO_EVENTO',
    nivel_acesso: 3
  },
  {
    nome: 'Início da última conexão',
    valor: true,
    id: 'CONECTOU',
    nivel_acesso: 3
  },
  {
    nome: 'Fim da última conexão',
    valor: true,
    id: 'DESCONECTOU',
    nivel_acesso: 3
  },
  {
    nome: 'Tempo da conexão',
    valor: true,
    id: 'TEMPO_DURACAO',
    nivel_acesso: 3
  },
  {
    nome: 'Tecnologia',
    valor: true,
    id: 'TECNOLOGIA',
    nivel_acesso: 3
  },
  {
    nome: 'Tráfego',
    valor: true,
    id: 'TRAFEGO',
    nivel_acesso: 3
  },
  {
    nome: 'Plano',
    valor: true,
    id: 'PLANO',
    nivel_acesso: 3
  },
  {
    nome: 'Consumo %', // ou 'Consumo (%)'
    valor: true,
    id: 'CONSUMO_PERCENTUAL',
    nivel_acesso: 3
  },
  {
    nome: 'Saldo',
    valor: true,
    id: 'SALDO_MB',
    nivel_acesso: 3
  },
  {
    nome: 'Consumo',
    valor: true,
    id: 'CONSUMO_MB',
    nivel_acesso: 3
  },
  {
    nome: 'Envio de SMS',
    valor: true,
    id: 'SMS_ATIVADO',
    nivel_acesso: 3
  },
  {
    nome: 'IMEI',
    valor: true,
    id: 'IMEI_VERIFICADOR',
    nivel_acesso: 3
  },
  {
    nome: 'Modelo',
    valor: false,
    id: 'IA_MODELO_DISPOSITIVO',
    nivel_acesso: 3
  },
  {
    nome: 'Marca',
    valor: false,
    id: 'IA_MARCA_DISPOSITIVO',
    nivel_acesso: 3
  },
  {
    nome: 'Grupo',
    valor: true,
    id: 'NOME_GRUPO',
    nivel_acesso: 3
  },
  {
    nome: 'Nome',
    valor: true,
    id: 'IA_NOME',
    nivel_acesso: 3
  },
  {
    nome: 'IMSI',
    valor: false,
    id: 'IMSI',
    nivel_acesso: 3
  },
  {
    nome: 'Tipo Simcard', // OU 'Tipo de SIM card'
    valor: false,
    id: 'TIPO_SIMCARD',
    nivel_acesso: 3
  },
  {
    nome: 'IP',
    valor: false,
    id: 'IP',
    nivel_acesso: 3
  },
  {
    nome: 'Operadora atual',
    valor: true,
    id: 'LAT_LONG_OPERADORA',
    nivel_acesso: 3
  },
  {
    nome: 'Dias Utilizados', // ou 'Dias utilizados'
    valor: false,
    id: 'DIAS_USADOS',
    nivel_acesso: 3
  },
  {
    nome: 'Conexão no ciclo',
    valor: false,
    id: 'NUMCONEXOES',
    nivel_acesso: 3
  },
  {
    nome: 'Pacotes no ciclo',
    valor: false,
    id: 'NUMPACOTES',
    nivel_acesso: 3
  },
  {
    nome: 'Consumo no dia',
    valor: false,
    id: 'CONSUMO_DIA_MB',
    nivel_acesso: 3
  },
  {
    nome: 'Conexão no dia',
    valor: false,
    id: 'CONEXOES_DIA',
    nivel_acesso: 3
  },
  {
    nome: 'Pacotes no dia',
    valor: false,
    id: 'PACOTES_DIA',
    nivel_acesso: 3
  },
  {
    nome: 'Modelo Modem',
    valor: true,
    id: 'IA_MODELO_IMEI', // ou 'Modelo modem'
    nivel_acesso: 3
  },
  {
    nome: 'Marca Modem',
    valor: true,
    id: 'IA_MARCA_IMEI',
    nivel_acesso: 3
  },
  {
    nome: 'Endereço',
    valor: false,
    id: 'IA_ENDERECO',
    nivel_acesso: 3
  },
  {
    nome: 'Cidade',
    valor: false,
    id: 'IA_CIDADE',
    nivel_acesso: 3
  },
  {
    nome: 'Estado',
    valor: false,
    id: 'IA_UF',
    nivel_acesso: 3
  },
  {
    nome: 'CEP',
    valor: false,
    id: 'IA_CEP',
    nivel_acesso: 3
  },
  {
    nome: 'Informações adicionais',
    valor: false,
    id: 'IA_OBSERVACOES',
    nivel_acesso: 3
  },
  {
    nome: 'Personalizado 1',
    valor: false,
    id: 'IAC_DESCRICAO_1',
    nivel_acesso: 3
  },
  {
    nome: 'Personalizado 2',
    valor: false,
    id: 'IAC_DESCRICAO_2',
    nivel_acesso: 3
  },
  {
    nome: 'Personalizado 3',
    valor: false,
    id: 'IAC_DESCRICAO_3',
    nivel_acesso: 3
  }
]

const colunasN1N2 = [
  {
    nome: 'Plano tipo',
    valor: true,
    id: 'PLANO_TIPO',
    nivel_acesso: 3
  },
  {
    nome: 'Serviço adicional',
    valor: true,
    id: 'SERVICO_ADICIONAL',
    nivel_acesso: 3
  },
  {
    nome: 'Contrato',
    valor: true,
    id: 'SITUACAO',
    nivel_acesso: 2
  },
  {
    nome: 'Valor',
    valor: true,
    id: 'VALOR_PLANO',
    nivel_acesso: 2
  },
  {
    nome: 'Aquisição',
    valor: true,
    id: 'DATA_COMPRA',
    nivel_acesso: 2
  },
  {
    nome: 'Ativação',
    valor: true,
    id: 'DATA_ATIVACAO',
    nivel_acesso: 3
  },
  {
    nome: 'Prazo contrato',
    valor: true,
    id: 'DATA_PRAZO_CONTRATO',
    nivel_acesso: 2
  },
  {
    nome: 'Situação',
    valor: false,
    id: 'SITUACAO_GRUPO',
    nivel_acesso: 3
  },
  {
    nome: 'Código do Cliente', // ou 'Cliente'
    valor: true,
    id: 'CLIENTE_CODIGO',
    nivel_acesso: 2
  },
  {
    nome: 'Nome do cliente',
    valor: false,
    id: 'CLIENTE_NOME',
    nivel_acesso: 2
  },
  {
    nome: 'Conta',
    valor: true,
    id: 'FAT_CONTA',
    nivel_acesso: 3
  },
  {
    nome: 'Ciclo de vida',
    valor: true,
    id: 'FAT_CICLOVIDA',
    nivel_acesso: 2
  },
  {
    nome: 'Nome Plano', // ou 'Nome do plano'
    valor: true,
    id: 'FAT_PLANO',
    nivel_acesso: 3
  },
  {
    nome: 'Pedido',
    valor: true,
    id: 'FAT_PEDIDO',
    nivel_acesso: 3
  },
  {
    nome: 'Nota Fiscal',
    valor: true,
    id: 'FAT_NOTA_FISCAL',
    nivel_acesso: 3
  },
  {
    nome: 'Valor Plano', // ou 'Valor fatura'
    valor: true,
    id: 'FAT_N2_VALOR_PLANO',
    nivel_acesso: 2
  }
]

export const getColunasClienteNivelN3 = (colunasInicial, campo) => {
  let colunasDsp = colunasInicial.filter((col) =>
    colunasTodosNiveisClientes.find((item) => item.id === col[campo])
  )
  return colunasDsp
}

const colunasSimcardInicial = [...colunasTodosNiveisClientes, ...colunasN1N2]

const colunasFaturasInicial = [
  /* Faturas -------------------------- */
  {
    nome: 'Fornecedor',
    valor: true,
    id: 'DS_FORNECEDOR'
  },
  /* fim Faturas -------------------------- */

  /* SIM card -------------------------- */
  {
    nome: 'Linha',
    valor: true,
    id: 'CALLERID'
  },
  {
    nome: 'ICCID',
    valor: true,
    id: 'ICCID'
  },
  {
    nome: 'Operadora',
    valor: true,
    id: 'DS_OPERADORA'
  },
  // {
  //     "nome": "IMSI",
  //     "valor": false,
  //     "id": "IMSI",
  // },
  {
    nome: 'Conta',
    valor: true,
    id: 'DS_CONTA'
  },
  {
    nome: 'Ciclo de vida',
    valor: true,
    id: 'DS_CICLO_VIDA'
  },
  {
    nome: 'Nome Plano',
    valor: true,
    id: 'DS_PLANO'
  },
  {
    nome: 'Pedido',
    valor: true,
    id: 'DS_PEDIDO_OPERADORA'
  },
  {
    nome: 'Nota fiscal',
    valor: true,
    id: 'DS_NOTA_FISCAL'
  },
  /* fim SIM card -------------------------- */

  /* Conectividade -------------------------- */
  {
    nome: 'Status Solicitação',
    valor: true,
    id: 'DS_STATUS_SOLIC'
  },
  {
    nome: 'Data do Desbloqueio',
    valor: true,
    id: 'DATA_DESBLOQUEIO'
  },
  {
    nome: 'Valor Plano', // OBS: Camarda disse para colocar em "Contrato" ?
    valor: true,
    id: 'FAT_N2_VALOR_PLANO'
  },
  // {
  //     "nome": "Status da conexão",
  //     "valor": true,
  //     "id": "ON_OFF",
  // },
  // {
  //     "nome": "Último Evento",
  //     "valor": true,
  //     "id": "ULTIMO_EVENTO",
  // },
  // {
  //     "nome": "IP",
  //     "valor": false,
  //     "id": "IP",
  // },
  // {
  //     "nome": "Tecnologia",
  //     "valor": true,
  //     "id": "TECNOLOGIA",
  // },
  /* fim Conectividade -------------------------- */

  /* Utilização -------------------------- */
  // {
  //     "nome": "Pacotes no ciclo",
  //     "valor": false,
  //     "id": "NUMPACOTES",
  // },
  // {
  //     "nome": "Consumo no dia",
  //     "valor": false,
  //     "id": "CONSUMO_DIA_MB",
  // },
  /* fim Utilização -------------------------- */

  /* Contrato -------------------------- */
  {
    nome: 'Data da Compra',
    valor: true,
    id: 'DATA_COMPRA'
  },
  {
    nome: 'Ativação',
    valor: true,
    id: 'DATA_ATIVACAO'
  },
  {
    nome: 'Prazo do Contrato',
    valor: true,
    id: 'DATA_PRAZO'
  },
  {
    nome: 'Envio de SMS',
    valor: true,
    id: 'SMS' // SMS_ATIVADO
  },
  /* fim Contrato -------------------------- */

  /* Dispositivo -------------------------- */
  // {
  //     "nome": "IMEI",
  //     "valor": true,
  //     "id": "IMEI",
  // },
  {
    nome: 'Marca',
    valor: true,
    id: 'IA_MARCA_DISPOSITIVO'
  },
  {
    nome: 'Modelo',
    valor: true,
    id: 'IA_MODELO_DISPOSITIVO'
  },
  /* fim Dispositivo -------------------------- */

  /* Identificação -------------------------- */
  /* fim Identificação -------------------------- */

  /* Cliente -------------------------------------*/
  {
    nome: 'Nome',
    valor: true,
    id: 'IA_NOME'
  },
  {
    nome: 'Endereço',
    valor: true,
    id: 'IA_ENDERECO'
  },
  {
    nome: 'Cidade',
    valor: true,
    id: 'IA_CIDADE'
  },
  {
    nome: 'Estado',
    valor: true,
    id: 'IA_UF'
  },
  {
    nome: 'CEP',
    valor: true,
    id: 'IA_CEP'
  },
  {
    nome: 'Informações Adicionais',
    valor: true,
    id: 'IA_OBSERVACOES'
  }
  /* fim Cliente -------------------------------------*/
]

const getItensComOrdens = (cols) =>
  cols.map((item, index) => ({ ...item, ordem: index }))

export const colunasSimcard = getItensComOrdens(colunasSimcardInicial)
export const colunasFaturas = getItensComOrdens(colunasFaturasInicial)
