import React, { useCallback, useEffect, useState } from 'react'

import CheckBox from '../../../icones/check-circle.svg'
import Fechar from '../../../icones/icone-fechar-branco.svg'

import * as S from './styles'

export default function Notification({
  type = 'default',
  title,
  message,
  autoHide = 3000,
  onClose
}) {
  const [visible, setVisible] = useState(true)

  const handleClose = useCallback(() => {
    setVisible(false)
    if (onClose) onClose()
  }, [onClose])

  const types = {
    default: {},
    success: {
      icon: <S.Img type={type} alt="success" src={CheckBox} />
    },
    error: {
      icon: (
        <S.CloseWrapper>
          <S.Img alt="close" src={Fechar} />
        </S.CloseWrapper>
      )
    },
    loading: { color: '#33CC99', loading: true, withCloseButton: false }
  }

  const notificationProps = types[type]

  useEffect(() => {
    if (autoHide && visible) {
      const timer = setTimeout(() => handleClose(), autoHide)
      return () => clearTimeout(timer)
    }
  }, [autoHide, visible, handleClose])

  if (!visible) return null

  return (
    <S.NotificationWrapper>
      <S.Notification
        onClose={handleClose}
        title={title}
        {...notificationProps}
      >
        {message}
      </S.Notification>
    </S.NotificationWrapper>
  )
}
