import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from '@mui/material'
import React, { useState } from 'react'
import {
  dropdownDotsVertical,
  setaParaBaixo
} from '../../../../../utils/alternanciaIcones'
import { getCoresOpcoesMenuGrafico } from '../../cores'
import OpcoesMeses from './SelecionarCiclo/OpcoesMeses'

const options = [
  'Últimos 6 ciclos',
  {
    label: 'Selecionar ciclo',
    subOptions: [] // Será substituído
  }
]

const OpcoesMenuGrafico = (props) => {
  const { backgroundColor, corTexto, corIconeSeta } =
    getCoresOpcoesMenuGrafico()

  const [anchorEl, setAnchorEl] = useState(null)
  const [expanded, setExpanded] = useState(false)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setExpanded(false)
  }

  const handleAccordionChange = () => {
    setExpanded(!expanded)
  }

  const alterarCiclo = (ciclo) => {
    props.handleAlterarCiclo(ciclo)
    handleClose()
  }

  // correção da posição do menu quando abre (gráficos da direita)
  const ajusteMenu = {
    1: { marginTop: '60px', marginLeft: '10.5%' },
    2: { marginTop: '60px', marginLeft: '18.5%' },
    3: { marginTop: '140px', marginLeft: '10%' },
    4: { marginTop: '140px', marginLeft: '18.5%' }
  }

  const ExpandMoreIcon = () => (
    <img className="seta-accordion" src={setaParaBaixo()} />
  )

  const MoreVertIcon = () => (
    <img className="dropdown-vertical" src={dropdownDotsVertical()} />
  )

  return (
    <div>
      <IconButton
        disableRipple
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{
          padding: '0px',
          color: corTexto,
          fontSize: 35
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor,
            color: corTexto,
            height: 'auto',
            width: '188px',
            ...ajusteMenu[props.id]
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {options.map((option, index) =>
          typeof option === 'string' ? (
            <MenuItem key={index} onClick={() => alterarCiclo('historico')}>
              {option}
            </MenuItem>
          ) : (
            <Accordion
              key={index}
              expanded={expanded}
              onChange={handleAccordionChange}
              style={{ backgroundColor, color: corTexto, boxShadow: 'none' }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ minHeight: '36px', height: '36px' }}
                sx={{
                  '.MuiSvgIcon-root': { color: corIconeSeta }
                }}
              >
                <Typography>{option.label}</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: 0, marginTop: 0 }}>
                <OpcoesMeses alterarCiclo={alterarCiclo} />
              </AccordionDetails>
            </Accordion>
          )
        )}
      </Menu>
    </div>
  )
}

export default OpcoesMenuGrafico
