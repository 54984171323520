import styled from 'styled-components'

const statusColors = {
  Aberta: '#ffff00',
  Concluída: '#33CC99',
  Cancelada: '#d3d3d3',
  'Em andamento': '#ED6C02',
  'Concluída parcial': '#0288D1',
  Falha: '#D32F2F'
}

export const modalMensagem = styled.div`
  h6,
  p {
    color: ${({ theme }) => theme.cor9}!important;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
  }

  h6 {
    font-weight: bold;
    font-size: 19px;
  }

  p {
    margin-bottom: 0px;
  }

  .gap-row {
    gap: 16px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .mantine-Group-root {
    margin-top: 20px;
    flex-direction: column;
  }
`

export const tabelaSolicitacoesWrapper = styled.div`
  td {
    font-family: RobotoRegular !important;
    font-size: 16px !important;
    line-height: 1.5em !important;
    color: ${({ theme }) => theme.cor9}!important;
  }
`

export const StatusWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`

export const StatusElipse = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ status }) => statusColors[status]};
`
