/* eslint-disable no-useless-escape */
//IMPORTAÇÕES
import ptBR from 'date-fns/locale/pt-BR'
import React from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import '../../css/cssGlobalNoturno.css'
//ICONES
import { calendario } from '../../utils/alternanciaIcones'

registerLocale('pt-BR', ptBR)

/*  COMO USAR
    Importe a modal para dentro do componente que irá fazer uso dela.
    ex: import Historico from '../../calendario/calendario'

    Após isso, posicione-a dentro do return do seu componente.
    ex: <Historico 
            imputCustomisado='De:'
            data={dataInicio}
            setData={setDataInicio}
            dataMax={dataMax}
            dataMin={dataMin}>
        </Historico>
        <Historico 
            imputCustomisado='Até:'
            data={dataInicio}
            setData={setDataInicio}
            dataMax={dataMax}
            dataMin={dataMin}>
        </Historico>*/

// const InputCustomizado = ({ value, ...rest }) => {
//   return (
//     <input
//       {...rest}
//       value={value}
//       onInput={(e) => {
//         e.target.value = e.target.value.replace(/[^0-9\/\-\:\s]/g, "");
//       }}
//       maxLength={18}
//     />
//   );
// };

export default function Calendario(props) {
  if (props.formato === 'MM/YYYY') {
    return (
      <>
        <DatePicker
          customInput={
            <input
              value={props.data}
              maxLength={18}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9\/\-\:\s]/g, '')
              }}
            />
          }
          className="campo-texto-cor-3 campo-texto-calendario-filtro-c"
          selected={props.data}
          onChange={(date) => props.setData(date)}
          showMonthYearPicker
          dateFormat="MM/yyyy"
          locale="pt-BR"
          minDate={props.dataMin}
          maxDate={props.dataMax}
          placeholder="00/0000"
        />
        <div className="texto-input-customizado-calendario-filtro-c fonte-cor-15">
          {props.imputCustomisado}
        </div>
        <div className="posicao-icone-calendario-filtro-c">
          <img
            className="icone-calendario"
            src={calendario()}
            alt="calendario"
          />
        </div>
      </>
    )
  } else if (props.rota && props.rota == 'filtroSimcard') {
    if (props.formato === 'YYYY/MM') {
      return (
        <>
          <DatePicker
            className="campo-texto-cor-3 campo-texto-calendario-filtro"
            selected={props.data}
            onChange={(date) => props.setData(date)}
            showTimeInput
            timeFormat="HH:mm"
            timeInputLabel="Horário:"
            dateFormat="yyyy/MM"
            locale="pt-BR"
            minDate={props.dataMin}
            maxDate={props.dataMax}
            useWeekdaysShort
            formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
            placeholder="0000/00"
          />
          <div className="texto-input-customizado-calendario-filtro fonte-cor-15">
            {props.imputCustomisado}
          </div>
          <div className="posicao-icone-calendario-filtro">
            <img
              className="icone-calendario"
              src={calendario()}
              alt="calendario"
            />
          </div>
        </>
      )
    } else if (props.formato === 'DD/MM/YYYY') {
      return (
        <>
          <DatePicker
            className="campo-texto-cor-3 campo-texto-calendario-filtro"
            selected={props.data}
            onChange={(date) => props.setData(date)}
            showTimeInput
            timeFormat="HH:mm"
            timeInputLabel="Horário:"
            dateFormat="dd/MM/yyyy"
            locale="pt-BR"
            minDate={props.dataMin}
            maxDate={props.dataMax}
            useWeekdaysShort
            formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
            placeholder="00/00/0000"
            readOnly={props.readOnly}
          />
          <div className="texto-input-customizado-calendario-filtro fonte-cor-15">
            {props.imputCustomisado}
          </div>
          <div className="posicao-icone-calendario-filtro">
            <img
              className="icone-calendario"
              src={calendario()}
              alt="calendario"
            />
          </div>
        </>
      )
    } else {
      return (
        <>
          <DatePicker
            className="campo-texto-cor-3 campo-texto-calendario-filtro"
            selected={props.data}
            onChange={(date) => props.setData(date)}
            dateFormat="dd/MM/yyyy"
            locale="pt-BR"
            minDate={props.dataMin}
            maxDate={props.dataMax}
            useWeekdaysShort
            formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
            placeholder="00/00/0000 - 00:00"
          />
          <div className="texto-input-customizado-calendario-filtro fonte-cor-15">
            {props.imputCustomisado}
          </div>
          <div className="posicao-icone-calendario-filtro">
            <img
              className="icone-calendario"
              src={calendario()}
              alt="calendario"
            />
          </div>
        </>
      )
    }
  } else if (props.rota && props.rota == 'filtroSimcard-b') {
    if (props.formato === 'YYYY/MM') {
      return (
        <>
          <DatePicker
            className="campo-texto-cor-3 campo-texto-calendario-filtro-b"
            selected={props.data}
            onChange={(date) => props.setData(date)}
            showTimeInput
            timeFormat="HH:mm"
            timeInputLabel="Horário:"
            dateFormat="yyyy/MM"
            locale="pt-BR"
            minDate={props.dataMin}
            maxDate={props.dataMax}
            useWeekdaysShort
            formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
            placeholder="0000/00"
          />
          <div className="texto-input-customizado-calendario-filtro-b fonte-cor-15">
            {props.imputCustomisado}
          </div>
          <div className="posicao-icone-calendario-filtro-b">
            <img
              className="icone-calendario"
              src={calendario()}
              alt="calendario"
            />
          </div>
        </>
      )
    } else if (props.formato === 'DD/MM/YYYY') {
      return (
        <>
          <DatePicker
            className="campo-texto-cor-3 campo-texto-calendario-filtro-b"
            selected={props.data}
            onChange={(date) => props.setData(date)}
            showTimeInput
            timeFormat="HH:mm"
            timeInputLabel="Horário:"
            dateFormat="dd/MM/yyyy"
            locale="pt-BR"
            minDate={props.dataMin}
            maxDate={props.dataMax}
            useWeekdaysShort
            formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
            placeholder="00/00/0000"
          />
          <div className="texto-input-customizado-calendario-filtro-b fonte-cor-15">
            {props.imputCustomisado}
          </div>
          <div className="posicao-icone-calendario-filtro-b">
            <img
              className="icone-calendario"
              src={calendario()}
              alt="calendario"
            />
          </div>
        </>
      )
    } else {
      return (
        <>
          <DatePicker
            className="campo-texto-cor-3 campo-texto-calendario-filtro-b"
            selected={props.data}
            onChange={(date) => props.setData(date)}
            dateFormat="dd/MM/yyyy"
            locale="pt-BR"
            minDate={props.dataMin}
            maxDate={props.dataMax}
            useWeekdaysShort
            formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
            placeholder="00/00/0000 - 00:00"
          />
          <div className="texto-input-customizado-calendario-filtro-b fonte-cor-15">
            {props.imputCustomisado}
          </div>
          <div className="posicao-icone-calendario-filtro-b">
            <img
              className="icone-calendario"
              src={calendario()}
              alt="calendario"
            />
          </div>
        </>
      )
    }
  } else if (props.rota && props.rota == 'filtroSimcard-c') {
    if (props.formato === 'YYYY/MM') {
      return (
        <>
          <DatePicker
            className="campo-texto-cor-3 campo-texto-calendario-filtro-c"
            selected={props.data}
            onChange={(date) => props.setData(date)}
            showTimeInput
            timeFormat="HH:mm"
            timeInputLabel="Horário:"
            dateFormat="yyyy/MM"
            locale="pt-BR"
            minDate={props.dataMin}
            maxDate={props.dataMax}
            useWeekdaysShort
            formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
            placeholder="0000/00"
          />
          <div className="texto-input-customizado-calendario-filtro-c fonte-cor-15">
            {props.imputCustomisado}
          </div>
          <div className="posicao-icone-calendario-filtro-c">
            <img
              className="icone-calendario"
              src={calendario()}
              alt="calendario"
            />
          </div>
        </>
      )
    } else if (props.formato === 'DD/MM/YYYY') {
      return (
        <>
          <DatePicker
            className="campo-texto-cor-3 campo-texto-calendario-filtro-c"
            selected={props.data}
            onChange={(date) => props.setData(date)}
            showTimeInput
            timeFormat="HH:mm"
            timeInputLabel="Horário:"
            dateFormat="dd/MM/yyyy"
            locale="pt-BR"
            minDate={props.dataMin}
            maxDate={props.dataMax}
            useWeekdaysShort
            formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
            placeholder="00/00/0000"
          />
          <div className="texto-input-customizado-calendario-filtro-c fonte-cor-15">
            {props.imputCustomisado}
          </div>
          <div className="posicao-icone-calendario-filtro-c">
            <img
              className="icone-calendario"
              src={calendario()}
              alt="calendario"
            />
          </div>
        </>
      )
    } else {
      return (
        <>
          <DatePicker
            className="campo-texto-cor-3 campo-texto-calendario-filtro-c"
            customInput={
              <input
                value={props.data}
                maxLength={18}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9\/\-\:\s]/g, '')
                }}
              />
            }
            selected={props.data}
            onChange={(date) => props.setData(date)}
            showTimeInput
            timeFormat="HH:mm"
            timeInputLabel="Horário:"
            dateFormat="dd/MM/yyyy - HH:mm"
            locale="pt-BR"
            minDate={props.dataMin}
            maxDate={props.dataMax}
            useWeekdaysShort
            formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
            placeholder="00/00/0000 - 00:00"
          />
          <div className="texto-input-customizado-calendario-filtro-c fonte-cor-15">
            {props.imputCustomisado}
          </div>
          <div className="posicao-icone-calendario-filtro-c">
            <img
              className="icone-calendario"
              src={calendario()}
              alt="calendario"
            />
          </div>
        </>
      )
    }
  } else if (props.rota && props.rota == 'editarSolicitacao') {
    if (props.formato === 'DD/MM/YYYY') {
      return (
        <>
          <DatePicker
            className="campo-texto-cor-3 campo-texto-calendario"
            selected={props.data}
            onChange={(date) => props.setData(date)}
            dateFormat="dd/MM/yyyy"
            locale="pt-BR"
            minDate={props.dataMin}
            maxDate={props.dataMax}
            useWeekdaysShort
            formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
            placeholder="00/00/0000"
          />
          <div className="texto-input-customizado-calendario-solicitacao">
            {props.imputCustomisado}
          </div>
          <div className="posicao-icone-calendario">
            <img
              className="icone-calendario"
              src={calendario()}
              alt="calendario"
            />
          </div>
        </>
      )
    }
  } else {
    if (props.formato === 'YYYY/MM') {
      return (
        <>
          <DatePicker
            className="campo-texto-cor-3 campo-texto-calendario"
            selected={props.data}
            onChange={(date) => props.setData(date)}
            showTimeInput
            timeFormat="HH:mm"
            timeInputLabel="Horário:"
            dateFormat="yyyy/MM"
            locale="pt-BR"
            minDate={props.dataMin}
            maxDate={props.dataMax}
            useWeekdaysShort
            formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
            placeholder="0000/00"
          />
          <div className="texto-input-customizado-calendario">
            {props.imputCustomisado}
          </div>
          <div className="posicao-icone-calendario">
            <img
              className="icone-calendario"
              src={calendario()}
              alt="calendario"
            />
          </div>
        </>
      )
    } else if (props.formato === 'DD/MM/YYYY') {
      return (
        <>
          <DatePicker
            className="campo-texto-cor-3 campo-texto-calendario"
            selected={props.data}
            onChange={(date) => props.setData(date)}
            showTimeInput
            timeFormat="HH:mm"
            timeInputLabel="Horário:"
            dateFormat="dd/MM/yyyy"
            locale="pt-BR"
            minDate={props.dataMin}
            maxDate={props.dataMax}
            useWeekdaysShort
            formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
            placeholder="00/00/0000"
            readOnly={props.readOnly}
          />
          <div className="texto-input-customizado-calendario-b">
            {props.imputCustomisado}
          </div>
          <div className="posicao-icone-calendario">
            <img
              className="icone-calendario"
              src={calendario()}
              alt="calendario"
            />
          </div>
        </>
      )
    } else {
      return (
        <>
          <DatePicker
            className="campo-texto-cor-3 campo-texto-calendario"
            selected={props.data}
            onChange={(date) => props.setData(date)}
            showTimeInput
            timeFormat="HH:mm"
            timeInputLabel="Horário:"
            dateFormat="dd/MM/yyyy - HH:mm"
            locale="pt-BR"
            minDate={props.dataMin}
            maxDate={props.dataMax}
            useWeekdaysShort
            formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
            placeholder="00/00/0000 - 00:00"
          />
          <div className="texto-input-customizado-calendario-b">
            {props.imputCustomisado}
          </div>
          <div className="posicao-icone-calendario">
            <img
              className="icone-calendario"
              src={calendario()}
              alt="calendario"
            />
          </div>
        </>
      )
    }
  }
}
