import { Stepper as StepperMantine } from '@mantine/core'
import styled from 'styled-components'

export const Stepper = styled(StepperMantine)`
  * {
    margin: 0px;
  }

  .mantine-Stepper-content {
    padding-top: 0;
    margin-bottom: 24px;
    .mantine-Grid-root {
      font-size: 20px;
      color: ${({ theme }) => theme.cor39};
    }

    .grid-gap {
      gap: 16px;
      display: grid;
    }
  }

  .mantine-Group-root {
    justify-content: flex-end !important;
  }

  .mantine-Stepper-separator {
    background-color: ${({ theme }) => theme.cor56};
  }

  .mantine-Stepper-stepIcon {
    border-color: transparent !important;
    background-color: #4f627561;
    color: white;
    margin: 0;
    padding: 0;
  }

  .mantine-Stepper-stepBody {
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.cor39};
  }

  .mantine-Stepper-stepIcon[data-progress] {
    background-color: #33cc99;
  }

  .mantine-Stepper-stepIcon[data-completed] {
    background-color: #33cc99;
  }

  form {
    padding-top: 8px;
  }

  .content-subtitle {
    padding-top: 24px;
    padding-bottom: 16px;
    font-size: 24px;
    color: ${({ theme }) => theme.cor39};
  }

  .add-row {
    font-size: 20px;
    margin-top: 8px;
    color: ${({ theme }) => theme.cor39};
  }

  .position-absolute {
    position: absolute;
  }

  .alert-icon {
    display: flex;
    gap: 5px;
  }

  .validation-fields {
    color: #d32f2f;
    font-weight: 400;
    size: 14px;
  }
`
