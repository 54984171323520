import styled from 'styled-components'

export const clientesConectadosWrapper = styled.div`
  gap: 20px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  background-color: ${({ theme }) => theme.cor53}!important;
  zoom: 90%;
  border-radius: 8px;
  padding: 10px;
  position: relative;

  .pagination-table,
  .grupo-botao-paginacao-tabela-4 {
    margin-right: 20px;
  }

  .pagination-description {
    margin-top: 0.16em;
    margin-left: 215px;
    font-family: RobotoRegular !important;
    font-size: 0.875em !important;
  }

  table {
    width: 100% !important;
  }

  td {
    font-family: RobotoRegular !important;
    font-size: 16px !important;
    line-height: 1.5em !important;
    color: ${({ theme }) => theme.cor9}!important;
  }
`
