import React from 'react'
import TabelaSimcardsVirgens from '../../components/SimcardsVirgens/Tabela'

export default function SimcardsVirgens() {
  return (
    <div>
      <TabelaSimcardsVirgens />
    </div>
  )
}
