import { useEffect, useRef, useState } from 'react'
import InfoVerde from '../../../../../../icones/iconesVersaoVerde/info.svg'
import { fechar } from '../../../../../../utils/alternanciaIcones'
import {
  AvisoWrapper,
  ConteudoAlerta,
  IconeTrigger,
  Texto,
  Titulo,
  TituloWrapper
} from './styles'

export const Aviso = () => {
  const [abrir, setAbrir] = useState(false)
  const alertaRef = useRef(null)

  const handleClickOutside = (event) => {
    if (alertaRef.current && !alertaRef.current.contains(event.target)) {
      setAbrir(false)
    }
  }

  useEffect(() => {
    if (abrir) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [abrir])

  return (
    <AvisoWrapper>
      <div onClick={() => setAbrir(true)}>
        <IconeTrigger src={InfoVerde} fontSize="large" />
      </div>

      {abrir && (
        <ConteudoAlerta ref={alertaRef}>
          <TituloWrapper>
            <Titulo>Aviso</Titulo>
            <img src={fechar()} onClick={() => setAbrir(false)} />
          </TituloWrapper>
          <Texto>
            A análise de economia aqui demonstrada se refere apenas a relação de
            custo entre o plano original e o novo plano da operadora. Não estão
            contemplados qualquer outro custo além dos valores de plano, como
            multas ou descontos na fatura.
          </Texto>
        </ConteudoAlerta>
      )}
    </AvisoWrapper>
  )
}
