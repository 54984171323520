export default function GirarSetaDropdown(imagemId) {
  let imagem = document.getElementById(imagemId)
  if (imagem.classList.contains('icone-botao-expandir-row')) {
    imagem.classList.remove('icone-botao-expandir-row')
    imagem.classList.add('icone-botao-expandir-row-ativado')
  } else if (imagem.classList.contains('icone-botao-expandir-row-ativado')) {
    imagem.classList.remove('icone-botao-expandir-row-ativado')
    imagem.classList.add('icone-botao-expandir-row')
  }
}
