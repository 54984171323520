/*eslint-disable*/
import { api } from '../../conexoes/api'

import decryptJWT from '../../utils/decryptJWT'

// FUNÇÃO DE ARMAZENAMENTO DOS DADOS NO MONGO
async function amostragemClickstream(rota) {
  // Salva a amostragem de dados em uma variável
  let cd_pav_usuario = 0
  let cd_pav_cliente = 0
  let token = localStorage['@veye-Token']
  if (!Number.isNaN(parseInt(decryptJWT('codigo')))) {
    cd_pav_usuario = parseInt(decryptJWT('codigo'))
    cd_pav_cliente = parseInt(decryptJWT('codigoCliente'))
  }

  await api.post('/m1/servicos/usabilidade/amostragem_de_usabilidade', {
    // '/m1/servicos/usabilidade' 'm1/servicos/clickstream/salvarclickstream'
    cd_pav_usuario: cd_pav_usuario,
    cd_pav_cliente: cd_pav_cliente,
    cd_dad_acao_tipo: rota
  })
}

export default async function ColetaClickstream(rota) {
  await amostragemClickstream(rota)
}

// https://upmostly.com/tutorials/build-a-react-timer-component-using-hooks
// https://github.com/origamid/clickstream

// LIXO:

/*
import Clickstream from './js/clickstream/Clickstream.js' //
import './js/clickstream/dependencies/heatmap.min.js' //
const clickstream = new Clickstream(" ", 10);
clickstream.track();
// Flag de ativação da coleta de clickstream a cada 30 segundos
const isActive = false
let interval = null;
if (isActive) {
  interval = setInterval(() => {
  // seconds++
  amostragemClickstream() // Chamada da função de armazenamento dos dados no mongo
}, 30000);
} else if (!isActive){// && seconds !== 0) {
  clearInterval(interval);
} 
*/
/*
  const clickDados = await clickstream.get() 
  if(!clickDados.email) clickDados.email = 'login'
  if((( clickDados.url.lastIndexOf('token') === -1 ) && ( clickDados.url.lastIndexOf('?data=heatmapPrint') === -1 ))) { // Verifica se o link não é de hetamap
      const dataAtual = new Date();
      const createdAt  = new Date(dataAtual.valueOf() - dataAtual.getTimezoneOffset() * 60000);
      await api.post('m1/servicos/clickstream/salvarclickstream', {
        email: clickDados.email,
        url: clickDados.url,
        rota: rota,
        createdAt,
        clickstream: clickDados.clickstream,
      })
      .then(function (response) {
        // if(response.data.dados){
        // }else{
        // }
      })
      .catch(function (error) {
      });
      await clickstream.track();
    // }
  }
  */
// return Url
