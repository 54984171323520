const axios = require('axios')

export async function validarCnpj(cnpj, consultarApi = false) {
  let resposta
  if (consultarApi) {
    resposta = await apiValidarCNPJ(cnpj)
  } else {
    return calculoValidaCNPJ(cnpj)
  }

  if (resposta.status === 200) {
    return { data: resposta?.data, invalido: false }
  } else if (!resposta.status || resposta.status >= 500) {
    return calculoValidaCNPJ(cnpj)
  } else {
    return false
  }
}

async function apiValidarCNPJ(cnpj) {
  return axios
    .get('https://brasilapi.com.br/api/cnpj/v1/' + cnpj)
    .then((resposta) => {
      return resposta
    })
    .catch((erro) => {
      return { status: erro.response?.status }
    })
}

function calculoValidaCNPJ(cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, '') // Remove caracteres não numéricos

  if (cnpj.length !== 14) {
    return false
  }

  // Calcula o primeiro dígito verificador
  let soma = 0
  for (let i = 0; i < 12; i++) {
    soma += parseInt(cnpj.charAt(i)) * (i < 4 ? 5 - i : 13 - i)
  }
  const digito1 = soma % 11 < 2 ? 0 : 11 - (soma % 11)

  // Calcula o segundo dígito verificador
  soma = 0
  for (let i = 0; i < 13; i++) {
    soma += parseInt(cnpj.charAt(i)) * (i < 5 ? 6 - i : 14 - i)
  }
  const digito2 = soma % 11 < 2 ? 0 : 11 - (soma % 11)

  // Verifica se os dígitos verificadores são iguais aos informados
  if (
    parseInt(cnpj.charAt(12)) !== digito1 ||
    parseInt(cnpj.charAt(13)) !== digito2
  ) {
    return false
  }

  return true
}

export function validarCpf(cpf) {
  var Soma = 0
  var Resto
  var strCPF = String(cpf).replace(/[^\d]/g, '')

  if (strCPF.length !== 11) return false

  if (
    [
      '00000000000',
      '11111111111',
      '22222222222',
      '33333333333',
      '44444444444',
      '55555555555',
      '66666666666',
      '77777777777',
      '88888888888',
      '99999999999'
    ].indexOf(strCPF) !== -1
  )
    return false

  for (let count = 1; count <= 9; count++)
    Soma = Soma + parseInt(strCPF.substring(count - 1, count)) * (11 - count)

  Resto = (Soma * 10) % 11

  if (Resto == 10 || Resto == 11) Resto = 0

  if (Resto != parseInt(strCPF.substring(9, 10))) return false

  Soma = 0

  for (let count = 1; count <= 10; count++)
    Soma = Soma + parseInt(strCPF.substring(count - 1, count)) * (12 - count)

  Resto = (Soma * 10) % 11

  if (Resto == 10 || Resto == 11) Resto = 0

  if (Resto != parseInt(strCPF.substring(10, 11))) return false

  return true
}
