import styled from "styled-components";

export const SemLocalizacao = styled.div`
  background-color: #0000000a;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`;
