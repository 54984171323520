import React from 'react';
import { exportarXlsLinhasNaoEncontradas } from '../../utils/exportarArquivo';
import { RenderMensagemAtencao } from '../componentesModais/mensagens/mensagemAtencao';

export function clickBotaoInvisivelExportarLinhasNaoEncontradas() {
    const exportar = document.getElementById('exportar-linhas-nao-encontradas')
    exportar.click();
}

/* TODO: Refazer isso da melhor maneira com muito cuidado para não quebrar em algum lugar que chama isso (pode demorar bastante). 
    Não entendi porque tem um botão pra isso. Só refatorei para ficar menos repetido.
    olhar em;
    - tabelaSimcard.js
    - fatDispositivos/tabela.js
    - alterarIccid.js
    - fatFebraban/tabela.js, etc
*/
export function BotaoInvisivelExportarLinhasNaoEncontradas({dadosParaDownloadLinhasNaoEncontradas}) {
    return (
        <button
            id='exportar-linhas-nao-encontradas'
            className='d-none'
            onClick={() => exportarXlsLinhasNaoEncontradas(dadosParaDownloadLinhasNaoEncontradas)}
        >
            Export
        </button>
    )
}

export function AtencaoBaixarArquivoLinhasNaoEncontradas({handleMensagemAtencao, dadosParaDownloadLinhasNaoEncontradas}) {
    const qtd = dadosParaDownloadLinhasNaoEncontradas?.length -1;

    return (
        <RenderMensagemAtencao
            handleMensagemAtencao={handleMensagemAtencao}
            mensagemTitulo="Atenção"
            mensagemParagrafo1={(qtd) +" linhas não foram encontradas no sistema. O arquivo com as linhas não encontradas será baixado em alguns instantes."}
            mensagemBotao="FECHAR"
            semBotaoCancelar
        />
    )
}