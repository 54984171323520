import React, { useEffect, useState } from "react";

import { ptBR as xptBR } from '@mui/x-date-pickers/locales';
import dayJs from 'dayjs';
import 'dayjs/locale/pt-br';

import { Form } from "react-bootstrap"; /*eslint-disable*/
import '../../css/pavFiltros/filtros.css';
import { elipseVerde, fecharFiltroVerde } from '../../utils/alternanciaIcones';
// import Calendario from '../calendario/calendario'
import moment from 'moment';
// import FiltroArquivo from './filtroArquivo';

// COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js';

import { useDispatch, useSelector } from 'react-redux';

import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import CheckboxContainer from "../MaterialUI/checkbox";

import { api } from "../../conexoes/api";
import { filtrosArrayBarra, maximoTagsExibidas, recarregarBarraFiltragem, revelarBarraFiltragem } from '../../redux/actions';


const arrOperadoras = [
    { value: '1', label: 'Claro' },
    { value: '2', label: 'Vivo' },
    { value: '4', label: 'Tim' },
    { value: '72', label: 'Arqia' },
    { value: '5', label: 'Algar' },
    { value: '77', label: 'Neo' },
    // { value: '', label: 'Todas' },
];

const arrStatus = [
    { value: '2', label: 'Concluída' },
    { value: '1', label: 'Aberta' },
    { value: '4', label: 'Andamento' },
    { value: '3', label: 'Cancelada' },
    // { value: '', label: 'Todos' },
];

const defaultGreen = '#33CC99';

export default function FiltrosFatSolicitacoes(props) {
    //HOOKS DOS VALORES DOS FILTROS
    const [filtroDataInicial, setFiltroDataInicial] = useState('')
    const [filtroDataFinal, setFiltroDataFinal] = useState('')
    const [filtroOperadora, setFiltroOperadora] = useState('')
    const [filtroStatus, setFiltroStatus] = useState('')
    const [DsSolicitacaoTipo, setDsSolicitacaoTipo] = useState("")
    const [cdSolicitacaoTipo, setCdSolicitacaoTipo] = useState()
    const [dsFornecedor, setDsFornecedor] = useState("Selecione")
    const [cdFornecedor, setCdFornecedor] = useState()
    const [solicitacaoTipoLista, setSolicitacaoTipoLista] = useState([])
    const [filtroLoaded, setFiltroLoaded] = useState(false)
    const localTema = localStorage.getItem('tema')
    const temaEscuro = (localTema === 'escuro');

    const despacho = useDispatch();
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let filtros = useSelector(state => state.filtrosState.filtros);
    let arrayFiltrosSimcard = []

    const classNameComboBoxDemo = temaEscuro ? 'combo-box-demo-noturno' : 'combo-box-demo';
    const classNameSelectTipoCliente = temaEscuro ? 'select-tipo-cliente-noturno' : 'select-tipo-cliente';

    const radioStyle = {
        '& .MuiSvgIcon-root': {
            fontSize: '20px !important',
        },
        color: defaultGreen,
            '&.Mui-checked': {
            color: defaultGreen,
        },
    }


    async function handleCancelar() {
        props.setRevelarDropFiltros(false)
    }

    function hendlerGetdadosFiltros(listaTipos){
        if(!filtros.vazio){
            //seta os valores dos inputs
            if(filtros.DATA_INICIO !=''  && filtros.DATA_INICIO){
                let auxData = new Date(filtros.DATA_INICIO)
                // auxData.setDate(auxData.getDate()+1)
                setFiltroDataInicial(auxData)
            }
            if(filtros.DATA_FIM != '' && filtros.DATA_FIM){
                let auxData = new Date(filtros.DATA_FIM)
                // auxData.setDate(auxData.getDate()+1)
                setFiltroDataFinal(auxData)
            }

            if(filtros.TIPO_SOLICITACAO && filtros.TIPO_SOLICITACAO != ''){
                const found = listaTipos.find(item => item.label === filtros.TIPO_SOLICITACAO)
                setCdSolicitacaoTipo(found?.id)
            }


            if(filtros.FORNECEDOR_SOLICITACAO && filtros.FORNECEDOR_SOLICITACAO != '')
               setCdFornecedor(filtros.FORNECEDOR_SOLICITACAO)

            if(filtros.STATUS && filtros.STATUS != '')
               setFiltroStatus(filtros.STATUS)

            if(filtros.OPERADORA && filtros.OPERADORA != '')
               setFiltroOperadora(filtros.OPERADORA)
        }
    }

    async function getSolicitacaoConectividadeTipos() 
    {
        const {data} = await api.get('/m1/consultar/tipo_solicitacao');
        const idFilter = [19];
        const arr = data.dados.filter(item => !idFilter.includes(item.cd_crm_solicitacao_tipo));
        return arr.map(item => ({
            id: item.cd_crm_solicitacao_tipo,
            label: item.ds_crm_solicitacao_tipo,
            nomeVeye: item.ds_crm_nome_veye
        }));
    }

    async function getSolicitacaoFaturasTipos () {
        const {data} = await api.get('/m1/consultar/fat_solicitacao_tipo');
        
        return data.dados.map(item => ({
            id: item.cd_fat_solicitacao_tipo,
            label: item.ds_solicitacao_tipo
        })); 
    }

    useEffect(() => {
        async function renderSolicitacaoTipos() {
            const urlPagina = window.location.href;
            let transformedData;

            if( urlPagina.includes('veye/solicitacoes/faturas') ) {
                transformedData = await getSolicitacaoFaturasTipos();
            } else {
                transformedData = await getSolicitacaoConectividadeTipos();
            }

            setSolicitacaoTipoLista(transformedData);
            if(transformedData?.length && (filtros.TIPO_SOLICITACAO && filtros.TIPO_SOLICITACAO != '')){
                let aux = transformedData.find(solicitacao => solicitacao.label == filtros.TIPO_SOLICITACAO)

                setDsSolicitacaoTipo(aux.label)
            }
            return transformedData;
        }

        async function carregaFiltro() {
            const lista = await renderSolicitacaoTipos();
            hendlerGetdadosFiltros(lista);
            setFiltroLoaded(true);
        }
        carregaFiltro();
    }, []);

    useEffect(() => {
        if(solicitacaoTipoLista?.length && cdSolicitacaoTipo){
            let solicita = solicitacaoTipoLista.find(solicitacao => solicitacao.id == cdSolicitacaoTipo)
            setDsSolicitacaoTipo(solicita.label);
        }
    }, [solicitacaoTipoLista]);




    //verifica se os radios butons estao ativos
    function handlerCheck(element,nome){
        if(filtros[element] == nome){
            return true
        }
        else{
            return false
        }
    }

    function ListCheckboxOperadora() {
        return (
            <ListCheckbox
                fieldName="OPERADORA"
                title='Operadora'
                valorAtual={filtroOperadora}
                setValor={setFiltroOperadora}
                arrOpcoes={arrOperadoras}
            />
        )
    }

    function ListCheckboxStatus() {
        return (
            <ListCheckbox
                fieldName="STATUS"
                title='Status'
                valorAtual={filtroStatus}
                setValor={setFiltroStatus}
                arrOpcoes={arrStatus}
                // className='checkbox-status'
            />
        )
    }

    function ListCheckboxStatus_OLD() {

        return (
            <div className={'filtro-novo-body-section'}>
                <div className={'filtro-novo-body-titulo fonte-cor-1'}>Status</div>
                <div className={'filtro-novo-radio-content'}>
                    {arrStatus.map(({value, label}) => {
                        return (
                            <div key={value} className={'radio-wrapper'}>
                                <Radio
                                    checked={filtroStatus == value}
                                    sx={radioStyle}
                                    value={value}
                                    name="STATUS"
                                    onChange={() => setFiltroStatus(value)}
                                />
                                <RadioButtonLabel>{label}</RadioButtonLabel>
                        </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    function handleFuncaoEstadosRedux(){ // quando clica no botão Filtrar
        ColetaClickstream(1235) /*fatSolicitacoesTabelaDropFiltrosFiltrar*/
        if(filtroDataInicial !== '' && filtroDataFinal !== '' && filtroDataInicial && filtroDataFinal && moment(filtroDataInicial)._isValid && moment(filtroDataFinal)._isValid){
            arrayFiltrosSimcard.push("Data: "+moment(filtroDataInicial).format("DD-MM-YYYY")+" até "+moment(filtroDataFinal).format("DD-MM-YYYY")+"?DATA_INICIO")
        }

        if(filtroStatus != '' && filtroStatus) {
            const todosStatus = filtroStatus === '';
            if(!todosStatus) {
                filtroStatus.split(',').forEach(op => {
                    arrayFiltrosSimcard.push(`Status: ${parseCheckboxLabel(op, arrStatus)} ${op}`)
                })
            }
        }

        if(filtroOperadora != '' && filtroOperadora) {
            const todasOperadoras = filtroOperadora === '';

            if(!todasOperadoras) {
                filtroOperadora.split(',').forEach(op => {
                    arrayFiltrosSimcard.push(`${parseCheckboxLabel(op, arrOperadoras)} ${op}`)
                })
            }
        }

        if(cdSolicitacaoTipo != '' && cdSolicitacaoTipo)
            arrayFiltrosSimcard.push("Tipo: "+DsSolicitacaoTipo+"?TIPO_SOLICITACAO")

        if(cdFornecedor != '' && cdFornecedor && dsFornecedor != "Selecione")
            arrayFiltrosSimcard.push("Fornecedor: "+dsFornecedor+"?FORNECEDOR_SOLICITACAO")

            
        despacho(maximoTagsExibidas(props.setRevelarDropFiltros))
        despacho(filtrosArrayBarra(arrayFiltrosSimcard))
        despacho(revelarBarraFiltragem(true))
        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    }



    useEffect(() => {
        //Check se todas as variaveis estao limpas
        function handlerCheckarTudoLimpo(verificador = []){
            if(filtroDataInicial == '') verificador.push(1)
            if(filtroDataFinal =='') verificador.push(1)
            //tamanho igual a quantidade de variaveis
            if(verificador.length === 4) return false
            else return true
        }

        async function handlerAtivarBotoes(){
            //se for alterado algo vai abilitar os botoes
            if(props.primeiraVez && !props.foiLimpado && await handlerCheckarTudoLimpo()) props.setFoiSelecionado(true);
            else props.setFoiSelecionado(false);
        }
        
        //limpa os dados de todas as variaveis
        if(props.foiLimpado){
            setFiltroDataInicial('')
            setFiltroDataFinal('')
            setFiltroOperadora('')
            setFiltroStatus('')
            setDsSolicitacaoTipo('')
            setCdSolicitacaoTipo()
            setDsFornecedor('')
            setCdFornecedor()
            props.setFoiLimpado(false)
        }

        handlerAtivarBotoes()
        props.setPrimeiraVez(true)
    }, [filtroDataInicial,filtroDataFinal,filtroStatus,filtroOperadora,cdSolicitacaoTipo,cdFornecedor,props.foiLimpado]);



    function handleChangeTipoSolicitacao(event, newValue) {
        if(newValue){
            setDsSolicitacaoTipo(newValue.label)
            setCdSolicitacaoTipo(newValue.id)
        } else {
            setDsSolicitacaoTipo('')
            setCdSolicitacaoTipo('')
        }

    }

    function dateHandler(newValue, time) {
        if(time == 'inicio'){
            setFiltroDataInicial(new Date(newValue.$d))
        }
        else if (time == 'fim'){
            setFiltroDataFinal(new Date(newValue.$d))
        }
    }
  
    return (
        <>
            {filtroLoaded &&
                <Form ref={props.modal} onSubmit={props.enviarFiltros} className="drop-acoes-container-filtro-novo">
                    <div className="campo-select-triangulo-menor-cor-2-novo"></div>
                    <div className="drop-acoes-corpo-filtro-novo fundo-cor-13">
                        <div className={'filtro-novo-header'}>
                            <div className={'filtro-novo-titulo'}>
                                <img className={'filtro-novo-elipse-titulo'} src={elipseVerde()}/>
                                <div className={'fonte-cor-1'}>Filtros</div>
                            </div>
                            <div className={'filtro-novo-action-buttons'}>
                                <div onClick={props.limparFiltros} className={'filtro-novo-limpar-btn'}>
                                    <div className={'filtro-green-text'}>LIMPAR FILTROS</div>
                                    <img className={'filtro-limpar-icon'} src={fecharFiltroVerde()}/>
                                </div>
                                <div className={'filtro-novo-btns'}>
                                    <button type="submit" onClick={() => handleFuncaoEstadosRedux()} className={'filtrar-novo-btn'}>FILTRAR</button>
                                    <div onClick={() => handleCancelar()} className={'filtrar-novo-voltar-btn'}>VOLTAR</div>

                                </div>
                            </div>
                        </div>
                        <div className={'filtro-novo-body fundo-cor-14'}>
                            <div className={'filtro-novo-body-section'}>
                                <div className={'filtro-novo-body-titulo fonte-cor-1'}>Tipo</div>
                                <div className={'filtro-novo-tipo-content'}>
                                    <Autocomplete
                                        disablePortal
                                        id={classNameComboBoxDemo}
                                        value={DsSolicitacaoTipo}
                                        className={classNameSelectTipoCliente}
                                        onChange={(event, newValue) => {handleChangeTipoSolicitacao(event, newValue)}}
                                        options={solicitacaoTipoLista}
                                        sx={{ width: 250}}
                                        renderInput={(params) => <TextField {...params} name={'TIPO_SOLICITACAO'} label={"Selecione a solicitação"} />}
                                    />
                                </div>
                            </div>
                            <div className={'filtro-novo-body-section'}>
                                <div className={'filtro-novo-body-titulo fonte-cor-1'}>Data de criação</div>
                                <div className={'filtro-novo-date-content'}>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale='pt-br'
                                        localeText={xptBR.components.MuiLocalizationProvider.defaultProps.localeText}
                                    >
                                        <DateTimePicker
                                            sx={{width: 220}}
                                            id={classNameComboBoxDemo}
                                            name={'DATA_INICIO'}
                                            className={classNameSelectTipoCliente}
                                            dayOfWeekFormatter={(_day, weekday) => `${weekday.format('ddd')}.`}
                                            value={filtroDataInicial ? dayJs(filtroDataInicial) : null}
                                            format={'DD/MM/YYYY HH:mm'}
                                            label={'Data Inicial'}
                                            onChange={(newValue) => dateHandler(newValue, 'inicio')}
                                        />

                                        <DateTimePicker
                                            sx={{width: 220}}
                                            id={classNameComboBoxDemo}
                                            name={'DATA_FIM'}
                                            className={classNameSelectTipoCliente}
                                            dayOfWeekFormatter={(_day, weekday) => `${weekday.format('ddd')}.`}
                                            value={filtroDataFinal ? dayJs(filtroDataFinal) : null}
                                            format={'DD/MM/YYYY HH:mm'}
                                            label={'Data Final'}
                                            onChange={(newValue) => dateHandler(newValue, 'fim')}
                                        />
                                    </LocalizationProvider>

                                </div>
                            </div>

                            <ListCheckboxOperadora />

                            <ListCheckboxStatus />
                        </div>
                    </div>
                </Form>
            }
        </>

    );
}

function ListCheckbox({fieldName, title, valorAtual, setValor, arrOpcoes, className='' }) {
    function onChangeCheckbox (codOpcao, desmarcou) {
        // Primeiro, dividimos o estado atual em um array de números
        const stateArray = valorAtual.split(',').filter(op => op !== '');
        let res;

        if(desmarcou) {
            // Se o valor já existe, removemos ele do estado
            const updatedStateArray = stateArray.filter((value) => value != codOpcao);

            res = updatedStateArray.join(',');
        }
        else {
            stateArray.push(codOpcao);
            res = stateArray.join(',');
        }

        setValor(res);
    }

    return <div className={`filtro-novo-body-section ${className}`}>
        <div className={'filtro-novo-body-titulo fonte-cor-1'}>{title}</div>
        <div className={'filtro-novo-checkbox-content'}>
            {arrOpcoes.map(({label, value}) => {
                const selecionado = isSelecionado(valorAtual, value);
                return (
                    <CheckboxContainer
                        key={label}
                        name={fieldName}
                        label={label}
                        valueChecked={selecionado ? value: ''}
                        isChecked={selecionado}
                        onChange={() => onChangeCheckbox(value, selecionado)}
                    />
                )
            })}
        </div>
    </div>
}

function parseCheckboxLabel(option, arrOptions) {
    const objOption = arrOptions.find(op => op.value === option);

    if(objOption) {
        return objOption.label;
    }
}

const isSelecionado = (arr, value) => {
    return !!arr?.split(',')?.find(op => op === value);
}

function RadioButtonLabel({children}) {
    return <div className={'radiobutton-title fonte-cor-1'}>{children}</div>
}