import React, { useState } from 'react'
import ClienteVerde from '../../../icones/iconesVersaoVerde/cliente.svg'
import LocalizacaoVerde from '../../../icones/iconesVersaoVerde/localizacao.svg'
import SimcardVerde from '../../../icones/iconesVersaoVerde/simcard.svg'
import {
  iconeCliente,
  iconeLocalizacaoPreenchido,
  iconeSimcard
} from '../../../utils/alternanciaIcones'
import decryptJWT from '../../../utils/decryptJWT'
import ClientesConectados from './ClientesConectados'
import Consumo from './Consumo'
import Historico from './Historico'
import Localizacao from './Localizacao'
import Simcards from './Simcards'

import * as S from './styles'
const ICONE_DEFAULT = (defaultIcon, selectedIcon) => ({
  default: defaultIcon(),
  selected: selectedIcon,
  width: '22px',
  height: '22px'
})

const OPCOES_DEFAULT = [
  {
    type: 'localizacao',
    label: 'LOCALIZAÇÃO',
    icone: ICONE_DEFAULT(iconeLocalizacaoPreenchido, LocalizacaoVerde)
  },
  {
    type: 'simcards',
    label: 'SIM CARDS',
    icone: ICONE_DEFAULT(iconeSimcard, SimcardVerde)
  },
  {
    type: 'clientes_conectados',
    label: 'CLIENTES CONECTADOS',
    icone: ICONE_DEFAULT(iconeCliente, ClienteVerde)
  }
  // TODO: Comentado até rotas de histórico e consumo serem implementadas
  // {
  //   type: "historico",
  //   label: "HISTÓRICO",
  //   icone: ICONE_DEFAULT(iconeHistorico_2, HistoricoVerde),
  // },
  // {
  //   type: "consumo",
  //   label: "CONSUMO",
  //   icone: ICONE_DEFAULT(iconeConsumo, ConsumoVerde),
  // },
]

export default function DetalhamentoLinha({
  setAuxAcaoMostrarEmDispositivos,
  row
}) {
  const [menuSelecionado, setMenuSelecionado] = useState('')
  const isLocalizacao = menuSelecionado === 'localizacao'
  const isSimcards = menuSelecionado === 'simcards'
  const isClientesConectados = menuSelecionado === 'clientes_conectados'
  const isHistorico = menuSelecionado === 'historico'
  const isConsumo = menuSelecionado === 'consumo'

  const isClienteValido = () => decryptJWT('codigoCliente') == 1
  const OPCOES = OPCOES_DEFAULT.filter((i) => {
    if (i.type === 'clientes_conectados') return isClienteValido()
    return true
  })

  return (
    <S.Container>
      <S.Opcoes itens={OPCOES.length}>
        {OPCOES.map((item) => (
          <S.Opcao
            onClick={() => setMenuSelecionado(item.type)}
            key={item.type}
          >
            <S.Img
              width={item.icone.width}
              height={item.icone.height}
              src={
                item.type === menuSelecionado
                  ? item.icone.selected
                  : item.icone.default
              }
              alt={item.label}
            />
            <S.Label selected={item.type === menuSelecionado}>
              {item.label}
            </S.Label>
          </S.Opcao>
        ))}
      </S.Opcoes>
      <S.GlobalStyles />
      {isLocalizacao && <Localizacao row={row} />}
      {isSimcards && <Simcards cdPortadora={row.id} />}
      {isClientesConectados && (
        <ClientesConectados
          setAuxAcaoMostrarEmDispositivos={setAuxAcaoMostrarEmDispositivos}
          cdPortadora={row.id}
        />
      )}
      {isHistorico && <Historico />}
      {isConsumo && <Consumo />}
    </S.Container>
  )
}
