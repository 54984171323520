import styled from "styled-components";

export const ContainerAction = styled.div`
  margin-left: -10px;
  display: flex;
  height: 100%;
`;

export const ConteudoForm = styled.div`
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
`;
