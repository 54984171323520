import { Button as ButtonMantine } from '@mantine/core'
import styled, { css } from 'styled-components'

const getStyles = (variant, theme, disabled) => {
  if (disabled)
    return {
      color: `${theme.cor1} !important`,
      backgroundColor: '#787B7A !important'
    }

  const variantopt = {
    primary: { color: theme.cor1, backgroundColor: theme.cor11 },
    outline: { color: theme.cor11, 'border-color': theme.cor11 },
    unstyled: { color: theme.cor11, padding: 0 },
    contained: {
      color: theme.cor54,
      backgroundColor: theme.cor27,
      'font-weight': '700'
    },
    containedRemove: {
      color: '#D32F2F',
      backgroundColor: theme.cor27,
      'font-weight': '700'
    },
    selected: { color: theme.cor1, backgroundColor: theme.cor11 },
    select: { color: theme.cor9, backgroundColor: theme.cor67 }
  }
  return variantopt[variant]
}

export const Button = styled(ButtonMantine)`
  .mantine-Button-label {
    font-size: 14px;
  }

  && {
    border-radius: 24px;
    g:nth-last-child(1) circle:nth-last-child(2) {
      fill: transparent;
    }

    ${({ variant, theme, disabled }) => css`
      ${getStyles(variant, theme, disabled)}
    `}
  }
`
