import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  gap: 16px;
`

export const Card = styled.div`
  background-color: ${({ theme }) => theme.cor40}!important;
  padding: 16px;
  border-radius: 8px;
`

export const FieldsWrapper = styled.div`
  display: grid;
  gap: 8px;
`
