//IMPORTAÇÕES
import React, { useEffect, useRef, useState } from "react"; /*eslint-disable*/
import { Button } from "react-bootstrap";
import { alertaSucesso } from "../../../utils/alternanciaIcones";

// COLETA CLICKSTREAM
/*  COMO USAR
    Importe a modal para dentro do componente que irá fazer uso dela.
    ex: import MensagemSucesso from '../componentesModais/mensagens/mensagemSucesso'

    Crie um hook apropriado para esconder e exibir a mensagem quando necessário.
    ex: const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)

    Após isso, posicione-a dentro do return do seu componente e passe os props para
    customizar o conteúdo da mensagem da seguinte forma.
    ex: { revelarMensagemSucesso &&
            <div className="componente-modal-overlay">
                <div className="componente-modal">
                    <MensagemSucesso
                        handleMensagemSucesso={handleMensagemSucesso}
                        mensagemTitulo={"TITULO DA MENSAGEM"}
                        mensagemParagrafo1={"CORPO DA MENSAGEM 1"}
                        mensagemParagrafo2={"CORPO DA MENSAGEM 2"}
                        mensagemParagrafo3={"CORPO DA MENSAGEM 3"}
                        mensagemBotao={"TEXTO DO BOTÃO"}
                    />
                </div>
            </div>
        }
        
    Agora crie uma função para manejar o estado da mensagem e executar a função de
    fechamento padrão caso necessário.
    ex: function handleMensagemSucesso(confirmacao){
            if(revelarMensagemSucesso === false){
                setRevelarMensagemSucesso(true)
            }else{
                setRevelarMensagemSucesso(false)
                if(confirmacao === true){
                    //ALGUMA FUNÇÃO
                }
            }
        }
    Ou se não precisar executar alguma outra funcao quando for verdadeiro bas apenas passar 
    para o handleMensagemSucesso o seu set trocando ela mesma de valor
    ex:
        <MensagemSucesso
            handleMensagemSucesso={() =>  setRevelarMensagemSucesso(!revelarMensagemSucesso)}  
            mensagemTitulo={"TITULO DA MENSAGEM"}
            mensagemParagrafo1={"CORPO DA MENSAGEM 1"}
            mensagemParagrafo2={"CORPO DA MENSAGEM 2"}
            mensagemParagrafo3={"CORPO DA MENSAGEM 3"}
            mensagemBotao={"TEXTO DO BOTÃO"}
        />  
    */

export default function MensagemSucesso(props) {
  //HOOK PRINCIPAIS
  const [mensagemTitulo, setMensagemTitulo] = useState("");
  const [mensagemParagrafo1, setMensagemParagrafo1] = useState("");
  const [mensagemParagrafo2, setMensagemParagrafo2] = useState("");
  const [mensagemParagrafo3, setMensagemParagrafo3] = useState("");
  const [mensagemBotao, setMensagemBotao] = useState("");
  //HOOKS MODAL
  const [clickFora, setClickFora] = useState(false);
  const modal = useRef();

  const handleClickFora = (e) => {
    if (!modal.current.contains(e.target)) {
      handleCancelar(false);
    }
  };

  const handleClickDentro = () => setClickFora(false);

  function handleCancelar(confirmacao) {
    if (confirmacao === true) {
      props.handleMensagemSucesso(true);
    } else {
      props.handleMensagemSucesso(false);
    }
  }

  useEffect(() => {
    setMensagemTitulo(props.mensagemTitulo);
    setMensagemParagrafo1(props.mensagemParagrafo1);
    setMensagemParagrafo2(props.mensagemParagrafo2);
    setMensagemParagrafo3(props.mensagemParagrafo3);
    setMensagemBotao(props.mensagemBotao);
    document.addEventListener("mousedown", handleClickFora);
    return () => document.removeEventListener("mousedown", handleClickFora);
  }, []);

  return (
    <div className="mensagem-overlay">
      <div className="mensagem-container">
        <div ref={modal} className="mensagem-corpo fundo-cor-6">
          <div className="mensagem-container-icone">
            <img
              className="mensagem-icone"
              src={alertaSucesso()}
              alt="sucesso"
            />
          </div>
          <div className="mensagem-container-titulo">
            <div className="fonte-cor-1 label-titulo-mensagem">
              {mensagemTitulo}
            </div>
          </div>
          <div>
            <div className="fonte-cor-1 label-corpo-mensagem">
              {mensagemParagrafo1}
            </div>
          </div>
          <div>
            <div className="fonte-cor-1 label-corpo-mensagem">
              {mensagemParagrafo2}
            </div>
          </div>
          <div>
            <div className="fonte-cor-1 label-corpo-mensagem">
              {mensagemParagrafo3}
            </div>
          </div>
          <div className="mensagem-container-botao">
            <Button
              className="mensagem-botao-confirmar"
              variant="success"
              size="sm"
              onClick={() => handleCancelar(true)}
            >
              <div className="fonte-cor-4">{mensagemBotao}</div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export function RenderMensagemSucesso(props) {
  return (
    <div className="componente-modal-overlay">
      <div className="componente-modal">
        <MensagemSucesso {...props} />
      </div>
    </div>
  );
}
