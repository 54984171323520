import React, { useEffect, useState } from 'react'
import { BrowserRouter, Redirect, Route } from 'react-router-dom'
import CabecalhoSistema from '../cabecalhoSistema'
import logout from '../components/logout/logout'
import TabelaImeiLog from '../components/painelAdministrativo/logs/imeiLog/tabela'
import TabelaCliente from '../components/painelAdministrativo/pavCliente/tabela'
import TabelaCrudRBAC from '../components/painelAdministrativo/pavCrudRBAC/tabela'
import TabelaElemento from '../components/painelAdministrativo/pavElemento/tabela'
import TabelaGit from '../components/painelAdministrativo/pavGit/tabela'
import TabelaMenu from '../components/painelAdministrativo/pavMenu/tabela'
import TabelaModulo from '../components/painelAdministrativo/pavModulo/tabela'
import TabelaParceiro from '../components/painelAdministrativo/pavParceiro/tabela'
import TabelaPerfil from '../components/painelAdministrativo/pavPerfil/tabela'
import TabelaSistema from '../components/painelAdministrativo/pavSistema/tabela'
import TabelaTela from '../components/painelAdministrativo/pavTela/tabela'
import TabelaUsuario from '../components/painelAdministrativo/pavUsuario/tabela'
import TabelaVersionamento from '../components/painelAdministrativo/pavVersionamento/tabela'
import NavegacaoSistema from '../navegacaoSistema'

import clickstreamAnalise from '../components/clickstreamAnalise/clickstreamAnalise.js'
import '../components/clickstreamAnalise/clickstreamColeta.js'
import TabelaAcessoLog from '../components/painelAdministrativo/logs/acessoLog/tabela'
import TabelaRequestLog from '../components/painelAdministrativo/logs/requestLog/tabela'
import CadastroPermissao from '../components/painelAdministrativo/pavPermissao/cadastro'
import TabelaCadastroPerfil from '../components/painelCadastros/perfil/tabela'
import TabelaDispositivos from '../components/painelDispositivos/dspSimcard/tabelaSimcard'
import TabelaCentralMensagens from '../components/painelVirtueyes/centralMensagens/tabela'
import MigracaoPlanoDashboard from '../components/painelVirtueyes/migracaoPlano/dashboard/index.js'
import MigracaoPlanoGerenciamento from '../components/painelVirtueyes/migracaoPlano/tabela'
import TabelaPedidoSmS from '../components/painelVirtueyes/pedidoSms/tabelaPedidoSms'
import TabelaBloqueioAcoes from '../components/painelVirtueyes/solicitacaoBloqueio/tabela'
import TabelaClientesAcoes from '../components/painelVirtueyes/solicitacaoCliente/tabela'
import TabelaOperadoraAcoes from '../components/painelVirtueyes/solicitacaoOperadora/tabela'
import TabelaClientes from '../components/painelVirtueyes/virtuTabelaClientes/tabelaClientes'
//TELAS DO PAINEL SMS
import TabelaMensagensSms from '../components/painelSms/smsMensagens/tabela'
import TabelaTemplateSms from '../components/painelSms/smsTemplate/tabela'
//PAINEL PEDIDOS
import TabelaNovoPedidoSms from '../components/painelPedidos/pedidosSms/tabela'
// PAINEL FINANCEIRO
import TabelaFinanceiro from '../components/painelFinanceiro/tabela'
// PAINEL AUTOMAÇÃO
import TabelaAutomacao from '../components/painelAutomacao/tabelaAutomacao'
/* PAINEL TUTORIAIS */
import PainelTutoriais from '../components/painelTutoriais/painelTutoriais'

//TELAS DO PAINEL PEDIDOS
import TabelaSimcardPedidos from '../components/painelPedidos/pedidosSimcard/tabela'

//MÓDULO DE LINHAS RESETADAS DA TRUCKS
import TabelaLinhasRestTrucks from '../components/painelResetTrucks/tabela'

//MÓDULO MENSAGENS
import DashboardGeral from '../components/painelDashboard/dashboard'
import DashboardSMS from '../components/painelDashboard/dashboardsms'
import TabelaMensagens from '../components/painelMensagens/tabela'
//PAINEL USUARIOS
import TabelaUsuarioCliente from '../components/painelUsuarios/tabela'

//PAINEL FATURAS
import TabelaConectSolicitacoes from '../components/painelFaturas/conectSolicitacoes/tabela'
import FaturasCadastroCicloVida from '../components/painelFaturas/fatCicloVida/tabela'
import FatConciliacao from '../components/painelFaturas/fatConciliacao/tabela'
import FaturasCadastroContas from '../components/painelFaturas/fatContas/tabela'
import FatContestacao from '../components/painelFaturas/fatContestacao/tabela'
import DashboardFaturas from '../components/painelFaturas/fatDashboard/dashboard'
import TabelaDispostiviosFaturas from '../components/painelFaturas/fatDispositivos/tabela'
import FaturasCadastroEmpresas from '../components/painelFaturas/fatEmpresas/tabela'
import FatFaturas from '../components/painelFaturas/fatFaturas/tabela'
import FatTabelaFebraban from '../components/painelFaturas/fatFebraban/tabela'
import FatTabelaFornecedores from '../components/painelFaturas/fatFornecedores/tabela'
import FatTabelaOperadoras from '../components/painelFaturas/fatOperadoras/tabela'
import FaturasPedidos from '../components/painelFaturas/fatPedidos/tabela'
import FatTabelaPlanos from '../components/painelFaturas/fatPlanos/tabela'
import FatRelatorios from '../components/painelFaturas/fatRelatorios/tabela'
import FatTabelaServico from '../components/painelFaturas/fatServico/tabela'
import TabelaFatSolicitacoes from '../components/painelFaturas/fatSolicitacoes/tabela'
import SimcardsVirgensSolicitacoes from '../pages/solicitacoes/index.jsx'
// PAINEL RELATORIOS
import PainelRelatorio from '../components/painelRelatorio/tabela'
// PAINEL CADASTRO
import PainelCadastroCliente from '../components/painelCadastros/cadCliente/tabela'
import trial from '../components/painelFaturas/fatTrial/trial'
import TelaInicial from '../components/telaInicial/telaInicial'

import BreadCrumbs from '../components/BreadCrumbs/BreadCrumbs'
import telaLogin from '../components/login/login'
import PainelLogin from '../components/painelLogin/painelLogin'
import TabelaPortadoras from '../pages/portadoras'
import TabelaSimcardsVirgens from '../pages/simcards-virgens/index.jsx'
import routers from '../statics/routers.json'

//CAPTURA A URL E O LOCALSTORAGE ATUAL
const url = window.location.href

//VALIDA O CONTEÚDO DA URL CAPTURADA
const validation = url.includes('sso')
  ? 'sso'
  : url.includes('algar')
    ? 'algar'
    : 'veye'

//OBJETOS DE REDIRECIONAMENTO
const algar = {
  pathname: '/login',
  algar: true
}

const sso = {
  pathname: '/login',
  sso: true
}

const IMAGEMS_TEMA_ESCURO = {
  img_nav_1:
    'https://veye.s3.sa-east-1.amazonaws.com/icones/virtueyesSimbolo.svg',
  img_nav_2:
    'https://veye.s3.sa-east-1.amazonaws.com/icones/virtueyesBranco.svg',
  img_nav_3: 'none',
  img_inicial: 'none'
}

const IMAGEMS_TEMA_CLARO = {
  img_nav_1:
    'https://veye.s3.sa-east-1.amazonaws.com/icones/virtueyesSimbolo.svg',
  img_nav_2:
    'https://veye.s3.sa-east-1.amazonaws.com/icones/virtueyesPreto.svg',
  img_nav_3: '',
  img_inicial:
    'https://veye.s3.sa-east-1.amazonaws.com/icones/tela_inicial_v2.svg'
}

export default function Routers({
  buscarTema,
  coresTema,
  modoTema,
  setBuscarTema,
  setModoTema
}) {
  const localTema = localStorage.getItem('tema')
  let temaAtual = localTema || 'claro'
  const isEscuro = temaAtual === 'escuro'

  const imgTema = () => {
    temaAtual = localTema === 'escuro' ? 'escuro' : 'claro'

    if (isEscuro) return IMAGEMS_TEMA_ESCURO
    else return IMAGEMS_TEMA_CLARO
  }

  const [imagensTema, setImagensTema] = useState(imgTema())

  useEffect(() => {
    if (localTema === 'claro' || localTema === 'escuro')
      setImagensTema(imgTema())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buscarTema, modoTema])

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      exact
      {...rest}
      render={(props) =>
        localStorage.getItem('@veye-Token') ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: routers.login, state: { from: props.location } }}
          />
        )
      }
    />
  )

  return (
    <BrowserRouter>
      {validation === 'sso' || validation === 'algar' ? (
        <Route
          path={routers.login}
          exact
          component={() => (
            <PainelLogin
              buscarTema={buscarTema}
              setBuscarTema={setBuscarTema}
            />
          )}
        />
      ) : (
        <Route path={routers.login} exact={true} component={telaLogin} />
      )}
      {validation === 'sso' ? (
        <Route path="/" exact>
          <Redirect from="/" to={sso} />
        </Route>
      ) : validation === 'algar' ? (
        <Route path="/" exact>
          <Redirect from="/" to={algar} />
        </Route>
      ) : (
        <Route path="/" exact>
          <Redirect from="/" to={routers.telaInicial} />
        </Route>
      )}
      <Route path={routers.logout} exact component={logout} />
      <Route path={routers.trial} component={trial} />
      <Route
        path={routers.telaInicial}
        render={() =>
          localStorage.getItem('@veye-Token') ? (
            <>
              <NavegacaoSistema imagensTema={imagensTema} />
              <div id="container-tela">
                <CabecalhoSistema
                  modoTema={modoTema}
                  setModoTema={setModoTema}
                />
                <div id="container-corpo">
                  <BreadCrumbs />
                  <PrivateRoute
                    path={routers.telaInicial}
                    component={() => <TelaInicial imagensTema={imagensTema} />}
                  />
                  <PrivateRoute
                    path={routers.dispositivos}
                    component={() => <TabelaDispositivos />}
                  />
                  <PrivateRoute
                    path={routers.painelSistema}
                    component={() => <TabelaSistema />}
                  />
                  <PrivateRoute
                    path={routers.painelModulo}
                    component={() => <TabelaModulo />}
                  />
                  <PrivateRoute
                    path={routers.painelTela}
                    component={() => <TabelaTela />}
                  />
                  <PrivateRoute
                    path={routers.painelElemento}
                    component={() => <TabelaElemento />}
                  />
                  <PrivateRoute
                    path={routers.painelUsuarios}
                    component={() => <TabelaUsuario />}
                  />
                  <PrivateRoute
                    path={routers.tabelaVersoes}
                    component={() => <TabelaVersionamento />}
                  />
                  <PrivateRoute
                    path={routers.painelVersionamento}
                    component={() => <TabelaVersionamento permissoesAdm />}
                  />
                  <PrivateRoute
                    path={routers.painelGit}
                    component={() => <TabelaGit />}
                  />
                  <PrivateRoute
                    path={routers.painelClientes}
                    component={() => <TabelaCliente />}
                  />
                  <PrivateRoute
                    path={routers.painelParceiros}
                    component={() => <TabelaParceiro />}
                  />
                  <PrivateRoute
                    path={routers.painelPapeis}
                    component={() => <TabelaCrudRBAC />}
                  />
                  <PrivateRoute
                    path={routers.painelPerfil}
                    component={() => <TabelaPerfil />}
                  />
                  <PrivateRoute
                    path={routers.painelRequestLog}
                    component={() => <TabelaRequestLog />}
                  />
                  <PrivateRoute
                    path={routers.painelAcessoLog}
                    component={() => <TabelaAcessoLog />}
                  />
                  <PrivateRoute
                    path={routers.painelPermissoes}
                    component={() => <CadastroPermissao />}
                  />
                  <PrivateRoute
                    path={routers.painelClickstreamAnalise}
                    component={clickstreamAnalise}
                  />
                  <PrivateRoute
                    path={routers.virtueyesClientes}
                    component={() => <TabelaClientes />}
                  />
                  <PrivateRoute
                    path={routers.virtueyesCentralMensagens}
                    component={() => <TabelaCentralMensagens />}
                  />
                  <PrivateRoute
                    path={routers.virtueyesPedidosSMS}
                    component={() => <TabelaPedidoSmS />}
                  />
                  <PrivateRoute
                    path={routers.virtueyesMigracaoPlanoGerenciamento}
                    component={() => <MigracaoPlanoGerenciamento />}
                  />
                  <PrivateRoute
                    path={routers.virtueyesMigracaoPlanoDashboard}
                    component={() => <MigracaoPlanoDashboard />}
                  />
                  <PrivateRoute
                    path={routers.virtueyesClientesAcoes}
                    component={() => <TabelaClientesAcoes />}
                  />
                  <PrivateRoute
                    path={routers.virtueyesOperadoraAcoes}
                    component={() => <TabelaOperadoraAcoes />}
                  />
                  <PrivateRoute
                    path={routers.virtueyesBloqueioAcoes}
                    component={() => <TabelaBloqueioAcoes />}
                  />
                  <PrivateRoute
                    path={routers.smsMensagens}
                    component={() => <TabelaMensagensSms />}
                  />
                  <PrivateRoute
                    path={routers.smsTemplate}
                    component={() => <TabelaTemplateSms />}
                  />
                  <PrivateRoute
                    path={routers.pedidosSms}
                    component={() => <TabelaNovoPedidoSms />}
                  />
                  <PrivateRoute
                    path={routers.pedidosSimcard}
                    component={() => <TabelaSimcardPedidos />}
                  />
                  <PrivateRoute
                    path={routers.financeiro}
                    component={() => <TabelaFinanceiro />}
                  />
                  <PrivateRoute
                    path={routers.dashboardDispositivos}
                    component={() => <DashboardGeral />}
                  />
                  <PrivateRoute
                    path={routers.dashboardSMS}
                    component={() => <DashboardSMS />}
                  />
                  <PrivateRoute
                    path={routers.automacao}
                    component={() => <TabelaAutomacao />}
                  />
                  <PrivateRoute
                    path={routers.tutoriais}
                    component={() => <PainelTutoriais />}
                  />
                  <PrivateRoute
                    path={routers.mensagens}
                    component={() => <TabelaMensagens />}
                  />
                  <PrivateRoute
                    path={routers.usuarios}
                    component={() => <TabelaUsuarioCliente />}
                  />
                  <PrivateRoute
                    path={routers.faturasPedidos}
                    component={() => <FaturasPedidos />}
                  />
                  <PrivateRoute
                    path={routers.faturasContas}
                    component={() => <FaturasCadastroContas />}
                  />
                  <PrivateRoute
                    path={routers.faturasFebraban}
                    component={() => <FatTabelaFebraban />}
                  />
                  <PrivateRoute
                    path={routers.faturasEmpresas}
                    component={() => <FaturasCadastroEmpresas />}
                  />
                  <PrivateRoute
                    path={routers.faturasPlanos}
                    component={() => <FatTabelaPlanos />}
                  />
                  <PrivateRoute
                    path={routers.faturasServicos}
                    component={() => <FatTabelaServico />}
                  />
                  <PrivateRoute
                    path={routers.faturasCicloVida}
                    component={() => <FaturasCadastroCicloVida />}
                  />
                  <PrivateRoute
                    path={routers.faturasOperadoras}
                    component={() => <FatTabelaOperadoras />}
                  />
                  <PrivateRoute
                    path={routers.solicitacoesFaturas}
                    component={() => <TabelaFatSolicitacoes />}
                  />
                  <PrivateRoute
                    path={routers.solicitacoesSimcardsEmBranco}
                    component={() => <SimcardsVirgensSolicitacoes />}
                  />
                  <PrivateRoute
                    path={routers.solicitacoesConectividade}
                    component={() => <TabelaConectSolicitacoes />}
                  />
                  <PrivateRoute
                    path={routers.faturasDispositivos}
                    component={() => <TabelaDispostiviosFaturas />}
                  />
                  <PrivateRoute
                    path={routers.faturasFornecedores}
                    component={() => <FatTabelaFornecedores />}
                  />
                  <PrivateRoute
                    path={routers.faturasFaturas}
                    component={() => <FatFaturas />}
                  />
                  <PrivateRoute
                    path={routers.faturasConciliacao}
                    component={() => <FatConciliacao />}
                  />
                  <PrivateRoute
                    path={routers.faturasContestacao}
                    component={() => <FatContestacao />}
                  />
                  <PrivateRoute
                    path={routers.faturasDashboard}
                    component={() => <DashboardFaturas />}
                  />
                  <PrivateRoute
                    path={routers.faturasRelatorios}
                    component={() => <FatRelatorios />}
                  />
                  <PrivateRoute
                    path={routers.painelRelatorios}
                    component={() => <PainelRelatorio />}
                  />
                  <PrivateRoute
                    path={routers.painelImeiLog}
                    component={() => <TabelaImeiLog />}
                  />
                  <PrivateRoute
                    path={routers.painelMenu}
                    component={() => <TabelaMenu />}
                  />
                  <PrivateRoute
                    path={routers.cadastroPerfil}
                    component={() => <TabelaCadastroPerfil />}
                  />
                  <PrivateRoute
                    path={routers.cadastroCliente}
                    component={() => <PainelCadastroCliente />}
                  />
                  <PrivateRoute
                    path={routers.portalCliente}
                    component={() => <PainelCadastroCliente />}
                  />
                  <PrivateRoute
                    path={routers.linhasReset}
                    component={() => <TabelaLinhasRestTrucks />}
                  />
                  <PrivateRoute
                    path={routers.simcardsEmBranco}
                    component={() => <TabelaSimcardsVirgens />}
                  />
                  <PrivateRoute
                    path={routers.portadoras}
                    component={() => <TabelaPortadoras />}
                  />
                </div>
              </div>
            </>
          ) : (
            <Redirect to={{ pathname: '/login' }} />
          )
        }
      />
    </BrowserRouter>
  )
}
