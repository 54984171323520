import React, { useState, useEffect } from 'react';/*eslint-disable*/
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';/*eslint-disable*/
import decryptJWT from '../../../../utils/decryptJWT'

import { api_svdispositivos } from '../../../../conexoes/api'

export default function GraficoConsumoCiclos(props) {
    const [consumo, setConsumo] = useState([]);
    const localTema = localStorage.getItem('tema');

    useEffect(() => {
        async function consumoMensal() {
            const { data } = await api_svdispositivos.get(`/api/v2/devices/simcard/${props.gsm}/consumption?codigocliente=${decryptJWT("codigoClienteErpAntigo")}&cicloatual=false&clientedono=${props.clientedono}`)
            setConsumo(data.consumo6ciclos)
        }
        consumoMensal();
    }, []);

    function CustomTooltip({ payload, label, active }) {
        if (active && payload !== null && payload.length > 0) {
            return (
                <div className="container-tooltip-grafico-ciclo-ultimo">
                    <p className="label-tooltip-grafico-ciclo-ultimo">Consumido: {parseFloat(`${payload[0] ? payload[0].value : '00'}`).toFixed(2)} MB</p>
                    <p className="label-tooltip-grafico-ciclo-ultimo">Plano: {payload[0] ? (payload[0].payload.n0_plano + payload[0].payload.exedente) : '00'} MB</p>
                </div>
            );
        }
        return null;
    }

    const temaClaro = (localTema === 'claro');
    const temaAlgarClaro = (localTema == 'algarClaro');
    const strokeColor = temaClaro || temaAlgarClaro ? "#000000" : '#FFFFFF';

    return (
        <div className="container-grafico-ciclo-ultimo">
            <ResponsiveContainer>
                <BarChart data={consumo}>
                    <CartesianGrid stroke={strokeColor} strokeDasharray="0" />
                    <XAxis stroke={strokeColor} dataKey="data" />
                    <YAxis stroke={strokeColor} dataKey="consumo_total_bytes" />
                    <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />} />
                    <Bar barSize={68} dataKey="consumo_total_bytes" fill="#36D896" background={{ fill: temaClaro || temaAlgarClaro ? "#ECF0EE" : '#1C1E1D' }} radius={5, 0, 0, 5}>
                        {
                            consumo.map((item_consumo, index) => (
                                <Cell key={`cell-${index}`} fill={item_consumo.consumo_total_bytes < item_consumo.n0_plano + item_consumo.exedente ? "#36D896" : "#cd2d37"} ></Cell>
                            ))
                        }
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}
