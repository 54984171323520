import { MultiSelect as MultiSelectMantine } from '@mantine/core'
import styled, { createGlobalStyle } from 'styled-components'

export const Img = styled.img`
  transition: ease-in 200ms;
  transform: ${({ isOpen }) => isOpen && 'rotate(180deg)'};
`

export const MultiSelect = styled(MultiSelectMantine)`
  .mantine-MultiSelect-input {
    border: none !important;
    color: ${({ theme }) => theme.cor9}!important;
    background-color: ${({ theme }) => theme.cor27}!important;
    height: 38px;
    width: ${({ width }) => width};
  }

  .mantine-MultiSelect-searchInput {
    font-size: 16px;
    margin: 0 !important;
    ::placeholder {
      color: ${({ theme }) => theme.cor39}!important;
      font-weight: 400 !important;
    }
  }

  .mantine-Input-rightSection {
    margin-right: 8px;
  }

  .mantine-MultiSelect-values {
    padding: 8px 10px !important;
    flex-wrap: nowrap;
    height: 36px !important;
  }

  .mantine-MultiSelect-defaultValue {
    margin: 0 !important;
    padding: 0 !important;
  }

  mantine-InputWrapper-root {
    padding: 10px !important;
  }

  .mantine-MultiSelect-defaultValueRemove,
  .mantine-MultiSelect-label,
  .mantine-MultiSelect-defaultValue {
    color: ${({ theme }) => theme.cor9} !important;
    font-size: 16px !important;
    background-color: transparent !important;
  }

  .mantine-MultiSelect-label {
    font-size: 20px !important;
  }
`

export const GlobalStyles = createGlobalStyle`
  .mantine-MultiSelect-dropdown {
    background-color: ${({ theme }) => theme.cor27} !important;
    border:none !important;
  }

  .mantine-MultiSelect-item {
    color: ${({ theme }) => theme.cor9} !important;
    background-color: transparent !important;
    font-size: 16px !important;
    
    & +&{
      margin-top: 2px !important;
    }

    :last-child{
     margin-top:3px !important;
    }
  }
`

export const LabelWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin: 0 !important;
`

export const Label = styled.div`
  font-size: 16px !important;
`

export const CustomItemWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

export const CustomItemSpan = styled.span`
  font-size: 16px !important;
  color: ${({ theme }) => theme.cor9} !important;
`
