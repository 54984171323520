import styled from 'styled-components'

export const MapaWrapper = styled.div`
  width: 1152px;
  max-width: 1152px;
  height: 623px;
`

export const ContainerAlerta = styled.div`
  width: 100%;
  display: grid;
  gap: 16px;
  justify-items: center;
`

export const MensagemAlerta = styled.span`
  text-align: center;
  color: ${({ theme }) => theme.cor9}!important;
`

export const TituloAlerta = styled.h1`
  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.cor9}!important;
`
