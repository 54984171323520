import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  .mantine-Popover-dropdown {
    background: ${({ theme }) => `${theme.cor23} !important`};
    color: ${({ theme }) => `${theme.cor9} !important`};
    margin-left: 40px;    
    border: 0.0625rem solid ${({ theme }) => `${theme.cor25}`} !important;
  }

  .mantine-Popover-arrow {
    border-width: 0 0 0.0625rem 0.0625rem !important;
    border-style: solid !important;
    border-color: ${({ theme }) => theme.cor25} !important;
  }
`

export const PopoverContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 8px;
`

export const Title = styled.div`
  font-weight: bold;
`
