import React from 'react'

import * as S from './styles'

export default function Modal({ position, children }) {
  return (
    <>
      <S.Group position={position}>{children}</S.Group>
    </>
  )
}
