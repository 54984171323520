import React from "react";
import { GoogleMap, withGoogleMap, withScriptjs } from "react-google-maps";
import HeatmapLayer from "react-google-maps/lib/components/visualization/HeatmapLayer";
import { GOOGLE_MAPS_JAVASCRIPT_API } from "../../utils/googleMapsApiKey";

function Mapa(props) {
  const arrayPontos = props.props.array || [];

  const pontosDeCalor = arrayPontos.map((ponto) => ({
    location: new window.google.maps.LatLng(ponto.latitude, ponto.longitude),
    weight: ponto?.intensidade || 1,
  }));

  return (
    <GoogleMap
      defaultZoom={10}
      defaultCenter={{
        lat: arrayPontos[0]?.latitude,
        lng: arrayPontos[0]?.longitude,
      }}
    >
      <HeatmapLayer
        data={pontosDeCalor}
        options={{
          radius: 20,
          opacity: 0.7,
          gradient: [
            "rgba(102, 255, 0, 0)",
            "rgba(147, 255, 0, 1)",
            "rgba(193, 255, 0, 1)",
            "rgba(238, 255, 0, 1)",
            "rgba(255, 225, 0, 1)",
            "rgba(255, 180, 0, 1)",
            "rgba(255, 135, 0, 1)",
            "rgba(255, 90, 0, 1)",
            "rgba(255, 45, 0, 1)",
            "rgba(255, 0, 0, 1)",
          ],
        }}
      />
    </GoogleMap>
  );
}

const WrappedMap = withScriptjs(withGoogleMap(Mapa));

export default function MapaGoogle(props) {
  return (
    <div style={{ width: "100%", height: "100%", zoom: "125%" }}>
      <WrappedMap
        props={props}
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places,visualization&key=${GOOGLE_MAPS_JAVASCRIPT_API}&amp;`}
        loadingElement={<div style={{ height: "100%" }} />}
        containerElement={<div style={{ height: "100%" }} />}
        mapElement={<div style={{ height: "100%" }} />}
      />
    </div>
  );
}
