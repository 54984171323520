import React from 'react'

import * as S from './styles'

export default function Tooltip({
  label,
  position = 'top',
  withArrow = true,
  children,
  multiline = true,
  width = 400
}) {
  const style = {
    textAlign: 'center',
    whiteSpace: 'normal',
    color: '#FFF'
  }

  const labelView = <div style={style}>{label}</div>

  return (
    <S.Tooltip
      width={width}
      multiline={multiline}
      label={labelView}
      color="#5b605d"
      withArrow={withArrow}
      position={position}
      transitionProps={{ duration: 500 }}
    >
      {children}
    </S.Tooltip>
  )
}
