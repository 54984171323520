//IMPORTAÇÕES
import React, { useEffect, useRef, useState } from 'react'; /*eslint-disable*/
import { Button, Form, Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { api } from '../../conexoes/api'; /*eslint-disable*/
import decryptJWT from '../../utils/decryptJWT';
import { isPortal } from '../../utils/nivelCliente';
import { validadorPermissaoElemento } from '../../utils/verificadorPermissaoElemento';
import MensagemErro from '../componentesModais/mensagens/mensagemErro';
import MensagemSucesso from '../componentesModais/mensagens/mensagemSucesso';
// SPINNER
import SpinerVeye from '../../components/spinnerVeye/spinnerVeye.js';

// ICONES
import { useDispatch, useSelector } from 'react-redux';
import {
  mudarDoppleGangerCliente,
  revelarAlterarLogin,
  revelarAlterarSenha,
  revelarDadosContaUsuario,
  revelarPerfilContaUsuario
} from '../../redux/actions/index';
import { logout } from '../../services/auth';
import { useGlobal } from '../../stores/useGlobal.js';
import { setaBranca } from '../../utils/alternanciaIcones';

export default function SelectTela(props) {
  const { revelarDadosCliente } = useGlobal()

  //HOOKS MODAL
  const [itenSelecionado, setItenSelecionado] = useState('')
  const [revelarRetornarCliente, setRevelarRetornarCliente] = useState(false)
  const [permissaoCarregarUsuario, setPermissaoCarregarUsuario] = useState()
  const [usuarioInicial, setUsuarioInicial] = useState(
    decryptJWT('usuarioInicial')
  )
  const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
  const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
  const [mensagemErro, setMensagemErro] = useState(
    'Ocorreu um erro. Por favor tente novamente mais tarde.'
  )

  const history = useHistory()

  const modal = useRef()
  const despacho = useDispatch()
  const [spinAtivo, setSpinAtivo] = useState(false)
  let dadosContaUsuario = useSelector(
    (state) => state.virtueyesState.revelarDadosContaUsuario
  )
  let perfilContaUsuario = useSelector(
    (state) => state.virtueyesState.revelarPerfilContaUsuario
  )
  let alterarLogin = useSelector(
    (state) => state.virtueyesState.revelarAlterarLogin
  )
  let alterarSenha = useSelector(
    (state) => state.virtueyesState.revelarAlterarSenha
  )
  let estadoExpansaoNavegacao = useSelector(
    (state) => state.manejadorState.expandirNavegacao
  )
  const permissaoElemento = useSelector(
    (state) => state.permissaoState.permissaoElemento
  )

  const cdClienteErp = decryptJWT('clientePrimario')
  const clienteNivel = decryptJWT('clienteNivel')

  const handleClickFora = (e) => {
    if (!modal.current.contains(e.target)) {
      handleCancelar()
    }
  }

  function handleCancelar() {
    props.setRevelarInfosUsuario(false)
  }

  function handleMensagemErro(confirmacao) {
    if (revelarMensagemErro === false) {
      setRevelarMensagemErro(true)
    } else {
      setRevelarMensagemErro(false)
      if (confirmacao === true) {
        //ALGUMA FUNÇÃO
      }
    }
  }

  function handleMensagemSucesso(confirmacao) {
    if (revelarMensagemSucesso === false) {
      setRevelarMensagemSucesso(true)
    } else {
      setRevelarMensagemSucesso(false)
      if (confirmacao === true) {
        window.location.reload()
        // props.handleAtualizar()
      } else {
        window.location.reload()
        //props.handleAtualizar()
      }
    }
  }

  useEffect(() => {
    async function handleValidarRetornar() {
      if (
        (await validadorPermissaoElemento(
          permissaoElemento,
          'veye/outros/dados/retornarCliente'
        )) == true
      ) {
        setRevelarRetornarCliente(true)
      } else {
        setRevelarRetornarCliente(false)
      }
    }
    handleValidarRetornar()
  }, [])

  useEffect(() => {
    async function handlerPermissoes() {
      setPermissaoCarregarUsuario(
        await validadorPermissaoElemento(
          permissaoElemento,
          'veye/virtueyes/clientes/carregarUsuaruio'
        )
      )
    }
    handlerPermissoes()
    document.addEventListener('mousedown', handleClickFora)
    return () => document.removeEventListener('mousedown', handleClickFora)
  }, [])

  function handleConta() {
    setItenSelecionado('Conta')
    if (dadosContaUsuario === false) {
      despacho(revelarDadosContaUsuario(true))
      props.setRevelarInfosUsuario(false)
    } else {
      despacho(revelarDadosContaUsuario(false))
      props.setRevelarInfosUsuario(false)
    }
  }

  async function handleDoppelgangerCliente() {
    setSpinAtivo(true)
    await api.post('/m1/alterar/usuario', {
      dados: {
        ds_cliente_texto: decryptJWT('codigoClienteErpAntigo'),
        cd_pav_cliente: decryptJWT('clienteOriginal')
      },
      condicao: {
        cd_pav_usuario: decryptJWT('codigo')
      },
      painel_virtueyes: true
    })

    let fatContas = []
    const contasUsuario = await api.get(
      '/m1/consultar/fat_conta/contas_usuario?cd_pav_usuario=' +
        decryptJWT('codigo')
    )
    await Promise.all(
      contasUsuario.data.dados.map((item) => {
        fatContas.push(item.cd_fat_conta)
      })
    )
    if (contasUsuario.data.dados.length == 0) {
      fatContas = [0]
    }
    /*
        PONTO DE ATENÇÃO!
        Os valores de:
            codigoCliente
            codigoClienteErpAntigo
            codigoClienteTexto
            clienteNivel
        Estão recebendo o clienteOriginal, por essa funcionalidade ser usada apenas pela VIRTUEYES. Caso necessário implementar para demais clientes
        e ainda estiverem atuando no LEGADO - dar a devida atenção a este detalhe.
        */
    const request =
      '/m1/servicos/alterar_token?login=' +
      decryptJWT('login') +
      '&email=' +
      decryptJWT('email') +
      '&codigo=' +
      decryptJWT('codigo') +
      '&codigoCliente=' +
      decryptJWT('clienteOriginal') +
      '&codigoClienteErpAntigo=' +
      decryptJWT('clienteOriginal') +
      '&codigoClienteTexto=' +
      decryptJWT('clienteOriginal') +
      '&nomeDeUsuario=' +
      decryptJWT('nomeDeUsuario') +
      '&perfil=' +
      decryptJWT('perfil') +
      '&cdSmsContaUsuario=' +
      decryptJWT('cdSmsContaUsuario') +
      '&id=' +
      decryptJWT('id') +
      '&fatContas=' +
      fatContas +
      '&clienteNivel=' +
      decryptJWT('clienteOriginal') +
      '&tipoPerfil=' +
      decryptJWT('tipoPerfil') +
      '&clientePrimario=' +
      decryptJWT('clientePrimario') +
      '&clienteOriginal=' +
      decryptJWT('clienteOriginal')
    await api
      .get(request)
      .then(function (response) {
        localStorage.setItem('@veye-Token', response.data.dados.token)
        localStorage.setItem(
          'codigoClienteErpAntigo',
          decryptJWT('codigoClienteErpAntigo')
        )
      })
      .catch(function (error) {})

    await api
      .get(
        '/m1/servicos/token/atualizarLogin?cd_pav_usuario=' +
          decryptJWT('codigo')
      )
      .then(async function (response) {
        localStorage.removeItem('temLinhasVivo')
        setRevelarMensagemSucesso(true)
      })
      .catch(function (error) {
        setMensagemErro(error.response.data.info)
        setRevelarMensagemErro(true)
      })

    setSpinAtivo(false)
    despacho(mudarDoppleGangerCliente(false))
  }

  function handlePerfil() {
    setItenSelecionado('Perfil')
    if (perfilContaUsuario === false) {
      despacho(revelarPerfilContaUsuario(true))
      props.setRevelarInfosUsuario(false)
    } else {
      despacho(revelarPerfilContaUsuario(false))
      props.setRevelarInfosUsuario(false)
    }
  }

  function handleAlterarLogin() {
    setItenSelecionado('Alterar login')
    if (alterarLogin === false) {
      despacho(revelarAlterarLogin(true))
      props.setRevelarInfosUsuario(false)
    } else {
      despacho(revelarAlterarLogin(false))
      props.setRevelarInfosUsuario(false)
    }
  }

  function handleAlterarSenha() {
    setItenSelecionado('Alterar senha')
    if (alterarSenha === false) {
      despacho(revelarAlterarSenha(true))
      props.setRevelarInfosUsuario(false)
    } else {
      despacho(revelarAlterarSenha(false))
      props.setRevelarInfosUsuario(false)
    }
  }

  async function handleDownloadContrato() {
    setItenSelecionado('Contrato')
    const downloadLink = document.createElement('a')
    const fileName = 'contrato.pdf'
    downloadLink.target = '_blank'
    const url = window.location.href.split('/')
    downloadLink.href = url[0] + '//' + url[2] + '/files/contrato.pdf'
    downloadLink.download = fileName
    downloadLink.click()
    props.setRevelarInfosUsuario(false)
  }

  const handleLogout = () => {
    logout()
    history.push('/login')
  }

  return (
    <Form ref={modal}>
      {spinAtivo && (
        <div className="componente-spinner-overlay">
          <div className="componente-spinner-container">
            <SpinerVeye />
          </div>
        </div>
      )}
      <Button
        className={
          estadoExpansaoNavegacao === true
            ? 'botao-expandir-infos-usuario'
            : 'botao-expandir-infos-usuario-2'
        }
        onClick={() => props.setRevelarInfosUsuario(false)}
      >
        <Image
          className={
            estadoExpansaoNavegacao === true
              ? 'icone-botao-expandir-infos-usuario'
              : 'icone-botao-expandir-infos-usuario-2'
          }
          src={setaBranca()}
        />
      </Button>
      <div
        className={
          estadoExpansaoNavegacao === true
            ? 'container-infos-usuario'
            : 'container-infos-usuario-2'
        }
      >
        <div
          className={
            estadoExpansaoNavegacao === true
              ? 'triangulo-infos-usuario'
              : 'triangulo-infos-usuario-2'
          }
        ></div>
        <div
          className={
            cdClienteErp == 2455
              ? 'corpo-infos-usuario-algar'
              : 'corpo-infos-usuario'
          }
        >
          {/* <div className={itenSelecionado==='Visualizar dados'?"item-menu-info-usuario-primeiro-desabilitado":"item-menu-info-usuario-primeiro"} onClick={() => handleVisualizarDados()}>
                        <span className="fonte-info-usuario">Visualizar dados</span>
                    </div> */}
          {revelarDadosCliente && decryptJWT('clienteOriginal') === '1' && (
            <>
              {/* <div className={!(itenSelecionado==="Visualizar dados" || itenSelecionado==="Retornar cliente") && "linha-menu-info-usuario"}/> */}
              <div
                className={
                  itenSelecionado === 'Retornar cliente'
                    ? 'item-menu-info-usuario-primeiro-desabilitado'
                    : 'item-menu-info-usuario-primeiro'
                }
                onClick={() => handleDoppelgangerCliente()}
              >
                <span className="fonte-info-usuario">Retornar cliente</span>
              </div>
              <div
                className={
                  !(
                    itenSelecionado === 'Retornar cliente' ||
                    itenSelecionado === 'Conta'
                  ) && 'linha-menu-info-usuario'
                }
              />
            </>
          )}
          {cdClienteErp != 2455 &&
            (decryptJWT('codigoClienteErpAntigo') != '2933' ||
              decryptJWT('codigoCliente') == '198') && (
              <>
                {/* <div className={!(itenSelecionado==='Retornar cliente' || itenSelecionado==='Conta') &&"linha-menu-info-usuario"}/> */}
                <div
                  className={
                    itenSelecionado === 'Conta'
                      ? 'item-menu-info-usuario-desabilitado'
                      : 'item-menu-info-usuario'
                  }
                  onClick={() => handleConta()}
                >
                  <span className="fonte-info-usuario">Conta</span>
                </div>
              </>
            )}
          {usuarioInicial && (
            <>
              <div
                className={
                  !(
                    itenSelecionado === 'Visualizar dados' ||
                    itenSelecionado === 'Retornar usuario'
                  ) && 'linha-menu-info-usuario'
                }
              />
              <div
                className={
                  itenSelecionado === 'Retornar usuario'
                    ? 'item-menu-info-usuario-primeiro-desabilitado'
                    : 'item-menu-info-usuario-primeiro'
                }
                onClick={() => handleRetornarUsuario()}
              >
                <span className="fonte-info-usuario">Retornar usuario</span>
              </div>
            </>
          )}
          {(decryptJWT('codigoClienteErpAntigo') != '2933' ||
            decryptJWT('codigoCliente') == '198') && (
            <>
              <div
                className={
                  !(
                    itenSelecionado === 'Conta' || itenSelecionado === 'Perfil'
                  ) && 'linha-menu-info-usuario'
                }
              />
              <div
                className={
                  itenSelecionado === 'Perfil'
                    ? 'item-menu-info-usuario-desabilitado'
                    : 'item-menu-info-usuario'
                }
                onClick={() => handlePerfil()}
              >
                <span className="fonte-info-usuario">Perfil</span>
              </div>
            </>
          )}
          <div
            className={
              !(
                itenSelecionado === 'Alterar login' ||
                itenSelecionado === 'Alterar senha'
              ) && 'linha-menu-info-usuario'
            }
          />
          <div
            className={
              itenSelecionado === 'Alterar senha'
                ? 'item-menu-info-usuario-desabilitado'
                : 'item-menu-info-usuario'
            }
            onClick={() => handleAlterarSenha()}
          >
            <span className="fonte-info-usuario">Alterar senha</span>
          </div>
          {cdClienteErp != 2455 &&
            (decryptJWT('codigoClienteErpAntigo') != '2933' ||
              decryptJWT('codigoCliente') == '198') && (
              <>
                <div
                  className={
                    !(
                      itenSelecionado === 'Perfil' ||
                      itenSelecionado === 'Alterar login'
                    ) && 'linha-menu-info-usuario'
                  }
                />
                <div
                  className={
                    itenSelecionado === 'Alterar login'
                      ? 'item-menu-info-usuario-desabilitado'
                      : 'item-menu-info-usuario'
                  }
                  onClick={() => handleAlterarLogin()}
                >
                  <span className="fonte-info-usuario">Alterar login</span>
                </div>
                {!isPortal() && (
                  <>
                    <div
                      className={
                        !(
                          itenSelecionado === 'Alterar senha' ||
                          itenSelecionado === 'Contrato'
                        ) && 'linha-menu-info-usuario'
                      }
                    />
                    <div
                      className={
                        itenSelecionado === 'Contrato'
                          ? 'item-menu-info-usuario-desabilitado'
                          : 'item-menu-info-usuario'
                      }
                      onClick={() => handleDownloadContrato()}
                    >
                      <span className="fonte-info-usuario">Contrato</span>
                    </div>
                  </>
                )}
              </>
            )}
          <div
            className={
              !(itenSelecionado === 'Alterar senha') &&
              'linha-menu-info-usuario'
            }
          />
          <div onClick={handleLogout} style={{ cursor: 'pointer' }}>
            <div className="item-menu-info-usuario-ultimo">
              <span className="fonte-info-usuario">Sair</span>
            </div>
          </div>
        </div>
      </div>
      {revelarMensagemErro && (
        <div className="componente-modal-overlay">
          <div className="componente-modal">
            <MensagemErro
              handleMensagemErro={handleMensagemErro}
              mensagemTitulo={'Erro'}
              mensagemParagrafo1={mensagemErro}
              mensagemBotao={'Retornar'}
            />
          </div>
        </div>
      )}
      {revelarMensagemSucesso && (
        <div className="componente-modal-overlay">
          <div className="componente-modal">
            <MensagemSucesso
              handleMensagemSucesso={handleMensagemSucesso}
              mensagemTitulo={'Sucesso'}
              mensagemParagrafo1={'Cliente original carregado.'}
              mensagemBotao={'OK'}
            />
          </div>
        </div>
      )}
    </Form>
  )
}
