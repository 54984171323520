import { useDisclosure } from '@mantine/hooks'
import React, { useEffect, useRef, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'

import '../../../css/painelDispositivos/dspSimcard/dspAcoes.css'
import iconeEditarVerde from '../../../icones/iconesVersaoClara/editar_verde.svg'
import buscarBranco from '../../../icones/iconesVersaoClara/search.svg'
import buscarPreto from '../../../icones/iconesVersaoEscura/search.svg'
import iconeCopiarVerde from '../../../icones/iconesVersaoVerde/copiar.svg'
import {
  desabilitarAcoes,
  desabilitarAcoesCliente,
  mudarFiltros,
  mudarManejador,
  revelarBarraFiltragem
} from '../../../redux/actions/index'
import { PortadorasService } from '../../../services/http/portadoras.service.js'
import { useGlobal } from '../../../stores/useGlobal.js'
import { setaDropdown } from '../../../utils/alternanciaIcones.js'
import { useCopiarParaAreaDeTransferencia } from '../../../utils/copiarTextoParaAreaDeTransferencia.jsx'
import decryptJWT from '../../../utils/decryptJWT'
import GirarSetaDropdown from '../../../utils/girarSetaDropdown.js'
import PaginacaoTabela from '../../../utils/paginacaoTabela.js'
import BlankSlate from '../../BlankSlate'
import BarraDadosCliente from '../../componentesModais/barraDadosCliente/barraDadosCliente.js'
import BarraFiltragem from '../../componentesModais/barraFiltragem/barraFiltragem.js'
import MensagemInformacao from '../../componentesModais/mensagens/mensagemInformacao.js'
import ActionIcon from '../../Mantine/ActionIcon'
import Botao from '../../Mantine/Botao'
import Group from '../../Mantine/Group'
import Input from '../../Mantine/Input'
import Notification from '../../Mantine/Notification/index.jsx'
import Popover from '../../Mantine/Popover'
import BarraTabelaLegenda from '../../tabelaSelecao/barraTabelaLegenda.js'
import { desselecionarTodosCheckboxes } from '../../tabelaSelecao/checkboxSelecao.js'
import DetalhamentoPortadora from '../DetalhamentoLinha/index.jsx'
import Mapa from '../Mapa/index.jsx'

import * as S from './styles.js'

let valorLimite = 50
let totalItens = 50
let contador = 0
let totalPaginas = 0

let auxDados = []

const TableprimaryKey = 'id'

const COUNT_ITEMS = true

//! Variável para gerenciar a redefinição dos filtros de portadora em dispositivos
let auxAcaoMostrarEmDispositivos = false

export default function TabelaPortadoras() {
  const {
    busca,
    filtros,
    setFiltros,
    revelarBarraFiltro,
    setRevelarBarraFiltro,
    revelarDadosCliente,
    separadorLinhaBusca,
    exportarRelatorio,
    setExportarRelatorio,
    selecionarParqueInteiro,
    setSelecionarParqueInteiro,
    mostrarAcao,
    setMostrarAcao,
    setIsLoadingGlobal
  } = useGlobal()

  const popoverRef = useRef(null)

  const [openedMapa, { open: openMapa, close: closeMapa }] =
    useDisclosure(false)

  const [
    openedNotificacao,
    { open: openNotificacao, close: closeNotificacao }
  ] = useDisclosure(false)

  const [
    openedMapaDeCalor,
    { open: openMapaDeCalor, close: closeMapaDeCalor }
  ] = useDisclosure(false)

  const codigoCliente = decryptJWT('codigoCliente')

  const COLUNAS = [
    {
      dataField: 'radio',
      text: 'Nome da Portadora',
      formatter: (cell, row) => {
        let newName = cell

        const handlePopoverOpen = () => {
          newName = cell
        }
        const handleInputChange = (e) => {
          newName = e.target.value
        }

        return (
          <S.TdComImagem>
            <span>{cell || '-'}</span>

            {codigoCliente !== '1' && (
              <S.TdIcon>
                <Popover
                  key={cell}
                  ref={popoverRef}
                  icon={iconeEditarVerde}
                  title="Editar nome da portadora"
                  onOpen={handlePopoverOpen}
                >
                  <Input
                    placeholder="Nome portadora"
                    value={newName}
                    variant="filled"
                    onChange={(e) => handleInputChange(e)}
                    maxLength={30}
                  />

                  <Group position="right" mt="xl">
                    <Botao
                      label="Cancelar"
                      uppercase={false}
                      variant="unstyled"
                      onClick={() => handleCancel()}
                    />
                    <Botao
                      disabled={false}
                      label="Salvar"
                      uppercase={false}
                      variant="unstyled"
                      onClick={() => {
                        handleSave(cell, newName, row.id)
                      }}
                    />
                  </Group>
                </Popover>
              </S.TdIcon>
            )}
          </S.TdComImagem>
        )
      }
    },
    {
      dataField: 'portadoraTipo',
      text: 'Classificação da Rede',
      editable: false,
      formatter: (cell) => cell || '-'
    },
    {
      dataField: 'rede',
      text: 'Rede',
      editable: false,
      formatter: (cell) => cell || '-'
    },
    {
      dataField: 'tecnologia',
      text: 'Tecnologia',
      editable: false,
      formatter: (cell) => cell || '-'
    },
    {
      dataField: 'mcc',
      text: 'MCC',
      editable: false,
      formatter: (cell) => cell || '-'
    },
    {
      dataField: 'mnc',
      text: 'MNC',
      editable: false,
      formatter: (cell) => cell || '-'
    },
    {
      dataField: 'lac',
      text: 'LAC',
      editable: false,
      formatter: (cell) => cell || '-'
    },
    {
      dataField: 'cellID',
      text: 'Cell ID',
      editable: false,
      formatter: (cell) => cell || '-'
    },
    {
      dataField: 'totalDeDispositivos',
      text: 'Total de dispositivos',
      editable: false,
      formatter: (cell) => cell || '-'
    },
    {
      dataField: 'online',
      text: 'Online',
      editable: false,
      formatter: (cell) => cell || '-'
    },
    {
      dataField: 'offline',
      text: 'Offline',
      editable: false,
      formatter: (cell) => cell || '-'
    },
    {
      dataField: 'latitude',
      text: 'Latitude',
      editable: false,
      formatter: (cell) => cell || '-'
    },
    {
      dataField: 'longitude',
      text: 'Longitude',
      editable: false,
      formatter: (cell) => cell || '-'
    },
    {
      dataField: 'uf',
      text: 'Estado',
      editable: false,
      formatter: (cell) => cell || '-'
    },
    {
      dataField: 'cidade',
      text: 'Cidade',
      editable: false,
      formatter: (cell, col) => {
        return cell ? (
          <S.TdComImagem>
            <span>{cell}</span>
            <ActionIcon
              alt="copiar"
              variant="transparent"
              onClick={() => handleCopiar(col)}
              icon={iconeCopiarVerde}
              tooltip={{
                label: 'Copiar endereço para a área de transferência',
                width: 200
              }}
            />
          </S.TdComImagem>
        ) : (
          '-'
        )
      }
    }
  ]

  const { getPortadoras, createReport, updateCarrierName } = PortadorasService
  const [showCaption, setShowCaption] = useState(false)
  const [paginaAtual, setPaginaAtual] = useState(1)
  const [limpandoSelecao, setLimpandoSelecao] = useState(false)
  const [mostrarSelecao, setMostrarSelecao] = useState()
  const [mostrarAlertaRelatorio, setMostrarAlertaRelatorio] = useState(false)

  const [dados, setDados] = useState([])
  const [notification, setNotification] = useState(null)

  const [simcardsSelecionados, setSimcardsSelecionados] = useState([])
  const despacho = useDispatch()

  const auxExportarRelatorio =
    exportarRelatorio &&
    exportarRelatorio?.acao === 'exportarDetalhamentoPortadora'

  const copiarParaAreaDeTransferencia = useCopiarParaAreaDeTransferencia()

  const mostrarMapa = mostrarAcao === 'revelarMostrarNoMapaPortadora'
  const mostrarMapaDeCalor = mostrarAcao === 'revelarMapaDeCalorPortadora'
  const localTema = localStorage.getItem('tema')
  let temaAtual = localTema || 'claro'
  const isEscuro = temaAtual === 'escuro'

  const corTema = () => {
    if (isEscuro) return buscarBranco
    else return buscarPreto
  }

  const handleSave = async (oldName, newName, idCarrier) => {
    if (oldName && newName && oldName !== newName) {
      updateCarrierName(idCarrier, newName)
        .then(() => {
          setDados((prev) => {
            const updatedData = prev.map((item) =>
              item.id === idCarrier ? { ...item, radio: newName } : item
            )
            return updatedData
          })

          setNotification({
            type: 'success',
            title: 'Nome editado',
            message: 'O nome da portadora foi editado com sucesso.'
          })
          openNotificacao()
        })
        .catch(() => {
          setNotification({
            type: 'error',
            title: 'Falha ao editar',
            message:
              'Não foi possível editar o nome da portadora, tente novamente.'
          })
          openNotificacao()
        })
    }

    popoverRef?.current?.closePopover()
  }

  const handleCancel = () => {
    popoverRef?.current?.closePopover()
  }

  useEffect(() => {
    if (mostrarMapa) openMapa()
  }, [mostrarMapa, openMapa])

  useEffect(() => {
    if (mostrarMapaDeCalor) openMapaDeCalor()
  }, [mostrarMapaDeCalor, openMapaDeCalor])

  const removeFiltros = () => {
    setFiltros({ vazio: 'vazio' })
    setRevelarBarraFiltro(false)
    despacho(mudarFiltros({ vazio: 'vazio' }))
    despacho(revelarBarraFiltragem(false))
  }

  useEffect(() => {
    setSelecionarParqueInteiro(false)
    if (!filtros.vazio && !filtros.GEOLOCATION && !filtros.ID_PORTADORA) {
      removeFiltros()
    }

    return () => {
      if (auxAcaoMostrarEmDispositivos) return
      if (filtros.GEOLOCATION || filtros.ID_PORTADORA) removeFiltros()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (auxExportarRelatorio) {
      const handleExportarLinhas = async () => {
        setIsLoadingGlobal(true)

        const bodyBase = {
          reportType: exportarRelatorio.tipo,
          filter: filtros,
          fetch: busca
        }

        if (bodyBase?.filter?.vazio) delete bodyBase.filter
        if (!bodyBase.fetch) delete bodyBase.fetch

        try {
          if (selecionarParqueInteiro) {
            createReport(bodyBase)
          } else {
            const auxArrayCdSimcard = simcardsSelecionados
              .map((simcard) => simcard[TableprimaryKey])
              .filter((idPortadora) => idPortadora)

            const bodySelecaoNormal = {
              ...bodyBase,
              carrierID: auxArrayCdSimcard
            }
            await createReport(bodySelecaoNormal)
          }
          setMostrarAlertaRelatorio(true)
        } catch (erro) {
          console.error(erro)
        } finally {
          setExportarRelatorio({})
          setIsLoadingGlobal(false)
        }
      }

      handleExportarLinhas()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auxExportarRelatorio])

  useEffect(() => {
    setPaginaAtual(1)
  }, [filtros, busca])

  useEffect(() => {
    despacho(mudarManejador('portadoras'))
  }, [despacho])

  useQuery(
    [
      `/portadoras/fetchAll?pagina=${paginaAtual}`,
      paginaAtual,
      valorLimite,
      filtros,
      busca,
      COUNT_ITEMS
    ],
    async () => {
      try {
        setIsLoadingGlobal(true)
        return await getPortadoras(
          paginaAtual,
          valorLimite,
          filtros,
          busca,
          separadorLinhaBusca,
          COUNT_ITEMS
        )
      } catch (err) {
        console.error(err)
      } finally {
        setIsLoadingGlobal(false)
      }
    },
    {
      onSuccess: async (data) => {
        contador = (paginaAtual - 1) * valorLimite
        totalItens = data?.item_count
        totalPaginas = Math.ceil(totalItens / valorLimite)

        const novosItens = [...auxDados]

        data.list?.forEach((portadora, index) => {
          novosItens[contador + index] = portadora
        })

        setDados(novosItens)
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false
    }
  )

  const trocarPagina = (novaPagina) => {
    if (totalPaginas <= 1) return

    setPaginaAtual(novaPagina)
  }

  const opcoesPaginacao = {
    page: paginaAtual,
    showTotal: true,
    sizePerPage: valorLimite,
    firstPageTitle: 'Primeira',
    lastPageTitle: 'Última',
    nextPageTitle: 'Proxima',
    prePageTitle: 'Anterior',
    custom: true,
    onPageChange: (newPage) => {
      trocarPagina(newPage)
    }
  }

  const onClickSelecaoParqueTodo = (novoValor) => {
    if (novoValor === false) {
      desselecionarTodosCheckboxes('limparSelecao')

      setLimpandoSelecao(true)
      setShowCaption(false)
    }
    handleAlternarSelecao(novoValor)
  }

  const handleAlternarSelecao = (valor) => {
    setSelecionarParqueInteiro(valor)
    setMostrarSelecao(valor)
    if (valor) {
      despacho(desabilitarAcoes(false))
      despacho(desabilitarAcoesCliente(true))
    } else {
      despacho(desabilitarAcoes(true))
      despacho(desabilitarAcoesCliente(false))
    }
    setSimcardsSelecionados([])
  }

  const getLimiteNaPagina = () =>
    contador + valorLimite <= totalItens ? contador + valorLimite : totalItens
  const getTotalItensNaPagina = () => getLimiteNaPagina() - contador

  const handleSelecionarLinha = (simcard) => {
    const selecionado = dados
      .filter((item) => item)
      .find((item) => item[TableprimaryKey] === simcard)

    if (selecionado) setSimcardsSelecionados((prev) => [...prev, selecionado])
    despacho(desabilitarAcoes(false))
    despacho(desabilitarAcoesCliente(true))
  }

  const handleDesselecionarLinha = (simcard) => {
    setSimcardsSelecionados((prev) =>
      prev.filter((item) => item[TableprimaryKey] !== simcard)
    )
    if (simcardsSelecionados.length === 1) {
      despacho(desabilitarAcoes(true))
    }
  }

  const handleSelecionarTodasLinhas = (rows) => {
    setSimcardsSelecionados((prev) => [...prev, ...rows])
    despacho(desabilitarAcoes(false))
    despacho(desabilitarAcoesCliente(true))
  }

  const handleDesselecionarTodosLinhas = (rows) => {
    setSimcardsSelecionados((prev) =>
      prev.filter(
        (item) =>
          !rows.some((row) => row[TableprimaryKey] === item[TableprimaryKey])
      )
    )
    if (simcardsSelecionados.length === rows.length) {
      despacho(desabilitarAcoes(true))
    }
  }

  const itemExpandido = {
    className: 'fundo-cor-1',
    renderer: (row) => {
      return (
        <DetalhamentoPortadora
          setAuxAcaoMostrarEmDispositivos={() =>
            (auxAcaoMostrarEmDispositivos = true)
          }
          row={row}
        />
      )
    },
    expandHeaderColumnRenderer: () => <div className="tela-background" />,
    expandColumnRenderer: (rowKey) => {
      return (
        <S.Botao onClick={() => GirarSetaDropdown(rowKey.rowKey)}>
          <S.Img
            id={rowKey.rowKey}
            className="icone-botao-expandir-row nao-selecionavel"
            src={setaDropdown()}
          />
        </S.Botao>
      )
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    headerClasses: 'tabela-coluna-primeira-header'
  }

  const selecaoLinhas = {
    mode: 'checkbox',
    hideSelectColumn: mostrarSelecao,
    hideSelectAll: mostrarSelecao,
    onSelect: (row, isSelect) => {
      if (limpandoSelecao) setLimpandoSelecao(false)

      if (isSelect) {
        handleSelecionarLinha(row[TableprimaryKey])
      } else {
        handleDesselecionarLinha(row[TableprimaryKey])
        if (showCaption) setShowCaption(false)
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (limpandoSelecao) setLimpandoSelecao(false)

      if (isSelect) {
        handleSelecionarTodasLinhas(rows)
        setShowCaption(true)
      } else {
        handleDesselecionarTodosLinhas(rows)
        if (showCaption) setShowCaption(false)
      }
    },
    selectionRenderer: ({ mode, ...rest }) => {
      return (
        <>
          <input type={mode} className="input-checkbox-simcard" {...rest} />
          <label className="label-checkbox-simcard" />
        </>
      )
    },
    selectionHeaderRenderer: ({ mode, ...rest }) => {
      if (rest.checked && !showCaption) {
        setShowCaption(true)
      }

      return (
        <>
          <input
            type={mode}
            className="input-checkbox-header-simcard"
            {...rest}
          />
          <label className="label-checkbox-header-simcard" />
        </>
      )
    }
  }

  const handleCopiar = (col) => {
    const endereco = [
      col.logradouro ? col.logradouro : '',
      col.numero && col.numero !== 0 ? col.numero : '',
      col.bairro ? col.bairro : '',
      col.cidade ? col.cidade : '',
      col.uf ? col.uf : '',
      col.cep ? col.cep : ''
    ]
      .filter((value) => value)
      .join(', ')

    if (!endereco) return

    copiarParaAreaDeTransferencia(endereco)

    setNotification({
      type: 'success',
      title: 'Endereço copiado',
      message: 'O endereço foi copiado para a área de transferência.'
    })
    openNotificacao()
  }

  const handleClose = () => {
    setMostrarAcao(undefined)
    closeMapa()
    closeMapaDeCalor()
  }

  return (
    <div>
      {revelarDadosCliente && <BarraDadosCliente />}
      {revelarBarraFiltro && <BarraFiltragem />}
      {dados?.length == 0 &&
      codigoCliente == 1 &&
      (filtros.vazio || Object.keys(filtros)?.length === 0) ? (
        <BlankSlate
          icon={corTema()}
          title="Pesquise a sua portadora."
          subTitle="Encontramos muitas portadoras em nosso banco de dados. Ultilize o filtro acima e pesquise por rede, classificação de rede, tecnologia, estado ou cidade para obter resultados."
        />
      ) : (
        <S.TabelaWrapper>
          <PaginationProvider pagination={Paginador(opcoesPaginacao)}>
            {({ paginationProps, paginationTableProps }) => (
              <>
                {showCaption && (
                  <BarraTabelaLegenda
                    setShowCaption={setShowCaption}
                    onClick={onClickSelecaoParqueTodo}
                    parqueTodoSelecionado={selecionarParqueInteiro}
                    filtros={revelarBarraFiltro}
                    totalItens={totalItens}
                    getTotalItensNaPagina={getTotalItensNaPagina}
                    itemLabel="portadoras"
                  />
                )}
                <BootstrapTable
                  classes="tabela"
                  condensed
                  keyField={TableprimaryKey}
                  data={dados}
                  columns={COLUNAS}
                  selectRow={selecaoLinhas}
                  bootstrap4
                  expandRow={itemExpandido}
                  bordered={false}
                  noDataIndication="Nenhum item encontrado"
                  {...paginationTableProps}
                />
                <PaginacaoTabela
                  setPaginaAtual={trocarPagina}
                  totalPaginas={totalPaginas}
                  totalItens={totalItens}
                  valorLimite={valorLimite}
                  paginaAtual={paginaAtual}
                  page={paginationProps.page}
                  onPageChange={paginationProps.onPageChange}
                />
              </>
            )}
          </PaginationProvider>
          <div className="itens-exibidos-tabela fonte-cor-1">
            Mostrando
            <span className="label-tabela-itens-exibidos">{contador + 1}</span>-
            <span className="label-tabela-itens-exibidos">
              {getLimiteNaPagina()}
            </span>
            de
            <span className="label-tabela-itens-exibidos">{totalItens}</span>
          </div>
        </S.TabelaWrapper>
      )}

      {mostrarMapa && (
        <Mapa
          selecionados={simcardsSelecionados}
          title="Mostrar no mapa"
          opened={openedMapa}
          close={handleClose}
        />
      )}
      {mostrarMapaDeCalor && (
        <Mapa
          tipoMapa="calor"
          selecionados={simcardsSelecionados}
          title="Mapa de calor"
          opened={openedMapaDeCalor}
          close={handleClose}
        />
      )}
      {openedNotificacao && (
        <Notification
          type={notification.type}
          title={notification.title}
          message={notification.message}
          autoHide={5000}
          onClose={closeNotificacao}
        />
      )}
      {mostrarAlertaRelatorio && (
        <div id="mensagem-relatorio" className="componente-modal-overlay">
          <div className="componente-modal">
            <MensagemInformacao
              handleMensagemInformacao={() => setMostrarAlertaRelatorio(false)}
              mensagemTitulo={
                selecionarParqueInteiro
                  ? 'O arquivo está sendo gerado'
                  : 'O arquivo foi gerado'
              }
              mensagemParagrafo1={
                selecionarParqueInteiro
                  ? 'Esse processo levará alguns minutos, você poderá fazer o download do seu arquivo na tela de Relatórios assim que o link do mesmo estiver disponível'
                  : 'Faça o download do seu arquivo na tela de Relatórios'
              }
              mensagemBotao="Voltar"
              marginTop={40}
            />
          </div>
        </div>
      )}
    </div>
  )
}
