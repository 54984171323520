import React, { useEffect, useState } from "react";
import * as S from "./styles";
import Group from "../../../Mantine/Group";
import Botao from "../../../Mantine/Botao";
import Grafico from "./Grafico";

const buttons = [
  { value: "24h", label: "Últimas 24h" },
  { value: "2d", label: "Últimos 2 dias" },
  { value: "3d", label: "Últimos 3 dias" },
  { value: "4d", label: "Últimos 4 dias" },
  { value: "5d", label: "Últimos 5 dias" },
  { value: "6d", label: "Últimos 6 dias" },
  { value: "7d", label: "Últimos 7 dias" },
];
const DEFAULT_DATA = { start: null, end: null };

export default function Historico() {
  const [selectedButton, setSelectedButton] = useState(null);
  const [selectedDate, setSelectedDate] = useState(DEFAULT_DATA);

  const handleButtonFilter = (value) => {
    if (value !== "periodo") setSelectedDate(DEFAULT_DATA);
    setSelectedButton(value);
  };

  const handleSelectedDate = (field, value) => {
    setSelectedDate((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <S.HistoricoWrapper>
      <Group>
        {buttons.map((button) => (
          <Botao
            key={button.value}
            label={button.label}
            onClick={() => handleButtonFilter(button.value)}
            variant={button.value === selectedButton ? "selected" : "select"}
          />
        ))}
      </Group>
      <Grafico />
    </S.HistoricoWrapper>
  );
}
