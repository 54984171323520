//IMPORTAÇÕES
import React, { useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { connect } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import { EstilosGlobais } from './css/global'
import { claroTema, escuroTema } from './css/temas'
import Routers from './routers/index.jsx'

const queryClient = new QueryClient()

function App() {
  const localTema = localStorage.getItem('tema')
  let temaAtual = localTema || 'claro'
  const isEscuro = temaAtual === 'escuro'

  const corTema = () => {
    if (isEscuro) return escuroTema
    else return claroTema
  }

  const [coresTema, setCoresTema] = useState(corTema())
  const [modoTema, setModoTema] = useState(false)
  const [buscarTema, setBuscarTema] = useState(false)

  useEffect(() => {
    setCoresTema(corTema())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buscarTema, modoTema])

  const originalSetItem = localStorage.setItem

  localStorage.setItem = function () {
    const event = new Event('itemInserted')
    document.dispatchEvent(event)
    originalSetItem.apply(this, arguments)
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={coresTema}>
        <EstilosGlobais />
        <Routers
          buscarTema={buscarTema}
          coresTema={coresTema}
          setBuscarTema={setBuscarTema}
          modoTema={modoTema}
          setModoTema={setModoTema}
        />
      </ThemeProvider>
    </QueryClientProvider>
  )
}

function mapStateToProps(state) {
  return {
    systemThemeState: state.systemControlStates.systemTheme
  }
}

export default connect(mapStateToProps)(React.memo(App))
