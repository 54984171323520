/* eslint-disable react-hooks/exhaustive-deps */
//IMPORTAÇÕES
import lodash from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { api, api_papeis } from '../../../conexoes/api'
import { elipseVerde, fecharJanela } from '../../../utils/alternanciaIcones'
//MODAIS
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
// SPINNER
import { useDispatch } from 'react-redux'
import {
  revelarCadastrarUsuario,
  revelarEdicaoUsuario
} from '../../../redux/actions/index'
import decryptJWT from '../../../utils/decryptJWT'
import { MascaraCpf, MascaraTelefone } from '../../../utils/mascarasCampos'
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'
//ESTILIZACAO
import { Step, StepLabel, Stepper } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Checkbox from '@mui/material/Checkbox'
import '../../../css/painelUsuarios/estilo.css'
import Seta from '../../../icones/iconesVersaoEscura/setaDown.svg'

const DESCRICAO_SIMCARDS_BRANCO = {
  LEGADO_ELEMENTO_275: 'Pedidos',
  LEGADO_TELA_90: 'Solicitações',
  LEGADO_ELEMENTO_274: 'Simcards em branco  - provisionamento'
}

export default function CadastroPerfil(props) {
  const localTema = localStorage.getItem('tema')
  const { register, handleSubmit, errors } = useForm()
  const despacho = useDispatch()
  const modal = useRef()
  const steps = [
    'Dados de usuário',
    'Papéis de permissionamento',
    'Confirmação'
  ]
  const stepsStyle = {
    '& .Mui-active': {
      '& .MuiStepIcon-root': {
        color: '#33CC99',
        fontSize: '1.5rem !important'
      }
    },
    '& .Mui-completed': {
      '& .MuiStepIcon-root': {
        color: '#33CC99',
        fontSize: '1.5rem !important'
      }
    },
    '& .Mui-disabled': {
      '& .MuiStepIcon-root': {
        fontSize: '1.5rem !important'
      }
    }
  }
  const stepLabelStyleEscuro = {
    '& .MuiStepLabel-label': {
      color: '#FFF !important',
      '& .Mui-active': {
        color: '#FFF !important'
      }
    }
  }
  const checkboxTemaClaro = {
    '& .MuiSvgIcon-root': {
      fontSize: '25px !important'
    },
    '&.Mui-checked': { color: '#33CC99' }
  }
  const checkboxTemaEscuro = {
    '& .MuiSvgIcon-root': {
      fontSize: '25px !important'
    },
    '&.Mui-checked': { color: '#33CC99' }
  }

  const [checkBoxValueMap, setCheckBoxValueMap] = useState({})
  const [cdUser, setCdUser] = useState(
    props.cdUsuario ? props.cdUsuario : undefined
  )
  const [noModule, setNoModule] = useState(false)
  const [resumoLista, setResumoLista] = useState([])
  const [moduloLista, setModuloLista] = useState([])
  // const [isReady, setIsReady] = useState(false)
  const [stepActive, setStepActive] = useState(0)
  const [spinAtivo, setSpinAtivo] = useState(true)
  const [cdClienteN3, setCdClienteN3] = useState(
    props.cdClienteN3 ? props.cdClienteN3 : undefined
  )
  const [cdCliente, setCdCliente] = useState(decryptJWT('codigoCliente'))
  const [loggedUserId, setLoggedUserId] = useState(decryptJWT('codigo'))
  const [cdPerfil, setCdPerfil] = useState(
    props.cdPerfil ? props.cdPerfil : undefined
  )
  const [dsNome, setDsNome] = useState(props.dsNome ? props.dsNome : undefined)
  const [phNome, setPhNome] = useState(
    props.dsNome ? 'Original: ' + props.dsNome : 'Insira o nome'
  )
  const [dsSobrenome, setDsSobrenome] = useState(
    props.dsSobrenome ? props.dsSobrenome : undefined
  )
  const [phSobrenome, setPhSobrenome] = useState(
    props.dsSobrenome ? 'Original: ' + props.dsSobrenome : 'Insira sobrenome'
  )
  const [dsCpf, setDsCpf] = useState(
    props.dsCpf ? MascaraCpf(props.dsCpf) : undefined
  )
  const [dsTelefone, setDsTelefone] = useState(
    props.dsTelefone ? MascaraTelefone(props.dsTelefone) : undefined
  )
  const [phTelefone, setPhTelefone] = useState(
    props.dsTelefone
      ? 'Original: ' + MascaraTelefone(props.dsTelefone)
      : '(00) 00000-0000'
  )
  const [dsEmail, setDsEmail] = useState(
    props.dsEmail ? props.dsEmail : undefined
  )
  const [phEmail, setPhEmail] = useState(
    props.dsEmail ? 'Original: ' + props.dsEmail : 'exemplo@email.com.br'
  )
  const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
  const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
  const [liberarCadastro, setLiberarCadastro] = useState(false)
  const [usuarioAtivo, setUsuarioAtivo] = useState(
    props.flAtivo !== undefined ? props.flAtivo : 1
  )
  const [mensagemErro, setMensagemErro] = useState('')
  const [mensagemSucesso, setMensagemSucesso] = useState('')
  let novosModulos
  let userPaperData

  async function getPaperData() {
    return await api_papeis.get('/api/v2/papers/read/group')
  }

  async function getUserPaperData(user) {
    const data = await api_papeis.get(
      '/api/v2/papers/read/group?cduser=' + user
    )

    return await formatarPermissoes(data)
  }

  async function getFilteredPermissions() {
    let loggedUserPermission = await getUserPaperData(loggedUserId)

    if (props.cadastroN3 && props.tipoN3 == 1) {
      let modulosN3 = [
        'Dispositivos',
        'Relatórios',
        'Dashboard',
        'Usuários',
        'Outros'
      ]
      loggedUserPermission = loggedUserPermission.filter((modulo) =>
        modulosN3.includes(modulo.descricao)
      )
    }

    let permissionsFiltered = novosModulos.filter(
      (modulo) =>
        modulo.codigo_papel ===
        loggedUserPermission.find(
          (moduloUser) => moduloUser.codigo_papel === modulo.codigo_papel
        )?.codigo_papel
    )
    permissionsFiltered.forEach((modulo) => {
      modulo.listaElementos = modulo.listaElementos.filter((elemento) =>
        loggedUserPermission
          .find((moduloUser) => moduloUser.codigo_papel === modulo.codigo_papel)
          ?.listaElementos.includes(elemento.codigo_papel)
      )
    })

    if (props.cdClienteN3) {
      let listaModulosCliente = await api.get(
        '/m1/consultar/modulo/modulo_cliente?clienteId=' + props.cdClienteN3
      )
      permissionsFiltered = permissionsFiltered.filter((modulo) =>
        listaModulosCliente.data.dados.find((moduloCliente) =>
          moduloCliente.ds_pav_modulo.includes(modulo.descricao)
        )
      )
    }
    return permissionsFiltered
  }

  function removeVeyePermissions(modulosLista) {
    let url = window.location.href
    //INICIALIZAÇÃO DO ARRAY DE PERMISSÕES EXCLUSIVAS DE AÇÕES DISPOSITIVOS
    //CONJUNTO DE PERMISSÕES EXCLUSIVAS VIRTUEYES EM DISPOSITIVOS - AMBIENTE INTEGRA
    let veyePermissionsDispositivos = [
      'LEGADO_ELEMENTO_42', // ATIVAR LINHA,
      'LEGADO_ELEMENTO_45', // BLOQUEAR LINHA,
      'LEGADO_ELEMENTO_46', // REMOVER BLOQUEIO,
      'LEGADO_ELEMENTO_50', // ATIVAR DEMONSTRAÇÃO,
      'LEGADO_ELEMENTO_52', // CANCELAR CONTA,
      'LEGADO_ELEMENTO_58', // ALTERAR PLANO/VALOR,
      'LEGADO_ELEMENTO_75', // CANCELAMENTO CONTA,
      'LEGADO_ELEMENTO_77', // BLOQUEAR TEMPORARIAMENTE,
      'LEGADO_ELEMENTO_80', // TROCA DE TITULARIDADE,
      'LEGADO_ELEMENTO_87', // ALTERAR PLANO,
      'LEGADO_ELEMENTO_88', // ALTERAR CICLO DE VIDA,
      'LEGADO_ELEMENTO_231', // TRANSFERIR LINHA,
      'LEGADO_ELEMENTO_233', // IOT TELECALL,
      'LEGADO_ELEMENTO_238' // ATIVAÇÃO AWS,
    ]

    //CONJUNTO DE PERMISSÕES EXCLUSIVOS VEYE PARA CADASTROS
    let veyePermissionsCadastros = [
      'LEGADO_TELA_85',
      'LEGADO_TELA_86',
      'LEGADO_TELA_5'
    ]

    //CONJUNTO DE PERMISSÕES EXCLUSIVOS VEYE PARA PEDIDOS
    let veyePermissionsPedidos = ['LEGADO_TELA_87']

    //REMOVE AS AÇÕES DA VIRTUEYES DO MODULO DE DISPOSITIVOS
    let moduloDispositivos = modulosLista.find(
      (modulo) => modulo.descricao === 'Dispositivos'
    )
    if (moduloDispositivos) {
      moduloDispositivos.listaElementos =
        moduloDispositivos.listaElementos.filter(
          (elemento) =>
            !veyePermissionsDispositivos.includes(elemento.codigo_papel)
        )
    }

    //REMOVE AS TELAS/AÇÕES DA VIRTUEYES DO MODULO DE CADASTROS
    let moduloCadastros = modulosLista.find(
      (modulo) => modulo.descricao === 'Cadastros'
    )
    if (moduloCadastros) {
      moduloCadastros.listaElementos = moduloCadastros.listaElementos.filter(
        (elemento) => !veyePermissionsCadastros.includes(elemento.codigo_papel)
      )
    }

    // /REMOVE AS TELAS/AÇÕES DA VIRTUEYES DO MODULO DE PEDIDOS
    //REMOVE AS TELAS DA VIRTUEYES DO MODULO
    let moduloPedidos = modulosLista.find(
      (modulo) => modulo.descricao === 'Pedidos'
    )
    if (moduloPedidos) {
      moduloPedidos.listaElementos = moduloPedidos.listaElementos.filter(
        (elemento) => !veyePermissionsPedidos.includes(elemento.codigo_papel)
      )
    }
  }

  useEffect(() => {
    if (props.cadastroN3) {
      setCdCliente(props.cdClienteN3)
      setCdUser(props.cdUsuarioN3)
    }

    const fetchData = async () => {
      try {
        const papers = await getPaperData()
        novosModulos = await getModulosObj(
          papers.data.grupos.find((sistema) => sistema.cd_pav_menu === 1).filhos
        )

        novosModulos = await transferirItems(novosModulos)
        if (cdCliente != 1 && cdCliente != 3234) {
          removeVeyePermissions(novosModulos)
        }
        novosModulos = await getFilteredPermissions()

        setModuloLista([...novosModulos])
      } catch (error) {
        console.error('Erro ao pegar os dados dos papéis', error)
      }
      if (props.tituloJanela === 'Editar' || props.tituloJanela === 'Clonar') {
        try {
          userPaperData = await getUserPaperData(cdUser)
          await createHashMap(userPaperData)
          validateModuleCheckAll(novosModulos, userPaperData)
        } catch (error) {
          console.error('Erro ao pegar os dados dos papéis do usuário', error)
        }
      }
    }

    fetchData()
  }, [])

  function validateModuleCheckAll(listaModulos, listaModulosUser) {
    listaModulos.forEach((modulo) => {
      if (modulo.listaElementos.length) {
        modulo.allChecked =
          modulo.listaElementos.length &&
          modulo.listaElementos.length ===
            listaModulosUser.find(
              (moduloUser) => moduloUser.codigo_papel === modulo.codigo_papel
            )?.listaElementos.length
      }
    })

    setModuloLista([...listaModulos])
  }

  function createHashMap(listaModulos) {
    const hashmap = {}

    listaModulos.forEach((item) => {
      hashmap[item.codigo_papel] = {}
      if (item.listaElementos) {
        item.listaElementos.forEach((elemento) => {
          hashmap[item.codigo_papel][elemento] = true
        })
      }
    })

    setCheckBoxValueMap(hashmap)
  }

  useEffect(() => {
    setSpinAtivo(false)
  }, [])

  async function handleCancelar() {
    setSpinAtivo(false)
    if (props.setRevelarModalUsuario) {
      props.setRevelarModalUsuario(false)
    } else {
      despacho(revelarCadastrarUsuario(false))
      despacho(revelarEdicaoUsuario(false))
    }
  }

  function handleMensagemSucesso(confirmacao) {
    if (revelarMensagemSucesso === false) {
      setRevelarMensagemSucesso(true)
    } else {
      setRevelarMensagemSucesso(false)
      handleCancelar()
    }
    if (confirmacao) {
      props.setRenderizar(!props.renderizar)
    }
  }

  function handleMensagemErro(confirmacao) {
    if (revelarMensagemErro === false) {
      setRevelarMensagemErro(true)
    } else {
      setRevelarMensagemErro(false)
    }
    if (confirmacao === true) {
      //ALGUMA FUNÇÃO
    }
  }

  function handleCpf(valor) {
    setDsCpf(MascaraCpf(valor))
  }

  function handleTelefone(valor) {
    setDsTelefone(MascaraTelefone(valor))
  }

  async function handleCadastrar() {
    setSpinAtivo(true)

    const mensagemErroSalvar = () =>
      mostraErro('Algo deu errado, por favor tente mais tarde.')

    const listaElementos = ['LEGADO_SISTEMA_1']
    for (const [key, value] of Object.entries(checkBoxValueMap)) {
      listaElementos.push(...[key, ...Object.keys(value)])
    }

    const mostraErro = (texto) => {
      setSpinAtivo(false)
      setMensagemErro(texto)
      handleMensagemErro(false)
    }

    let auxTelefone = ''
    if (dsTelefone) {
      auxTelefone = dsTelefone.replaceAll('(', '')
      auxTelefone = auxTelefone.replaceAll(')', '')
      auxTelefone = auxTelefone.replaceAll('-', '')
      auxTelefone = auxTelefone.replaceAll(' ', '')
    }

    let auxCpf = ''
    if (dsCpf) {
      auxCpf = dsCpf.replaceAll('.', '')
      auxCpf = auxCpf.replaceAll('-', '')
    }

    if (props.tituloJanela === 'Cadastrar' || props.tituloJanela === 'Clonar') {
      if (!dsNome || !dsEmail) {
        mostraErro('Preencha todos os campos obrigatórios')
        setSpinAtivo(false)
      }

      // if (dsSenha !== dsConfirmacaoSenha) {
      //     mostraErro('Senhas não conferem')
      //     setSpinAtivo(false)
      // }

      ColetaClickstream(1449) // UsuariosDropAcoesCadastrarNovo
      const bodyCadastrarUsuario = {
        cd_pav_cliente_n3: cdClienteN3,
        ds_pav_usuario: dsNome,
        ds_pav_usuario_sobrenome: dsSobrenome ? dsSobrenome.trim() : '',
        cd_pav_perfil: cdPerfil,
        ds_cpf: auxCpf,
        ds_email: dsEmail ? dsEmail.trim() : '',
        ds_telefone: auxTelefone,
        fl_ativo: usuarioAtivo ? 1 : 0
        // "ds_senha": dsSenha,
      }

      await api
        .post('/m1/cadastrar/usuario', bodyCadastrarUsuario)
        .then(async function (response) {
          if (response.data.status === 200) {
            const papers = {
              cdUsuario: response.data.cdUsuario,
              ds_pav_papel_codigo: listaElementos
            }
            try {
              await api_papeis.post('/api/v2/papers/user/connect', papers)
            } catch (e) {
              console.error('erro ao cadastrar papeis', e)
              mensagemErroSalvar()
            }

            let enviouEmail = false

            await api
              .get(
                `/m1/servicos/token/email_primeiro_acesso?ds_email=${dsEmail.trim()}`
              )
              .then((response) => {
                if (response.data.status === 200) {
                  enviouEmail = true
                }
              })
              .catch((erro) => erro)

            if (enviouEmail) {
              if (props.tituloJanela === 'Cadastrar') {
                setMensagemSucesso([
                  `Usuário cadastrado com sucesso`, // ${dsNome} ${(dsSobrenome || '')}
                  'O link de primeiro acesso será enviado por e-mail. ' +
                    'Caso não esteja na sua caixa de entrada, verifique sua caixa de spam.'
                ])
              } else if (props.tituloJanela === 'Clonar') {
                setMensagemSucesso(
                  `Usuário ${dsNome} ${dsSobrenome || ''}` +
                    ` clonado a partir do usuário ${props.dsNomeOriginal} com sucesso`
                )
              }
            } else {
              setMensagemSucesso([
                `Usuário cadastrado com sucesso`,
                'OBS: ocorreu um erro ao enviar o link de primeiro acesso por e-mail. Você pode tentar reenviar mais tarde.'
              ])
            }
            handleMensagemSucesso(false)
          }
        })
        .catch((erro) => {
          setMensagemErro(erro.response.data?.info)
          handleMensagemErro()
        })
    } else {
      ColetaClickstream(1450) // UsuariosDropAcoesEditar
      const bodyAlterarUsuario = {
        dados: {
          ds_pav_usuario: dsNome.trim(),
          ds_pav_usuario_sobrenome: dsSobrenome ? dsSobrenome.trim() : '',
          cd_pav_perfil: cdPerfil,
          ds_cpf: auxCpf,
          ds_email: dsEmail.trim(),
          ds_telefone: auxTelefone,
          fl_ativo: usuarioAtivo ? 1 : 0
        },
        condicao: {
          cd_pav_usuario: props.cdUsuario,
          cd_pav_pessoa: props.cdPessoa
        }
      }
      await api
        .post('/m1/alterar/usuario', bodyAlterarUsuario, { timeout: 20000 })
        .then(async function (response) {
          if (response.data.status === 200) {
            const papers = {
              cdUsuario: props.cdUsuario,
              ds_pav_papel_codigo: listaElementos
            }
            try {
              await api_papeis.post('/api/v2/papers/user/connect', papers)
              setMensagemSucesso('Usuário editado com sucesso.')
              handleMensagemSucesso(false)
            } catch (e) {
              console.error('erro ao cadastrar papeis', e)
              mensagemErroSalvar()
            }
          }
          handleMensagemSucesso(false)
        })
        .catch(function (error) {
          mostraErro(error.response.data.info)
        })
    }
    setSpinAtivo(false)
  }

  useEffect(() => {
    const isCadastroValido = dsNome && dsEmail && cdCliente

    if (['Cadastrar', 'Clonar', 'Editar'].includes(props.tituloJanela)) {
      setLiberarCadastro(isCadastroValido)
    }
  }, [
    cdPerfil,
    cdCliente,
    dsNome,
    dsSobrenome,
    dsCpf,
    dsTelefone,
    dsEmail,
    props.tituloJanela
  ])

  const modulosNaoCarregados = () => {
    return stepActive > 0 && !moduloLista?.length
  }

  useEffect(() => {
    const isCadastroLiberado = !modulosNaoCarregados()
    setLiberarCadastro(isCadastroLiberado)
  }, [stepActive, moduloLista.length])

  function validateForm() {
    if (modulosNaoCarregados()) {
      setMensagemErro('Não foi possível concluir a busca')
      handleMensagemErro()
      return
    }

    let emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

    if (props.tituloJanela === 'Cadastrar' || props.tituloJanela === 'Clonar') {
      if (!dsNome || !dsEmail || emailRegExp.test(dsEmail) === false) {
        if (!dsNome) {
          setMensagemErro('O nome deve ser preenchido.')
          handleMensagemErro()
        } else if (!dsEmail) {
          setMensagemErro('O e-mail deve ser preenchido.')
          handleMensagemErro()
        } else if (emailRegExp.test(dsEmail) === false) {
          setMensagemErro('O e-mail inserido é inválido.')
          handleMensagemErro()
        }
      } else {
        setStepActive(stepActive + 1)
      }
    } else if (props.tituloJanela === 'Editar') {
      if (!dsNome || !dsEmail || emailRegExp.test(dsEmail) === false) {
        if (!dsNome) {
          setMensagemErro('O nome deve ser preenchido.')
          handleMensagemErro()
        } else if (!dsEmail) {
          setMensagemErro('O e-mail deve ser preenchido.')
          handleMensagemErro()
        } else if (emailRegExp.test(dsEmail) === false) {
          setMensagemErro('O e-mail inserido é inválido.')
          handleMensagemErro()
        }
      } else {
        setStepActive(stepActive + 1)
      }
    }
  }

  function handleNextStep() {
    if (stepActive === 0) {
      validateForm()
      return
    }

    if (stepActive === 1) {
      let resumoModuloLista = lodash.cloneDeep(
        moduloLista.filter((modulo) => checkBoxValueMap[modulo.codigo_papel])
      )
      if (resumoModuloLista.length) {
        resumoModuloLista.forEach((modulo, index) => {
          resumoModuloLista[index].listaElementos = [
            ...modulo.listaElementos.filter(
              (elemento) =>
                checkBoxValueMap[modulo.codigo_papel][elemento.codigo_papel]
            )
          ]
        })
        setNoModule(false)
      } else {
        setNoModule(true)
      }
      setResumoLista(resumoModuloLista)

      setStepActive(stepActive + 1)
    }
    if (stepActive === 2) {
      handleCadastrar()
    }
  }

  function handlePreviousStep() {
    if (stepActive !== 0) {
      setStepActive(stepActive - 1)
    } else {
      handleCancelar()
    }
  }

  function getElementoAndChildren({ codigo_papel, descricao, filhos }) {
    const obj = { codigo_papel, descricao }
    let listaObj = [obj]
    for (let filho of filhos) {
      listaObj = [...listaObj, ...getElementoAndChildren(filho)]
    }
    return listaObj
  }

  function getModulosObj(modulos) {
    return modulos.map((modulo) => {
      const moduloObj = {
        descricao: modulo.descricao,
        codigo_papel: modulo.codigo_papel,
        listaElementos: []
      }
      for (let elemento of modulo.filhos) {
        moduloObj.listaElementos = [
          ...moduloObj.listaElementos,
          ...getElementoAndChildren(elemento)
        ]
      }

      return moduloObj
    })
  }

  function handleCheckBox(elemento, elementoIndex, moduloIndex, event, modulo) {
    if (!event.target.checked) {
      delete checkBoxValueMap[modulo.codigo_papel][elemento.codigo_papel]
      if (Object.keys(checkBoxValueMap[modulo.codigo_papel]).length === 0) {
        delete checkBoxValueMap[modulo.codigo_papel]
      }
      modulo.allChecked = false
    } else {
      if (!checkBoxValueMap[modulo.codigo_papel]) {
        checkBoxValueMap[modulo.codigo_papel] = {}
      }
      checkBoxValueMap[modulo.codigo_papel][elemento.codigo_papel] = true

      modulo.allChecked =
        modulo.listaElementos.length &&
        modulo.listaElementos.length ===
          Object.keys(checkBoxValueMap[modulo.codigo_papel]).length
    }

    setCheckBoxValueMap({ ...checkBoxValueMap })
  }

  function handleAllCheckBox(modulo, moduloIndex, event) {
    modulo.allChecked = event.target.checked

    if (!event.target.checked) {
      delete checkBoxValueMap[modulo.codigo_papel]
    } else {
      if (!checkBoxValueMap[modulo.codigo_papel]) {
        checkBoxValueMap[modulo.codigo_papel] = {}
      }
      modulo.listaElementos.forEach((elemento) => {
        checkBoxValueMap[modulo.codigo_papel][elemento.codigo_papel] = true
      })
    }
    setCheckBoxValueMap({ ...checkBoxValueMap })
  }

  function buildAccordionModulo(moduloObj, moduloIndex) {
    const ExpandMoreIcon = () => <img className="seta-accordion" src={Seta} />
    return (
      <Accordion
        key={moduloIndex}
        sx={
          localTema === 'escuro'
            ? { backgroundColor: '#4B4B4B', color: '#F8FAFB' }
            : { backgroundColor: '#F4F6F5' }
        }
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id={moduloIndex}
        >
          <div>{moduloObj.descricao}</div>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            overflowX: 'scroll !important',
            maxHeight: '500px !important'
          }}
        >
          <div className={'accordion-content-container'}>
            <div>
              {stepActive === 1 && (
                <Checkbox
                  sx={
                    localTema === 'escuro'
                      ? checkboxTemaEscuro
                      : checkboxTemaClaro
                  }
                  checked={moduloObj.allChecked ?? false}
                  onChange={(event) =>
                    handleAllCheckBox(moduloObj, moduloIndex, event)
                  }
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              )}
            </div>
            <div>
              {(stepActive === 1 || moduloObj.allChecked) && (
                <div
                  className={
                    localTema === 'escuro'
                      ? 'user-data-title-escuro'
                      : 'user-data-title'
                  }
                >
                  {props.cadastroN3 && props.tipoN3 == 1
                    ? 'Ativar módulo'
                    : 'Todas as permissões'}
                </div>
              )}
            </div>
          </div>
          {moduloObj.listaElementos.map((elemento, elementoIndex) => (
            <div
              className={
                props.cadastroN3 && props.tipoN3 == 1
                  ? `accordion-content-container permission-data divider-${elementoIndex}`
                  : `accordion-content-container divider-${elementoIndex}`
              }
            >
              <div>
                {stepActive === 1 && (
                  <Checkbox
                    sx={
                      localTema === 'escuro'
                        ? checkboxTemaEscuro
                        : checkboxTemaClaro
                    }
                    checked={
                      checkBoxValueMap[moduloObj.codigo_papel]?.[
                        elemento.codigo_papel
                      ] ?? false
                    }
                    onChange={(event) =>
                      handleCheckBox(
                        elemento,
                        elementoIndex,
                        moduloIndex,
                        event,
                        moduloObj
                      )
                    }
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                )}
              </div>
              <div>
                <div
                  className={
                    localTema === 'escuro'
                      ? 'user-data-title-escuro'
                      : 'user-data-title'
                  }
                >
                  {DESCRICAO_SIMCARDS_BRANCO[elemento.codigo_papel] ||
                    elemento.descricao}
                </div>
              </div>
            </div>
          ))}
        </AccordionDetails>
      </Accordion>
    )
  }

  function parseStatusAtivo(status) {
    if (status == true) {
      return 'Ativo'
    } else {
      return 'Inativo'
    }
  }

  function buildResumo() {
    return (
      <div>
        <div
          className={
            localTema === 'escuro'
              ? 'user-data-title-escuro'
              : 'user-data-title'
          }
        >
          Dados do usuário
        </div>
        <div className={'user-data-content'}>
          <div className={'user-data-column'}>
            <div
              className={
                localTema === 'escuro'
                  ? 'user-data-label-escuro'
                  : 'user-data-label'
              }
            >
              Nome: {dsNome}
            </div>
            <div
              className={
                localTema === 'escuro'
                  ? 'user-data-label-escuro'
                  : 'user-data-label'
              }
            >
              Sobrenome: {dsSobrenome}
            </div>
            <div
              className={
                localTema === 'escuro'
                  ? 'user-data-label-escuro'
                  : 'user-data-label'
              }
            >
              Telefone: {dsTelefone}
            </div>
          </div>
          <div className={'user-data-column'}>
            <div
              className={
                localTema === 'escuro'
                  ? 'user-data-label-escuro'
                  : 'user-data-label'
              }
            >
              E-mail: {dsEmail}
            </div>
            <div
              className={
                localTema === 'escuro'
                  ? 'user-data-label-escuro'
                  : 'user-data-label'
              }
            >
              Status: {parseStatusAtivo(usuarioAtivo)}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const isMsgSucessoArray =
    typeof mensagemSucesso === 'object' && mensagemSucesso.length

  const transferirItems = (data) => {
    const descricao = 'SIM Cards em Branco'

    // remover os itens LEGADO_TELA_90 e LEGADO_ELEMENTO_275 de seus objetos originais
    const dadosAtualizados = data.map((obj) => {
      if (obj.descricao !== descricao) {
        const filteredItems = obj.listaElementos.filter(
          (item) =>
            item.codigo_papel !== 'LEGADO_ELEMENTO_275' &&
            item.codigo_papel !== 'LEGADO_TELA_90'
        )

        return { ...obj, listaElementos: filteredItems }
      }

      return obj
    })

    // Pegar os itens que precisam ser transferidos
    const dadosParaTransferir = data
      .flatMap((obj) => obj.listaElementos)
      .filter(
        (item) =>
          item.codigo_papel === 'LEGADO_ELEMENTO_275' ||
          item.codigo_papel === 'LEGADO_TELA_90'
      )

    // Adicionar os itens no objeto alvo
    const permissoesAtualizadas = dadosAtualizados.map((obj) => {
      if (obj.descricao === descricao) {
        return {
          ...obj,
          listaElementos: [...obj.listaElementos, ...dadosParaTransferir]
        }
      }
      return obj
    })

    return permissoesAtualizadas
  }

  const formatarPermissoes = (data) => {
    const grupos = data.data.grupos
    const descricao = 'SIM Cards em Branco'

    // Remover os itens LEGADO_TELA_90 e LEGADO_ELEMENTO_275 de seus objetos originais
    const dadosAtualizados = grupos.map((obj) => {
      if (obj.descricao !== descricao) {
        const filteredItems = obj.listaElementos.filter(
          (item) => item !== 'LEGADO_ELEMENTO_275' && item !== 'LEGADO_TELA_90'
        )

        return { ...obj, listaElementos: filteredItems }
      }

      return obj
    })

    // Pegar os itens que precisam ser transferidos
    const dadosParaTransferir = grupos
      .flatMap((obj) => obj.listaElementos)
      .filter(
        (item) => item === 'LEGADO_ELEMENTO_275' || item === 'LEGADO_TELA_90'
      )

    // Adicionar os itens no objeto alvo
    const permissoesAtualizadas = dadosAtualizados.map((obj) => {
      if (obj.descricao === descricao) {
        return {
          ...obj,
          listaElementos: [...obj.listaElementos, ...dadosParaTransferir]
        }
      }
      return obj
    })

    return permissoesAtualizadas
  }

  return (
    <Form ref={modal} onSubmit={handleSubmit(() => handleCadastrar())}>
      {spinAtivo && (
        <div className="componente-spinner-overlay">
          <div className="componente-spinner-container">
            <SpinerVeye />
          </div>
        </div>
      )}
      <div className="backoffice-modal-cadastro-usuario fundo-cor-6">
        <Form.Row
          style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
        >
          <Col>
            <img
              className="icone-label-janela nao-selecionavel"
              src={elipseVerde()}
              alt="elipse"
            />
            <Form.Label>
              <div className="fonte-cor-1 label-janela">
                {props.tituloJanela} Usuário
              </div>
            </Form.Label>
            <Button
              className="botao-fechar-janela"
              variant="danger"
              onClick={() => handleCancelar()}
            >
              <img
                className="botao-fechar-janela-icone"
                src={fecharJanela()}
                alt="fechar-janela"
              />
            </Button>
          </Col>
          <Stepper activeStep={stepActive} sx={stepsStyle}>
            {steps.map((label, index) => {
              const stepProps = {}
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel
                    sx={localTema === 'escuro' ? stepLabelStyleEscuro : {}}
                  >
                    {label}
                  </StepLabel>
                </Step>
              )
            })}
          </Stepper>
        </Form.Row>

        <div className={'steps-container'}>
          <div className={'steps-content'}>
            {stepActive === 0 && (
              <div className="painel-cadastro-usuario-step-1">
                <Form.Row className="cadastro-usuario-form-row">
                  <Col>
                    <Form.Group className="backoffice-cadastro-usuario-coluna-1">
                      <Form.Label>
                        <div className="fonte-cor-1 label-campo fonte-cliente-tamanho">
                          Nome *
                        </div>
                      </Form.Label>
                      <OverlayTrigger
                        delay={{ show: 500, hide: 250 }}
                        placement="bottom"
                        overlay={
                          <Tooltip id={`tooltip-bottom`}>
                            Insira o nome do usuário
                          </Tooltip>
                        }
                      >
                        <Form.Control
                          value={dsNome}
                          className="campo-texto-cor-3 campo-texto altura-campo-padrao"
                          type="text"
                          placeholder={phNome}
                          onChange={(e) => setDsNome(e.target.value)}
                        />
                      </OverlayTrigger>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="backoffice-cadastro-usuario-coluna-1">
                      <Form.Label>
                        <div className="fonte-cor-1 label-campo fonte-cliente-tamanho">
                          Sobrenome
                        </div>
                      </Form.Label>
                      <OverlayTrigger
                        delay={{ show: 500, hide: 250 }}
                        placement="bottom"
                        overlay={
                          <Tooltip id={`tooltip-bottom`}>
                            Insira o sobrenome do usuário
                          </Tooltip>
                        }
                      >
                        <Form.Control
                          value={dsSobrenome}
                          className="campo-texto-cor-3 campo-texto altura-campo-padrao"
                          type="text"
                          placeholder={phSobrenome}
                          onChange={(e) => setDsSobrenome(e.target.value)}
                        />
                      </OverlayTrigger>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="backoffice-cadastro-usuario-coluna-1">
                      <Form.Label>
                        <div className="fonte-cor-1 label-campo fonte-cliente-tamanho">
                          Telefone
                        </div>
                      </Form.Label>
                      <OverlayTrigger
                        delay={{ show: 500, hide: 250 }}
                        placement="bottom"
                        overlay={
                          <Tooltip id={`tooltip-bottom`}>
                            Insira o telefone
                          </Tooltip>
                        }
                      >
                        <Form.Control
                          value={dsTelefone}
                          className="campo-texto-cor-3 campo-texto altura-campo-padrao"
                          type="tel"
                          placeholder={phTelefone}
                          onChange={(e) => handleTelefone(e.target.value)}
                        />
                      </OverlayTrigger>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Group className="backoffice-cadastro-usuario-coluna-3">
                      <Form.Label>
                        <div className="fonte-cor-1 label-campo fonte-cliente-tamanho">
                          E-mail *
                        </div>
                      </Form.Label>
                      <OverlayTrigger
                        delay={{ show: 500, hide: 250 }}
                        placement="bottom"
                        overlay={
                          <Tooltip id={`tooltip-bottom`}>
                            Insira o e-mail
                          </Tooltip>
                        }
                      >
                        <Form.Control
                          value={dsEmail}
                          className="campo-texto-cor-3 campo-texto altura-campo-padrao"
                          type="email"
                          placeholder={phEmail}
                          onChange={(e) => setDsEmail(e.target.value)}
                          ref={register({
                            validate: {
                              minimo: (value) =>
                                /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) == true
                            }
                          })}
                        />
                      </OverlayTrigger>
                    </Form.Group>
                  </Col>
                  <Col className="imput-status-cadastro-usuario-cliente">
                    <Form.Group className="switch-group-cadastro-usuario-cliente">
                      <div className="fonte-cor-1 label-campo fonte-cliente-tamanho">
                        Status
                      </div>
                      <div>
                        <div className="flipswitch">
                          <input
                            defaultChecked={usuarioAtivo}
                            type="checkbox"
                            name="flipswitch"
                            className="flipswitch-cb"
                            id="habilitarResposta"
                            onChange={() => setUsuarioAtivo(!usuarioAtivo)}
                          />
                          <label
                            className="flipswitch-label"
                            htmlFor="habilitarResposta"
                          >
                            <div className="flipswitch-inner flipswitch-cor-1"></div>
                            <div className="flipswitch-switch"></div>
                          </label>
                        </div>
                        <div className="fonte-cor-1 label-campo label-termos-enviar-sms">
                          {' '}
                          Ativo
                        </div>
                      </div>
                    </Form.Group>
                  </Col>
                </Form.Row>
              </div>
            )}

            {stepActive === 1 && (
              <div className={'step-2'}>
                {moduloLista.map((moduloObj, moduloIndex) =>
                  buildAccordionModulo(moduloObj, moduloIndex)
                )}
              </div>
            )}
            {stepActive === 2 && (
              <div className={'step-3'}>
                {buildResumo()}
                {resumoLista.map((moduloObj, moduloIndex) =>
                  buildAccordionModulo(moduloObj, moduloIndex)
                )}
              </div>
            )}
          </div>

          <div className={'action-buttons-row'}>
            {stepActive === 0 && (
              <div style={{ color: '#D32F2F' }}>Campos obrigatórios *</div>
            )}
            {stepActive === 1 && noModule && (
              <div style={{ color: '#D32F2F' }}>
                O usuário não possui permissões *
              </div>
            )}

            <div className="botoes-wrapper">
              <Button
                className="botao-cancelar"
                variant="success"
                size="sm"
                onClick={() => handlePreviousStep()}
              >
                <div className="fonte-cor-2">
                  {stepActive === 0 ? 'Cancelar' : 'Anterior'}
                </div>
              </Button>
              {liberarCadastro === false ? (
                <Button
                  onClick={() => validateForm()}
                  className={'botao-confirmar-desabilitado'}
                  variant="success"
                  size="sm"
                >
                  <div className="fonte-cor-4">
                    {stepActive === 2 ? 'Confirmar' : 'Próximo'}
                  </div>
                </Button>
              ) : (
                <Button
                  onClick={() => handleNextStep()}
                  className={'botao-confirmar'}
                  variant="success"
                  size="sm"
                >
                  <div className="fonte-cor-4">
                    {stepActive === 2 ? 'Confirmar' : 'Próximo'}
                  </div>
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      {revelarMensagemSucesso && (
        <div className="componente-modal-overlay">
          <div className="componente-modal">
            <MensagemSucesso
              handleMensagemSucesso={handleMensagemSucesso}
              mensagemTitulo={
                isMsgSucessoArray ? mensagemSucesso[0] : 'Sucesso'
              }
              mensagemParagrafo1={
                isMsgSucessoArray ? mensagemSucesso[1] : mensagemSucesso
              }
              mensagemBotao={'FECHAR'}
            />
          </div>
        </div>
      )}
      {revelarMensagemErro && (
        <div className="componente-modal-overlay">
          <div className="componente-modal">
            <MensagemErro
              handleMensagemErro={handleMensagemErro}
              mensagemTitulo={'Erro'}
              mensagemParagrafo1={mensagemErro}
              mensagemBotao={'Retornar'}
            />
          </div>
        </div>
      )}
    </Form>
  )
}
