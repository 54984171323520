import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";

import { api } from "../../../conexoes/api";
import { setaDropdown } from "../../../utils/alternanciaIcones";
import SelectInput from "../../componentesModais/selects/selectComOpcoesPreConfiguradas";

export default function DivAtivacao({
  dsVersaoApi,
  setDsProdutoTelecall,
  dsPerfilHlr,
  setDsPerfilHlr,
  services,
}) {
  const [revelarSelectProdutoTelecall, setRevelarSelectProdutoTelecall] =
    useState(false);
  const [revelarSelectPerfilHLR, setRevelarSelectPerfilHLR] = useState(false);
  const [listaProdutos, setListaProdutos] = useState([]);
  const [produtosSelect, setProdutosSelect] = useState([]);
  const [produto, setProduto] = useState("Selecione");

  useEffect(() => {
    async function carregarProdutos() {
      const produtos = await getProdutos(dsVersaoApi);

      const auxProdutos = produtos.map(({ nome_produto }) => ({
        text: nome_produto,
        value: nome_produto,
      }));

      setListaProdutos(produtos);
      setProdutosSelect(auxProdutos);
    }
    carregarProdutos();
  }, [dsVersaoApi]);

  async function getProdutos(core) {
    const fl_core_novo = core === "novo" ? 1 : 0;
    return await api
      .get("/m1/consultar/produtos_telecall?fl_core_novo=" + fl_core_novo)
      .then((response) => response.data.dados)
      .catch((err) => console.error(err));
  }

  function escolherProduto(nomeProduto) {
    setProduto(nomeProduto);
    setDsProdutoTelecall(
      listaProdutos.find((p) => p.nome_produto === nomeProduto)
    );
  }

  return (
    <>
      <div className="grupo-bloquear-temporariamente-1">
        <div className="coluna-select-iot-telecom-1">
          <Form.Group id="produtos">
            <label className="mt-3 fonte-cor-1 campo-texto-titulo-opcoes-ativacao-2">
              Produto
            </label>
            {revelarSelectProdutoTelecall === false && (
              <Button
                name="cdProdutoTelecall"
                className="campo-texto-cor-3 campo-select"
                onClick={() =>
                  setRevelarSelectProdutoTelecall(!revelarSelectProdutoTelecall)
                }
              >
                <div className="fonte-cor-1 label-campo">{produto}</div>
                <img
                  className="campo-select-icone-desativado"
                  src={setaDropdown()}
                  alt="drop"
                />
              </Button>
            )}
            {revelarSelectProdutoTelecall === true && (
              <SelectInput
                setRevelarSelect={setRevelarSelectProdutoTelecall} //Hook para fechar a modal
                setDs={escolherProduto} //Nome do ProdutoTelecall
                ds={produto}
                options={produtosSelect}
              ></SelectInput>
            )}
          </Form.Group>

          <Form.Group>
            <label className="mt-3 fonte-cor-1 campo-texto-titulo-opcoes-ativacao-2">
              Perfil HLR
            </label>
            {revelarSelectPerfilHLR === false && (
              <Button
                name="cdProdutoTelecall"
                className="campo-texto-cor-3 campo-select"
                onClick={() =>
                  setRevelarSelectPerfilHLR(!revelarSelectPerfilHLR)
                }
              >
                <div className="fonte-cor-1 label-campo">{dsPerfilHlr}</div>
                <img
                  className="campo-select-icone-desativado"
                  src={setaDropdown()}
                  alt="drop"
                />
              </Button>
            )}
            {revelarSelectPerfilHLR === true && (
              <SelectInput
                setRevelarSelect={setRevelarSelectPerfilHLR}
                setDs={setDsPerfilHlr}
                ds={dsPerfilHlr}
                options={[
                  { text: "63", value: 63 },
                  { text: "109", value: 109 },
                ]}
              ></SelectInput>
            )}
          </Form.Group>
        </div>
      </div>

      <div className="container-principal-checkbox-selecao-ativacao">
        <label className="fonte-cor-1 campo-texto-titulo-opcoes-ativacao-2">
          Serviços
        </label>
        <div className="container-checkbox-selecao-ativacao">
          <div className="grupo-1-selecao-ativacao">
            <div className="checkbox-selecao-ativacao">
              <input
                type="checkbox"
                className="input-checkbox-header-simcard"
                id="entradaSmsBloqueada"
                name="entradaSmsBloqueada"
                onChange={(e) =>
                  services.setEntradaSmsBloqueada(e.target.checked)
                }
              />
              <label
                htmlFor="entradaSmsBloqueada"
                className="label-checkbox-ativacao"
              ></label>
              <label className="campo-texto-selecao-ativacao fonte-cor-1">
                Bloquear entrada de SMS
              </label>
            </div>
            <div className="checkbox-selecao-ativacao">
              <input
                type="checkbox"
                className="input-checkbox-header-simcard"
                id="saidaSmsBloqueada"
                name="saidaSmsBloqueada"
                onChange={(e) =>
                  services.setSaidaSmsBloqueada(e.target.checked)
                }
              />
              <label
                htmlFor="saidaSmsBloqueada"
                className="label-checkbox-ativacao"
              ></label>
              <label className="campo-texto-selecao-ativacao fonte-cor-1">
                Bloquear saída de SMS
              </label>
            </div>
            <div className="checkbox-selecao-ativacao">
              <input
                type="checkbox"
                className="input-checkbox-header-simcard"
                id="servicoGprsBloqueado"
                name="servicoGprsBloqueado"
                onChange={(e) =>
                  services.setServicoGprsBloqueado(e.target.checked)
                }
              />
              <label
                htmlFor="servicoGprsBloqueado"
                className="label-checkbox-ativacao"
              ></label>
              <label className="campo-texto-selecao-ativacao fonte-cor-1">
                bloquear serviço de GPRS
              </label>
            </div>
            <div className="checkbox-selecao-ativacao">
              <input
                type="checkbox"
                className="input-checkbox-header-simcard"
                id="chamadaRecebidaBloqueada"
                name="chamadaRecebidaBloqueada"
                onChange={(e) =>
                  services.setChamadaRecebidaBloqueada(e.target.checked)
                }
              />
              <label
                htmlFor="chamadaRecebidaBloqueada"
                className="label-checkbox-ativacao"
              ></label>
              <label className="campo-texto-selecao-ativacao fonte-cor-1">
                Bloquear chamada recebida
              </label>
            </div>
            <div className="checkbox-selecao-ativacao">
              <input
                type="checkbox"
                className="input-checkbox-header-simcard"
                id="chamadaRecebidaEmRoamingBloqueada"
                name="chamadaRecebidaEmRoamingBloqueada"
                onChange={(e) =>
                  services.setChamadaRecebidaEmRoamingBloqueada(
                    e.target.checked
                  )
                }
              />
              <label
                htmlFor="chamadaRecebidaEmRoamingBloqueada"
                className="label-checkbox-ativacao"
              ></label>
              <label className="campo-texto-selecao-ativacao fonte-cor-1">
                Bloquear chamada recebida em roaming
              </label>
            </div>
          </div>
          <div className="grupo-1-selecao-ativacao">
            <div className="checkbox-selecao-ativacao">
              <input
                type="checkbox"
                className="input-checkbox-header-simcard"
                id="chamadaEfetuadasBloqueada"
                name="chamadaEfetuadasBloqueada"
                onChange={(e) =>
                  services.setChamadaEfetuadasBloqueada(e.target.checked)
                }
              />
              <label
                htmlFor="chamadaEfetuadasBloqueada"
                className="label-checkbox-ativacao"
              ></label>
              <label className="campo-texto-selecao-ativacao fonte-cor-1">
                Bloquear chamadas efetuadas
              </label>
            </div>
            <div className="checkbox-selecao-ativacao">
              <input
                type="checkbox"
                className="input-checkbox-header-simcard"
                id="chamadaInternacionalBloqueada"
                name="chamadaInternacionalBloqueada"
                onChange={(e) =>
                  services.setChamadaInternacionalBloqueada(e.target.checked)
                }
              />
              <label
                htmlFor="chamadaInternacionalBloqueada"
                className="label-checkbox-ativacao"
              ></label>
              <label className="campo-texto-selecao-ativacao fonte-cor-1">
                Bloquear chamadas internacionais
              </label>
            </div>
            <div className="checkbox-selecao-ativacao">
              <input
                type="checkbox"
                className="input-checkbox-header-simcard"
                id="chamadaInternacionalEmRoamingBloqueada"
                name="chamadaInternacionalEmRoamingBloqueada"
                onChange={(e) =>
                  services.setChamadaInternacionalEmRoamingBloqueada(
                    e.target.checked
                  )
                }
              />
              <label
                htmlFor="chamadaInternacionalEmRoamingBloqueada"
                className="label-checkbox-ativacao"
              ></label>
              <label className="campo-texto-selecao-ativacao fonte-cor-1">
                Bloquear chamadas internacionais em roaming, <br /> exceto para
                o país de origem do assinante
              </label>
            </div>
            <div className="checkbox-selecao-ativacao">
              <input
                type="checkbox"
                className="input-checkbox-header-simcard"
                id="todasChamadasEmRoamingBloqueadas"
                name="todasChamadasEmRoamingBloqueadas"
                onChange={(e) =>
                  services.setTodasChamadasEmRoamingBloqueadas(e.target.checked)
                }
              />
              <label
                htmlFor="todasChamadasEmRoamingBloqueadas"
                className="label-checkbox-ativacao"
              ></label>
              <label className="campo-texto-selecao-ativacao fonte-cor-1">
                Bloquear todas as chamadas feitas em roaming
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="container-principal-checkbox-selecao-ativacao">
        <label className="fonte-cor-1 campo-texto-titulo-opcoes-ativacao-2">
          Bloqueio
        </label>
        <div className="container-checkbox-selecao-ativacao">
          <div className="grupo-1-selecao-ativacao">
            <div className="checkbox-selecao-ativacao">
              <input
                type="checkbox"
                className="input-checkbox-header-simcard"
                id="bloqueioDeLinha"
                name="bloqueioDeLinha"
                onChange={(e) => services.setBloquearLinha(e.target.checked)}
              />
              <label
                htmlFor="bloqueioDeLinha"
                className="label-checkbox-ativacao"
              ></label>
              <label className="campo-texto-selecao-ativacao fonte-cor-1">
                Bloquear linha
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
