//IMPORTAÇÕES
import React, { useState } from 'react'

import {
  passarPaguinaDireita,
  passarPaguinaEsquerda
} from './alternanciaIcones'

function paginaAnterior(paginaAtual, page, onPageChange) {
  if (paginaAtual != 1) {
    onPageChange(page - 1)
  }
}

function paginaSeguinte(totalPaginas, paginaAtual, page, onPageChange) {
  if (totalPaginas != paginaAtual) {
    onPageChange(page + 1)
  }
}

export default function PaguinacaoTabela(props) {
  const styleClass = useState(
    props.styleClass || `grupo-botao-paginacao-tabela-3`
  )

  if (props.extrato) {
    return (
      <div
        className={`grupo-botao-paginacao-tabela-3${props.tipo2 ? '-tipo2' : ''} ${styleClass}`}
      >
        <button
          className="botao-paginacao-primeira-ultima-tabela"
          onClick={(e) => {
            props.setPaginaAtual(1)
            e.preventDefault()
          }}
        >
          Primeira
        </button>
        <div className="botao-setas-paginacao-seta-tabela">
          <button
            className="botao-paginacao-seta-tabela"
            onClick={(e) => {
              paginaAnterior(props.paginaAtual, props.page, props.onPageChange)
              e.preventDefault()
            }}
          >
            <img src={passarPaguinaEsquerda()} alt="pagina anterior" />
          </button>
          {criadorNumeros(props)}
          <button
            className="botao-paginacao-seta-tabela"
            onClick={(e) => {
              paginaSeguinte(
                props.totalPaginas,
                props.paginaAtual,
                props.page,
                props.onPageChange
              )
              e.preventDefault()
            }}
          >
            <img src={passarPaguinaDireita()} alt="proxima pagina" />
          </button>
        </div>
        <button
          className="botao-paginacao-primeira-ultima-tabela"
          onClick={(e) => {
            props.setPaginaAtual(props.totalPaginas)
            e.preventDefault()
          }}
        >
          Última
        </button>
      </div>
    )
  } else if (props.solicitacao) {
    return (
      <div className="grupo-botao-paginacao-tabela-5">
        <button
          className="botao-paginacao-primeira-ultima-tabela"
          onClick={(e) => {
            props.setPaginaAtual(1)
            e.preventDefault()
          }}
        >
          Primeira
        </button>
        <div className="botao-setas-paginacao-seta-tabela">
          <button
            className="botao-paginacao-seta-tabela"
            onClick={(e) => {
              paginaAnterior(props.paginaAtual, props.page, props.onPageChange)
              e.preventDefault()
            }}
          >
            <img src={passarPaguinaEsquerda()} alt="pagina anterior" />
          </button>
          {criadorNumeros(props)}
          <button
            className="botao-paginacao-seta-tabela"
            onClick={(e) => {
              paginaSeguinte(
                props.totalPaginas,
                props.paginaAtual,
                props.page,
                props.onPageChange
              )
              e.preventDefault()
            }}
          >
            <img src={passarPaguinaDireita()} alt="proxima pagina" />
          </button>
        </div>
        <button
          className="botao-paginacao-primeira-ultima-tabela"
          onClick={(e) => {
            props.setPaginaAtual(props.totalPaginas)
            e.preventDefault()
          }}
        >
          Última
        </button>
      </div>
    )
  } else if (props.preview) {
    return (
      <div className="grupo-botao-paginacao-tabela-4">
        <button
          className="botao-paginacao-primeira-ultima-tabela"
          onClick={(e) => {
            props.setPaginaAtual(1)
            e.preventDefault()
          }}
        >
          Primeira
        </button>
        <div className="botao-setas-paginacao-seta-tabela">
          <button
            className="botao-paginacao-seta-tabela"
            onClick={(e) => {
              paginaAnterior(props.paginaAtual, props.page, props.onPageChange)
              e.preventDefault()
            }}
          >
            <img src={passarPaguinaEsquerda()} alt="pagina anterior" />
          </button>
          {criadorNumeros(props)}
          <button
            className="botao-paginacao-seta-tabela"
            onClick={(e) => {
              paginaSeguinte(
                props.totalPaginas,
                props.paginaAtual,
                props.page,
                props.onPageChange
              )
              e.preventDefault()
            }}
          >
            <img src={passarPaguinaDireita()} alt="proxima pagina" />
          </button>
        </div>
        <button
          className="botao-paginacao-primeira-ultima-tabela"
          onClick={(e) => {
            props.setPaginaAtual(props.totalPaginas)
            e.preventDefault()
          }}
        >
          Última
        </button>
      </div>
    )
  } else {
    return (
      <div
        className={
          props.modal === undefined
            ? 'grupo-botao-paginacao-tabela'
            : 'grupo-botao-paginacao-tabela-2'
        }
      >
        <button
          className="botao-paginacao-primeira-ultima-tabela"
          onClick={(e) => {
            props.setPaginaAtual(1)
            e.preventDefault()
          }}
        >
          Primeira
        </button>
        <div className="botao-setas-paginacao-seta-tabela">
          <button
            className="botao-paginacao-seta-tabela"
            onClick={(e) => {
              paginaAnterior(props.paginaAtual, props.page, props.onPageChange)
              e.preventDefault()
            }}
          >
            <img src={passarPaguinaEsquerda()} alt="pagina anterior" />
          </button>
          {criadorNumeros(props)}
          <button
            className="botao-paginacao-seta-tabela"
            onClick={(e) => {
              paginaSeguinte(
                props.totalPaginas,
                props.paginaAtual,
                props.page,
                props.onPageChange
              )
              e.preventDefault()
            }}
          >
            <img src={passarPaguinaDireita()} alt="proxima pagina" />
          </button>
        </div>
        <button
          className="botao-paginacao-primeira-ultima-tabela"
          onClick={(e) => {
            props.setPaginaAtual(props.totalPaginas)
            e.preventDefault()
          }}
        >
          Última
        </button>
      </div>
    )
  }
}

function criadorNumeros({
  totalPaginas,
  totalItens,
  valorLimite,
  paginaAtual,
  page,
  onPageChange,
  extrato = false
}) {
  function paginaAnteriorDuas(page, onPageChange) {
    if (paginaAtual != 1) {
      onPageChange(page - 2)
    }
  }

  function paginaSeguinteDuas(page, onPageChange) {
    if (totalPaginas != paginaAtual) {
      onPageChange(page + 2)
    }
  }

  function paginaPenultima(page, onPageChange) {
    onPageChange(totalPaginas - 1)
  }

  function paginaUltima(page, onPageChange) {
    onPageChange(totalPaginas)
  }

  if (extrato || totalItens > valorLimite * 2) {
    return (
      <>
        {paginaAtual === totalPaginas && paginaAtual - 2 > 0 && (
          <button
            className="botao-paginacao-tabela"
            onClick={(e) => {
              paginaAnteriorDuas(page, onPageChange)
              e.preventDefault()
            }}
          >
            <a className="fonte-cor-1">{paginaAtual - 2}</a>
          </button>
        )}
        {paginaAtual >= 2 && (
          <button
            className="botao-paginacao-tabela"
            onClick={(e) => {
              paginaAnterior(paginaAtual, page, onPageChange)
              e.preventDefault()
            }}
          >
            <a className="fonte-cor-1">{paginaAtual - 1}</a>
          </button>
        )}
        <button
          className="botao-paginacao-tabela"
          onClick={(e) => e.preventDefault()}
        >
          <a
            className={
              paginaAtual === page
                ? 'fonte-paginacao-verde'
                : 'botao-paginacao-tabela'
            }
          >
            {paginaAtual}
          </a>
        </button>
        {paginaAtual != totalPaginas && (
          <button
            className="botao-paginacao-tabela"
            onClick={(e) => {
              paginaSeguinte(totalPaginas, paginaAtual, page, onPageChange)
              e.preventDefault()
            }}
          >
            <a className="fonte-cor-1">{paginaAtual + 1}</a>
          </button>
        )}
        {paginaAtual <= 1 && totalPaginas > 3 && (
          <button
            className="botao-paginacao-tabela"
            onClick={(e) => {
              paginaSeguinteDuas(page, onPageChange)
              e.preventDefault()
            }}
          >
            <a className="fonte-cor-1">{paginaAtual + 2}</a>
          </button>
        )}
        {paginaAtual != totalPaginas &&
          paginaAtual != totalPaginas - 1 &&
          paginaAtual != totalPaginas - 2 &&
          paginaAtual != totalPaginas - 3 && (
            <button className="botao-paginacao-tabela">
              <a className="fonte-cor-1">. . .</a>
            </button>
          )}
        {paginaAtual != totalPaginas &&
          paginaAtual != totalPaginas - 1 &&
          paginaAtual != totalPaginas - 2 &&
          totalPaginas - 1 > 0 && (
            <button
              className="botao-paginacao-tabela"
              onClick={(e) => {
                paginaPenultima(page, onPageChange)
                e.preventDefault()
              }}
            >
              <a className="fonte-cor-1">{totalPaginas - 1}</a>
            </button>
          )}
        {paginaAtual != totalPaginas &&
          paginaAtual != totalPaginas - 1 &&
          totalPaginas > 0 && (
            <button
              className="botao-paginacao-tabela"
              onClick={(e) => {
                paginaUltima(page, onPageChange)
                e.preventDefault()
              }}
            >
              <a className="fonte-cor-1">{totalPaginas}</a>
            </button>
          )}
      </>
    )
  } else {
    return (
      <>
        {paginaAtual >= 2 && (
          <button
            className="botao-paginacao-tabela"
            onClick={(e) => {
              paginaAnterior(paginaAtual, page, onPageChange)
              e.preventDefault()
            }}
          >
            <a className="fonte-cor-1">{paginaAtual - 1}</a>
          </button>
        )}
        <button className="botao-paginacao-tabela">
          <a className="fonte-cor-1">{paginaAtual}</a>
        </button>
        {paginaAtual < 2 && totalPaginas > 1 && (
          <button
            className="botao-paginacao-tabela"
            onClick={(e) => {
              paginaSeguinte(totalPaginas, paginaAtual, page, onPageChange)
              e.preventDefault()
            }}
          >
            <a className="fonte-cor-1">{paginaAtual + 1}</a>
          </button>
        )}
      </>
    )
  }
}
