import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
import * as S from "./styles";

function generateDashData() {
  const dash = [];
  const startDate = new Date("2025-01-01");

  for (let i = 0; i < 7; i++) {
    const date = new Date(startDate);
    date.setDate(startDate.getDate() + i);

    const randomHour = Math.floor(Math.random() * 24); // Horas entre 0 e 23
    const randomMinute = Math.floor(Math.random() * 60); // Minutos entre 0 e 59
    const formattedHour = `${randomHour
      .toString()
      .padStart(2, "0")}:${randomMinute.toString().padStart(2, "0")}`;

    const randomConsumo = Math.floor(Math.random() * 1000) + 1; // Consumo entre 1 e 1000
    const randomPlano = Math.floor(Math.random() * 2000) + 500; // Plano entre 500 e 2500

    dash.push({
      data: date.toISOString().split("T")[0], // Formata para YYYY-MM-DD
      hora: formattedHour,
      consumo: randomConsumo,
      plano: randomPlano,
    });
  }

  return dash;
}

const dash = generateDashData();

export default function Grafico(props) {
  const localTema = localStorage.getItem("tema");
  const gridColor = "#656565";
  const barColor = localTema === "claro" ? "#ECF0EE" : "#2b2b2b";
  const filledBarColor = "#36D896";

  const CustomTooltip = ({ payload, active }) => {
    if (active && payload && payload.length) {
      const plano = payload[0]?.payload?.plano || "00";
      return (
        <S.Tooltip>
          <S.TooltipParagraph>
            Consumido:{" "}
            {parseFloat(`${payload[0] ? payload[0].value : "00"}`).toFixed(2)}{" "}
            MB
          </S.TooltipParagraph>
          <S.TooltipParagraph>{`Plano: ${plano} MB`}</S.TooltipParagraph>
        </S.Tooltip>
      );
    }
    return null;
  };

  return (
    <S.Container>
      <ResponsiveContainer>
        <BarChart
          data={dash}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <CartesianGrid stroke={gridColor} strokeDasharray="0" />
          <XAxis stroke={gridColor} dataKey="data" />
          <YAxis stroke={gridColor} dataKey="consumo" />
          <Tooltip
            cursor={{ fill: "transparent" }}
            content={<CustomTooltip />}
          />
          <Bar
            barSize={68}
            dataKey="consumo"
            fill="#36D896"
            background={{
              fill: barColor,
            }}
            radius={(5, 0, 0, 5)}
          >
            {dash.map((index) => (
              <Cell key={`cell-${index}`} fill={filledBarColor}></Cell>
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </S.Container>
  );
}
