export function handlerDesativarImagens(imagem, terminacao) {
  const elementosAtivosDESC = document.querySelectorAll('.icone-ordenacao-DESC')
  const elementosAtivosASC = document.querySelectorAll('.icone-ordenacao-ASC')
  elementosAtivosDESC.forEach((el) => el.classList.add('d-none'))
  elementosAtivosASC.forEach((el) => el.classList.add('d-none'))
  elementosAtivosDESC.forEach((el) =>
    el.classList.remove('icone-ordenacao-DESC')
  )
  elementosAtivosASC.forEach((el) => el.classList.remove('icone-ordenacao-ASC'))

  imagem.classList.remove('d-none')
  imagem.classList.add('icone-ordenacao-' + terminacao)
}

export function handleRemoverImagens(imagem) {
  const elementosAtivosDESC = document.querySelectorAll('.icone-ordenacao-DESC')
  const elementosAtivosASC = document.querySelectorAll('.icone-ordenacao-ASC')
  elementosAtivosDESC.forEach((el) => el.classList.add('d-none'))
  elementosAtivosASC.forEach((el) => el.classList.add('d-none'))
  elementosAtivosDESC.forEach((el) =>
    el.classList.remove('icone-ordenacao-DESC')
  )
  elementosAtivosDESC.forEach((el) =>
    el.classList.remove('icone-ordenacao-ASC')
  )
  elementosAtivosASC.forEach((el) => el.classList.remove('icone-ordenacao-ASC'))
  elementosAtivosASC.forEach((el) =>
    el.classList.remove('icone-ordenacao-DESC')
  )
  imagem.classList.remove('icone-ordenacao-ASC')
  imagem.classList.remove('icone-ordenacao-DESC')
}
