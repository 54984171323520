import styled from 'styled-components'

export const BlankSlateContainer = styled.div`
  display: flex;
  color: ${({ theme }) => theme.cor54}!important;
  justify-content: space-evenly;
  flex-direction: column;
  height: 100vh;
`
export const BlankSlateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Icon = styled.div`
  img {
    width: 70px;
    height: 70px;
    margin-bottom: 130px;
  }
`

export const Title = styled.div`
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
`

export const SubTitle = styled.div`
  margin-top: 30px;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  width: 690px;
`
