import React from 'react'

export const columnValue = (cell) => {
  return <p className="fonte-cor-1 label-tabela-1">{cell}</p>
}

export const formatterText = (cell) => {
  const val = cell && cell !== 'undefined' ? cell : '-'
  return columnValue(val)
}
