import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import { useQuery } from 'react-query'
import '../../../../css/painelDispositivos/dspSimcard/dspAcoes.css'
import { PortadorasService } from '../../../../services/http/portadoras.service'
import { useGlobal } from '../../../../stores/useGlobal.js'
import decryptJWT from '../../../../utils/decryptJWT'
import PaginacaoTabela from '../../../../utils/paginacaoTabela'
import * as S from './styles'

let valorLimite = 10
let totalItens = 10
let contador = 0
let totalPaginas = 0

let auxDados = []

const COUNT_ITEMS = true

const TableprimaryKey = 'iccid'

const COLUNAS = [
  {
    dataField: 'nome',
    text: 'Nome',
    formatter: (cell) => cell || '-'
  },
  {
    dataField: 'linha',
    text: 'Linha',
    formatter: (cell) => cell || '-'
  },
  {
    dataField: 'iccid',
    text: 'ICCID',
    formatter: (cell) => cell || '-'
  },
  {
    dataField: 'imsi',
    text: 'IMSI',
    formatter: (cell) => cell || '-'
  },
  {
    dataField: 'imei',
    text: 'IMEI ',
    formatter: (cell) => cell || '-'
  },
  {
    dataField: 'operadora',
    text: 'Operadora ',
    formatter: (cell) => cell || '-'
  },
  {
    dataField: 'status',
    text: 'Status',
    formatter: (cell) => {
      return (
        <S.ElipseStatusWrapper>
          <S.ElipseStatus status={cell} />
          {cell || '-'}
        </S.ElipseStatusWrapper>
      )
    }
  },
  {
    dataField: 'ultimoEvento',
    text: 'Último evento',
    formatter: (cell) => cell || '-'
  },
  {
    dataField: 'inicioDaUltimaConexao',
    text: 'Início da última conexão',
    formatter: (cell) => cell || '-'
  },
  {
    dataField: 'fimDaUltimaConexao',
    text: 'Fim da última conexão',
    formatter: (cell) => cell || '-'
  },
  {
    dataField: 'tempoDaConexao',
    text: 'Tempo da Conexão ',
    formatter: (cell) => cell || '-'
  },
  {
    dataField: 'ip',
    text: 'IP',
    formatter: (cell) => cell || '-'
  },
  {
    dataField: 'codigoCliente',
    text: 'Código do cliente',
    formatter: (cell) => cell || '-'
  },
  {
    dataField: 'cliente',
    text: 'Cliente',
    formatter: (cell) => cell || '-'
  }
]

const COLUNAS_VIRTUEYES = ['codigoCliente', 'cliente']

export default function Simcards({ cdPortadora }) {
  const { setIsLoadingGlobal } = useGlobal()
  const [paginaAtual, setPaginaAtual] = useState(1)
  const [dados, setDados] = useState([])
  const [colunas, setColunas] = useState(COLUNAS)

  const codigoErp = decryptJWT('codigoClienteErpAntigo')

  const { getSimcards } = PortadorasService

  useEffect(() => {
    if (codigoErp !== '1') {
      setColunas((prev) => {
        const novasColunas = [...prev]
        return novasColunas.filter(
          (item) => !COLUNAS_VIRTUEYES.includes(item.dataField)
        )
      })
    }
  }, [codigoErp])

  const { isFetching } = useQuery(
    [
      `/portadoras/simcards`,
      paginaAtual,
      valorLimite,
      COUNT_ITEMS,
      cdPortadora
    ],
    () => {
      try {
        setIsLoadingGlobal(true)
        return getSimcards(paginaAtual, valorLimite, COUNT_ITEMS, cdPortadora)
      } catch (err) {
        console.error(err)
      } finally {
        setIsLoadingGlobal(false)
      }
    },
    {
      onSuccess: async (data) => {
        contador = (paginaAtual - 1) * valorLimite
        totalItens = data?.item_count
        totalPaginas = Math.ceil(totalItens / valorLimite)

        const novosItens = [...auxDados]

        data.list?.forEach((item, index) => {
          novosItens[contador + index] = item
        })

        setDados(novosItens)
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false
    }
  )

  const trocarPagina = (novaPagina) => {
    if (totalPaginas <= 1) return

    setPaginaAtual(novaPagina)
  }

  const opcoesPaginacao = {
    page: paginaAtual,
    showTotal: true,
    sizePerPage: valorLimite,
    firstPageTitle: 'Primeira',
    lastPageTitle: 'Última',
    nextPageTitle: 'Proxima',
    prePageTitle: 'Anterior',
    custom: true,
    onPageChange: (newPage) => {
      trocarPagina(newPage)
    }
  }

  const getLimiteNaPagina = () =>
    contador + valorLimite <= totalItens ? contador + valorLimite : totalItens

  return (
    <S.SimcardsWrapper>
      <div className="container-simcards-erb">
        <PaginationProvider pagination={Paginador(opcoesPaginacao)}>
          {({ paginationProps, paginationTableProps }) => (
            <>
              <div className="container-table-simcards-erb">
                <BootstrapTable
                  classes={'tabela'}
                  condensed={true}
                  keyField={TableprimaryKey}
                  data={dados}
                  columns={colunas}
                  bootstrap4={true}
                  bordered={false}
                  noDataIndication={'Nenhum item encontrado'}
                  {...paginationTableProps}
                />
              </div>

              <div className="pagination-table">
                <PaginacaoTabela
                  preview={true}
                  setPaginaAtual={trocarPagina}
                  totalPaginas={totalPaginas}
                  totalItens={totalItens}
                  valorLimite={valorLimite}
                  paginaAtual={paginaAtual}
                  page={paginationProps.page}
                  onPageChange={paginationProps.onPageChange}
                />
              </div>
              <div className="pagination-description">
                Mostrando
                <span className="label-tabela-itens-exibidos">
                  {contador + 1}
                </span>
                -
                <span className="label-tabela-itens-exibidos">
                  {getLimiteNaPagina()}
                </span>
                de
                <span className="label-tabela-itens-exibidos">
                  {totalItens}
                </span>
              </div>
            </>
          )}
        </PaginationProvider>
      </div>
    </S.SimcardsWrapper>
  )
}
