import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Button, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next'; /*eslint-disable*/
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { api, api_svsolicitacoes } from '../../../conexoes/api'; /*eslint-disable*/
import { elipseAmarelo, elipseAzul, elipseCinza, elipseLaranja, elipseRoxo, elipseVerde, elipseVermelho, setaDropdown } from '../../../utils/alternanciaIcones';
import decryptJWT from '../../../utils/decryptJWT';
import { formatarData } from '../../../utils/formatadorDataTabela';
import GirarSetaDropdown from '../../../utils/girarSetaDropdown';
import PaginacaoTabela from '../../../utils/paginacaoTabela';
import BarraDadosCliente from '../../componentesModais/barraDadosCliente/barraDadosCliente';
import MensagemErro from '../../componentesModais/mensagens/mensagemErro';
import MensagemInformacao from '../../componentesModais/mensagens/mensagemInformacao';
import DetalhamentoSolicitacao from './detalhamentoSolicitacao';

import {
    desabilitarAcoes,
    desabilitarAcoesCliente,
    desabilitarEditar
    // limparFiltroPorArquivoFatSolicitacao as limparFiltroPorArquivoFatSolicitacaoFunc, 
    ,


    filtrosArrayBarra,
    limpadorFiltros, limpouBusca,
    mudarFiltros,
    mudarManejador,
    mudarPermissaoElementoAcao,
    realizouBusca,
    realizouFiltro,
    recarregarBarraFiltragem,
    revelarBarraFiltragem,
    revelarEditarSolicitacao,
    revelarMostrarEmDispositivosSolicitacao,
    revelarRelatorioFatura,
    revelarRelatorioLinhasAfetadas,
    separadorLinhaBusca
} from "../../../redux/actions/index";

import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js';
import '../../../css/painelFaturas/fatSolicitacoes/tabela.css';
import { validadorPermissaoElemento } from '../../../utils/verificadorPermissaoElemento';
import BarraFiltragem from '../../componentesModais/barraFiltragem/barraFiltragem';
import ModalEditarSolicitacao from "./editarSolicitacao/editarSolicitacao";
// COLETA CLICKSTREAM
import moment from 'moment';
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js';
import { columnValue, formatterText } from '../../../utils/tabela.js';


let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0
let itensVar = []
let today = moment().format("DD-MM-YYYY")



const routeConsultarFatSolicitacoes = '/api/v2/listagemSolConectividade';

function TabelaConectSolicitacoes(props){
    const [spinAtivo, setSpinAtivo] = useState(true);
    const [itens, setItens] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(false);
    const [valorLimite, setValorLimite] = useState(50);
    const [cdSolicitacao, setCdSolicitacao]= useState()
    const [dsSolicitacao, setDsSolicitacao]= useState()
    const [cdStatus, setCdStatus]= useState()
    const [dsStatus, setDsStatus]= useState()
    const [protocoloOperadora, setProtocoloOperadora]= useState()
    const [dataPrazoConclusao, setDataPrazoConclusao]= useState()
    const [dataAlteracao, setDataAlteracao]= useState()
    const [dataFim, setDataFim]= useState()
    const [motivo, setMotivo]= useState()
    const [cdTipo, setCdTipo]= useState()
    const [solicitacaoSelecionada, setSolicitacaoSelecionada] = useState(undefined);
    // const [filtroPorArquivo, setFiltroPorArquivo] = useState(undefined);
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [permissaoDescricao, setPermissaoDescricao] = useState();
    const [revelarEditarsolicitacao, setRevelarEditarSolicitacao] = useState();
    const [mensagemTitulo ,setMensagemTitulo] = useState('')
    const [mensagemParagrafo1 ,setMensagemParagrafo1] = useState('')
    const [revelarMensagemInformacao, setRevelarMensagemInformacao] = useState(false)
    //HOOKS REDUX
    const despacho = useDispatch();
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    let filtros = useSelector(state => state.filtrosState.filtros);
    const buscaArquivo = useSelector(state => state.buscaArquivoState.buscaArquivo);
    let separadorLinha = useSelector(state => state.manejadorState.separadorLinha);
    let ordenador = useSelector(state => state.manejadorState.ordenador);
    // let revelarFiltrarSolicitacaoPorArquivo = useSelector(state => state.faturasState.revelarFiltrarSolicitacaoPorArquivo);
    // let limparFiltroPorArquivo = useSelector(state => state.faturasState.limparFiltroPorArquivoFatSolicitacao);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    let limparFiltros = useSelector(state => state.filtrosState.limparFiltros);
    let temFiltros = false;
    let editarSolicitacao = useSelector(state => state.faturasState.revelarEditarSolicitacao)
    let relatorioLinhasAfetadas = useSelector(state => state.faturasState.revelarRelatorioLinhasAfetadas)
    let relatorioFatura = useSelector(state => state.faturasState.revelarRelatorioFatura)
    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let revelarMostrarEmDispositivo =  useSelector(state => state.faturasState.revelarMostrarEmDispositivosSolicitacao);
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let estadoExpansaoMenu = useSelector(state => state.manejadorState.expandirNavegacao);
    const permissaoElemento = useSelector(state => state.permissaoState.permissaoElemento);
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(760) // 'fatSolicitacoesTabela'
            setColetaUsabilidade(false)
        }
    },[])


    useEffect(() => {
        function checarFiltros() {
            if (filtros.vazio == 'vazio') {
                temFiltros = false;
            } else {
                temFiltros = true;
            }
        }
        checarFiltros();
    }, [filtros]);

    useEffect(()=>{
        const url_query=queryString.parse(window.location.search.substring(1));
        if(url_query.CODIGO_SIMCARD){
            despacho(mudarFiltros({CODIGO_SIMCARD: url_query.CODIGO_SIMCARD}))
        }
        despacho(limpadorFiltros(false))
        if(limparFiltros){
            despacho(revelarBarraFiltragem(false))
            if(!filtros.vazio){
                despacho(mudarFiltros({vazio: 'vazio'}))
            }
        }
    }, [])

    useEffect(() => {
        async function pegarSeparador(){
            const separador = await api.get('/m1/consultar/preferencias?cd_pav_usuario='+decryptJWT('codigoCliente')).then(
                function(resposta){
                    return resposta.data.dados[0];
                }
            ).catch(
                function(erro){
                    throw erro.stack;
                }
            )
            despacho(separadorLinhaBusca(separador.busca_separador))
        }

        pegarSeparador();

    }, [])

    useEffect(()=>{
        async function consultarItens(){
            let arrayIccid=[]
            await api.get('/m1/consultar/fat_solicitacao_simcard?cd_fat_solicitacao='+cdSolicitacao)
                .then(async function (response) {
                    if(response.data.status == 200){
                        await response.data.dados.map((item_consulta,i)=>{
                            let auxIccid = "'"+item_consulta.ds_iccid+"'"
                            arrayIccid.push(auxIccid)
                        })

                        despacho(mudarFiltros({ICCID: arrayIccid}))
                        despacho(filtrosArrayBarra(['Código da Solicitação: '+cdSolicitacao+'?ICCID']))
                        despacho(revelarBarraFiltragem(true))
                        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
                        despacho(limpadorFiltros(false))
                        //aciona o event click para mudar de paguina
                        const link = document.getElementById('link-faturas-dispositivos')
                        link.click();
                    }
                })
                .catch(function (error) {
                    return error
                })
        }
        if(revelarMostrarEmDispositivo){
            ColetaClickstream(1443) // SolicitacoesDropAcoesMostrarEmDispossitivos
            consultarItens()
            despacho(revelarMostrarEmDispositivosSolicitacao(false))
        }
    }, [revelarMostrarEmDispositivo])

    useEffect(() => {
        const controller = new AbortController();
        setSpinAtivo(true)
        //despacho redux
        despacho(mudarManejador("con_solicitacao"));
        despacho(desabilitarAcoes(true));
        despacho(desabilitarAcoesCliente(false));

        async function carregarTabela(){
            //clicou no botão de limpar a busca geral e retorna tudo ao estado original
            if(limpouBuscaGeral){
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if(realizouBuscaGeral){
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            if(realizouFiltragem){
                despacho(realizouFiltro(false));
                restaurarConfigsIniciaisPagina()
            }

            //necessario para pegar os dados do filtro, pois por algum motivo ele se perde e nao aciona os filtros
            if (temFiltros) {
                temFiltros = false;
                restaurarConfigsIniciaisPagina()
            }

            if (totalPaginas != 0) {
                contador = (paginaAtual - 1) * valorLimite;
            }

            if(conteudoBusca  && filtros.vazio && !buscaArquivo?.chave){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens({ controllerSignal: controller.signal });
                }
                else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    await contarItens({ controllerSignal: controller.signal }); 
                    const { data } = await api_svsolicitacoes.get(routeConsultarFatSolicitacoes+'?contar=true&buscaArquivo='+JSON.stringify(buscaArquivo)+'&busca='+conteudoBusca+'&separador='+separadorLinha+'&offset='+contador+'&limit='+valorLimite+"&ordenador="+ordenador+"&cd_pav_cliente="+decryptJWT('codigoCliente'), { signal: controller.signal });
                    inserirData(data.devices)
                }
            }else if(!filtros.vazio || buscaArquivo?.chave){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens({ controllerSignal: controller.signal });
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                const dados = {
                    paginaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar:conteudoBusca,
                    separador:separadorLinha,
                    filtros: filtros,
                    buscaArquivo,
                    // filtroArquivo:filtroPorArquivo,
                    ordenador:ordenador,
                    cd_pav_usuario:decryptJWT('codigo')
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api_svsolicitacoes.get(routeConsultarFatSolicitacoes+'?contar=true&buscaArquivo='+JSON.stringify(buscaArquivo)+'&busca='+conteudoBusca+'&separador='+separadorLinha+'&filtro='+JSON.stringify(filtros)+'&offset='+contador+'&limit='+valorLimite+"&ordenador="+ordenador+"&cd_pav_cliente="+decryptJWT('codigoCliente'), { signal: controller.signal });
                    if(data?.devices){
                        inserirData(data.devices)
                    }

                }
            }else{
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens({ controllerSignal: controller.signal });
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api_svsolicitacoes.get(routeConsultarFatSolicitacoes+'?offset='+contador+'&contar=true&buscaArquivo='+JSON.stringify(buscaArquivo)+'&limit='+valorLimite+"&ordenador="+ordenador+"&cd_pav_cliente="+decryptJWT('codigoCliente'), { signal: controller.signal });
                    inserirData(data.devices)
                }
            }
            setSpinAtivo(false)
        }
        carregarTabela();
        return () => {
            controller.abort();
        }
    }, [paginaAtual, conteudoBusca, renderizar, filtros, buscaArquivo, ordenador /*,filtroPorArquivo */])

    function inserirData(data){
        if(itensVar.length == totalItens){
            for(let i=0;i<totalItens;i++){
                if (contador === i){
                    let k = i
                    for(let j=0;j<data.length;j++){
                        itensVar[k] = data[j]
                        k++
                    }
                }
                if(i == (totalItens-1)){
                    setItens(JSON.parse(JSON.stringify(itensVar)))
                }
            }
        }
    }
  
    async function contarItens({controllerSignal}){
        let arrayItens = []
        let apiData = []

        if (controladorPagina <= paginaAtual) { 
            if (controladorPagina <= paginaAtual && (filtros.vazio && !buscaArquivo?.chave)) { // rota CONTADOR
                apiData = await api_svsolicitacoes.get(routeConsultarFatSolicitacoes+'?contar=true&busca='+conteudoBusca+'&separador='+separadorLinha+"&cd_pav_cliente="+decryptJWT('codigoCliente'), { signal: controllerSignal });
            }else{
                const dados = {
                    paginaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar:conteudoBusca,
                    separador:separadorLinha,
                    filtros: filtros,
                    buscaArquivo,
                    ordenador:ordenador,
                    contar: true,
                    cd_pav_usuario:decryptJWT('codigo')
                }
                apiData = await api_svsolicitacoes.get(routeConsultarFatSolicitacoes+'?contar=true&buscaArquivo='+JSON.stringify(buscaArquivo)+'&filtro='+JSON.stringify(filtros)+'&busca='+conteudoBusca+'&separador='+separadorLinha+"&cd_pav_cliente="+decryptJWT('codigoCliente'), { signal: controllerSignal })
            }
            itensVar = []
            if(apiData.data.devices){
                totalPaginas = Math.ceil(apiData.data.item_count / valorLimite)
                totalItens = apiData.data.item_count;
                for( var i = 0 ; i < apiData.data.item_count; i++){
                    arrayItens.push(i)
                }
                itensVar = arrayItens
            }
        }
    }



    function restaurarConfigsIniciaisPagina(){
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 0
        setPaginaAtual(1)
    }

    function handlerDesativarImagens(imagem, terminacao){
        const elementosAtivosDESC = document.querySelectorAll(".icone-ordenacao-DESC");
        const elementosAtivosASC = document.querySelectorAll(".icone-ordenacao-ASC");
        elementosAtivosDESC.forEach(el => el.classList.add("d-none"))
        elementosAtivosASC.forEach(el => el.classList.add("d-none"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))

        if(imagem){
            imagem.classList.remove("d-none")
            imagem.classList.add("icone-ordenacao-"+terminacao)
        }
    }

    function handleRemoverImagens(imagem){
        const elementosAtivosDESC = document.querySelectorAll(".icone-ordenacao-DESC");
        const elementosAtivosASC = document.querySelectorAll(".icone-ordenacao-ASC");
        elementosAtivosDESC.forEach(el => el.classList.add("d-none"))
        elementosAtivosASC.forEach(el => el.classList.add("d-none"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        imagem.classList.remove("icone-ordenacao-ASC")
        imagem.classList.remove("icone-ordenacao-DESC")
    }

    async function handlerOrdenador(nomeOrdenador,nomeDiferenciado){
        let isItDescrescente = false
        const imagem = document.getElementById(nomeOrdenador+nomeDiferenciado)
        //verifica esta mudando a forma de ordenacao no mesmo elemento
        if(nomeOrdenador === ordenador.split(' ASC')[0] || nomeOrdenador === ordenador.split(' DESC')[0]){
            await ordenador.split(' ').map((item_ordenador,i)=>{
                //verifica se existe tal item e muda para decrescente
                if(item_ordenador === 'ASC'){
                    //Sera tal ordenador para decrescente
                    ordenador = nomeOrdenador +' DESC'
                    isItDescrescente = true
                    //transforma a imagem para o modo DESC ao qual mosta a imagem normalmente e faz a limpeza dos outros icones
                    handlerDesativarImagens(imagem,'ASC')            
                }
                if(item_ordenador === 'DESC'){
                    //Sera tal ordenador para crescente
                    ordenador = ''
                    isItDescrescente = false
                    //transforma a imagem para o modo ASC ao qual mosta a imagem rotacionada 180deg e faz a limpeza dos outros icones
                    handleRemoverImagens(imagem)
                }
            })
        }else if(!isItDescrescente){
            ordenador = nomeOrdenador +' ASC'
            //Adicaiona a classe de crescente para a imagem e faz a limpeza dos outros icones
            handlerDesativarImagens(imagem,'DESC')
        }
        // despacho(mudarOrdenador(JSON.parse(JSON.stringify(ordenador))));
    }

    function parseEmptyICCID(cell) {
        if(cell){
            return cell
        } else {
            return "ICCID Indisponível"
        }
    }

    const colunas = [

        {
            dataField: 'DESC_TIPO_SOLICITACAO',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome-tabela-nova fonte-cor-1' onClick={() => handlerOrdenador("DESC_TIPO_SOLICITACAO",'DESC_TIPO_SOLICITACAO')}>
            Tipo solicitação
            <Image id="DESC_TIPO_SOLICITACAODESC_TIPO_SOLICITACAO" className='d-none' src={setaDropdown()}/>
            </button>,
            sort: true,
            formatter: columnValue
        },
        {
            dataField: 'DESC_STATUS_SOLICITACAO',
            headerClasses: 'nao-selecionavel',
            text:
                <button className='btn-header-nome-tabela-nova fonte-cor-1' onClick={() => handlerOrdenador("DESC_STATUS_SOLICITACAO",'DESC_STATUS_SOLICITACAO')}>
                    Status
                    <Image id="DESC_STATUS_SOLICITACAODESC_STATUS_SOLICITACAO" className='d-none' src={setaDropdown()}/>
                </button>,
            sort: true,
            formatter: (cell, row) => {
                if(cell && cell.toLowerCase() == 'aberta'){
                    return (
                        <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>{cell}</Tooltip>}>
                            <p className="fonte-cor-1 label-tabela-1 label-tabela-tooltip" key={row.CD_SIMCARD}><Image className="img-eclipse-status" src={elipseAmarelo()}/>Aberta</p>
                        </OverlayTrigger>
                    )
                }
                if(cell && cell.toLowerCase() == 'cancelada'){
                    return (
                        <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>{cell}</Tooltip>}>
                            <p className="fonte-cor-1 label-tabela-1 label-tabela-tooltip" key={row.CD_SIMCARD}><Image className="img-eclipse-status" src={elipseCinza()}/>Cancelada</p>
                        </OverlayTrigger>
                    )
                }
                if(cell && cell.toLowerCase() == 'concluída'){
                    return (
                        <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>{cell}</Tooltip>}>
                            <p className="fonte-cor-1 label-tabela-1 label-tabela-tooltip" key={row.CD_SIMCARD}><Image className="img-eclipse-status" src={elipseVerde()}/>Concluída</p>
                        </OverlayTrigger>
                    )
                }
                if(cell && cell.toLowerCase() == 'em andamento'){
                    return (
                        <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>{cell}</Tooltip>}>
                            <p className="fonte-cor-1 label-tabela-1 label-tabela-tooltip" key={row.CD_SIMCARD}><Image className="img-eclipse-status" src={elipseAzul()}/>Em andamento</p>
                        </OverlayTrigger>
                    )
                }
                if(cell && cell.toLowerCase() == 'agendada'){
                    return (
                        <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>{cell}</Tooltip>}>
                            <p className="fonte-cor-1 label-tabela-1 label-tabela-tooltip" key={row.CD_SIMCARD}><Image className="img-eclipse-status" src={elipseRoxo()}/>Agendada</p>
                        </OverlayTrigger>
                    )
                }
                if(cell && cell.toLowerCase() == 'concluída parcial'){
                    return (
                        <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>{cell}</Tooltip>}>
                            <p className="fonte-cor-1 label-tabela-1 label-tabela-tooltip" key={row.CD_SIMCARD}><Image className="img-eclipse-status" src={elipseLaranja()}/>Concluída parcial</p>
                        </OverlayTrigger>
                    )
                }
                if(cell && cell.toLowerCase() == 'falha'){
                    return (
                        <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>{cell}</Tooltip>}>
                            <p className="fonte-cor-1 label-tabela-1 label-tabela-tooltip" key={row.CD_SIMCARD}><Image className="img-eclipse-status" src={elipseVermelho()}/>Falha</p>
                        </OverlayTrigger>
                    )
                }
            }
        },

        {
            dataField: 'CD_SOLICITACAO',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome-tabela-nova fonte-cor-1' onClick={() => handlerOrdenador("CD_SOLICITACAO",'CD_SOLICITACAO')}>
                Cod. Solicitação
            <Image id="CD_SOLICITACAOCD_SOLICITACAO" className='d-none' src={setaDropdown()}/>
            </button>,
            sort: true,
            formatter: formatterText
        },
        {
            dataField:"DATA_CRIACAO",
            headerClasses: 'nao-selecionavel',
            text:
            <button className='btn-header-nome-tabela-nova fonte-cor-1' onClick={() => handlerOrdenador("DATA_CRIACAO",'DATA_CRIACAO')}>
                Data da solicitação
                <Image id="DATA_CRIACAODATA_CRIACAO" className='d-none' src={setaDropdown()}/>
            </button>,
            sort: true,
            formatter: (cell, row) => {
                return formatarData(cell,false);
            }
        },
        {
            dataField: 'DS_EMAIL',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome-tabela-nova fonte-cor-1' onClick={() => handlerOrdenador("DS_EMAIL",'DS_EMAIL')}>
                Usuário
            <Image id="DS_EMAILDS_EMAIL" className='d-none' src={setaDropdown()}/>
            </button>,
            sort: true,
            formatter: formatterText
        },
    ]

    const selecaoLinhas = {
        mode: 'radio',

        onSelect: (row, isSelect, rowIndex, e) => {
            if(isSelect){
                ColetaClickstream(1227) /*fatSolicitacoesTabelaSelecionarItem*/
                handleSelecionar(
                    row.CD_SOLICITACAO,
                    row.ds_solicitacao,
                    row.ds_status,
                    row.cd_status,
                    row.ds_protocolo_operadora,
                    row.dt_prazo_conclusao,
                    row.dt_inicio,
                    row.dt_fim,
                    row.ds_motivo,
                    row.cd_tipo,
                    row.tem_cmp
                    )
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <label className="label-checkbox-cliente-virtueyes fonte-cor-1">
                    <input type={mode} id=" CLIENTEVEYE" name="CLIENTE"/>
                    <span className="checkbox-cliente-virtueyes radio-button-cor-1"></span>
                </label>
            )
        }
    };

    function handleSelecionar(cd_fat_solicitacao, ds_solicitacao, dsStatus, cdStatus, ds_protocolo_operadora, dt_prazo_conclusao,dt_inicio, dt_fim, ds_motivo,cd_tipo, tem_cmp){
        setCdSolicitacao(cd_fat_solicitacao)
        setDsSolicitacao(ds_solicitacao)
        setDsStatus(dsStatus)
        setCdStatus(cdStatus)
        setProtocoloOperadora(ds_protocolo_operadora)
        setDataPrazoConclusao(dt_prazo_conclusao)
        setDataAlteracao(dt_inicio)
        setDataFim(dt_fim)
        setMotivo(ds_motivo)
        setCdTipo(cd_tipo)
        if(tem_cmp != 0){
            // para solicitações que contem cmp e não devem realizar a edição
        despacho(desabilitarEditar(true));
        }else {
        despacho(desabilitarEditar(false));
        }
        despacho(desabilitarAcoes(false));
        despacho(desabilitarAcoesCliente(true));
    }

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            setSolicitacaoSelecionada(row)
            despacho(desabilitarAcoes(false));
            despacho(desabilitarAcoesCliente(true));
        }
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            if(confirmacao === true){
                setRenderizar(!renderizar)
            }
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                setRenderizar(!renderizar)
            }
        }
    }

    const itemExpandido = {
        className: 'fundo-cor-1',
        renderer: (row, rowIndex) => (
            <div className={estadoExpansaoMenu===true?"expand-row-tabela-financeiro-1":"expand-row-tabela-financeiro-2"}>
                <DetalhamentoSolicitacao
                    contador={contador} 
                    cdSolicitacao={row.CD_SOLICITACAO}
                    dsStatus={row.DESC_STATUS_SOLICITACAO}
                    protocolo={row.ds_protocolo_operadora}
                    dataInicio={row.DATA_CRIACAO}
                    tipoSolicitacao={row.DESC_TIPO_SOLICITACAO}
                />
            </div>
        ),
        expandHeaderColumnRenderer: (row, rowIndex) => (
            <div className="tela-background"></div>
        ),
        expandColumnRenderer: ( rowKey ) => {
            return (
                <Button className="botao-expandir-row" onClick={e => GirarSetaDropdown( rowKey.rowKey )}>
                    <Image id={ rowKey.rowKey } className="icone-botao-expandir-row nao-selecionavel" src={setaDropdown()}/>
                </Button>
            );
        },
        showExpandColumn: permissaoDescricao,
        expandByColumnOnly: true,
        headerClasses: 'tabela-coluna-primeira-header',
    };

    useEffect(()=>{
        async function handlerVerificarPermissaoAcao(){
            despacho(mudarPermissaoElementoAcao( !await validadorPermissaoElemento(permissaoElemento,"veye/usuarios/acoes") ))
            setPermissaoDescricao(await validadorPermissaoElemento(permissaoElemento,"veye/financeiro/detalhamento"))
        }
        handlerVerificarPermissaoAcao()
    }, [permissaoElemento])

    useEffect(() => {
        if(editarSolicitacao){
            if(cdStatus == 2){
                setRevelarMensagemErro(true)
                despacho(revelarEditarSolicitacao(false))
            }
            else{
                setRevelarEditarSolicitacao(true)
            }
        }
        else{
            setRevelarEditarSolicitacao(false)
        }
    }, [editarSolicitacao])

    function handleMensagemErro(){
        if(revelarMensagemErro === false) {
            setRevelarMensagemErro(true)
        }
        else {
            setRevelarMensagemErro(false)
        }
    }

    useEffect(() => {
        if(relatorioLinhasAfetadas !== false){
            // if(dsStatus.toLowerCase() == 'concluída'){
                const dados = {
                    codigo_cliente: decryptJWT('codigoCliente'),
                    codigo_usuario: decryptJWT('codigo'),
                    descricao: 'Relatório de linhas afetadas por solicitação',
                    tipo_arquivo: relatorioLinhasAfetadas,
                    tipo_relatorio: 29,
                    cd_crm_solicitacao: cdSolicitacao,
                    buscaArquivo,
                }

                api.post('/m1/cadastrar/relatorios',dados)
                setMensagemTitulo("O arquivo está sendo gerado")
                setMensagemParagrafo1("Esse processo levará alguns minutos, você poderá fazer o download do seu arquivo na tela de relatórios assim que o link do mesmo estiver disponível")
                handleMensagemInformacao(true)
            // }
            // else{
            //     setMensagemTitulo("Informação")
            //     setMensagemParagrafo1("Esse processo só pode ser efetuado para solicitações concluídas.")
            //     handleMensagemInformacao(true)
            // }
            despacho(revelarRelatorioLinhasAfetadas(false))
        }
    },[relatorioLinhasAfetadas])

    useEffect(() => {
        if(relatorioFatura !== false){
            const dados = {
                codigo_solicitacao: cdSolicitacao,
                codigo_cliente: decryptJWT('codigoCliente'),
                codigo_usuario: decryptJWT('codigo'),
                descricao: 'Relatório de linhas ignoradas',
                tipo_arquivo: relatorioFatura,
                tipo_relatorio: 18,
                buscar: cdSolicitacao,
                buscaArquivo,
            }
            api.post('/m1/cadastrar/relatorios',dados)
            setMensagemTitulo("O arquivo está sendo gerado")
            setMensagemParagrafo1("Esse processo levará alguns minutos, você poderá fazer o download do seu arquivo na tela de relatórios assim que o link do mesmo estiver disponível")
            handleMensagemInformacao(true)
            despacho(revelarRelatorioFatura(false))
        }
    },[relatorioFatura])

    function handleMensagemInformacao(){
        if(revelarMensagemInformacao === false){
            setRevelarMensagemInformacao(true)
        }else{
            setRevelarMensagemInformacao(false)
        }
    }

    return(
        <div className='container-tela'>
        { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            {revelarEditarsolicitacao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <ModalEditarSolicitacao
                            setRenderizar={setRenderizar}
                            renderizar={renderizar}
                            tituloJanela={"Editar"}
                            cdSolicitacao={cdSolicitacao}
                            dsSolicitacao={dsSolicitacao}
                            cdStatus={cdStatus}
                            dsStatus={dsStatus}
                            cdTipo={cdTipo}
                            protocoloOperadora={protocoloOperadora}
                            dataPrazoConclusao={dataPrazoConclusao}
                            dataAlteracao={dataAlteracao}
                            dataFim={dataFim}
                            motivo={motivo}
                        />
                    </div>
                </div>
            } 
            <BarraDadosCliente/>
            { revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={"Essa solicitação já está concluída"}
                            mensagemParagrafo2={"e não pode ser editada."}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemInformacao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemInformacao
                            handleMensagemInformacao={handleMensagemInformacao}
                            mensagemTitulo={mensagemTitulo}
                            mensagemParagrafo1={mensagemParagrafo1}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            <Link id='link-faturas-dispositivos' className="d-none" to={"/veye/faturas/dispositivos"}/>
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                hover={true}
                                condensed={true}
                                keyField='CD_SOLICITACAO'
                                data={itens} 
                                columns={colunas} 
                                selectRow={selecaoLinhas}
                                rowClasses="row-tabela-nova"
                                rowEvents={eventosLinhas}
                                expandRow={itemExpandido}
                                bootstrap4={true}
                                bordered={false}
                                classes="tabela"
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                             <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando 
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    - 
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
           
        </div>
    )
}

function mapStateToProps(state) {
    return {
        conteudoBusca: state.manejadorState.conteudoBusca,
        filtros: state.filtrosState.filtros,
        buscaArquivo: state.buscaArquivoState.buscaArquivo
    };
} 
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(TabelaConectSolicitacoes));