import { Notification as NotificationMantine } from '@mantine/core'
import styled from 'styled-components'

export const Notification = styled(NotificationMantine)`
  .mantine-Notification-icon {
    background-color: transparent;
  }
`

export const NotificationWrapper = styled.div`
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);

  .mantine-Notification-closeButton {
    :hover {
      background-color: transparent;
    }
  }

  .mantine-Notification-root {
    max-width: 500px;
    background-color: ${({ theme }) => theme.cor40};

    ::before {
      background-color: ${({ theme }) => theme.cor11};
    }

    .mantine-Text-root {
      color: ${({ theme }) => theme.cor9};
      font-family: RobotoRegular;
    }

    .mantine-Notification-description {
      color: ${({ theme }) => theme.cor66};
    }
  }
`

export const CloseWrapper = styled.div`
  width: 1.75rem;
  height: 1.75rem;
  background-color: #e03131;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Img = styled.img`
  width: ${({ type }) => (type === 'success' ? '1.75rem' : '12px')};
  height: ${({ type }) => (type === 'success' ? '1.75rem' : '12px')};
`
