import { Group as GroupMantine } from '@mantine/core'
import styled from 'styled-components'

export const Group = styled(GroupMantine)`
  .mantine-Group-root {
    align-items: center !important;
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: nowrap !important;
  }
`
