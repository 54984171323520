import React from 'react'

import ActionIcon from '../Mantine/ActionIcon'

import * as S from './styles.js'

export default function TabelaPortadoras({ icon, title, subTitle }) {
  return (
    <div>
      <S.BlankSlateContainer>
        <S.BlankSlateWrapper>
          <S.Icon>
            <ActionIcon
              alt="blankSlateIcon"
              variant="transparent"
              icon={icon}
            />
          </S.Icon>
          <S.Title>{title}</S.Title>

          <S.SubTitle>{subTitle}</S.SubTitle>
        </S.BlankSlateWrapper>
      </S.BlankSlateContainer>
    </div>
  )
}
