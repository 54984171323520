import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import '../../css/breadCrumbs.css'
import {
  dividerVertical,
  divisaDireita,
  setaVoltar
} from '../../utils/alternanciaIcones'

import { getItemBreadCrumbDados, getTituloPagina } from './tituloPaginas'

/* Bread Crumbs: Para futuras melhorias pode consultar: 
        https://developer.mozilla.org/pt-BR/docs/Web/CSS/Layout_cookbook/Breadcrumb_Navigation
*/

function BotaoVoltar() {
  const history = useHistory()

  return (
    <div onClick={() => history.goBack()} id="breadcrumbs-div-voltar">
      <img id="breadcrumbs-seta-voltar" src={setaVoltar()} alt="voltar" />
      <img
        id="breadcrumbs-divider-vertical"
        src={dividerVertical()}
        alt="divisor"
      />
    </div>
  )
}

const SetaDireita = () => (
  <img
    className="breadcrumbs-seta-direita"
    src={divisaDireita()}
    alt="seta direita"
  />
)

function BreadCrumbs() {
  const history = useHistory()
  const rota = history.location.pathname

  const breadCrumbs = useSelector(
    (state) => state.breadCrumbsState.breadCrumbsObj
  )
  let breadCrumbArr

  if (rota === '/veye') return null

  if (!breadCrumbs || breadCrumbs?.length === 0) return null

  breadCrumbArr = getItemBreadCrumbDados(breadCrumbs, rota)

  const tituloPagina = getTituloPagina(breadCrumbArr)

  return (
    <nav aria-label="Breadcumb" className="container-bread-crumbs">
      <BotaoVoltar />

      <div id="div-bread-crumbs">
        <h4 id="titulo-pagina">{tituloPagina}</h4>
        <div>
          <Link to="/veye" className="fonte-cor-18">
            Home
          </Link>

          {breadCrumbArr.map(({ rota, label }) => {
            const text = label === 'mensagens' ? tituloPagina : label

            return (
              <Fragment key={rota}>
                <SetaDireita />
                {rota ? (
                  <Link to={rota} className="span-bread-crumbs fonte-cor-18">
                    {text}
                  </Link>
                ) : (
                  <span className="span-bread-crumbs fonte-cor-18">{text}</span>
                )}
              </Fragment>
            )
          })}
        </div>
      </div>
    </nav>
  );
}

export default BreadCrumbs;