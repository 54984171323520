const usuarioNomeCompleto = 'usuarioNomeCompleto'
const cdSmsContaUsuario = 'cdSmsContaUsuario_novo'

const utilsLocalStorage = {
  getUsuarioNomeCompleto: () => localStorage.getItem(usuarioNomeCompleto),

  setUsuarioNomeCompleto: ({ ds_pav_usuario, ds_pav_usuario_sobrenome }) => {
    localStorage.setItem(
      usuarioNomeCompleto,
      `${ds_pav_usuario} ${ds_pav_usuario_sobrenome}`
    )
  },

  setCdSmsContaUsuario: (valor) =>
    localStorage.setItem(cdSmsContaUsuario, valor),
  getCdSmsContaUsuario: () => localStorage.getItem(cdSmsContaUsuario),
  removeLocalStorageSms: () => localStorage.removeItem(cdSmsContaUsuario)
}

export default utilsLocalStorage
