export function getTempFiltros(target, filtro_arquivo) {
  let tempFiltros = {}
  if (filtro_arquivo) {
    tempFiltros = filtro_arquivo
  } else {
    const formData = new FormData(target)
    for (var [key, value] of formData.entries()) {
      if (value !== '') {
        if (tempFiltros[key] !== '' && tempFiltros[key]) {
          tempFiltros[key] += ',' + value
        } else {
          tempFiltros[key] = value
        }
      }
    }
  }
  return tempFiltros
}
