import dayjs from 'dayjs'
import React from 'react'


import { calendario } from '../../../utils/alternanciaIcones'

import 'dayjs/locale/pt-br'
import * as S from './styles'
import { GlobalStyles } from './styles'


const customWeekDays = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']

export default function DatePicker({
  prefixo,
  type = 'default',
  placeholder,
  label,
  onChange,
  value,
  defaultValue,
  minDate
}) {
  const weekdayFormat = (date) => {
    const dayOfWeek = date.getDay()
    return customWeekDays[dayOfWeek]
  }

  const auxValue = minDate && dayjs(value).isBefore(minDate) ? minDate : value

  return (
    <>
      <GlobalStyles type={type} />
      <S.DatePickerInput
        $hasPrefixo={!!prefixo}
        minDate={minDate}
        type={type}
        allowSingleDateInRange
        popoverProps={{
          withinPortal: true,
          zIndex: 99999
        }}
        icon={<S.Prefixo>{prefixo}</S.Prefixo>}
        firstDayOfWeek={0}
        valueFormat="DD/MM/YYYY"
        zIndex={99999}
        locale="pt-BR"
        rightSection={<S.Img src={calendario()} />}
        placeholder={placeholder}
        label={label}
        radius="xl"
        value={auxValue}
        defaultValue={defaultValue}
        onChange={onChange}
        weekdayFormat={weekdayFormat}
      />
    </>
  )
}
