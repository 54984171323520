import React from 'react'
import { IMaskInput } from 'react-imask'

import * as S from './styles'
export default function Input({
  placeholder,
  label,
  variant = 'filled',
  value,
  disabled,
  type = 'text',
  mask,
  withAsterisk = false,
  onBlur,
  onChange,
  onKeyDown,
  autoFocus,
  maxLength
}) {
  return (
    <S.Input
      maxLength={maxLength}
      type={type}
      autoFocus={autoFocus}
      placeholder={placeholder}
      label={label}
      disabled={disabled}
      variant={variant}
      radius="xl"
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      withAsterisk={withAsterisk}
      component={IMaskInput}
      mask={mask}
    />
  )
}
