import { api_svdispositivos } from '../../conexoes/api'
import { formatBusca } from '../../utils/genericFormatters'
import { requestResolver } from '../../utils/requestResolver'

const getParams = (busca, separadorLinha) => {
  if (!busca) return ''
  return formatBusca(busca, separadorLinha)
}

export const SimcardsVirgensService = {
  getWhitechip: (
    pagina,
    linhasPorPagina,
    filtros = {},
    busca,
    buscaArquivo = {},
    separadorLinhaBusca,
    contagem
  ) => {
    const params = {
      params: {
        pagina,
        linhasPorPagina,
        contagem,
        filtro: filtros.vazio ? undefined : filtros,
        busca: busca ? getParams(busca, separadorLinhaBusca) : undefined,
        buscaArquivo: buscaArquivo.chave ? buscaArquivo : undefined
      }
    }

    Object.keys(params.params).forEach(
      (key) => params.params[key] === undefined && delete params.params[key]
    )

    return requestResolver(
      api_svdispositivos.get(`/api/v2/whitechip/iccids`, params)
    )
  },
  getCarriersFilter: () => {
    return requestResolver(
      api_svdispositivos.get(`/api/v2/whitechip/iccids/list/carriers`)
    )
  },
  getStatusFilter: () => {
    return requestResolver(
      api_svdispositivos.get(`/api/v2/whitechip/iccids/list/status`)
    )
  },
  getPreActivationDeadLineFilter: () => {
    return requestResolver(
      api_svdispositivos.get(
        `/api/v2/whitechip/iccids/list/preactivationdeadline`
      )
    )
  },
  getStatusPlan: () => {
    return requestResolver(
      api_svdispositivos.get(`/api/v2/whitechip/iccids/list/plan`)
    )
  },
  createReport: (body) => {
    return requestResolver(
      api_svdispositivos.post(`/api/v2/whitechip/iccids/export`, body)
    )
  },

  getNotFoundIccids: (body) => {
    return requestResolver(
      api_svdispositivos.post(`/api/v2/whitechip/iccids/iccidsNotFound`, body)
    )
  },

  getDevices: (filtros = {}, busca, buscaArquivo = {}, separadorLinhaBusca) => {
    const params = {
      params: {
        filtro: filtros.vazio ? undefined : filtros,
        busca: busca ? getParams(busca, separadorLinhaBusca) : undefined,
        buscaArquivo: buscaArquivo.chave ? buscaArquivo : undefined
      }
    }

    Object.keys(params.params).forEach(
      (key) => params.params[key] === undefined && delete params.params[key]
    )

    return requestResolver(
      api_svdispositivos.get(`/api/v2/whitechip/iccids/devices`, params)
    )
  }
}
