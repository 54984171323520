import { useDisclosure } from '@mantine/hooks'
import React, { useEffect, useRef, useState } from 'react'
import {
  GoogleMap,
  InfoWindow,
  Marker,
  withGoogleMap,
  withScriptjs
} from 'react-google-maps'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { filtrosArrayBarra, mudarFiltros } from '../../redux/actions/index'
import { PortadorasService } from '../../services/http/portadoras.service'
import { useGlobal } from '../../stores/useGlobal'
import { GOOGLE_MAPS_JAVASCRIPT_API } from '../../utils/googleMapsApiKey'
import Alerta from '../painelDispositivos/dspSimcard/Portadoras/Alertas/alertaLocalizacao.jsx'

function Mapa(props) {
  const { getDevicesPortadora } = PortadorasService
  const despacho = useDispatch()
  const history = useHistory()
  const { setFiltros, setRevelarBarraFiltro, setIsLoadingGlobal } = useGlobal()
  const [pontoSelecionado, setPontoSelecionado] = useState(null)
  const ref = useRef()

  const tipo = props.props.tipo
  const item = props.props.array[0]
  const permissions = props.props.permissions

  const handleClickFora = (e) => {
    if (ref.current !== null) {
      if (!ref.current.contains(e.target)) {
        setPontoSelecionado(null)
      }
    }
  }

  const manejadorState = useSelector((state) => state.manejadorState.manejador)

  useEffect(() => {
    document.addEventListener('mousedown', handleClickFora)
    return () => document.removeEventListener('mousedown', handleClickFora)
  }, [])

  const handleGetIdCarrier = async () => {
    setIsLoadingGlobal(true)
    const filtro = {
      CD_SIMCARD: pontoSelecionado.cd_simcard
    }

    await getDevicesPortadora(filtro)
      .then((res) => {
        if (res.portadora_id?.length) {
          const idsPortadoras = res.portadora_id.map((item) => item.id)

          const limite = 2
          const totalPortadoras = idsPortadoras.length
          let label

          history.push('/veye/portadoras')

          despacho(
            mudarFiltros({
              ID_PORTADORA: idsPortadoras.join()
            })
          )
          setFiltros({
            ID_PORTADORA: idsPortadoras.join()
          })

          if (totalPortadoras <= limite) {
            label = `idPortadoraOperator: ${idsPortadoras.join(', ')}`
          } else {
            const portadorasExibidas = idsPortadoras.slice(0, limite)
            const restante = totalPortadoras - limite
            label = `idPortadoraOperator: ${portadorasExibidas.join(
              ', '
            )}... +${restante}`
          }

          despacho(filtrosArrayBarra([label]))
          setRevelarBarraFiltro(true)
        } else {
          setPontoSelecionado(null)
          props.openAlerta()
        }
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => setIsLoadingGlobal(false))
  }

  return (
    <GoogleMap
      defaultZoom={10}
      defaultCenter={{ lat: item?.latitude, lng: item?.longitude }}
    >
      {props.props.array.map((ponto, index) => (
        <div key={index} ref={ref}>
          {ponto?.status === 'online' ? (
            <Marker
              icon={{
                url:
                  tipo === 'historico'
                    ? '../../icones/filled-marker-gray.svg'
                    : '../../icones/filled-marker-green.svg',
                scaledSize: new window.google.maps.Size(25, 25)
              }}
              key={index}
              position={{
                lat: ponto?.latitude,
                lng: ponto?.longitude
              }}
              onClick={() => setPontoSelecionado(ponto)}
            />
          ) : (
            <Marker
              icon={{
                url:
                  tipo === 'historico'
                    ? '../../icones/filled-marker-gray.svg'
                    : '../../icones/filled-marker-red.svg',
                scaledSize: new window.google.maps.Size(25, 25)
              }}
              key={index}
              position={{
                lat: ponto?.latitude,
                lng: ponto?.longitude
              }}
              onClick={() => setPontoSelecionado(ponto)}
            />
          )}
        </div>
      ))}

      {pontoSelecionado && (
        <div>
          <InfoWindow
            position={{
              lat: pontoSelecionado.latitude,
              lng: pontoSelecionado.longitude
            }}
            onCloseClick={() => setPontoSelecionado(null)}
          >
            <div ref={ref}>
              <div className="info-window-google-maps-detalhamento-linha">
                <div className="info-window-google-maps-detalhamento-titulo fonte-cor-1">
                  Endereço:{' '}
                </div>
                <div
                  className="info-window-google-maps-detalhamento-texto-link"
                  onClick={() =>
                    window.open(
                      'https://www.google.com/maps/search/?api=1&query=' +
                        pontoSelecionado.latitude +
                        ',' +
                        pontoSelecionado.longitude +
                        '',
                      '_blank'
                    )
                  }
                >
                  Abrir em nova aba
                </div>
              </div>
              <div className="info-window-google-maps-detalhamento-linha">
                <div className="info-window-google-maps-detalhamento-titulo fonte-cor-1">
                  Operadora:{' '}
                </div>
                <div className="info-window-google-maps-detalhamento-texto fonte-cor-1">
                  {pontoSelecionado.operadora || pontoSelecionado.rede}
                </div>
              </div>
              <div className="info-window-google-maps-detalhamento-linha">
                <div className="info-window-google-maps-detalhamento-coluna-1">
                  <div className="info-window-google-maps-detalhamento-titulo-1 fonte-cor-1">
                    Área(Lac):{' '}
                  </div>
                  <div className="info-window-google-maps-detalhamento-texto-1 fonte-cor-1">
                    {pontoSelecionado.lac}
                  </div>
                </div>
                <div className="info-window-google-maps-detalhamento-coluna-2">
                  <div className="info-window-google-maps-detalhamento-titulo-1 fonte-cor-1">
                    ID(antena):{' '}
                  </div>
                  <div className="info-window-google-maps-detalhamento-texto-1 fonte-cor-1">
                    {pontoSelecionado.cid || pontoSelecionado.id}
                  </div>
                </div>
              </div>
              <div className="info-window-google-maps-detalhamento-linha">
                <div className="info-window-google-maps-detalhamento-titulo fonte-cor-1">
                  Data/hora:{' '}
                </div>
                <div className="info-window-google-maps-detalhamento-texto fonte-cor-1">
                  {pontoSelecionado.data}
                </div>
              </div>
              {manejadorState === 'dspDispositivos' &&
                permissions?.portadoras && (
                  <div className="info-window-google-maps-detalhamento-linha">
                    <div
                      className="info-window-google-maps-detalhamento-texto-link"
                      onClick={handleGetIdCarrier}
                    >
                      Mostrar em portadoras
                    </div>
                  </div>
                )}
            </div>
          </InfoWindow>
        </div>
      )}
    </GoogleMap>
  )
}

const WrappedMap = withScriptjs(withGoogleMap(Mapa))

export default function MapaGoogle(props) {
  const [openedAlerta, { open: openAlerta, close: closeAlerta }] =
    useDisclosure(false)

  return (
    <div style={{ width: '100%', height: '100%', zoom: '125%' }}>
      <WrappedMap
        props={props}
        openAlerta={openAlerta}
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places,visualization&key=${GOOGLE_MAPS_JAVASCRIPT_API}&amp;`}
        loadingElement={<div style={{ height: '100%' }} />}
        containerElement={<div style={{ height: '100%' }} />}
        mapElement={<div style={{ height: '100%' }} />}
      />
      {openedAlerta && <Alerta opened={openedAlerta} onClick={closeAlerta} />}
    </div>
  )
}
