import React, { useState, useEffect } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import * as S from "./styles";

function generateDashDataWithHistorico() {
  const dash = [];
  const startDate = new Date("2025-01-01");

  for (let i = 0; i < 7; i++) {
    const date = new Date(startDate);
    date.setDate(startDate.getDate() + i);

    const randomHour = Math.floor(Math.random() * 24); // Horas entre 0 e 23
    const randomMinute = Math.floor(Math.random() * 60); // Minutos entre 0 e 59
    const formattedHour = `${randomHour
      .toString()
      .padStart(2, "0")}:${randomMinute.toString().padStart(2, "0")}`;

    const randomHistorico = Math.floor(Math.random() * 1000) + 1; // Histórico entre 1 e 1000

    dash.push({
      data: date.toISOString().split("T")[0], // Formata para YYYY-MM-DD
      hora: formattedHour,
      historico: randomHistorico,
    });
  }

  return dash;
}

const dash = generateDashDataWithHistorico();

export default function Grafico(props) {
  const gridColor = "#656565";
  const lineColor = "#228BE6";

  function TooltipGraficoArea({ payload, label, active }) {
    if (active && payload !== null && payload.length > 0) {
      if (
        payload[0].payload.historico !== null &&
        payload[0].payload.historico !== undefined
      ) {
        return (
          <div className="container-tooltip-grafico-area-historico-ultimo">
            <p className="label-tooltip-grafico-area-historico-ultimo">{`${payload[0].payload.historico}`}</p>
            <div className="triangulo-tooltip-grafico-area-historico-ultimo"></div>

            <div className="elipse-tooltip-grafico-area-historico-ultimo"></div>
          </div>
        );
      } else {
        return (
          <div className="container-tooltip-grafico-area-historico-ultimo">
            <p className="label-tooltip-grafico-area-historico-ultimo">{`${payload[0].payload.previsao}`}</p>
            <div className="triangulo-tooltip-grafico-area-historico-ultimo"></div>
            <div className="barra-tooltip-grafico-area-historico-ultimo"></div>
            <div className="elipse-tooltip-grafico-area-historico-ultimo"></div>
          </div>
        );
      }
    }
    return null;
  }

  const CustomTooltip = ({ payload, active }) => {
    if (active && payload && payload.length) {
      return (
        <S.Tooltip>
          <S.TooltipParagraph>
            Consumido:{" "}
            {parseFloat(`${payload[0] ? payload[0].value : "00"}`).toFixed(2)}{" "}
            MB
          </S.TooltipParagraph>
        </S.Tooltip>
      );
    }
    return null;
  };

  return (
    <S.Container>
      <ResponsiveContainer>
        <AreaChart
          data={dash}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="azul" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={lineColor} stopOpacity={0.2} />
              <stop offset="95%" stopColor={lineColor} stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid stroke={gridColor} strokeDasharray="0" />
          <XAxis stroke={gridColor} dataKey="hora" />
          <YAxis stroke={gridColor} />
          <Tooltip content={<CustomTooltip />} />
          <Area
            type="basic"
            dataKey="historico"
            stroke={lineColor}
            fillOpacity={1}
            fill="url(#azul)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </S.Container>
  );
}
