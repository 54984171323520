import moment from 'moment'

export const limpaDataInvalida = (v) => {
  if (v === '') return v

  let selectedData = v
  if (
    typeof selectedData === 'object' &&
    Object.keys(selectedData).length === 0
  ) {
    // Invalid Date
    selectedData = '' // evita crashar o frontend quando dá erro de "Invalid Date" dentro do componente DatePicker
  }
  return selectedData
}

export function getFormatedData(strData, hour = true) {
  if (strData === '0000-00-00 00:00:00') return '-'

  let strDataFormat = hour ? 'DD/MM/YYYY - HH:mm' : 'DD/MM/YYYY'

  return moment(strData).format(strDataFormat)
}
