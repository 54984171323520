import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { api } from '../../../conexoes/api'
import '../../../css/componentesModais/barraDadosCliente/barraDadosCliente.css'
import { useGlobal } from '../../../stores/useGlobal'
import { fecharJanela } from '../../../utils/alternanciaIcones'
import decryptJWT from '../../../utils/decryptJWT'
import { MascaraCnpj } from '../../../utils/mascarasCampos'

function BarraDadosCliente() {
  const { revelarDadosCliente, setRevelarDadosCliente } = useGlobal()

  const [codigoCliente, setCodigoCliente] = useState('-')
  const [razaoSocial, setRazaoSocial] = useState('-')
  const [cpfCnpj, setCpfCnpj] = useState('-')

  let visualizarDadosRefresh = useSelector(
    (state) => state.virtueyesState.visualizarDadosRefresh
  )

  useEffect(() => {
    async function carregarDados() {
      await api
        .get(
          '/m1/consultar/cliente?cd_pav_cliente=' + decryptJWT('codigoCliente')
        )
        .then((response) => {
          const n0_codigo_cliente = response.data.dados[0].n0_codigo_cliente
          const ds_cnpj = response.data.dados[0]?.ds_cnpj
          const ds_nome = response.data.dados[0]?.ds_nome

          setCodigoCliente(n0_codigo_cliente)
          setRazaoSocial(ds_nome)
          setCpfCnpj(ds_cnpj)
        })
        .catch((error) => console.error(error))
    }
    carregarDados()
  }, [visualizarDadosRefresh])

  const handleFecharBarra = () => setRevelarDadosCliente(false)

  if (!revelarDadosCliente) return null

  return (
    <div className="barra-dados-cliente fundo-cor-5">
      <div className="barra-dados-caixa-titulo">
        <span>Cliente</span>
      </div>
      <div className="barra-dados-caixa">
        <span className="fonte-cor-1">Código: {codigoCliente || '-'}</span>
      </div>
      <div className="divisora-vertical-barra-dados campo-select-divisor-cor-2" />
      <div className="barra-dados-caixa">
        <span className="fonte-cor-1">Razão social: {razaoSocial || '-'}</span>
      </div>
      <div className="divisora-vertical-barra-dados campo-select-divisor-cor-2" />
      <div className="barra-dados-caixa">
        <span className="fonte-cor-1">CNPJ: {MascaraCnpj(cpfCnpj) || '-'}</span>
      </div>
      <div className="barra-dados-caixa-fechar" onClick={handleFecharBarra}>
        <img
          className="botao-fechar-janela-icone-barra-dados-cliente"
          src={fecharJanela()}
          alt="fechar-janela"
        />
      </div>
    </div>
  )
}

export default BarraDadosCliente
