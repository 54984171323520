import React from 'react';

import * as S from "./styles";

export default function Botao({
  variant = "primary",
  label,
  disabled,
  loading,
  compact,
  uppercase = true,
  onClick,
  style,
  width,
  leftIcon = undefined,
}) {
  return (
    <S.Button
      w={width}
      style={style}
      leftIcon={leftIcon}
      onClick={onClick}
      uppercase={uppercase}
      compact={compact}
      disabled={disabled}
      variant={variant}
      loading={loading}
      loaderPosition="right"
      loaderProps={{
        size: "sm",
      }}
    >
      {label}
    </S.Button>
  );
}
