//IMPORTAÇÕES
/*eslint-disable*/
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { connect, useDispatch, useSelector } from 'react-redux'

//IMPORTAÇÕES ICONES
import {
  fecharBarraFiltro,
  fecharJanela
} from '../../../utils/alternanciaIcones'
//IMPORTAÇÕES REDUX
import {
  mudarFiltros,
  realizouFiltro,
  revelarBarraFiltragem
} from '../../../redux/actions/index'

import '../../../css/componentesModais/barraFiltragem/barraFiltragem.css'
import { useGlobal } from '../../../stores/useGlobal'

let nomeCampoFiltro = []
const objFiltroVazio = { vazio: 'vazio' }

const TYPE_FILTERS = ['WhiteChip', 'Operator']

function BarraFiltragem() {
  const {
    filtros: filtrosZustand,
    setFiltros: setFiltrosZustand,
    setRevelarBarraFiltro: setRevelarBarraFiltroZustand
  } = useGlobal()

  const despacho = useDispatch()
  let filtrosArrayBarra = useSelector(
    (state) => state.filtrosState.filtrosArrayBarra
  )
  let filtros = useSelector((state) => state.filtrosState.filtros)
  let recarregarBarraFiltragem = useSelector(
    (state) => state.filtrosState.recarregarBarraFiltragem
  )
  let maximoTagsExibidas2 = useSelector(
    (state) => state.filtrosState.maximoTagsExibidas
  )
  let cont = 0
  let temporarioFiltro = JSON.parse(JSON.stringify(filtros))

  const filtroMap = {
    CONEXOES_MIN: { CONEXOES_MIN: '', CONEXOES_MAX: '', CONEXOES: '' },
    PACOTES_MIN: { PACOTES_MIN: '', PACOTES_MAX: '', PACOTES: '' },
    ULTIMA_CONEXAO_INICIO: {
      ULTIMA_CONEXAO_INICIO: '',
      ULTIMA_CONEXAO_FINAL: ''
    },
    DtRequisicaoInicio: { DtRequisicaoInicio: '', DtRequisicaoFim: '' },
    CicloEfetivacaoInicio: {
      CicloEfetivacaoInicio: '',
      CicloEfetivacaoFim: ''
    },
    PLanoCliente: { PLanoClienteInicio: '', PLanoClienteFim: '' },
    PlanoOperadora: { PlanoOperadoraInicio: '', PlanoOperadoraFim: '' },
    ConsumoCicloAnterior: {
      ConsumoCicloAnteriorInicio: '',
      ConsumoCicloAnteriorFim: ''
    },
    ConsumoConsiderado: {
      ConsumoConsideradoInicio: '',
      ConsumoConsideradoFim: ''
    },
    CONSUMO_MIN: { CONSUMO_MIN: '', CONSUMO_MAX: '', CONSUMO: '' },
    VALOR_MIN: { VALOR_MIN: '', VALOR_MAX: '' },
    DIAS_USADOS_MIN: { DIAS_USADOS_MIN: '', DIAS_USADOS_MAX: '' },
    AQUISICAO_INICIO: { AQUISICAO_INICIO: '', AQUISICAO_FINAL: '' },
    ATIVACAO_INICIO: { ATIVACAO_INICIO: '', ATIVACAO_FINAL: '' },
    SALDO_MIN: { SALDO_MIN: '', SALDO_MAX: '' },
    RANGE_ICCID_MIN: { RANGE_ICCID_MIN: '', RANGE_ICCID_MAX: '' }
  }

  const [haveFiltros, setHaveFiltros] = useState(false)
  const [tagsExtras, setTagsExtras] = useState([])
  const [monitorarOperadora, setMonitorarOperadora] = useState(false)
  const [monitorarFiltros, setMonitorarFiltros] = useState(false)

  function removeFiltro({ i, temporarioFiltro, filtrosArrayBarra }) {
    filtrosArrayBarra.splice(i, 1)
    despacho(mudarFiltros(temporarioFiltro))
    setMonitorarFiltros(temporarioFiltro)
  }

  useEffect(() => {
    // temFiltros(filtrosArrayBarra.length)
    let barraPrincipal
    let larguraBarraPrincipal
    let larguraBarraTag
    let tag
    let total
    //pega a largura da barra principal
    if (document.getElementById('elemento-principal')) {
      barraPrincipal = document.getElementById('elemento-principal')
      larguraBarraPrincipal = barraPrincipal.clientWidth
    }
    if (document.getElementById('meu-elemento')) {
      tag = document.getElementById('meu-elemento')
      larguraBarraTag = tag.clientWidth
    }
    //transforma em percentual a largura da div que contem as tags
    if (larguraBarraPrincipal && larguraBarraTag) {
      let percentual = larguraBarraTag * 100
      total = percentual / larguraBarraPrincipal
      total = Math.round(total)
    }
    countTags()
    if (total >= 50) {
      setHaveFiltros(true)
    } else {
      setHaveFiltros(false)
    }
    countTags()
  }, [recarregarBarraFiltragem, monitorarFiltros, monitorarOperadora])

  //! comentado pois estava causando um bug, testei e nao consegui ver onde isso afeta
  // useEffect(() => {
  // return () => {
  //   if (JSON.stringify(filtros).includes("ICCID")) return;
  //   despacho(mudarFiltros(objFiltroVazio));
  //   despacho(revelarBarraFiltragem(false));
  //   despacho(realizouFiltro(false));
  // };
  // }, [filtros]);

  function countTags() {
    const divGeral = document.getElementById('meu-elemento')
    if (divGeral) {
      let countMaxWidth = 0
      let divsOverflow = 0
      // pega a largura de cada tag de filtros dentro da div
      // se caso a somatoria do tamanho de todas as tags forem maior que a div que compõem as tags
      // o botão de abrir a modal de filtros será ativado ocultando o restante de filtros que ficaram de fora da tela
      const allDivs = divGeral.querySelectorAll('.barra-filtragem-caixa')
      for (const el of allDivs) {
        countMaxWidth += el.offsetWidth + 17.5
        if (countMaxWidth > divGeral.offsetWidth) {
          divsOverflow += 1
        }
      }
      setTagsExtras(divsOverflow)
      if (divsOverflow <= 0) {
        setHaveFiltros(false)
      }
    }
  }

  function removerIdDoCampo(auxfiltros, campo, id) {
    if (auxfiltros[campo]) {
      auxfiltros[campo] = auxfiltros[campo]
        .split(',')
        .filter((item) => item !== id)
        .join(',')
    }
  }

  function handlerDeleteLastFiltro(id, i, tipoFiltro) {
    let getTipoFiltro = []
    let idOperadora = []
    let numberArr = []
    idOperadora.push(id)
    numberArr.push(parseInt(idOperadora[0]))

    if (tipoFiltro && TYPE_FILTERS.some((key) => tipoFiltro.includes(key))) {
      const auxfiltros = { ...filtrosZustand }

      const camposParaRemover = {
        operadoraOperator: () => removerIdDoCampo(auxfiltros, 'OPERADORA', id),
        tecnologiaOperator: () =>
          removerIdDoCampo(auxfiltros, 'TECNOLOGIA', id),
        estadoOperator: () => removerIdDoCampo(auxfiltros, 'ESTADO', id),
        cidadeOperator: () => removerIdDoCampo(auxfiltros, 'CIDADE', id),
        idPortadoraOperator: () => delete auxfiltros.ID_PORTADORA,
        carrierWhiteChip: () => removerIdDoCampo(auxfiltros, 'CdOperadora', id),
        statusWhiteChip: () => removerIdDoCampo(auxfiltros, 'CdStatus', id),
        userWhiteChip: () => removerIdDoCampo(auxfiltros, 'CdUsuario', id),
        planWhiteChip: () => removerIdDoCampo(auxfiltros, 'CdPlano', id),
        preActivationWhiteChip: () => delete auxfiltros.PrazoPreAtivacao,
        acquisitionDateWhiteChip: () => {
          delete auxfiltros.DataAquisicaoInicio
          delete auxfiltros.DataAquisicaoFim
        },
        provisioningWhiteChip: () => {
          delete auxfiltros.DataDeProvisionamentoInicio
          delete auxfiltros.DataDeProvisionamentoFim
        },
        preProvisioningWhiteChip: () => {
          delete auxfiltros.PreProvisionamentoInicio
          delete auxfiltros.PreProvisionamentoFim
        },
        iccidWhiteChip: () => {
          delete auxfiltros.IccidInicial
          delete auxfiltros.IccidFinal
        }
      }

      if (camposParaRemover[tipoFiltro]) camposParaRemover[tipoFiltro]()

      const limparCamposVazios = (obj) =>
        Object.fromEntries(
          Object.entries(obj).filter(
            ([_, value]) =>
              value !== '' && value !== null && value !== undefined
          )
        )

      const auxfiltrosLimpos = limparCamposVazios(auxfiltros)

      removeFiltro(i)
      setFiltrosZustand(auxfiltrosLimpos)

      countTags()
      return
    }

    if (numberArr[0] > 0 && tipoFiltro) {
      temporarioFiltro = JSON.parse(JSON.stringify(filtros))
      // caso o usuario feche uma tag de operadora na barra de dispositivos ou solicitações/conectividade
      getTipoFiltro.push(filtros[tipoFiltro].split(','))
      let removeTipoFiltroOpcao = getTipoFiltro[0].filter(function (objeto) {
        return idOperadora[0].indexOf(objeto) == -1
      })
      removeFiltro(i)
      delete temporarioFiltro[tipoFiltro]
      temporarioFiltro[tipoFiltro] = removeTipoFiltroOpcao.join(',')
      despacho(mudarFiltros(temporarioFiltro))

      if (tipoFiltro === 'OPERADORA') {
        let arr = temporarioFiltro?.CdOperadora?.split(',')
        if (arr) {
          arr = arr?.filter((num) => num != id)
          temporarioFiltro.CdOperadora = arr.join(arr?.length ? ',' : '')
          filtrosArrayBarra.filter((f) => f.trim() !== `NEO ${id}`)
        }

        setMonitorarOperadora(temporarioFiltro[tipoFiltro])
      }
    } else {
      let TemporarioValor = ''
      let TemporarioData = ''

      id[0].split('_').map((item_id) => {
        //caso para o grupo de input, onde verifica se tem tais terminacoes e deleta o valor maximo
        if (item_id == 'MIN' || item_id == 'MAX') {
          TemporarioValor += '_MAX'
          //retira o primeiro dash
          TemporarioValor = TemporarioValor.replace('_', '')
          //caso especial onde e necessario resetar outro elemento
          if (TemporarioValor == 'CONSUMO_MAX') temporarioFiltro['CONSUMO'] = ''
          if (TemporarioValor == 'CONEXOES_MAX')
            temporarioFiltro['CONEXOES'] = ''
          if (TemporarioValor == 'PACOTES_MAX') temporarioFiltro['PACOTES'] = ''
          //reseta o valor
          temporarioFiltro[TemporarioValor] = ''
        } else {
          TemporarioValor += '_' + item_id
        }

        //caso para o calendario, onde verifica se tem tais terminacoes e deleta a data final
        if (item_id == 'INICIO' || item_id == 'FINAL') {
          TemporarioData += '_FINAL'
          //retira o primeiro dash
          TemporarioData = TemporarioData.replace('_', '')

          //caso especial onde e necessario resetar outro elemento
          // if(TemporarioData == "ULTIMA_CONEXAO_FINAL") temporarioFiltro['ULTIMA_CONEXAO'] = ''
          //reseta o valor
          temporarioFiltro[TemporarioData] = ''
        } else {
          TemporarioData += '_' + item_id
        }
      })

      //reseta o valor
      if (id && filtroMap[id]) {
        removeFiltro(i, filtroMap[id])
      } else if (tipoFiltro === 'StatusMigracao') {
        const arrStatusM = temporarioFiltro?.StatusMigracao?.split(',') || []
        if (!arrStatusM.length) return

        const newArrStatusMigracao = arrStatusM.filter((item) => item !== id)
        const StatusMigracao = newArrStatusMigracao.toString() || undefined
        removeFiltro(i, { StatusMigracao })
      } else {
        temporarioFiltro[id] = ''
        removeFiltro(
          i,
          id === 'CD_SITUACAO' ? { STATUS_CONTRATO: '' } : undefined
        )

        temporarioFiltro = removeEmptyProperties(temporarioFiltro)
        if (JSON.stringify(temporarioFiltro) === '{}') {
          temporarioFiltro = objFiltroVazio
        }

        const hasFilters = Object.keys(filtrosZustand)?.length > 0
        if (hasFilters) {
          const auxFilter = { ...filtrosZustand }
          delete auxFilter[`${id}`]
          setFiltrosZustand(auxFilter)
        }
      }
    }
    countTags()
  }

  function removeFiltro(index, fields) {
    if (fields) {
      Object.entries(fields).forEach(([key, value]) => {
        temporarioFiltro[key] = value
      })
    }
    filtrosArrayBarra.splice(index, 1)
    despacho(mudarFiltros(temporarioFiltro))
    setMonitorarFiltros(temporarioFiltro)
  }

  function removeEmptyProperties(obj) {
    return Object.fromEntries(Object.entries(obj).filter(([, v]) => v !== ''))
  }

  //se nao possuir nenhum filtro ativo ela ira fechar
  if (filtrosArrayBarra.length == 0) {
    setRevelarBarraFiltroZustand(false)
    despacho(revelarBarraFiltragem(false))
  }

  function renderFiltros() {
    nomeCampoFiltro = []
    let contagem = 0
    let comOperadora = false

    filtrosArrayBarra.map(function (item) {
      if (!item.includes('?')) {
        contagem++
      }
    })
    if (contagem >= 0) {
      comOperadora = true
    }

    return filtrosArrayBarra.map(function (item, i) {
      if (item.includes('?')) {
        const valor = item.split('?')
        nomeCampoFiltro.push(valor[1].split(','))
        if (cont === 0) {
          cont++
          return (
            <React.Fragment key={'tag-' + valor[0]}>
              <div id={'tag-' + valor[0]} className="barra-filtragem-caixa">
                <div id="testeBox" className="filtragem-caixa">
                  <span className="fonte-barra-filtro">{valor[0]}</span>
                  <Button
                    className="botao-fechar-janela"
                    variant="danger"
                    onClick={() => handlerDeleteLastFiltro(valor[1], i)}
                  >
                    <img
                      className="botao-limpar-item-icone"
                      src={fecharBarraFiltro()}
                      alt="fechar-janela"
                    />
                  </Button>
                  <div className="barra-filtragem-caixa-limpar-item"></div>
                </div>
              </div>
            </React.Fragment>
          )
        } else if (filtrosArrayBarra.length == i + 1) {
          return (
            <React.Fragment key={'tag-' + valor[0]}>
              <div id={'tag-' + valor[0]} className="barra-filtragem-caixa">
                <div className="filtragem-caixa">
                  <span className="fonte-barra-filtro">{valor[0]}</span>
                  <Button
                    className="botao-fechar-janela"
                    variant="danger"
                    onClick={() =>
                      comOperadora
                        ? handlerDeleteLastFiltro(valor[1], i)
                        : handlerDeleteLastFiltro(nomeCampoFiltro[i], i)
                    }
                  >
                    <img
                      className="botao-limpar-item-icone"
                      src={fecharBarraFiltro()}
                      alt="fechar-janela"
                    />
                  </Button>
                  <div className="barra-filtragem-caixa-limpar-item"></div>
                </div>
              </div>
            </React.Fragment>
          )
        } else {
          return (
            <React.Fragment key={'tag-' + valor[0]}>
              <div id={'tag-' + valor[0]} className="barra-filtragem-caixa">
                <div className="filtragem-caixa">
                  <span className="fonte-barra-filtro">{valor[0]}</span>
                  <Button
                    className="botao-fechar-janela"
                    variant="danger"
                    onClick={() => handlerDeleteLastFiltro(valor[1], i)}
                  >
                    <img
                      className="botao-limpar-item-icone"
                      src={fecharBarraFiltro()}
                      alt="fechar-janela"
                    />
                  </Button>
                  <div className="barra-filtragem-caixa-limpar-item"></div>
                </div>
              </div>
            </React.Fragment>
          )
        }
      } else if (TYPE_FILTERS.some((key) => item.includes(key))) {
        let auxItem = item.split(': ')[1] || ''

        const tiposDeFiltro = {
          statusWhiteChip: 'Status',
          planWhiteChip: 'Plano',
          preActivationWhiteChip: 'Prazo pré-ativação',
          preProvisioningWhiteChip: 'Pré-provisionamento',
          acquisitionDateWhiteChip: 'Aquisição',
          provisioningWhiteChip: 'Data de provisionamento',
          iccidWhiteChip: 'ICCID entre',
          carrierWhiteChip: 'Operadora',
          userWhiteChip: 'Usuário',
          estadoOperator: 'Estado',
          cidadeOperator: 'Cidade',
          tecnologiaOperator: 'Tecnologia',
          operadoraOperator: 'Rede',
          idPortadoraOperator: 'ID Portadora'
        }

        const tipoFiltro =
          Object.keys(tiposDeFiltro).find((key) => item.includes(key)) || ''

        const descricao = tiposDeFiltro[tipoFiltro] || ''
        const [valor, codigo] = auxItem.split('_').filter(Boolean)
        const titulo = descricao ? `${descricao}: ${valor}` : ''

        return (
          <div
            key={`tag-${titulo}`}
            id={`tag-${titulo}`}
            className="barra-filtragem-caixa"
          >
            <div className="filtragem-caixa">
              <span className="fonte-barra-filtro">{titulo}</span>
              <Button
                className="botao-fechar-janela"
                variant="danger"
                onClick={() => handlerDeleteLastFiltro(codigo, i, tipoFiltro)}
              >
                <img
                  className="botao-limpar-item-icone"
                  src={fecharBarraFiltro()}
                  alt="fechar-janela"
                />
              </Button>
              <div className="barra-filtragem-caixa-limpar-item"></div>
            </div>
          </div>
        )
      } else {
        //OS IFs ABAIXO SÃO APENAS PARA VALIDAR CHAMADAS ESPECIFICAS EM DISPOSITIVOS OU SOLICITAÇÕES/CONECTIVIDADE
        let auxItem = item
        let tipoFiltro = 'OPERADORA'

        const filtroPorStatus = auxItem.includes('Status: ')
        const filtroPorStatusMigracao = auxItem.includes('Status migração: ')

        if (filtroPorStatus) {
          auxItem = auxItem.split(': ')[1]
          tipoFiltro = 'STATUS'
        } else if (filtroPorStatusMigracao) {
          auxItem = auxItem.split(': ')[1]
          tipoFiltro = 'StatusMigracao'
        }

        /* apenas para checkbox nas telas:
               operadora em disositivos e Status 
               e Operadora em solicitações/conectividade
           */
        const valor = auxItem.split(' ')
        var resultado = valor.filter(function (v) {
          return v !== ''
        })

        let codigo = resultado[1]
        let titulo = valor.length === 3 ? valor[1] : valor[0]

        if (filtroPorStatusMigracao) {
          if (titulo === 'Sem') titulo = 'Sem migração'

          codigo = titulo

          titulo = `Status migração: ${titulo}`
        } else if (filtroPorStatus) {
          titulo = `Status: ${titulo}`
        } else if (tipoFiltro === 'OPERADORA') {
          titulo = `Operadora: ${titulo}`
        }

        return (
          <React.Fragment key={`tag-${titulo}`}>
            <div id={'tag-' + titulo} className="barra-filtragem-caixa">
              <div className="filtragem-caixa">
                <span className="fonte-barra-filtro">{titulo}</span>
                <Button
                  className="botao-fechar-janela"
                  variant="danger"
                  onClick={() => {
                    handlerDeleteLastFiltro(codigo, i, tipoFiltro) // chamado em Dispositivo filtro por operadora / Solicitações/Conectividade filtro por Status
                  }}
                >
                  <img
                    className="botao-limpar-item-icone"
                    src={fecharBarraFiltro()}
                    alt="fechar-janela"
                  />
                </Button>
                <div className="barra-filtragem-caixa-limpar-item"></div>
              </div>
            </div>
          </React.Fragment>
        )
      }
    })
  }

  function abrirFiltros() {
    maximoTagsExibidas2(true)
  }

  return (
    <>
      <div
        id="elemento-principal"
        className="barra-filtragem-cliente fundo-cor-5"
      >
        <p className="filtrado-por">Filtrado por: </p>
        <div id="meu-elemento" className="tags-barra-filtragem">
          {renderFiltros()}
        </div>
        <button
          className={haveFiltros ? 'mais-filtros' : 'menos-filtros'}
          onClick={() => abrirFiltros()}
        >
          + {tagsExtras} Filtros
        </button>
        <div className="barra-filtragem-cliente-fechar fundo-cor-5">
          <div className="barra-filtragem-caixa-fechar">
            <p className="limpar-filtro">
              {filtrosArrayBarra.length > 1
                ? 'LIMPAR FILTROS'
                : 'LIMPAR FILTRO'}
            </p>
            <Button
              className="botao-fechar-janela"
              variant="danger"
              onClick={() => {
                despacho(mudarFiltros(objFiltroVazio))
                despacho(revelarBarraFiltragem(false))
                despacho(realizouFiltro(true))
                setFiltrosZustand(objFiltroVazio)
                setRevelarBarraFiltroZustand(false)
              }}
            >
              <img
                className="botao-fechar-janela-icone-barra-filtragem"
                src={fecharJanela()}
                alt="fechar-janela"
              />
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

function mapStateToProps(state) {
  return {
    filtrosArrayBarra: state.filtrosState.filtrosArrayBarra,
    recarregarBarraFiltragem: state.filtrosState.recarregarBarraFiltragem
  }
}

export default connect(mapStateToProps)(BarraFiltragem)
