import styled from "styled-components";

export const HistoricoWrapper = styled.div`
  background-color: ${({ theme }) => theme.cor53}!important;
  zoom: 90%;
  border-radius: 8px;
  padding: 10px;
  gap: 20px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  .mantine-Input-wrapper {
    width: 200px;
  }
`;
