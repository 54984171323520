import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  table {
    tbody tr {
      td.reset-expansion-style {
        padding: 0 !important;
      }
    }
  }
`;

export const Container = styled.div`
  padding: 16px 0;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;
  overflow: hidden;
  height: 405px;
`;

export const Opcoes = styled.div`
  width: 248px;
  height: ${({ itens }) => `${52 * Number(itens)}px`}; //258px
  padding: 18px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.cor53};
  display: grid;
  gap: 24px;
`;

export const Opcao = styled.div`
  cursor: pointer;
  display: flex;
  gap: 12px;
`;

export const Label = styled.span`
  font-size: 15px;
  font-weight: 700;
  color: ${({ theme, selected }) => (selected ? theme.cor11 : theme.cor9)};
`;

export const Img = styled.img``;

export const ContainerMapa = styled.div`
  height: 358px;
  width: calc(100% - 17em);
`;
