import React, { useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import '../../../../css/painelDispositivos/dspSimcard/dspAcoes.css'
import {
  desabilitarAcoes,
  desabilitarAcoesCliente,
  filtrosArrayBarra,
  limpadorFiltros,
  mudarDoppleGangerCliente,
  mudarFiltros,
  recarregarBarraFiltragem,
  revelarBarraFiltragem
} from '../../../../redux/actions/index'
import { PortadorasService } from '../../../../services/http/portadoras.service.js'
import { useGlobal } from '../../../../stores/useGlobal.js'
import PaginacaoTabela from '../../../../utils/paginacaoTabela'
import Botao from '../../../Mantine/Botao'
import * as S from './styles'

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js'
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso.js'

const TableprimaryKey = 'codigo'
let valorLimite = 9
let totalItens = 9
let contador = 0
let totalPaginas = 0

let auxDados = []

const COUNT_ITEMS = true

const COLUNAS = [
  {
    dataField: 'codigo',
    text: 'Código',
    formatter: (cell) => cell || '-'
  },
  {
    dataField: 'razao',
    text: 'Razão social',
    formatter: (cell) => cell || '-'
  },
  {
    dataField: 'fantasia',
    text: 'Nome fantasia',
    formatter: (cell) => cell || '-'
  }
]

export default function ClientesConectados({
  setAuxAcaoMostrarEmDispositivos,
  cdPortadora = undefined
}) {
  const { filtros, setFiltros, setIsLoadingGlobal } = useGlobal()
  const despacho = useDispatch()
  const [paginaAtual, setPaginaAtual] = useState(1)
  const [dados, setDados] = useState([])
  const [cliente, setCliente] = useState(null)
  const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)

  const history = useHistory()
  const { getRadioCustomers, getAlterarToken, getAtualizarLogin } =
    PortadorasService

  const opcoesPaginacao = {
    page: paginaAtual,
    showTotal: true,
    sizePerPage: valorLimite,
    firstPageTitle: 'Primeira',
    lastPageTitle: 'Última',
    nextPageTitle: 'Proxima',
    prePageTitle: 'Anterior',
    custom: true,
    onPageChange: (page) => {
      totalItens ? setPaginaAtual(page) : setPaginaAtual(1)
    }
  }

  const handleMensagemSucesso = async (confirmacao) => {
    setRevelarMensagemSucesso(!revelarMensagemSucesso)
    setAuxAcaoMostrarEmDispositivos()

    //! setFiltros É SÓ PARA CONTROLE DENTRO USEEFFECT QUE LIMPA OS FILTROS AO ENTRAR NESSA TELA
    setFiltros({ RADIO: cliente.codigoPortadora })
    despacho(mudarFiltros({ RADIO: cliente.codigoPortadora }))
    despacho(filtrosArrayBarra(['Portadora?RADIO']))
    despacho(revelarBarraFiltragem(true))
    despacho(recarregarBarraFiltragem(true))
    despacho(limpadorFiltros(false))
    if (revelarMensagemSucesso && confirmacao)
      history.push('/veye/dispositivos')
  }

  const getLimiteNaPagina = () =>
    contador + valorLimite <= totalItens ? contador + valorLimite : totalItens

  const trocarPagina = (novaPagina) => {
    if (totalPaginas <= 1) return
    setPaginaAtual(novaPagina)
  }

  const selecaoLinhas = {
    mode: 'radio',
    onSelect: (row, isSelect) => {
      if (isSelect) {
        despacho(desabilitarAcoes(false))
        despacho(desabilitarAcoesCliente(true))
        setCliente({
          codigoPortadora: row?.codigoPortadora ?? undefined,
          codigo: row.codigoCliente,
          codigoErp: String(row.codigo),
          nome: row.razao
        })
      } else {
        despacho(desabilitarAcoes(true))
        despacho(desabilitarAcoesCliente(false))
      }
    },
    selectionRenderer: ({ mode }) => {
      return (
        <label className="label-checkbox-cliente-virtueyes fonte-cor-1">
          <input type={mode} id=" CLIENTEVEYE" name="CLIENTE" />
          <span className="checkbox-cliente-virtueyes radio-button-cor-1"></span>
        </label>
      )
    },
    bgColor: { backgroundColor: 'gray' },
    color: 'red'
  }

  useQuery(
    [
      `/portadoras/radioCustomers`,
      paginaAtual,
      valorLimite,
      filtros,
      COUNT_ITEMS,
      cdPortadora
    ],
    async () => {
      try {
        setIsLoadingGlobal(true)
        return await getRadioCustomers(
          paginaAtual,
          valorLimite,
          filtros,
          COUNT_ITEMS,
          cdPortadora
        )
      } catch (err) {
        console.error(err)
      } finally {
        setIsLoadingGlobal(false)
      }
    },
    {
      onSuccess: async (data) => {
        contador = (paginaAtual - 1) * valorLimite
        totalItens = data?.item_count
        totalPaginas = Math.ceil(totalItens / valorLimite)

        const novosItens = [...auxDados]

        data.customers?.forEach((portadora, index) => {
          novosItens[contador + index] = portadora
        })

        setDados(novosItens)
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false
    }
  )

  const handleAlterarToken = async () => {
    await fetchAlterarToken()
  }

  const handleAtualizarLogin = async () => {
    await fetchAtualizarLogin()
  }
  const { refetch: fetchAlterarToken } = useQuery(
    ['alterarToken'],
    () => getAlterarToken(cliente),
    {
      enabled: false,
      onSuccess: (response) => {
        localStorage.setItem('@veye-Token', response.data.dados.token)
        localStorage.setItem('codigoClienteErpAntigo', cliente.codigoErp)
      },
      onError: (error) => {
        console.error(error)
      }
    }
  )

  const { refetch: fetchAtualizarLogin } = useQuery(
    ['atualizarLogin'],
    getAtualizarLogin,
    {
      enabled: false,
      onError: (error) => {
        console.error(error)
      }
    }
  )

  const carregaCliente = () => {
    handleDoppelgangerCliente()
  }

  async function handleDoppelgangerCliente() {
    setIsLoadingGlobal(true)
    try {
      await handleAlterarToken()
      await handleAtualizarLogin()
      setRevelarMensagemSucesso(true)
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoadingGlobal(false)
      despacho(mudarDoppleGangerCliente(false))
    }
  }

  return (
    <S.clientesConectadosWrapper>
      <Botao
        disabled={!cliente}
        label="CARREGAR CLIENTE"
        width={175}
        onClick={carregaCliente}
      />
      <div className="container-simcards-erb">
        <PaginationProvider pagination={Paginador(opcoesPaginacao)}>
          {({ paginationProps, paginationTableProps }) => (
            <>
              <div className="container-table-simcards-erb">
                <BootstrapTable
                  classes={'tabela'}
                  condensed={true}
                  keyField={TableprimaryKey}
                  data={dados}
                  columns={COLUNAS}
                  bootstrap4={true}
                  bordered={false}
                  selectRow={selecaoLinhas}
                  noDataIndication={'Nenhum item encontrado'}
                  {...paginationTableProps}
                />
              </div>
              {dados?.length > 0 && (
                <>
                  <div className="pagination-table">
                    <PaginacaoTabela
                      preview={true}
                      setPaginaAtual={trocarPagina}
                      totalPaginas={totalPaginas}
                      totalItens={totalItens}
                      valorLimite={valorLimite}
                      paginaAtual={paginaAtual}
                      page={paginationProps.page}
                      onPageChange={paginationProps.onPageChange}
                    />
                  </div>
                  <div className="pagination-description">
                    Mostrando
                    <span className="label-tabela-itens-exibidos">
                      {contador + 1}
                    </span>
                    -
                    <span className="label-tabela-itens-exibidos">
                      {getLimiteNaPagina()}
                    </span>
                    de
                    <span className="label-tabela-itens-exibidos">
                      {totalItens}
                    </span>
                  </div>
                </>
              )}
            </>
          )}
        </PaginationProvider>
      </div>
      {revelarMensagemSucesso && (
        <div className="componente-modal-overlay">
          <div className="componente-modal">
            <MensagemSucesso
              handleMensagemSucesso={handleMensagemSucesso}
              mensagemTitulo={'Sucesso'}
              mensagemParagrafo1={'O cliente ' + cliente.nome + ','}
              mensagemParagrafo2={
                'cód. ' + cliente.codigoErp + ' foi carregado.'
              }
              mensagemBotao={'OK'}
            />
          </div>
        </div>
      )}
    </S.clientesConectadosWrapper>
  )
}
