// import { showNotification } from "@mantine/notifications";

export function useCopiarParaAreaDeTransferencia() {
  const copiarParaAreaDeTransferencia = (texto) => {
    navigator.clipboard
      .writeText(texto)
      .then(() => {
        // showNotification({
        //   title: "Sucesso!",
        //   message: "Texto copiado para a área de transferência!",
        // });
      })
      .catch(() => {
        // showNotification({
        //   title: "Erro!",
        //   message: "Erro ao copiar o texto para a área de transferência.",
        // });
      })
  }

  return copiarParaAreaDeTransferencia
}
