import { useDisclosure } from '@mantine/hooks'
import React, { useEffect, useState } from 'react'
import { Button, Image } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import MensagemInformacao from '../../../components/componentesModais/mensagens/mensagemInformacao'
import '../../../css/painelDispositivos/dspSimcard/dspAcoes.css'
import info from '../../../icones/iconesVersaoVerde/icone-info.svg'
import {
  desabilitarAcoes,
  desabilitarAcoesCliente,
  filtrosArrayBarra,
  limpadorFiltros,
  mudarFiltros,
  mudarManejador,
  recarregarBarraFiltragem,
  revelarBarraFiltragem
} from '../../../redux/actions/index'
import { SolicitationsService } from '../../../services/http/solicitations.service'
import { useGlobal } from '../../../stores/useGlobal'
import { setaDropdown } from '../../../utils/alternanciaIcones'
import GirarSetaDropdown from '../../../utils/girarSetaDropdown'
import PaginacaoTabela from '../../../utils/paginacaoTabela'
import BarraDadosCliente from '../../componentesModais/barraDadosCliente/barraDadosCliente'
import BarraFiltragem from '../../componentesModais/barraFiltragem/barraFiltragem'
import ActionIcon from '../../Mantine/ActionIcon'
import Botao from '../../Mantine/Botao'
import Group from '../../Mantine/Group'
import Modal from '../../Mantine/Modal'
import Select from '../../Mantine/Select'
import DetalhamentoSolicitacaoSimcardVirgem from '../../Solicitacoes/SimcardsVirgens/detalhamentoSolicitacaoSimcardVirgem'
import BarraTabelaLegenda from '../../tabelaSelecao/barraTabelaLegenda'
import * as S from './styles'

let valorLimite = 50
let totalItens = 58
let contador = 0
let totalPaginas = 0
let auxData = []

const TableprimaryKey = 'CodigoSolicitacao'
const COUNT_ITEMS = true

const SUCCESSFUL_STATUS = ['Concluída', 'Concluída parcial']

const columns = [
  {
    dataField: 'CodigoSolicitacao',
    text: 'Cód. solicitação',
    headerClasses: 'nao-selecionavel',
    sort: true
  },
  {
    dataField: 'Operadora',
    text: 'Operadora',
    headerClasses: 'nao-selecionavel',
    sort: true,
    headerWrapperClasses: 'operadoraFinal'
  },
  {
    dataField: 'TipoSolicitacao',
    headerClasses: 'nao-selecionavel',
    text: 'Tipo da solicitação',
    sort: true
  },
  {
    dataField: 'Status',
    headerClasses: 'nao-selecionavel',
    text: 'Status',
    sort: true,
    formatter: (cell) => {
      return (
        <S.StatusWrapper>
          <S.StatusElipse status={cell} />
          {cell}
        </S.StatusWrapper>
      )
    }
  },
  {
    dataField: 'DataSolicitacao',
    headerClasses: 'nao-selecionavel',
    text: 'Data da solicitação',
    sort: true
  },
  {
    dataField: 'Usuario',
    headerClasses: '',
    text: 'Usuário',
    sort: true,
    formatter: (cell) => {
      return cell === 'automacao@virtueyes.com.br' ? (
        <div style={{ display: 'flex', 'justify-content': 'space-between' }}>
          <span>{cell}</span>
          <ActionIcon
            alt="info"
            variant="transparent"
            icon={info}
            tooltip={{
              label:
                'O prazo de pré-provisionamento expirou e o SIM card foi automaticamente provisionado pelo sistema.',
              width: 280
            }}
          />
        </div>
      ) : (
        cell
      )
    }
  },
  {
    dataField: 'QtdDeSimcards',
    headerClasses: 'nao-selecionavel',
    text: 'Quantidade de SIM Cards',
    sort: true
  }
]

export default function TabelaSolicitacoesSimcardsVirgens() {
  const history = useHistory()
  const [opened, { open, close }] = useDisclosure(false)
  const [
    openedErroMostrarEmDispositivos,
    {
      open: openErroMostrarEmDispositivos,
      close: closeErroMostrarEmDispositivos
    }
  ] = useDisclosure(false)
  const recarregarBarraFiltragemValor = useSelector(
    (state) => state.filtrosState.recarregarBarraFiltragem
  )
  const {
    filtros,
    busca,
    setFiltros,
    setRevelarBarraFiltro,
    buscaArquivo,
    separadorLinhaBusca,
    revelarBarraFiltro,
    exportarRelatorio,
    setExportarRelatorio,
    mostrarAcao,
    setMostrarAcao,
    setIsLoadingGlobal
  } = useGlobal()

  const { getWhitechip, createReport, createPeriodReport, getDevices } =
    SolicitationsService

  const [showCaption, setShowCaption] = useState(false)
  const [paginaAtual, setPaginaAtual] = useState(1)
  const [periodo, setPeriodo] = useState('')
  const [solicitations, setSolicitations] = useState([])
  const [mostrarAlertaRelatorio, setMostrarAlertaRelatorio] = useState(false)
  const [solicitationItem, setSolicitationItem] = useState('')
  const mostrarEmDispositivos =
    mostrarAcao === 'revelarMostrarEmDispositivosSolicitacoesSimcardsEmBranco'

  useQuery(
    [
      `whitechip/solicitations?page=${paginaAtual}`,
      paginaAtual,
      valorLimite,
      filtros,
      busca,
      buscaArquivo,
      true
    ],
    async () => {
      try {
        setIsLoadingGlobal(true)
        return await getWhitechip(
          paginaAtual,
          valorLimite,
          filtros,
          busca,
          buscaArquivo,
          separadorLinhaBusca,
          COUNT_ITEMS
        )
      } catch (err) {
        console.error(err)
      } finally {
        setIsLoadingGlobal(false)
      }
    },
    {
      onSuccess: (data) => {
        contador = (paginaAtual - 1) * valorLimite
        totalItens = data?.item_count
        totalPaginas = Math.ceil(totalItens / valorLimite)

        const novosItens = [...auxData]

        data.solicitations?.forEach((iccid, index) => {
          novosItens[contador + index] = iccid
        })

        setSolicitations(novosItens)
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false
    }
  )

  const despacho = useDispatch()

  useEffect(() => {
    despacho(mudarManejador('solicitacoesSimcardsVirgens'))
  }, [despacho])

  function trocarPagina(novaPagina) {
    if (totalPaginas <= 1) return

    setPaginaAtual(novaPagina)
  }

  const opcoesPaginacao = {
    page: paginaAtual,
    showTotal: true,
    sizePerPage: valorLimite,
    firstPageTitle: 'Primeira',
    lastPageTitle: 'Última',
    nextPageTitle: 'Proxima',
    prePageTitle: 'Anterior',
    custom: true,
    onPageChange: (newPage) => {
      trocarPagina(newPage)
    }
  }

  const itemExpandido = {
    className: 'fundo-cor-1',
    renderer: (row) => {
      return (
        <DetalhamentoSolicitacaoSimcardVirgem
          cdSolicitacao={row?.CodigoSolicitacao}
        />
      )
    },
    expandHeaderColumnRenderer: () => <div className="tela-background"></div>,
    expandColumnRenderer: (rowKey) => {
      return (
        <Button
          className="botao-expandir-row"
          onClick={(e) => GirarSetaDropdown(rowKey.rowKey)}
        >
          <Image
            id={rowKey.rowKey}
            className="icone-botao-expandir-row nao-selecionavel"
            src={setaDropdown()}
          />
        </Button>
      )
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    headerClasses: 'tabela-coluna-primeira-header'
  }

  const getLimiteNaPagina = () =>
    contador + valorLimite <= totalItens ? contador + valorLimite : totalItens
  const getTotalItensNaPagina = () => getLimiteNaPagina() - contador

  const selecaoLinhas = {
    mode: 'radio',
    onSelect: (row, isSelect) => {
      if (isSelect) {
        despacho(desabilitarAcoes(false))
        despacho(desabilitarAcoesCliente(true))
        setSolicitationItem(row.CodigoSolicitacao)
      } else {
        despacho(desabilitarAcoes(true))
        despacho(desabilitarAcoesCliente(false))
      }
    },
    selectionRenderer: ({ mode }) => {
      return (
        <label className="label-checkbox-cliente-virtueyes fonte-cor-1">
          <input type={mode} id=" CLIENTEVEYE" name="CLIENTE" />
          <span className="checkbox-cliente-virtueyes radio-button-cor-1"></span>
        </label>
      )
    },
    bgColor: { backgroundColor: 'gray' },
    color: 'red'
  }

  const getOptions = () => {
    const arrOpcoes = Array.from({ length: 4 }, (_, i) => {
      const auxDate = new Date()
      auxDate.setMonth(auxDate.getMonth() - i)
      const aux = auxDate.toISOString().slice(0, 7).replace('-', '/')
      return { label: aux, value: aux }
    })

    return arrOpcoes
  }

  const onConfirm = () => {
    createPeriodReport(periodo)
    setMostrarAlertaRelatorio(true)
    setExportarRelatorio({})
    close()
  }

  const handleMostrarEmDispositivos = async () => {
    try {
      const { data: iccidList } = await getDevices(solicitationItem)

      //! ESSE setFiltros É SOMENTE PARA CONTROLE DENTRO USEEFFECT QUE LIMPA OS FILTROS AO ENTRAR NESSA TELA
      setFiltros({ ICCID: iccidList })
      despacho(mudarFiltros({ ICCID: iccidList }))
      despacho(revelarBarraFiltragem(true))
      despacho(filtrosArrayBarra(['ICCIDS Provisionados?ICCID']))
      despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
      despacho(limpadorFiltros(false))
      history.push('/veye/dispositivos')
    } catch (err) {
      console.error(err)
    } finally {
      setMostrarAcao(undefined)
    }
  }

  useEffect(() => {
    if (!filtros.vazio) {
      setFiltros({ vazio: 'vazio' })
      setRevelarBarraFiltro(false)
      despacho(mudarFiltros({ vazio: 'vazio' }))
      despacho(revelarBarraFiltragem(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setPaginaAtual(1)
  }, [filtros])

  useEffect(() => {
    if (
      exportarRelatorio &&
      exportarRelatorio?.acao === 'exportarSolicitacaoSimcardsEmBranco'
    ) {
      if (exportarRelatorio.tipo === 'Periodo') {
        setPeriodo('')
        open()
      } else {
        async function handleExportarLinhas() {
          createReport(solicitationItem, exportarRelatorio.tipo)
          setMostrarAlertaRelatorio(true)
          setExportarRelatorio({})
        }
        handleExportarLinhas()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportarRelatorio])

  useEffect(() => {
    if (!mostrarEmDispositivos) return
    setIsLoadingGlobal(true)

    const solicitation = solicitations.find(
      (s) => s.CodigoSolicitacao === solicitationItem
    )

    if (SUCCESSFUL_STATUS.includes(solicitation?.Status))
      handleMostrarEmDispositivos()
    else {
      openErroMostrarEmDispositivos()
      setMostrarAcao('')
    }
    setIsLoadingGlobal(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mostrarEmDispositivos])

  return (
    <div>
      <BarraDadosCliente />
      {revelarBarraFiltro && <BarraFiltragem />}
      <S.tabelaSolicitacoesWrapper>
        <PaginationProvider pagination={Paginador(opcoesPaginacao)}>
          {({ paginationProps, paginationTableProps }) => {
            return (
              <>
                {showCaption && (
                  <BarraTabelaLegenda
                    setShowCaption={setShowCaption}
                    filtros={revelarBarraFiltro}
                    totalItens={totalItens}
                    getTotalItensNaPagina={getTotalItensNaPagina}
                  />
                )}
                <BootstrapTable
                  classes={'tabela'}
                  condensed={true}
                  keyField={TableprimaryKey}
                  data={solicitations}
                  columns={columns}
                  selectRow={selecaoLinhas}
                  expandRow={itemExpandido}
                  bootstrap4={true}
                  bordered={false}
                  noDataIndication={'Nenhum item encontrado'}
                  {...paginationTableProps}
                />

                <PaginacaoTabela
                  setPaginaAtual={trocarPagina}
                  totalPaginas={totalPaginas}
                  totalItens={totalItens}
                  valorLimite={valorLimite}
                  paginaAtual={paginaAtual}
                  page={paginationProps.page}
                  onPageChange={paginationProps.onPageChange}
                />
              </>
            )
          }}
        </PaginationProvider>
        <div className="itens-exibidos-tabela fonte-cor-1">
          Mostrando
          <span className="label-tabela-itens-exibidos">{contador + 1}</span>-
          <span className="label-tabela-itens-exibidos">
            {getLimiteNaPagina()}
          </span>
          de
          <span className="label-tabela-itens-exibidos">{totalItens}</span>
        </div>
      </S.tabelaSolicitacoesWrapper>
      {mostrarAlertaRelatorio && (
        <div id="mensagem-relatorio" className="componente-modal-overlay">
          <div className="componente-modal">
            <MensagemInformacao
              handleMensagemInformacao={() => setMostrarAlertaRelatorio(false)}
              mensagemTitulo="O arquivo está sendo gerado"
              mensagemParagrafo1="Esse processo levará alguns minutos, você poderá fazer o download do seu arquivo na tela de Relatórios assim que o link do mesmo estiver disponível"
              mensagemBotao={'Voltar'}
              marginTop={40}
            />
          </div>
        </div>
      )}
      {openedErroMostrarEmDispositivos && (
        <Modal
          width="444px"
          type="alerta"
          opened={openedErroMostrarEmDispositivos}
        >
          <S.modalMensagem>
            <div className="gap-row">
              <h6>Não é possível exibir em dispositivos</h6>
              <p>A solicitação ainda não foi concluída.</p>
            </div>
            <div>
              <Group position="left">
                <Botao
                  variant="primary"
                  label="Fechar"
                  onClick={() => closeErroMostrarEmDispositivos()}
                />
              </Group>
            </div>
          </S.modalMensagem>
        </Modal>
      )}

      <Modal
        width={'444px'}
        titulo="Relatório por período"
        type="default"
        opened={opened}
        close={close}
        onConfirm={onConfirm}
        disableConfirm={!periodo}
      >
        <Select
          label="Período"
          width={'220px'}
          options={getOptions()}
          placeholder="Selecione"
          value={periodo}
          onChange={(e) => setPeriodo(e)}
        />
      </Modal>
    </div>
  )
}
