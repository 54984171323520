import { Group } from '@mantine/core'
import React from 'react'

import fechar from '../../../icones/iconesVersaoEscura/fechar_janela.svg'
import ellipseVerde from '../../../icones/iconesVersaoVerde/ellipse.svg'
import { limparFiltr } from '../../../utils/alternanciaIcones'
import Botao from '../Botao'

import * as S from './styles'

export default function Modal({
  titulo,
  positionTitle = 'left',
  type = 'default',
  children,
  size = 'auto',
  width,
  minHeight,
  opened,
  close,
  onConfirm,
  handleCleanFilters,
  disableConfirm = false,
  withCloseButton = true,
  showActionsModal = true
}) {
  const isFiltro = type === 'filtro'
  const isAlerta = type === 'alerta'
  const isDefault = type === 'default'
  const mostrarCloseButton = withCloseButton && !isFiltro && !isAlerta

  return (
    <S.ModalMantine
      type={type}
      width={width}
      minHeight={minHeight}
      size={size}
      closeOnClickOutside={!isAlerta}
      opened={opened}
      onClose={close}
      withCloseButton={mostrarCloseButton}
      closeButtonProps={{
        children: (
          <img
            className="botao-fechar-janela-icone"
            src={fechar}
            alt="fechar-janela"
          />
        )
      }}
      title={
        <S.TituloWrapper type={type}>
          {titulo && (
            <>
              {positionTitle === 'center' && (
                <S.TituloCenter>
                  <S.TituloModal>{titulo}</S.TituloModal>
                </S.TituloCenter>
              )}
              {positionTitle === 'left' && (
                <S.Titulo>
                  <S.Elipse src={ellipseVerde} alt="elipse" />
                  <S.TituloModal>{titulo}</S.TituloModal>
                </S.Titulo>
              )}
            </>
          )}

          {isFiltro ? (
            <S.BotoesFiltroWrapper>
              <Botao
                label={
                  <S.LimparFiltroLabelWrapper onClick={handleCleanFilters}>
                    <span>LIMPAR FILTROS</span>
                    <img src={limparFiltr()} alt="fechar-janela" />
                  </S.LimparFiltroLabelWrapper>
                }
                variant="unstyled"
              />
              <Botao label="Filtrar" variant="outline" onClick={onConfirm} />
              <Botao label="Voltar" variant="primary" onClick={close} />
            </S.BotoesFiltroWrapper>
          ) : null}
        </S.TituloWrapper>
      }
      centered
    >
      {children}

      {showActionsModal && isDefault && (
        <Group position="center">
          <Botao label="Cancelar" variant="outline" onClick={close} />
          <Botao
            label="Confirmar"
            variant="primary"
            disabled={disableConfirm}
            onClick={onConfirm}
          />
        </Group>
      )}
    </S.ModalMantine>
  )
}
