import React from 'react';

import * as S from "./styles";

export default function Skeleton({
  visible,
  height = 30,
}) {
  return (
    <S.Skeleton height={height} visible={visible} />
  );
}
