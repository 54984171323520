/* eslint-disable react-hooks/exhaustive-deps */
//IMPORTAÇÕES
import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Image } from 'react-bootstrap'
import { setaDropdown } from '../utils/alternanciaIcones'
import { validadorPermissaoElemento } from '../utils/verificadorPermissaoElemento'
import SpinerVeye from './spinnerVeye/spinnerVeye.js'
// COLETA CLICKSTREAM
import ColetaClickstream from "./clickstreamAnalise/clickstreamColeta.js"
//IMPORTAÇÕES REDUX
import { useDispatch, useSelector } from 'react-redux'
import {
  conciliacaoEmLote,
  confimarRecebimentoVirtueyes,
  exportarLinhas,
  exportarLinhasDesbloqueioCiclo,
  exportarLinhasOperadora,
  exportarOperadora,
  limparFiltroPorArquivoFatSolicitacao,
  novaSolicitacaoVirtueyes,
  reenviarEmailVirtueyes,
  revelarAbrirTelaConciliacao,
  revelarAdicionarLogin,
  revelarAdicionarPacoteExtra,
  revelarAlteracaoprazodepermanenciaFaturas,
  revelarAlterarGrupo,
  revelarAlterarICCIDFaturas,
  revelarAlterarPlanoValor,
  revelarAlterarPrazoPermanencia,
  revelarAlterarciclodevidaFaturas,
  revelarAlterarcontaFaturas,
  revelarAlterarplanoFaturas,
  revelarAssociarFatura,
  revelarAtivarDemonstracao,
  revelarAtivarLinha,
  revelarAtivarLinhasAWS,
  revelarAtribuirLinhas,
  revelarAtualizarConciliacao,
  revelarAtualizarSms,
  revelarBloquearSms,
  revelarBloquearTemporariamento,
  revelarBloquearUso,
  revelarCadastrarFatura,
  revelarCadastrarUsuario,
  revelarCadastroCliente,
  revelarCadastroClienteCarregar,
  revelarCadastroClienteEditar,
  revelarCadastroClienteExcluir,
  revelarCadastroClienteNovo,
  revelarCadastroClienteVirtueyes,
  revelarCancelamento,
  revelarCancelamentoDeConta,
  revelarCancelamentoReposicao,
  revelarCancelarPedido,
  revelarConcluirPedido,
  revelarConfigurarGrupo,
  revelarConsultarSimcard,
  revelarContestarFatura,
  revelarDeletarFatura,
  revelarDeletarPedidoImportado,
  revelarDesabilitarRegra,
  revelarDesativarDemonstracao,
  revelarDesativarSMSFaturas,
  revelarDesatribuirLinhas,
  revelarDesbloquearUso,
  revelarDesbloqueioCiclo,
  revelarDesbloqueioPermanente,
  revelarDispositivosAlterarCicloDeVida,
  revelarDispositivosAlterarConta,
  revelarDispositivosAlterarIccid,
  revelarDispositivosAlterarPlano,
  revelarDispositivosAlterarServicos,
  revelarDoppelgangerCliente,
  revelarDownloadBoleto,
  revelarDownloadRelatorio,
  revelarEdicaoUsuario,
  revelarEditarCicloVida,
  revelarEditarCliente,
  revelarEditarConta,
  revelarEditarElemento,
  revelarEditarEmpresa,
  revelarEditarFatPedidos,
  revelarEditarFatura,
  revelarEditarFornecedor,
  revelarEditarMenu,
  revelarEditarModulo,
  revelarEditarOperadora,
  revelarEditarPerfil,
  revelarEditarPlano,
  revelarEditarRegra,
  revelarEditarServico,
  revelarEditarSistema,
  revelarEditarSolicitacao,
  revelarEditarTela,
  revelarEditarTemplateSms,
  revelarEditarUsuario,
  revelarEnviarSms,
  revelarEnviarSmsModulo,
  revelarExcluirCicloVida,
  revelarExcluirCliente,
  revelarExcluirConta,
  revelarExcluirElemento,
  revelarExcluirEmpresa,
  revelarExcluirFatPedidos,
  revelarExcluirFatura,
  revelarExcluirFebraban,
  revelarExcluirFornecedor,
  revelarExcluirMenu,
  revelarExcluirModulo,
  revelarExcluirOperadora,
  revelarExcluirPerfil,
  revelarExcluirPlano,
  revelarExcluirRegra,
  revelarExcluirServico,
  revelarExcluirSistema,
  revelarExcluirSolicitacao,
  revelarExcluirTela,
  revelarExcluirTemplateSms,
  revelarExcluirUsuario,
  revelarExcluirUsuarioCliente,
  revelarExportarDetalhamento,
  revelarExportarDetalhamentoFinanceiro,
  revelarFalhaNaOperadora,
  revelarFiltrarSolicitacaoPorArquivo,
  revelarGerarRelatorio,
  revelarGerarRelatorios,
  revelarHabilitarRegra,
  revelarHistoricoAcessos,
  revelarImportarClientesUsuarios,
  revelarImportarDescricao,
  revelarImportarFebrabans,
  revelarIotTelecom,
  revelarLiberarSMSFaturas,
  revelarLiberarSms,
  revelarLogReset,
  revelarMapa,
  revelarMostrarEmDispositivos,
  revelarMostrarEmDispositivosSolicitacao,
  revelarNovaConta,
  revelarNovaEmpresa,
  revelarNovaMensagem,
  revelarNovaOperadora,
  revelarNovaRegra,
  revelarNovoCicloVida,
  revelarNovoCliente,
  revelarNovoElemento,
  revelarNovoFatPedidos,
  revelarNovoFornecedor,
  revelarNovoMenu,
  revelarNovoModulo,
  revelarNovoPedidoImportado,
  revelarNovoPedidoSMS,
  revelarNovoPedidoSimcard,
  revelarNovoPedidoSmsVirtueyes,
  revelarNovoPerfil,
  revelarNovoPlano,
  revelarNovoRelease,
  revelarNovoServico,
  revelarNovoSistema,
  revelarNovoTela,
  revelarNovoTemplateSms,
  revelarNovoUsuario,
  revelarNovoUsuarioCliente,
  revelarProcessarPedido,
  revelarRealocacao,
  revelarReativarLinha,
  revelarRecalcularFatura,
  revelarRecalcularFaturaConta,
  revelarRecalcularFaturaEmpresa,
  revelarRecalcularFaturaReferencia,
  revelarReenviarLinkPrimeiroAcessoUsuarioCliente,
  revelarReenviarSolicitacaoFaturas,
  revelarRelatorioConciliacao,
  revelarRelatorioConsumo,
  revelarRelatorioDisponibilidade,
  revelarRelatorioEnvioSms,
  revelarRelatorioFatura,
  revelarRelatorioFaturaDispositivos,
  revelarRelatorioFebraban,
  revelarRelatorioLinhasAfetadas,
  revelarRelatorioNotaFiscalGeral,
  revelarRelatorioPreviewFatura,
  revelarRelatorioResumoFebraban,
  revelarRemoverBloqueioTemporario,
  revelarRenegociarContrato,
  revelarReset,
  revelarResetFaturas,
  revelarSolicitacaBloquearVirtueyes,
  revelarSolicitacaEnviarAvisoVirtueyes,
  revelarSolicitacaExportarVirtueyes,
  revelarSolicitacaRemoverBloqueioVirtueyes,
  revelarSuporteTecnico,
  revelarSuspenderLinha,
  revelarTesteConexao,
  revelarTransferirLinhaClienteFinal,
  revelarTrocaDeTitularidade,
  revelarVerConversasSms,
  solicitacaCancelarVirtueyes,
  solicitacaConcluirVirtueyes,
  solicitacaReenviarProtocoloVirtueyes,
  solicitacaReenviarTrocaOcorrenciaVirtueyes
} from '../redux/actions/index'
import { useGlobal } from '../stores/useGlobal.js'

const relatoriosZustand = [
  'exportarLinhasSimcardsEmBranco',
  'exportarSolicitacaoSimcardsEmBranco',
  'exportarDetalhamentoPortadora'
]

const acoesZustand = [
  'revelarNovoPedidoSimcardsEmBranco',
  'revelarMostrarEmDispositivosSimcardsEmBranco',
  'revelarMostrarEmDispositivosSolicitacoesSimcardsEmBranco',
  'revelarMostrarProvisionamentoSimcardsEmBranco',
  'revelarMostrarNoMapaPortadora',
  'revelarMapaDeCalorPortadora',
  'revelarMostrarEmPortadoras'
]

export default function DropAcoes(props) {
  const { setExportarRelatorio, setMostrarAcao } = useGlobal()

  const { mainAcoes, subAcoes } = props
  //HOOKS VARIÁVEIS
  const [renderizar, setRenderizar] = useState(false)

  //HOOKS MODAL
  const [acoes, setAcoes] = useState([])
  const modal = useRef()
  //HOOKS REDUX
  const despacho = useDispatch()
  const manejador = useSelector((state) => state.manejadorState.manejador)
  const reduxModoSelecaoSimcard = useSelector(
    (state) => state.manejadorState.modoSelecaoSimcard
  )
  const desabilitarAcoes = useSelector(
    (state) => state.manejadorState.desabilitarAcoes
  )
  const tempdesabilitarAcoesCliente = useSelector(
    (state) => state.virtueyesState.desabilitarAcoesCliente
  )
  const esconderMenuAcoesDispositivosBloqueio = useSelector(
    (state) => state.manejadorState.esconderAcoesDispositivosClientes
  )
  let limparFiltroPorArquivo = useSelector(
    (state) => state.faturasState.limparFiltroPorArquivoFatSolicitacao
  )

  const permissaoElemento = useSelector(
    (state) => state.permissaoState.permissaoElemento
  )

  const handleClickFora = (e) => {
    ColetaClickstream(209) // 'dropAcoesHandleClickFora'
    const botaoAcoesOuRelatorios = document.querySelector(
      'form.drop-acoes-container'
    ) // para funcionar o clicar fora quando "relatórios" está aberto e clica no botão "ações"
    if (
      !modal.current.contains(e.target) ||
      (props.titulo === 'Relatórios' && e.target == botaoAcoesOuRelatorios)
    ) {
      props.toggleRevelarDropAcoes(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickFora)
    return () => document.removeEventListener('mousedown', handleClickFora)
  }, [])

  useEffect(() => {
    renderAcoes()
  }, [renderizar, mainAcoes])

  async function renderSubAcoes(cd_pav_acao) {
    return Promise.all(
      subAcoes
        .filter((subMenu) => subMenu.cd_pav_acao_superior == cd_pav_acao)
        .map(async (subMenu, i) => {
          if (subMenu.fl_selecionado == 1) {
            if (!desabilitarAcoes) {
              if (subMenu.ds_rota_permissao) {
                if (
                  await validadorPermissaoElemento(
                    permissaoElemento,
                    subMenu.ds_rota_permissao
                  )
                ) {
                  return (
                    <li key={'subMenu-' + subMenu.cd_pav_acao}>
                      <div
                        className="drop-acoes-submenu item-drop-acoes-hover"
                        onClick={() => handleDespacho(subMenu.ds_acao_redux)}
                      >
                        <span className="drop-acoes-submenu-label fonte-cor-1">
                          {subMenu.ds_acao}
                        </span>
                      </div>
                      {subAcoes[i + 1] != undefined && (
                        <div className="drop-acoes-divisora campo-select-divisor-cor-1"></div>
                      )}
                    </li>
                  )
                }
              } else {
                return (
                  <li key={'subMenu-' + subMenu.cd_pav_acao}>
                    <div
                      className="drop-acoes-submenu item-drop-acoes-hover"
                      onClick={() => handleDespacho(subMenu.ds_acao_redux)}
                    >
                      <span className="drop-acoes-submenu-label fonte-cor-1">
                        {subMenu.ds_acao}
                      </span>
                    </div>
                    {subAcoes[i + 1] != undefined && (
                      <div className="drop-acoes-divisora campo-select-divisor-cor-1"></div>
                    )}
                  </li>
                )
              }
            }
          } else {
            if (subMenu.ds_rota_permissao) {
              if (
                await validadorPermissaoElemento(
                  permissaoElemento,
                  subMenu.ds_rota_permissao
                )
              ) {
                return (
                  <li key={'subMenu-' + subMenu.cd_pav_acao}>
                    <div
                      className="drop-acoes-submenu item-drop-acoes-hover"
                      onClick={() => handleDespacho(subMenu.ds_acao_redux)}
                    >
                      <span className="drop-acoes-submenu-label fonte-cor-1">
                        {subMenu.ds_acao}
                      </span>
                    </div>
                    {subAcoes[i + 1] != undefined && (
                      <div className="drop-acoes-divisora campo-select-divisor-cor-1"></div>
                    )}
                  </li>
                )
              }
            } else {
              return (
                <li key={'subMenu-' + subMenu.cd_pav_acao}>
                  <div
                    className="drop-acoes-submenu item-drop-acoes-hover"
                    onClick={() => handleDespacho(subMenu.ds_acao_redux)}
                  >
                    <span className="drop-acoes-submenu-label fonte-cor-1">
                      {subMenu.ds_acao}
                    </span>
                  </div>
                  {subAcoes[i + 1] != undefined && (
                    <div className="drop-acoes-divisora campo-select-divisor-cor-1"></div>
                  )}
                </li>
              )
            }
          }
        })
    )
  }

  async function renderAcoes() {
    if (
      esconderMenuAcoesDispositivosBloqueio === true &&
      manejador == 'dspDispositivos'
    ) {
      let objData = await Promise.all(
        mainAcoes
          .filter(
            (menu) =>
              (menu.cd_pav_acao_superior !== null &&
                desabilitarAcoes &&
                menu.cd_pav_acao == 6) ||
              (!desabilitarAcoes && menu.cd_pav_acao == 6)
          )
          .map(async (menu, i) => {
            const listKey = 'menu-' + menu.cd_pav_acao

            if (menu.ds_rota_permissao) {
              if (
                await validadorPermissaoElemento(
                  permissaoElemento,
                  menu.ds_rota_permissao
                )
              ) {
                if (menu.fl_filho == false) {
                  return (
                    // nível 1
                    <li key={listKey}>
                      <div
                        className="drop-acoes-menu item-drop-acoes-hover"
                        onClick={() => {
                          handleDespacho(menu.ds_acao_redux)
                        }}
                      >
                        <span className="drop-acoes-menu-label fonte-cor-1">
                          {menu.ds_acao}
                        </span>
                      </div>
                      {mainAcoes[i + 1] != undefined && (
                        <div className="drop-acoes-divisora campo-select-divisor-cor-1"></div>
                      )}
                    </li>
                  )
                } else {
                  return (
                    // nível 2 (sub-item)
                    <li key={listKey}>
                      <div
                        className="drop-acoes-menu item-drop-acoes-hover"
                        onClick={(e) =>
                          dropAcoes(
                            e,
                            listKey,
                            'imagem-menu-' + menu.cd_pav_acao
                          )
                        }
                      >
                        <span className="drop-acoes-menu-label fonte-cor-1">
                          {menu.ds_acao}
                        </span>
                        <img
                          id={'imagem-menu-' + menu.cd_pav_acao}
                          className="drop-acoes-menu-icone-desativado nao-selecionavel"
                          src={setaDropdown()}
                          alt="drop"
                        />
                      </div>
                      {mainAcoes[i + 1] != undefined && (
                        <div className="drop-acoes-divisora campo-select-divisor-cor-1"></div>
                      )}
                      <ul
                        id={listKey}
                        className="drop-acoes-lista-submenu dropdown-desativado"
                      >
                        {await renderSubAcoes(menu.cd_pav_acao)}
                      </ul>
                    </li>
                  )
                }
              }
            } else {
              if (menu.fl_filho == false) {
                return (
                  <li key={listKey}>
                    <div
                      className="drop-acoes-menu item-drop-acoes-hover"
                      onClick={() => {
                        handleDespacho(menu.ds_acao_redux)
                      }}
                    >
                      <span className="drop-acoes-menu-label fonte-cor-1">
                        {menu.ds_acao}
                      </span>
                    </div>
                    {mainAcoes[i + 1] != undefined && (
                      <div className="drop-acoes-divisora campo-select-divisor-cor-1"></div>
                    )}
                  </li>
                )
              } else {
                return (
                  <li key={listKey}>
                    <div
                      className="drop-acoes-menu item-drop-acoes-hover"
                      onClick={(e) =>
                        dropAcoes(e, listKey, 'imagem-menu-' + menu.cd_pav_acao)
                      }
                    >
                      <span className="drop-acoes-menu-label fonte-cor-1">
                        {menu.ds_acao}
                      </span>
                      <img
                        id={'imagem-menu-' + menu.cd_pav_acao}
                        className="drop-acoes-menu-icone-desativado nao-selecionavel"
                        src={setaDropdown()}
                        alt="drop"
                      />
                    </div>
                    {mainAcoes[i + 1] != undefined && (
                      <div className="drop-acoes-divisora campo-select-divisor-cor-1"></div>
                    )}
                    <ul
                      id={listKey}
                      className="drop-acoes-lista-submenu dropdown-desativado"
                    >
                      {await renderSubAcoes(menu.cd_pav_acao)}
                    </ul>
                  </li>
                )
              }
            }
          })
      )
      setAcoes(objData)
    } else {
      // na maioria das vezes entra aqui
      if (!mainAcoes) return

      const acoesFiltradas = mainAcoes.filter(
        (menu) =>
          menu.cd_pav_acao_superior !== null &&
          (!desabilitarAcoes ||
            menu.fl_selecionado == 1 ||
            reduxModoSelecaoSimcard)
      )

      let objData = await Promise.all(
        acoesFiltradas.map(async (menu, i) => {
          const listKey = 'menu-' + (menu.cd_pav_acao || 'index' + i)

          if (menu.ds_rota_permissao) {
            if (
              await validadorPermissaoElemento(
                permissaoElemento,
                menu.ds_rota_permissao
              )
            ) {
              if (menu.fl_filho == false) {
                return (
                  <li key={listKey}>
                    <div
                      className="drop-acoes-menu item-drop-acoes-hover"
                      onClick={() => {
                        handleDespacho(menu.ds_acao_redux)
                      }}
                    >
                      <span className="drop-acoes-menu-label fonte-cor-1">
                        {menu.ds_acao}
                      </span>
                    </div>
                    {mainAcoes[i + 1] != undefined && (
                      <div className="drop-acoes-divisora campo-select-divisor-cor-1"></div>
                    )}
                  </li>
                )
              } else {
                return (
                  <li key={listKey}>
                    <div
                      className="drop-acoes-menu item-drop-acoes-hover"
                      onClick={(e) =>
                        dropAcoes(e, listKey, 'imagem-menu-' + menu.cd_pav_acao)
                      }
                    >
                      <span className="drop-acoes-menu-label fonte-cor-1">
                        {menu.ds_acao}
                      </span>
                      <img
                        id={'imagem-menu-' + menu.cd_pav_acao}
                        className="drop-acoes-menu-icone-desativado nao-selecionavel"
                        src={setaDropdown()}
                        alt="drop"
                      />
                    </div>
                    {mainAcoes[i + 1] != undefined && (
                      <div className="drop-acoes-divisora campo-select-divisor-cor-1"></div>
                    )}
                    <ul
                      id={listKey}
                      className="drop-acoes-lista-submenu dropdown-desativado"
                    >
                      {await renderSubAcoes(menu.cd_pav_acao)}
                    </ul>
                  </li>
                )
              }
            }
          } else {
            if (menu.fl_filho == false) {
              const acaoImportarClientesUsuarios = menu.cd_pav_acao === 269
              // gambiarra porque fizeram 1 tela poder se comportar como 2
              if (
                acaoImportarClientesUsuarios &&
                retirarAcaoDaTela('/veye/cadastro/cliente')
              ) {
                return null
              }

              return (
                <li key={listKey}>
                  <div
                    className="drop-acoes-menu item-drop-acoes-hover"
                    onClick={() => {
                      handleDespacho(menu.ds_acao_redux)
                    }}
                  >
                    <span className="drop-acoes-menu-label fonte-cor-1">
                      {menu.ds_acao}
                    </span>
                  </div>
                  {mainAcoes[i + 1] != undefined && (
                    <div className="drop-acoes-divisora campo-select-divisor-cor-1"></div>
                  )}
                </li>
              )
            } else {
              return (
                <li key={listKey}>
                  <div
                    className="drop-acoes-menu item-drop-acoes-hover"
                    onClick={(e) =>
                      dropAcoes(e, listKey, 'imagem-menu-' + menu.cd_pav_acao)
                    }
                  >
                    <span className="drop-acoes-menu-label fonte-cor-1">
                      {menu.ds_acao}
                    </span>
                    <img
                      id={'imagem-menu-' + menu.cd_pav_acao}
                      className="drop-acoes-menu-icone-desativado nao-selecionavel"
                      src={setaDropdown()}
                      alt="drop"
                    />
                  </div>
                  <div className="drop-acoes-divisora campo-select-divisor-cor-1"></div>
                  <ul
                    id={listKey}
                    className="drop-acoes-lista-submenu dropdown-desativado"
                  >
                    {await renderSubAcoes(menu.cd_pav_acao)}
                  </ul>
                </li>
              )
            }
          }
        })
      )
      setAcoes(objData)
    }
  }

  function retirarAcaoDaTela(telaRetirarAcao) {
    const path = window.location.pathname

    if (path === telaRetirarAcao) {
      return true
    }
  }

  //ESTE É O COMPONENTE QUE RENDERIZA O DROPDOWN DE AÇÕES
  async function handleDespacho(acaoRedux) {
    //  #region  acoes REDUX
    if (acaoRedux === 'revelarAtribuirLinhas') {
      despacho(revelarAtribuirLinhas(true))
      props.toggleRevelarDropAcoes(false)
    }

    if (acaoRedux === 'revelarDesatribuirLinhas') {
      despacho(revelarDesatribuirLinhas(true))
      props.toggleRevelarDropAcoes(false)
    }

    if (acaoRedux === 'revelarAdicionarPacoteExtra') {
      ColetaClickstream(210) // 'dropAcoesRevelarAdicionarPacoteExtra'
      despacho(revelarAdicionarPacoteExtra(true))
      props.toggleRevelarDropAcoes(false)
    }
    /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */

    if (acaoRedux === 'revelarResetFaturas') {
      despacho(revelarResetFaturas(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarAlterarICCIDFaturas') {
      despacho(revelarAlterarICCIDFaturas(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarAlterarcontaFaturas') {
      despacho(revelarAlterarcontaFaturas(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarAlterarciclodevidaFaturas') {
      despacho(revelarAlterarciclodevidaFaturas(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarAlterarplanoFaturas') {
      despacho(revelarAlterarplanoFaturas(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarLiberarSMSFaturas') {
      despacho(revelarLiberarSMSFaturas(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarDesativarSMSFaturas') {
      despacho(revelarDesativarSMSFaturas(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarAlteracaoprazodepermanenciaFaturas') {
      despacho(revelarAlteracaoprazodepermanenciaFaturas(true))
      props.toggleRevelarDropAcoes(false)
    }

    if (acaoRedux === 'revelarReset') {
      ColetaClickstream(211) // 'dropAcoesRevelarReset'
      despacho(revelarReset(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarTesteConexao') {
      ColetaClickstream(1485) // 'dropAcoesRevelarTesteConexao'
      despacho(revelarTesteConexao(true))
      props.toggleRevelarDropAcoes(false)
    }

    if (acaoRedux === 'revelarFalhaNaOperadora') {
      ColetaClickstream(212) // 'dropAcoesRevelarFalhaNaOperadora'
      despacho(revelarFalhaNaOperadora(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarSuporteTecnico') {
      ColetaClickstream(213) // 'dropAcoesRevelarSuporteTecnico'
      despacho(revelarSuporteTecnico(true))
      props.toggleRevelarDropAcoes(false)
    }
    /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */
    if (acaoRedux === 'revelarRealocacao') {
      ColetaClickstream(214) // 'dropAcoesRevelarRealocacao'
      despacho(revelarRealocacao(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarCancelamento') {
      ColetaClickstream(215) // 'dropAcoesRevelarCancelamento'
      despacho(revelarCancelamento(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarCancelamentoReposicao') {
      ColetaClickstream(216) // 'dropAcoesRevelarCancelamentoReposicao'
      despacho(revelarCancelamentoReposicao(true))
      props.toggleRevelarDropAcoes(false)
    }
    /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */
    if (acaoRedux === 'revelarAtivarLinha') {
      ColetaClickstream(217) // 'dropAcoesRevelarAtivarLinha'
      despacho(revelarAtivarLinha(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'exportarLinhasCsvOperadora') {
      ColetaClickstream(236) // 'dropAcoesExportarLinhasCsv'
      despacho(exportarLinhasOperadora('CSV'))
      despacho(exportarOperadora(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'exportarLinhasXlsOperadora') {
      ColetaClickstream(236) // 'dropAcoesExportarLinhasXLS'
      despacho(exportarLinhasOperadora('XLS'))
      despacho(exportarOperadora(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'exportarLinhasXlsxOperadora') {
      ColetaClickstream(236) // 'dropAcoesExportarLinhasXLSX'
      despacho(exportarLinhasOperadora('XLSX'))
      despacho(exportarOperadora(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarReativarLinha') {
      ColetaClickstream(218) // 'dropAcoesRevelarReativarLinha'
      despacho(revelarReativarLinha(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarDesbloquearUso') {
      ColetaClickstream(219) // 'dropAcoesRevelarDesbloquearUso'
      despacho(revelarDesbloquearUso(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarDesbloqueioCiclo') {
      despacho(revelarDesbloqueioCiclo(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarDesbloqueioPermanente') {
      despacho(revelarDesbloqueioPermanente(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarRemoverBloqueioTemporario') {
      ColetaClickstream(220) // 'dropAcoesRevelarBloqueioTemporario'
      despacho(revelarRemoverBloqueioTemporario(true))
      props.toggleRevelarDropAcoes(false)
    }
    /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */
    if (acaoRedux === 'revelarSuspenderLinha') {
      ColetaClickstream(221) // 'dropAcoesRevelarSuspenderLinha'
      despacho(revelarSuspenderLinha(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarBloquearUso') {
      ColetaClickstream(222) // 'dropAcoesRevelarBloquearUso'
      despacho(revelarBloquearUso(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarBloquearTemporariamento') {
      ColetaClickstream(223) // 'dropAcoesRevelarBloquearTemporariamento'
      despacho(revelarBloquearTemporariamento(true))
      props.toggleRevelarDropAcoes(false)
    }
    /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */
    if (acaoRedux === 'revelarAlterarPlanoValor') {
      ColetaClickstream(224) // 'dropAcoesRevelarAlterarPlanoValor'
      despacho(revelarAlterarPlanoValor(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarRenegociarContrato') {
      despacho(revelarRenegociarContrato(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarTrocaDeTitularidade') {
      ColetaClickstream(225) // 'dropAcoesRevelarTrocaDeTitularidade'
      despacho(revelarTrocaDeTitularidade(true))
      props.toggleRevelarDropAcoes(false)
    }
    /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */
    if (acaoRedux === 'revelarAtivarDemonstracao') {
      ColetaClickstream(226) // 'dropAcoesRevelarAtivarDemonstracao'
      despacho(revelarAtivarDemonstracao(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarDesativarDemonstracao') {
      ColetaClickstream(227) // 'dropAcoesRevelarDesativarDemonstracao'
      despacho(revelarDesativarDemonstracao(true))
      props.toggleRevelarDropAcoes(false)
    }
    /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */
    if (acaoRedux === 'revelarCancelamentoDeConta') {
      ColetaClickstream(228) // 'dropAcoesRevelarCancelamentoDeConta'
      despacho(revelarCancelamentoDeConta(true))
      props.toggleRevelarDropAcoes(false)
    }
    /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */
    if (acaoRedux === 'revelarTransferirLinhaClienteFinal') {
      despacho(revelarTransferirLinhaClienteFinal(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarLiberarSms') {
      ColetaClickstream(229) // 'dropAcoesRevelarLiberarSms'
      despacho(revelarLiberarSms(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarBloquearSms') {
      ColetaClickstream(230) // 'dropAcoesRevelarBloquearSms'
      despacho(revelarBloquearSms(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarEnviarSms') {
      ColetaClickstream(231) // 'dropAcoesRevelarEnviarSms'
      despacho(revelarEnviarSms(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarMapa') {
      ColetaClickstream(232) // 'dropAcoesRevelarMapa'
      despacho(revelarMapa(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarAlterarGrupo') {
      ColetaClickstream(233) // 'dropAcoesAlterarGrupo'
      despacho(revelarAlterarGrupo(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarConfigurarGrupo') {
      despacho(revelarConfigurarGrupo(true))
    }
    if (acaoRedux === 'revelarImportarDescricao') {
      ColetaClickstream(235) // 'dropAcoesImportarDescricao'
      despacho(revelarImportarDescricao(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarRelatorioConsumo') {
      ColetaClickstream(1484) // 'dropAcoesRelatorioConsumo'
      despacho(revelarRelatorioConsumo(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarRelatorioDisponibilidade') {
      despacho(revelarRelatorioDisponibilidade(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'exportarLinhasCsv') {
      ColetaClickstream(236) // 'dropAcoesExportarLinhasCsv'
      despacho(exportarLinhas('CSV'))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'exportarLinhasXls') {
      ColetaClickstream(237) // 'dropAcoesExportarLinhasXls'
      despacho(exportarLinhas('XLS'))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'exportarLinhasXlsx') {
      ColetaClickstream(238) // 'dropAcoesExportarLinhasXlsx'
      despacho(exportarLinhas('XLSX'))
      props.toggleRevelarDropAcoes(false)
    }

    /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */
    if (acaoRedux === 'revelarConsultarSimcard') {
      ColetaClickstream(239) // 'dropAcoesRevelarConsultarSimcard'
      despacho(revelarConsultarSimcard(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarHistoricoAcessos') {
      ColetaClickstream(240) // 'dropAcoesRevelarHistoricoAcessos'
      despacho(revelarHistoricoAcessos(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarCadastrarUsuario') {
      ColetaClickstream(241) // 'dropAcoesRevelarCadastrarUsuario'
      despacho(revelarCadastrarUsuario(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarAdicionarLogin') {
      ColetaClickstream(242) // 'dropAcoesRevelarAdicionarLogin'
      despacho(revelarAdicionarLogin(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarDoppelgangerCliente') {
      ColetaClickstream(243) // 'dropAcoesRevelarDoppelgangerCliente'
      despacho(revelarDoppelgangerCliente(true))
      props.toggleRevelarDropAcoes(false)
    }
    /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */
    if (acaoRedux === 'revelarEnviarSmsModulo') {
      ColetaClickstream(244) // 'dropAcoesRevelarEnviarSmsModulo'
      despacho(revelarEnviarSmsModulo(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarVerConversasSms') {
      ColetaClickstream(245) // 'dropAcoesRevelarVerConversasSms'
      despacho(revelarVerConversasSms(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarAtualizarSms') {
      ColetaClickstream(246) // 'dropAcoesRevelarAtualizarSms'
      despacho(revelarAtualizarSms(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarRelatorioEnvioSms') {
      //          ColetaClickstream() // 'dropAcoesRevelarRelatorioEnvioSms'
      despacho(revelarRelatorioEnvioSms(true))
      props.toggleRevelarDropAcoes(false)
    }
    /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */
    if (acaoRedux === 'revelarProcessarPedido') {
      ColetaClickstream(247) // 'dropAcoesRevelarProcessarPedido'
      despacho(revelarProcessarPedido(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarConcluirPedido') {
      ColetaClickstream(248) // 'dropAcoesRevelarConcluirPedido'
      despacho(revelarConcluirPedido(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarCancelarPedido') {
      ColetaClickstream(249) // 'dropAcoesRevelarCancelarPedido'
      despacho(revelarCancelarPedido(true))
    }
    /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */
    if (acaoRedux === 'solicitacaConcluirVirtueyes') {
      ColetaClickstream(250) // 'dropAcoesSolicitacaConcluirVirtueyes'
      despacho(solicitacaConcluirVirtueyes(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'solicitacaCancelarVirtueyes') {
      ColetaClickstream(251) // 'dropAcoesSolicitacaCancelarVirtueyes'
      despacho(solicitacaCancelarVirtueyes(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'solicitacaReenviarProtocoloVirtueyes') {
      ColetaClickstream(252) // 'dropAcoesSolicitacaReenviarProtocoloVirtueyes'
      despacho(solicitacaReenviarProtocoloVirtueyes(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'solicitacaReenviarTrocaOcorrenciaVirtueyes') {
      ColetaClickstream(253) // 'dropAcoesSolicitacaReenviarTrocaOcorrenciaVirtueyes'
      despacho(solicitacaReenviarTrocaOcorrenciaVirtueyes(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarLogReset') {
      ColetaClickstream(254) // 'dropAcoesRevelarLogReset'
      despacho(revelarLogReset(true))
      props.toggleRevelarDropAcoes(false)
    }
    /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */
    if (acaoRedux === 'confimarRecebimentoVirtueyes') {
      ColetaClickstream(255) // 'dropAcoesConfimarRecebimentoVirtueyes'
      despacho(confimarRecebimentoVirtueyes(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'reenviarEmailVirtueyes') {
      ColetaClickstream(256) // 'dropAcoesReenviarEmailVirtueyes'
      despacho(reenviarEmailVirtueyes(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'novaSolicitacaoVirtueyes') {
      ColetaClickstream(257) // 'dropAcoesNovaSolicitacaoVirtueyes'
      despacho(novaSolicitacaoVirtueyes(true))
      props.toggleRevelarDropAcoes(false)
    }
    /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */
    if (acaoRedux === 'revelarNovoTemplateSms') {
      despacho(revelarNovoTemplateSms(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarEditarTemplateSms') {
      despacho(revelarEditarTemplateSms(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarExcluirTemplateSms') {
      despacho(revelarExcluirTemplateSms(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarNovoPedidoSMS') {
      ColetaClickstream(261) // 'dropAcoesRevelarNovoPedidoSMS'
      despacho(revelarNovoPedidoSMS(true))
      props.toggleRevelarDropAcoes(false)
    }
    /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */
    if (acaoRedux === 'revelarNovoPedidoSimcard') {
      despacho(revelarNovoPedidoSimcard(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarMostrarEmDispositivos') {
      ColetaClickstream(263) // 'dropAcoesRevelarMostrarEmDispositivos'
      despacho(revelarMostrarEmDispositivos(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarExportarDetalhamento') {
      ColetaClickstream(264) // 'dropAcoesRevelarExportarDetalhamento'
      despacho(revelarExportarDetalhamento(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarDownloadBoleto') {
      ColetaClickstream(265) // 'dropAcoesRevelarDownloadBoleto'
      despacho(revelarDownloadBoleto(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarExportarDetalhamentoFinanceiro') {
      ColetaClickstream(266) // 'dropAcoesRevelarExportarDetalhamentoFinanceiro'
      despacho(revelarExportarDetalhamentoFinanceiro(true))
      props.toggleRevelarDropAcoes(false)
    }
    /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */
    if (acaoRedux === 'revelarNovaRegra') {
      ColetaClickstream(267) // 'dropAcoesRevelarNovaRegra'
      despacho(revelarNovaRegra(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarEditarRegra') {
      ColetaClickstream(268) // 'dropAcoesRevelarEditarRegra'
      despacho(revelarEditarRegra(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarExcluirRegra') {
      ColetaClickstream(269) // 'dropAcoesRevelarExcluirRegra'
      despacho(revelarExcluirRegra(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarHabilitarRegra') {
      ColetaClickstream(270) // 'dropAcoesRevelarHabilitarRegra'
      despacho(revelarHabilitarRegra(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarDesabilitarRegra') {
      ColetaClickstream(271) // 'dropAcoesRevelarDesabilitarRegra'
      despacho(revelarDesabilitarRegra(true))
      props.toggleRevelarDropAcoes(false)
    }
    /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */
    if (acaoRedux === 'solicitacaBloquearVirtueyes') {
      ColetaClickstream(272) // 'dropAcoesSolicitacaBloquearVirtueyes'
      despacho(revelarSolicitacaBloquearVirtueyes(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'solicitacaRemoverBloqueioVirtueyes') {
      ColetaClickstream(273) // 'dropAcoesSolicitacaRemoverBloqueioVirtueyes'
      despacho(revelarSolicitacaRemoverBloqueioVirtueyes(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'solicitacaEnviarAvisoVirtueyes') {
      ColetaClickstream(274) // 'dropAcoesSolicitacaEnviarAvisoVirtueyes'
      despacho(revelarSolicitacaEnviarAvisoVirtueyes(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarNovaMensagem') {
      ColetaClickstream(275) // 'dropAcoesRevelarNovaMensagem'
      despacho(revelarNovaMensagem(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'solicitacaExportarVirtueyes') {
      ColetaClickstream(276) // 'dropAcoesSolicitacaExportarVirtueyes'
      despacho(revelarSolicitacaExportarVirtueyes(true))
      props.toggleRevelarDropAcoes(false)
    }
    /* */ /* */ /* */ /* */ /* */ /* */ /* */ /* */ /* */ /* */ /* */ /* */
    if (acaoRedux === 'revelarNovoUsuarioCliente') {
      despacho(revelarNovoUsuarioCliente(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarEdicaoUsuario') {
      ColetaClickstream(278) // 'dropAcoesRevelarEdicaoUsuario'
      despacho(revelarEdicaoUsuario(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarExcluirUsuarioCliente') {
      ColetaClickstream(279) // 'dropAcoesRevelarExcluirUsuarioCliente'
      despacho(revelarExcluirUsuarioCliente(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarReenviarLinkPrimeiroAcessoUsuarioCliente') {
      ColetaClickstream(1486) // 'dropAcoesRevelarReenviarLinkPrimeiroAcessoUsuarioCliente'
      despacho(revelarReenviarLinkPrimeiroAcessoUsuarioCliente(true))
      props.toggleRevelarDropAcoes(false)
    }

    /* */ /* */ /* */ /* */ /* */ /* */ /* */ /* */ /* */ /* */ /* */ /* */
    if (acaoRedux === 'revelarNovoUsuario') {
      ColetaClickstream(280) // 'dropAcoesRevelarNovoUsuario'
      despacho(revelarNovoUsuario(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarEditarUsuario') {
      ColetaClickstream(281) // 'dropAcoesRevelarEditarUsuario'
      despacho(revelarEditarUsuario(true))
      props.toggleRevelarDropAcoes(false)
    }
    // if (acaoRedux === "revelarClonarUsuario") {
    //   ColetaClickstream(281); // 'dropAcoesRevelarEditarUsuario'
    //   despacho(revelarClonarUsuario(true));
    //   props.toggleRevelarDropAcoes(false);
    // }
    if (acaoRedux === 'revelarExcluirUsuario') {
      ColetaClickstream(282) // 'dropAcoesRevelarExcluirUsuario'
      despacho(revelarExcluirUsuario(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarNovoCliente') {
      ColetaClickstream(283) // 'dropAcoesRevelarNovoCliente'
      despacho(revelarNovoCliente(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarEditarCliente') {
      ColetaClickstream(284) // 'dropAcoesRevelarEditarCliente'
      despacho(revelarEditarCliente(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarExcluirCliente') {
      ColetaClickstream(285) // 'dropAcoesRevelarExcluirCliente'
      despacho(revelarExcluirCliente(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarNovoElemento') {
      ColetaClickstream(286) // 'dropAcoesRevelarNovoElemento'
      despacho(revelarNovoElemento(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarEditarElemento') {
      ColetaClickstream(287) // 'dropAcoesRevelarEditarElemento'
      despacho(revelarEditarElemento(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarExcluirElemento') {
      despacho(revelarExcluirElemento(true))
      props.toggleRevelarDropAcoes(false)
    }

    if (acaoRedux === 'revelarNovoModulo') {
      ColetaClickstream(289) // 'dropAcoesRevelarNovoModulo'
      despacho(revelarNovoModulo(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarEditarModulo') {
      ColetaClickstream(290) // 'dropAcoesRevelarEditarModulo'
      despacho(revelarEditarModulo(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarExcluirModulo') {
      despacho(revelarExcluirModulo(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarNovoPerfil') {
      ColetaClickstream(292) // 'dropAcoesRevelarNovoPerfil'
      despacho(revelarNovoPerfil(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarEditarPerfil') {
      ColetaClickstream(293) // 'dropAcoesRevelarEditarPerfil'
      despacho(revelarEditarPerfil(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarExcluirPerfil') {
      despacho(revelarExcluirPerfil(true))
      props.toggleRevelarDropAcoes(false)
    }

    if (acaoRedux === 'revelarNovoSistema') {
      ColetaClickstream(295) // 'dropAcoesRevelarNovoSistema'
      despacho(revelarNovoSistema(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarEditarSistema') {
      ColetaClickstream(296) // 'dropAcoesRevelarEditarSistema'
      despacho(revelarEditarSistema(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarExcluirSistema') {
      despacho(revelarExcluirSistema(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarNovoTela') {
      ColetaClickstream(298) // 'dropAcoesRevelarNovoTela'
      despacho(revelarNovoTela(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarEditarTela') {
      ColetaClickstream(299) // 'dropAcoesRevelarEditarTela'
      despacho(revelarEditarTela(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarExcluirTela') {
      despacho(revelarExcluirTela(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarExcluirFatPedidos') {
      despacho(revelarExcluirFatPedidos(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarNovoFatPedidos') {
      ColetaClickstream(302) // 'dropAcoesRevelarNovoFatPedidos'
      despacho(revelarNovoFatPedidos(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarEditarFatPedidos') {
      despacho(revelarEditarFatPedidos(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarAssociarFatura') {
      ColetaClickstream(304) // 'dropAcoesRevelarAssociarFatura'
      despacho(revelarAssociarFatura(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarAlterarPrazoPermanencia') {
      ColetaClickstream(305) // 'dropAcoesRevelarAlterarPrazoPermanencia'
      despacho(revelarAlterarPrazoPermanencia(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarImportarFebrabans') {
      ColetaClickstream(306) // 'dropAcoesRevelarImportarFebrabans'
      despacho(revelarImportarFebrabans(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarNovoPedidoImportado') {
      despacho(revelarNovoPedidoImportado(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarExcluirFebraban') {
      ColetaClickstream(307) // 'dropAcoesRevelarExcluirFebraban'
      despacho(revelarExcluirFebraban(true))
    }
    if (acaoRedux === 'revelarNovaConta') {
      ColetaClickstream(308) // 'dropAcoesRevelarNovaConta'
      despacho(revelarNovaConta(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarEditarConta') {
      ColetaClickstream(309) // 'dropAcoesRevelarEditarConta'
      despacho(revelarEditarConta(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarExcluirConta') {
      ColetaClickstream(310) // 'dropAcoesRevelarExcluirConta'
      despacho(revelarExcluirConta(true))
    }
    if (acaoRedux === 'revelarNovaEmpresa') {
      ColetaClickstream(311) // 'dropAcoesRevelarNovaEmpresa'
      despacho(revelarNovaEmpresa(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarEditarEmpresa') {
      ColetaClickstream(312) // 'dropAcoesRevelarEditarEmpresa'
      despacho(revelarEditarEmpresa(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarExcluirEmpresa') {
      despacho(revelarExcluirEmpresa(true))
    }
    if (acaoRedux === 'revelarNovoPlano') {
      ColetaClickstream(314) // 'dropAcoesRevelarNovoPlano'
      despacho(revelarNovoPlano(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarEditarPlano') {
      ColetaClickstream(315) // 'dropAcoesRevelarEditarPlano'
      despacho(revelarEditarPlano(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarExcluirPlano') {
      ColetaClickstream(316) // 'dropAcoesRevelarExcluirPlano'
      despacho(revelarExcluirPlano(true))
      props.toggleRevelarDropAcoes(false)
    }

    if (acaoRedux === 'revelarNovoCicloVida') {
      ColetaClickstream(317) // 'dropAcoesRevelarNovoCicloVida'
      despacho(revelarNovoCicloVida(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarEditarCicloVida') {
      ColetaClickstream(318) // 'dropAcoesRevelarEditarCicloVida'
      despacho(revelarEditarCicloVida(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarExcluirCicloVida') {
      ColetaClickstream(1408) // 'dropAcoesRevelarExcluirCicloVida'
      despacho(revelarExcluirCicloVida(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'exportarLinhasDesbloqueioCicloXLSX') {
      despacho(exportarLinhasDesbloqueioCiclo('XLSX'))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'exportarLinhasDesbloqueioCicloCSV') {
      despacho(exportarLinhasDesbloqueioCiclo('CSV'))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarNovoFornecedor') {
      ColetaClickstream(320) // 'dropAcoesRevelarNovoFornecedor'
      despacho(revelarNovoFornecedor(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarEditarFornecedor') {
      ColetaClickstream(321) // 'dropAcoesRevelarEditarFornecedor'
      despacho(revelarEditarFornecedor(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarExcluirFornecedor') {
      ColetaClickstream(322) // 'dropAcoesRevelarExcluirFornecedor'
      despacho(revelarExcluirFornecedor(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarNovoServico') {
      ColetaClickstream(323) // 'dropAcoesRevelarNovoServico'
      despacho(revelarNovoServico(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarExcluirEmpresa') {
      despacho(revelarExcluirEmpresa(true))
    }
    if (acaoRedux === 'revelarEditarServico') {
      ColetaClickstream(325) // 'dropAcoesRevelarEditarServico'
      despacho(revelarEditarServico(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarExcluirServico') {
      ColetaClickstream(326) // 'dropAcoesRevelarExcluirServico'
      despacho(revelarExcluirServico(true))
    }
    if (acaoRedux === 'revelarNovaOperadora') {
      ColetaClickstream(327) // 'dropAcoesRevelarNovaOperadora'
      despacho(revelarNovaOperadora(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarEditarOperadora') {
      ColetaClickstream(328) // 'dropAcoesRevelarEditarOperadora'
      despacho(revelarEditarOperadora(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarExcluirOperadora') {
      ColetaClickstream(329) // 'dropAcoesRevelarExcluirOperadora'
      despacho(revelarExcluirOperadora(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarDispositivosAlterarServicos') {
      ColetaClickstream(330) // 'dropAcoesRevelarDispositivosAlterarServicos'
      despacho(revelarDispositivosAlterarServicos(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarDispositivosAlterarPlano') {
      ColetaClickstream(331) // 'dropAcoesRevelarDispositivosAlterarPlano'
      despacho(revelarDispositivosAlterarPlano(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarDispositivosAlterarCicloDeVida') {
      ColetaClickstream(332) // 'dropAcoesRevelarDispositivosAlterarCicloDeVida'
      despacho(revelarDispositivosAlterarCicloDeVida(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarDispositivosAlterarIccid') {
      despacho(revelarDispositivosAlterarIccid(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarDispositivosAlterarConta') {
      ColetaClickstream(334) // 'dropAcoesRevelarDispositivosAlterarConta'
      despacho(revelarDispositivosAlterarConta(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarEditarSolicitacao') {
      ColetaClickstream(335) // 'dropAcoesRevelarEditarSolicitacao'
      despacho(revelarEditarSolicitacao(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarRelatorioLinhasAfetadasCsv') {
      despacho(revelarRelatorioLinhasAfetadas('CSV'))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarRelatorioLinhasAfetadasXls') {
      despacho(revelarRelatorioLinhasAfetadas('XLS'))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarRelatorioLinhasAfetadasXlsx') {
      despacho(revelarRelatorioLinhasAfetadas('XLSX'))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarRelatorioLinhasIgnoradasCsv') {
      despacho(revelarRelatorioFatura('CSV'))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarRelatorioLinhasIgnoradasXls') {
      despacho(revelarRelatorioFatura('XLS'))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarRelatorioLinhasIgnoradasXlsx') {
      despacho(revelarRelatorioFatura('XLSX'))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarExcluirSolicitacao') {
      despacho(revelarExcluirSolicitacao(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarFiltrarSolicitacaoPorArquivo') {
      despacho(revelarFiltrarSolicitacaoPorArquivo(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'limparFiltroPorArquivoFatSolicitacao') {
      despacho(limparFiltroPorArquivoFatSolicitacao(!limparFiltroPorArquivo))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarMostrarEmDispositivosSolicitacao') {
      despacho(revelarMostrarEmDispositivosSolicitacao(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarReenviarSolicitacaoFaturas') {
      ColetaClickstream(1487) // 'dropAcoesRevelar...'
      despacho(revelarReenviarSolicitacaoFaturas(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarAbrirTelaConciliacao') {
      despacho(revelarAbrirTelaConciliacao(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarEditarFatura') {
      despacho(revelarEditarFatura(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarCadastrarFatura') {
      ColetaClickstream(342) // 'dropAcoesRevelarCadastrarFatura'
      despacho(revelarCadastrarFatura(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarDeletarFatura') {
      despacho(revelarDeletarFatura(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarExcluirFatura') {
      despacho(revelarExcluirFatura(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarContestarFatura') {
      despacho(revelarContestarFatura(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarRecalcularFatura') {
      despacho(revelarRecalcularFatura(true))
      props.toggleRevelarDropAcoes(false)
    }
    // RELATORIOS PREVIEW//
    if (acaoRedux === 'revelarRelatorioPreviewFaturaCsv') {
      ColetaClickstream(1446) // FaturasFaturasDropAcoesRelatorioPreviewCSV
      despacho(revelarRelatorioPreviewFatura('CSV'))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarRelatorioPreviewFaturaXls') {
      ColetaClickstream(1447) // FaturasFaturasDropAcoesRelatorioPreviewXLS
      despacho(revelarRelatorioPreviewFatura('XLS'))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarRelatorioPreviewFaturaXlsx') {
      ColetaClickstream(1448) // FaturasFaturasDropAcoesRelatorioPreviewXLSX
      despacho(revelarRelatorioPreviewFatura('XLSX'))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarRelatorioResumoFebrabanCsv') {
      ColetaClickstream(1446) // FaturasFaturasDropAcoesRelatorioPreviewCSV
      despacho(revelarRelatorioResumoFebraban('CSV'))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarRelatorioResumoFebrabanXls') {
      ColetaClickstream(1447) // FaturasFaturasDropAcoesRelatorioPreviewXLS
      despacho(revelarRelatorioResumoFebraban('XLS'))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarRelatorioResumoFebrabanXlsx') {
      ColetaClickstream(1448) // FaturasFaturasDropAcoesRelatorioPreviewXLSX
      despacho(revelarRelatorioResumoFebraban('XLSX'))
      props.toggleRevelarDropAcoes(false)
    }
    // RELATORIOS FATURAS //
    if (acaoRedux === 'revelarRelatorioFaturaCsv') {
      ColetaClickstream(350) // 'dropAcoesRevelarRelatorioFaturaCsv'
      despacho(revelarRelatorioFatura('CSV'))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarRelatorioFaturaXls') {
      ColetaClickstream(351) // 'dropAcoesRevelarRelatorioFaturaXls'
      despacho(revelarRelatorioFatura('XLS'))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarRelatorioFaturaXlsx') {
      ColetaClickstream(352) // 'dropAcoesRevelarRelatorioFaturaXlsx'
      despacho(revelarRelatorioFatura('XLSX'))
      props.toggleRevelarDropAcoes(false)
    }
    // RELATORIOS NOTA FISCAL //
    if (acaoRedux === 'revelarRelatorioNotaFiscalGeralCsv') {
      ColetaClickstream(353) // 'dropAcoesRevelarRelatorioNotaFiscalGeralCsv'
      despacho(revelarRelatorioNotaFiscalGeral('CSV'))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarRelatorioNotaFiscalGeralXls') {
      ColetaClickstream(354) // 'dropAcoesRevelarRelatorioNotaFiscalGeralXls'
      despacho(revelarRelatorioNotaFiscalGeral('XLS'))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarRelatorioNotaFiscalGeralXlsx') {
      ColetaClickstream(355) // 'dropAcoesRevelarRelatorioNotaFiscalGeralXlsx'
      despacho(revelarRelatorioNotaFiscalGeral('XLSX'))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarRelatorioFebrabanCsv') {
      ColetaClickstream(356) // 'dropAcoesRevelarRelatorioFebrabanCsv'
      despacho(revelarRelatorioFebraban('CSV'))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarRelatorioFebrabanXls') {
      ColetaClickstream(357) // 'dropAcoesRevelarRelatorioFebrabanXls'
      despacho(revelarRelatorioFebraban('XLS'))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarRelatorioFebrabanXlsx') {
      ColetaClickstream(358) // 'dropAcoesRevelarRelatorioFebrabanXlsx'
      despacho(revelarRelatorioFebraban('XLSX'))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarRelatorioFaturaDispositivosCsv') {
      ColetaClickstream(359) // 'dropAcoesRevelarRelatorioFaturaDispositivosCsv'
      despacho(revelarRelatorioFaturaDispositivos('CSV'))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarRelatorioFaturaDispositivosXls') {
      ColetaClickstream(360) // 'dropAcoesRevelarRelatorioFaturaDispositivosXls'
      despacho(revelarRelatorioFaturaDispositivos('XLS'))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarRelatorioFaturaDispositivosXlsx') {
      ColetaClickstream(361) // 'dropAcoesRevelarRelatorioFaturaDispositivosXlsx'
      despacho(revelarRelatorioFaturaDispositivos('XLSX'))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarRelatorioConciliacaoCsv') {
      despacho(revelarRelatorioConciliacao('CSV'))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarRelatorioConciliacaoXls') {
      despacho(revelarRelatorioConciliacao('XLS'))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarRelatorioConciliacaoXlsx') {
      despacho(revelarRelatorioConciliacao('XLSX'))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarAtualizarConciliacao') {
      despacho(revelarAtualizarConciliacao(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarNovoPedidoSmsVirtueyes') {
      ColetaClickstream(366) // 'dropAcoesRevelarNovoPedidoSmsVirtueyes'
      despacho(revelarNovoPedidoSmsVirtueyes(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarGerarRelatorios') {
      despacho(revelarGerarRelatorios(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarCadastroCliente') {
      despacho(revelarCadastroCliente(true))
    }
    if (acaoRedux === 'revelarCadastroClienteVirtueyes') {
      ColetaClickstream(368) // 'dropAcoesRevelarCadastroClienteVirtueyes'
      despacho(revelarCadastroClienteVirtueyes(true))
      props.toggleRevelarDropAcoes(false)
    }
    // --------------- PAINEL RELATORIOS --------------- //
    if (acaoRedux === 'revelarDownloadRelatorio') {
      ColetaClickstream(369) // 'dropAcoesRevelarDownloadRelatorio'
      despacho(revelarDownloadRelatorio(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarGerarRelatorio') {
      ColetaClickstream(370) // 'dropAcoesRevelarGerarRelatorio'
      despacho(revelarGerarRelatorio(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarNovoRelease') {
      ColetaClickstream(371) // 'dropAcoesRevelarNovoRelease'
      despacho(revelarNovoRelease(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarNovoMenu') {
      ColetaClickstream(372) // 'dropAcoesRevelarNovoMenu'
      despacho(revelarNovoMenu(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarEditarMenu') {
      ColetaClickstream(373) // 'dropAcoesRevelarEditarMenu'
      despacho(revelarEditarMenu(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarExcluirMenu') {
      despacho(revelarExcluirMenu(true))
      props.toggleRevelarDropAcoes(false)
    }
    /****************** PAINEL CADASTROS *********************/
    if (acaoRedux === 'revelarCadastroClienteExcluir') {
      ColetaClickstream(1431) // dropAcoesRevelarCadastroClienteExcluir
      despacho(revelarCadastroClienteExcluir(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarCadastroClienteEditar') {
      ColetaClickstream(1430) // dropAcoesRevelarCadastroClienteEditar
      despacho(revelarCadastroClienteEditar(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarCadastroClienteNovo') {
      ColetaClickstream(1429) // dropAcoesrevelarCadastroClienteNovo
      despacho(revelarCadastroClienteNovo(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarCadastroClienteCarregar') {
      ColetaClickstream(1432) // dropAcoesRevelarCadastroClienteCarregar
      despacho(revelarCadastroClienteCarregar(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarDeletarPedidoImportado') {
      ColetaClickstream(1432) // revelarDeletarPedidoImportado
      despacho(revelarDeletarPedidoImportado(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarRecalcularFaturaReferencia') {
      despacho(revelarRecalcularFaturaReferencia(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarRecalcularFaturaConta') {
      despacho(revelarRecalcularFaturaConta(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarRecalcularFaturaEmpresa') {
      despacho(revelarRecalcularFaturaEmpresa(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'conciliacaoEmLote') {
      despacho(conciliacaoEmLote(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarIotTelecom') {
      despacho(revelarIotTelecom(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarAtivarLinhasAWS') {
      despacho(revelarAtivarLinhasAWS(true))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarImportarClientesUsuarios') {
      ColetaClickstream(283) // 'dropAcoesRevelarNovoCliente'
      despacho(revelarImportarClientesUsuarios(true))
      props.toggleRevelarDropAcoes(false)
      // ColetaClickstream(235) // 'dropAcoesImportarDescricao'
      // despacho(revelarImportarDescricao(true))
      // props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarExportarLinhasMigracaoPlanoCSV') {
      // ColetaClickstream(?) // TODO: 'dropAcoesExportarLinhasXls ?'
      despacho(exportarLinhas('CSV'))
      props.toggleRevelarDropAcoes(false)
    }
    if (acaoRedux === 'revelarExportarLinhasMigracaoPlanoXLSX') {
      // ColetaClickstream(?) // TODO: 'dropAcoesExportarLinhasXls ?'
      despacho(exportarLinhas('XLSX'))
      props.toggleRevelarDropAcoes(false)
    }

    //#endregion acoes REDUX

    if (relatoriosZustand.some((rel) => acaoRedux?.includes(rel))) {
      const acao =
        relatoriosZustand.find((rel) => acaoRedux.includes(rel)) || ''
      const tipo = acaoRedux.replace(acao, '')

      setExportarRelatorio({
        acao,
        tipo
      })
      props.toggleRevelarDropAcoes(false)
    }

    if (acoesZustand.some((rel) => acaoRedux?.includes(rel))) {
      setMostrarAcao(acaoRedux)
      props.toggleRevelarDropAcoes(false)
    }
  }

  function dropAcoes(event, elementoId, imagemId) {
    ColetaClickstream(1075) // 'dropAcoes'
    let elemento = document.getElementById(elementoId)
    let imagem = document.getElementById(imagemId)

    let imagemClasses = document.querySelectorAll(
      '.drop-acoes-menu-icone-ativado'
    )
    let classes = document.querySelectorAll('.dropdown-ativado')

    if (elemento.classList.contains('dropdown-desativado')) {
      if (classes.length > 0) {
        classes.forEach((item) => item.classList.remove('dropdown-desativado'))
        classes.forEach((item) => item.classList.add('dropdown-ativado'))

        imagemClasses.forEach((item) =>
          item.classList.remove('drop-acoes-menu-icone-desativado')
        )
        imagemClasses.forEach((item) =>
          item.classList.add('drop-acoes-menu-icone-ativado')
        )
      }
      elemento.classList.add('dropdown-ativado')
      elemento.classList.remove('dropdown-desativado')

      imagem.classList.add('drop-acoes-menu-icone-ativado')
      imagem.classList.remove('drop-acoes-menu-icone-desativado')
    }
    // se o filtro estiver ativado e for clicado em cima do mesmo filtro ele fecha
    else if (elemento.classList.contains('dropdown-ativado')) {
      if (classes.length > 0) {
        classes.forEach((item) => item.classList.add('dropdown-desativado'))
        classes.forEach((item) => item.classList.remove('dropdown-ativado'))

        imagemClasses.forEach((item) =>
          item.classList.add('drop-acoes-menu-icone-desativado')
        )
        imagemClasses.forEach((item) =>
          item.classList.remove('drop-acoes-menu-icone-ativado')
        )
      }
      elemento.classList.add('dropdown-desativado')
      elemento.classList.remove('dropdown-ativado')

      imagem.classList.add('drop-acoes-menu-icone-desativado')
      imagem.classList.remove('drop-acoes-menu-icone-ativado')
    }

    if (elemento.classList.contains('dropdown-ativado')) {
      if (classes.length > 0) {
        classes.forEach((item) => item.classList.add('dropdown-desativado'))
        classes.forEach((item) => item.classList.remove('dropdown-ativado'))

        imagemClasses.forEach((item) =>
          item.classList.add('drop-acoes-menu-icone-desativado')
        )
        imagemClasses.forEach((item) =>
          item.classList.remove('drop-acoes-menu-icone-ativado')
        )
      }
      classes.forEach((item) => item.classList.add('dropdown-desativado'))
      classes.forEach((item) => item.classList.remove('dropdown-ativado'))
    }
    setRenderizar(!renderizar)
  }

  return (
    <Form ref={modal} className="drop-acoes-container">
      {props.spinAtivo && (
        <div className="componente-spinner-overlay">
          <div className="componente-spinner-container">
            <SpinerVeye />
          </div>
        </div>
      )}
      <Button
        className="botao-filtros-acoes-cabecalho-sistema fundo-cor-7"
        data-place="bottom"
        onClick={() => {
          props.toggleRevelarDropAcoes(false)
        }}
      >
        <Form.Label
          className={`label-filtros-acoes-cabecalho-sistema ${
            props.titulo === 'Relatórios' ? 'margem-botao-relatorios' : ''
          } fonte-cor-1`}
        >
          {props.titulo}
        </Form.Label>
        <Image className="icone-acoes-cabecalho-sistema" src={setaDropdown()} />
      </Button>

      {!mainAcoes || !tempdesabilitarAcoesCliente || !acoes?.length > 0 ? (
        <>
          <div className="campo-select-triangulo-menor-cor-2"></div>
          <div disabled className="campo-select-corpo fundo-cor-7">
            <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">
              Selecione um item na tabela.
            </span>
          </div>
        </>
      ) : (
        <>
          <div className="campo-select-triangulo-menor-cor-2"></div>
          <div className="drop-acoes-corpo fundo-cor-7">
            <ul className="drop-acoes-lista-menu">{acoes}</ul>
          </div>
        </>
      )}
    </Form>
  )
}
