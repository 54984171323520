import React from 'react'
import Botao from '../../../../Mantine/Botao/index.jsx'
import Group from '../../../../Mantine/Group/index.jsx'
import Modal from '../../../../Mantine/Modal/index.jsx'
import * as S from './styles.js'

export default function AlertaLocalizacao({ onClick, opened }) {
  return (
    <div>
      <Modal
        titulo="Não é possível mostrar em portadoras."
        width={'600px'}
        type="alerta"
        opened={opened}
        positionTitle="center"
      >
        <S.ModalAlert>
          <p className="modal-alert">
            Não foram encontrados dados para a mostrar a linha em portadoras.
          </p>
          <Group position="center">
            <Botao label="Fechar" variant="outline" onClick={onClick} />
          </Group>
        </S.ModalAlert>
      </Modal>
    </div>
  )
}
