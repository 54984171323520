const { exportarXlsx } = require('./exportarArquivo')

/*
    usado em;
    - Dispositivos -> ações -> Ativar linhas na AWS
    - Meus Clientes -> ações -> Importar Clientes e Usuários
*/

export async function montarXlsErros(dados, errorFileName) {
  if (!dados) return

  const colunas = dados[0]

  let auxDadosParaDownloadErros = await Promise.all(
    dados.map((linhaArquivo, index) => {
      if (index === 0) {
        return linhaArquivo
      } else {
        const getItensColuna = () => {
          const resArr = []
          const getItemColuna = (numColuna) => {
            return linhaArquivo?.item && linhaArquivo.item[colunas[numColuna]]
          }

          const limit = colunas.length - 2 // tira as 2 últimas colunas

          for (let i = 0; i < limit; i++) {
            const res = getItemColuna(i)
            resArr.push(res)
          }

          return resArr
        }

        return [
          ...getItensColuna(),
          linhaArquivo?.arrStrErros?.join(', '),
          linhaArquivo?.numLinhaArquivo
        ]
      }
    })
  )

  const observacao = getObservacao(colunas)

  auxDadosParaDownloadErros = [
    ...auxDadosParaDownloadErros,
    [],
    [],
    [[observacao]]
  ]
  exportarXlsx(auxDadosParaDownloadErros, errorFileName + '.xlsx')
}

function getObservacao(colunas) {
  const tam = colunas.length
  const letterColumnError = getUppercaseLetter(tam - 1) // exemplo: getUppercaseLetter(2);  // Returns 'B'
  const letterColumnLine = getUppercaseLetter(tam)
  const colunasStr = letterColumnError + ' e ' + letterColumnLine // exemplo 'D e E';

  return `OBS: o erro pode ser editado neste mesmo arquivo, as colunas ${colunasStr} devem ser excluídas e o arquivo enviado novamente sem essa observação.`
}

function getUppercaseLetter(number) {
  const uppercaseLetter = String.fromCharCode(number + 64)
  return uppercaseLetter
}
