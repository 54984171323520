import React from 'react'

import Tooltip from '../Tooltip'

import * as S from './styles'

export default function ActionIcon({
  icon,
  variant = 'default',
  radius,
  alt,
  onClick,
  tooltip = {}
}) {
  const content = (
    <S.ActionIcon onClick={onClick} variant={variant} radius={radius}>
      <img alt={alt} src={icon} />
    </S.ActionIcon>
  )

  return (
    <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      {tooltip.label ? (
        <Tooltip
          opened
          width={tooltip.width || '150px'}
          withArrow
          transitionProps={{ duration: 200 }}
          label={tooltip.label}
        >
          {content}
        </Tooltip>
      ) : (
        content
      )}
    </div>
  )
}
