import styled from "styled-components";

export const Container = styled.div`
  height: 17em !important;
  width: 100% !important;
  zoom: 125% !important;
`;

export const Tooltip = styled.div`
  padding: 0.625em 0.625em 0.625em 0.625em !important;
  height: 3.5 !important;
  border-radius: 0.3125em !important;
  background-color: #656565e0 !important;
`;

export const TooltipParagraph = styled.div`
  color: #ffffff !important;
  font-weight: 700 !important;
  height: 0.4 !important;
  width: 100% !important;
  font-family: RobotoRegular !important;
  font-size: 0.75em !important;
  line-height: 1.375em !important;
`;
