import styled from 'styled-components'

export const TabelaWrapper = styled.div`
  td {
    font-family: RobotoRegular !important;
    font-size: 16px !important;
    line-height: 1.5em !important;
    color: ${({ theme }) => theme.cor9}!important;
  }
`

export const StatusWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`

export const Botao = styled.div`
  margin-left: 0.9em;
  margin-top: 10px;
`

export const Img = styled.img``

export const TdComImagem = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
`

export const TdIcon = styled.div`
  position: relative;
`
