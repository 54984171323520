import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'

import { filtrosArrayBarra } from '../../../redux/actions/index'
import { PortadorasService } from '../../../services/http/portadoras.service'
import { useGlobal } from '../../../stores/useGlobal'
import {
  formatArrayToString,
  formatStringToNumberArray
} from '../../../utils/genericFormatters'
import Grid from '../../Mantine/Grid'
import Modal from '../../Mantine/Modal'
import MultiSelect from '../../Mantine/MultiSelect'

import * as S from './styles'

// eslint-disable-next-line no-magic-numbers
const WIDTH_COLUMNS = [3, 3, 3, 3]

const DEFAULT_FILTERS_VALUES = {
  operadoras: [],
  tecnologias: [],
  estados: [],
  cidades: []
}

const FILTERS = [
  { label: 'Rede', value: 'operadoras', filterKey: 'operadoras' },
  { label: 'Tecnologia', value: 'tecnologias', filterKey: 'tecnologias' },
  { label: 'Estado', value: 'estados', filterKey: 'estados' },
  { label: 'Cidade', value: 'cidades', filterKey: 'cidades' }
]

const mapCarriers = (carriers) =>
  (carriers || []).map((item) => ({
    value: item.carrierID,
    label: item.carrierName
  }))

const mapTechnologies = (technologies) =>
  (technologies || []).map((item) => ({
    value: item.technologyID,
    label: item.technologyName
  }))

const mapStates = (address) =>
  (address || []).map((item) => ({
    value: item.stateID,
    label: item.stateName
  }))

export default function FiltroPortadoras({ opened, close }) {
  const { filtros, setFiltros, setRevelarBarraFiltro, setIsLoadingGlobal } =
    useGlobal()
  const despacho = useDispatch()

  const [filterOptions, setFilterOptions] = useState({
    operadoras: [],
    tecnologias: [],
    estados: [],
    cidades: []
  })
  const [localFilters, setLocalFilters] = useState(DEFAULT_FILTERS_VALUES)
  const [estado, setEstado] = useState(null)

  const { getCarrierFilter, getTechnologiesFilter, getAddressFilter } =
    PortadorasService

  let arrayPortadorasFilters = []

  const fetchFilters = async () => {
    const [carriers, technologies, address] = await Promise.all([
      getCarrierFilter(),
      getTechnologiesFilter(),
      getAddressFilter()
    ])

    return {
      carriers: mapCarriers(carriers),
      technologies: mapTechnologies(technologies),
      states: mapStates(address)
    }
  }

  useQuery(
    ['portadoras/filter'],
    async () => {
      setIsLoadingGlobal(true)
      try {
        return await fetchFilters()
      } catch (err) {
        console.error(err)
      } finally {
        setIsLoadingGlobal(false)
      }
    },
    {
      onSuccess: (data) => {
        setFilterOptions((prev) => ({
          ...prev,
          operadoras: data.carriers,
          tecnologias: data.technologies,
          estados: data.states
        }))

        if (filtros.CIDADE) refetch()
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false
    }
  )

  const { refetch } = useQuery(
    ['get-city', estado],
    async () => {
      try {
        setIsLoadingGlobal(true)
        const address = await getAddressFilter(estado)

        setFilterOptions((prev) => ({
          ...prev,
          cidades: (address || [])?.map((item) => ({
            value: item.cityID,
            label: item.cityName
          }))
        }))
      } catch (err) {
        console.error(err)
      } finally {
        setIsLoadingGlobal(false)
      }
    },
    {
      enabled: !!estado,
      keepPreviousData: true,
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    if (!filtros.vazio) {
      const auxFilter = {
        ...DEFAULT_FILTERS_VALUES
      }

      if (filtros?.OPERADORA) {
        auxFilter.operadoras = formatStringToNumberArray(filtros.OPERADORA)
      }

      if (filtros?.TECNOLOGIA) {
        auxFilter.tecnologias = formatStringToNumberArray(filtros.TECNOLOGIA)
      }

      if (filtros?.ESTADO) {
        const estados = formatStringToNumberArray(filtros.ESTADO)
        auxFilter.estados = estados
        setEstado(estados)
      }

      if (filtros?.CIDADE) {
        auxFilter.cidades = formatStringToNumberArray(filtros.CIDADE)
      }

      setLocalFilters(auxFilter)
    }
  }, [filtros])

  const handleLocalFiltroChange = (fieldPath, value) => {
    if (value && fieldPath === 'estados') {
      setLocalFilters((prev) => ({
        ...prev,
        cidades: []
      }))
      setEstado(value)
    }

    const [parent, child] = fieldPath.split('.')
    setLocalFilters((prev) => {
      if (!child) {
        return {
          ...prev,
          [parent]: value
        }
      }

      return {
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }
    })
  }

  const formattedFilter = () => {
    const addFilters = (localFilterKey, filterOptionKey, prefix = '') => {
      const filteredItems = filterOptions[filterOptionKey].filter((item) =>
        localFilters[localFilterKey].includes(item.value)
      )
      const formattedItems = filteredItems.map(
        (item) => `${prefix}${item.label?.trim()} _${item.value}`
      )

      arrayPortadorasFilters.push(...formattedItems)
    }

    if (localFilters.operadoras?.length)
      addFilters('operadoras', 'operadoras', 'operadoraOperator: ')
    if (localFilters.tecnologias?.length)
      addFilters('tecnologias', 'tecnologias', 'tecnologiaOperator: ')
    if (localFilters.estados?.length)
      addFilters('estados', 'estados', 'estadoOperator: ')
    if (localFilters.cidades?.length)
      addFilters('cidades', 'cidades', 'cidadeOperator: ')

    let label

    if (filtros.ID_PORTADORA) {
      let idsPortadoras = Array.isArray(filtros.ID_PORTADORA)
        ? filtros.ID_PORTADORA
        : filtros.ID_PORTADORA.split(',')

      const limite = 2
      const totalPortadoras = idsPortadoras.length

      if (totalPortadoras <= limite) {
        label = `idPortadoraOperator: ${idsPortadoras.join(', ')}`
      } else {
        const portadorasExibidas = idsPortadoras.slice(0, limite)
        const restante = totalPortadoras - limite
        label = `idPortadoraOperator: ${portadorasExibidas.join(', ')}... +${restante}`
      }

      arrayPortadorasFilters.push(label)
    }
    if (filtros.GEOLOCATION)
      arrayPortadorasFilters.push('Geolocalização?GEOLOCATION')

    despacho(filtrosArrayBarra(arrayPortadorasFilters))
  }

  const handleFilter = () => {
    const filteredData = {
      OPERADORA: formatArrayToString(localFilters.operadoras),
      CIDADE: formatArrayToString(localFilters.cidades),
      TECNOLOGIA: formatArrayToString(localFilters.tecnologias),
      ESTADO: formatArrayToString(localFilters.estados)
    }
    let finalFiltros = Object.fromEntries(
      Object.entries(filteredData).filter(([, value]) => value)
    )

    finalFiltros = {
      ...finalFiltros,
      ...(filtros.ID_PORTADORA && { ID_PORTADORA: filtros.ID_PORTADORA }),
      ...(filtros.GEOLOCATION && { GEOLOCATION: filtros.GEOLOCATION })
    }

    setFiltros(finalFiltros)
    setRevelarBarraFiltro(true)
    formattedFilter()
    close()
  }

  const handleCleanFilters = () => {
    setLocalFilters(DEFAULT_FILTERS_VALUES)
    setFiltros({ vazio: 'vazio' })
    despacho(filtrosArrayBarra([]))
    setRevelarBarraFiltro(false)
  }

  return (
    <Modal
      width="1000px"
      type="filtro"
      titulo="Filtros"
      opened={opened}
      close={close}
      onConfirm={handleFilter}
      handleCleanFilters={handleCleanFilters}
    >
      <S.Container>
        <S.Card>
          <Grid colSize={WIDTH_COLUMNS}>
            {FILTERS.map(({ label, value, filterKey }) => (
              <MultiSelect
                disabled={value === 'cidades' && !localFilters.estados.length}
                key={value}
                label={label}
                value={localFilters[value]}
                options={filterOptions?.[value]}
                onChange={(selectedValue) =>
                  handleLocalFiltroChange(filterKey, selectedValue)
                }
              />
            ))}
          </Grid>
        </S.Card>
      </S.Container>
    </Modal>
  )
}
