//BREAD CRUMBS

//IMAGEM PADRÃO

//LIXERIA
//EDITAR
//CONFIGURACOES
//ADICIONAR
//ADICIONAR
//PASSAR PAGUINA
//EMAIL
import envelopeFechadoPreto from '../icones/icone-envelope-fechado-preto.svg'
import emailBranca from '../icones/iconesVersaoClara/email_branca.svg'
import emailPreta from '../icones/iconesVersaoEscura/email_preto.svg'
//ALERTA
import alertaAmareloSvg from '../icones/alerta_amarelo.svg'
import alertaVermelhoSvg from '../icones/alerta_vermelho.svg'
import alertaBranca from '../icones/iconesVersaoClara/alerta_branco.svg'
import alertaPreta from '../icones/iconesVersaoEscura/alerta_preto.svg'
//DISPOSITIVO
import dispositivoBranca from '../icones/iconesVersaoClara/dispositivos_clara.svg'
import dispositivoPreta from '../icones/iconesVersaoEscura/dispositivos_preta.svg'
import dispositivoVerde from '../icones/iconesVersaoVerde/dispositivos_verde.svg'
import dispositivoVerdeAlgar from '../icones/iconesVersaoVerde/dispositivos_verde_algar.svg'
//ADMIN
import adminBranca from '../icones/iconesVersaoClara/admin_branca.svg'
import adminPreta from '../icones/iconesVersaoEscura/admin_preto.svg'
import adminVerde from '../icones/iconesVersaoVerde/admin_verde.svg'
import adminVerdeAlgar from '../icones/iconesVersaoVerde/admin_verde_algar.svg'
//LOGO VEYE
import veyeEyeBranca from '../icones/iconesVersaoClara/eye_preto.svg'
import veyeVBranca from '../icones/iconesVersaoClara/icone-logo-veye-v.svg'
import logoVirtueyesBranco from '../icones/iconesVersaoClara/virtueyes_preto.svg'
import veyeEyePreta from '../icones/iconesVersaoEscura/eye_branco.svg'
import veyeVPreta from '../icones/iconesVersaoEscura/icone-logo-veye-v.svg'
import logoVirtueyesPreto from '../icones/iconesVersaoEscura/virtueyes_branco.svg'
//ALGAR
import AlgarBranca from '../icones/iconesVersaoClara/algar_Telecom_logo.svg'
import AlgarBrancaMin from '../icones/iconesVersaoClara/algar_Telecom_logo_min.svg'
import AlgarPreta from '../icones/iconesVersaoEscura/algar_Telecom_logo.svg'
import AlgarPretaMin from '../icones/iconesVersaoEscura/algar_Telecom_logo_min.svg'

//LOGO VEYE TELA INICIAL
import logoTelaInicialEscura from '../icones/iconesVersaoClara/tela_inicial_modo_claro.svg'
import logoTelaInicialClara from '../icones/iconesVersaoEscura/tela_inicial_modo_escuro.svg'
// LOGO TELA INICIAR ALGAR
import logoTelaInicialEscuraAlgar from '../icones/iconesVersaoClara/algar_tela_inicial.svg'
import logoTelaInicialClaraAlgar from '../icones/iconesVersaoEscura/algar_tela_inicial.svg'

//POWERED BY VEYE
import poweredPreto from '../icones/iconesVersaoClara/powered_by_veye.svg'
import poweredBranco from '../icones/iconesVersaoEscura/powered_by_veye.svg'
//TOGGLE MENU
import toggleMenu from '../icones/iconesVersaoEscura/toogle_menu.svg'
import toggleMenuAlgar from '../icones/iconesVersaoEscura/toogle_menu_algar.svg'
import toggleMenuRecolhido from '../icones/iconesVersaoEscura/toogle_menu_recolhido.svg'
//LUPA
import buscaBranca from '../icones/iconesVersaoClara/busca_branca.svg'
import buscaPreta from '../icones/iconesVersaoEscura/busca_preta.svg'
//BOTÃO BUSCAR POR ARQUIVO
import buscaArquivoBranca from '../icones/iconesVersaoClara/busca_arquivo_branca.svg'
import buscaArquivoPreta from '../icones/iconesVersaoEscura/busca_arquivo_preta.svg'
//FECHAR JANELA
import fecharAba from '../icones/iconesVersaoEscura/fechar_janela.svg'
//LIMPAR
import fecharFiltroBarra from '../icones/iconesVersaoEscura/filtro_barra.svg'
//FECHAR FILTRO DE BARRA
import fecharFiltroBarraClara from '../icones/iconesVersaoClara/filtro_barra.svg'
//FECHAR FILTRO DE BARRA
import limparVermelho from '../icones/iconesVersaoVermelha/icone-fechar-vermelho.svg'

//FECHAR FILTRO
import fecharVerde from '../icones/icone-fechar-verde.svg'

//SETA DROPDOWN
// import setaDropdownVerde from '../icones/iconesVersaoVerde/icone-seta-dropdown-verde.svg'
import setaDropdownVerde from '../icones/icone-seta-dropdown-verde.png'
import setaDropdownBranca from '../icones/iconesVersaoClara/icone-seta-dropdown-branco.svg'
import setaDropdownVerdeAlgar from '../icones/iconesVersaoVerde/icone-seta-dropdown-verde-algar.svg'
import setaLadoVerde from '../icones/iconesVersaoVerde/icone-seta-lado-verde.svg'
import setaUpVerde from '../icones/iconesVersaoVerde/icone-up-verde.svg'
// FILTRO VERDE
import filtroVerdeAlgar from '../icones/iconesVersaoVerde/icone-filtro-verde-algar.svg'
import filtroVerde from '../icones/iconesVersaoVerde/icone-filtro-verde.svg'

//ECLIPSE
import ellipseAmarelo from '../icones/ellipseAmarelo.svg'
import ellipseAzul from '../icones/ellipseAzul.svg'
import ellipseCinza from '../icones/ellipseCinza.svg'
import ellipseLaranja from '../icones/ellipseLaranja.svg'
import ellipseRoxo from '../icones/ellipseRoxo.svg'
import ellipseClaro from '../icones/iconesVersaoClara/eclipse_claro.svg'
import ellipsePreto from '../icones/iconesVersaoEscura/eclipse_preto.svg'
import ellipseVerde from '../icones/iconesVersaoVerde/ellipse.svg'
import ellipseVerdeAlgar from '../icones/iconesVersaoVerde/ellipse_algar.svg'
import ellipseVermelho from '../icones/iconesVersaoVermelha/ellipse.svg'
//POWER
import powerVerde from '../icones/iconesVersaoVerde/icone-power.svg'
//OLHO
import disableCircleValidationBranco from '../icones/disable-circle-claro.svg'
import disableCircleValidationPreto from '../icones/disable-circle.svg'
import olhoDeslidadoBranca from '../icones/iconesVersaoClara/icone-olho-desligado.svg'
import olhoLigadoBranca from '../icones/iconesVersaoClara/icone-olho-ligado.svg'
import olhoDesligadoCinza from '../icones/iconesVersaoEscura/icone-olho-desligado-cinza.svg'
import olhoDeslidadoPreta from '../icones/iconesVersaoEscura/icone-olho-desligado.svg'
import olhoLigadoCinza from '../icones/iconesVersaoEscura/icone-olho-ligado-cinza.svg'
import olhoLigadoPreta from '../icones/iconesVersaoEscura/icone-olho-ligado.svg'
import olhoVerdeAlgar from '../icones/iconesVersaoVerde/icone-olho-verde-algar.svg'
import olhoVerde from '../icones/iconesVersaoVerde/icone-olho-verde.svg'
//EXCLAMACAO
import exclamacaoVermelha from '../icones/iconesVersaoVermelha/exclamacao.svg'
//SCROLL
import iconeScroll from '../icones/icone-scroll.svg'
//MENSAGEMS
import iconeAlertaAtencao from '../icones/iconesMensagens/alerta_atencao.svg'
import iconeAlertaErro from '../icones/iconesMensagens/alerta_erro.svg'
import iconeAlertaInformacao from '../icones/iconesMensagens/alerta_informacao.svg'
import iconeAlertaSucesso from '../icones/iconesMensagens/alerta_sucesso.svg'
import iconeAlertaSucessoAlgar from '../icones/iconesMensagens/alerta_sucesso_algar.svg'

// IMPORTAR ARQUIVO
import downloadPretoSvg from '../icones/icone-download-preto.svg'
import downloadBrancoSvg from '../icones/icone-download.svg'
import downloadVerdeSvg from '../icones/misc/download.svg'

import exportarCinza from '../icones/iconesVersaoClara/exportarCinza.svg'
import exportarPreto from '../icones/iconesVersaoClara/exportarPreto.svg'
import exportarBranco from '../icones/iconesVersaoEscura/exportarBranco.svg'
import exportarVerdeAlgar from '../icones/iconesVersaoVerde/exportar-verde-algar.svg'
import exportarVerde from '../icones/iconesVersaoVerde/exportar-verde.svg'
import importarPreto from '../icones/importarBranco.svg'
import importarCinza from '../icones/importarCinza.svg'
import importarBranco from '../icones/importarPreto.svg'
import importarVerdeSvg from '../icones/importarVerde.svg'

// LOGO VEYE
import iconeLogoRedondo from '../icones/logoRedondo.png'

import iconeGoogleBrandsBranco from '../icones/iconesVersaoClara/googleBrands.svg'
import iconeGoogleBrandsEscuro from '../icones/iconesVersaoEscura/googleBrands.svg'

import iconeMapMarkedBranco from '../icones/iconesVersaoClara/map-marked-alt.svg'
import iconeMapMarkedEscuro from '../icones/iconesVersaoEscura/map-marked-alt.svg'
//DETALHAMENTO DE LINHA
import iconeConexoesClaro from '../icones/iconesVersaoClara/icone-detalhamento-conexoes.svg'
import iconeConsumoClaro from '../icones/iconesVersaoClara/icone-detalhamento-consumo.svg'
import iconeDescricaoClaro from '../icones/iconesVersaoClara/icone-detalhamento-descricao.svg'
import iconeLocalizacaoClaro from '../icones/iconesVersaoClara/icone-detalhamento-localizacao.svg'
import iconeConexoesEscuro from '../icones/iconesVersaoEscura/icone-detalhamento-conexoes.svg'
import iconeConsumoEscuro from '../icones/iconesVersaoEscura/icone-detalhamento-consumo.svg'
import iconeDescricaoEscuro from '../icones/iconesVersaoEscura/icone-detalhamento-descricao.svg'
import iconeLocalizacaoEscuro from '../icones/iconesVersaoEscura/icone-detalhamento-localizacao.svg'
import iconeAntenaVerde from '../icones/iconesVersaoVerde/cellTower.svg'
import iconeConexoesVerdeAlgar from '../icones/iconesVersaoVerde/icone-detalhamento-conexoes-algar.svg'
import iconeConexoesVerde from '../icones/iconesVersaoVerde/icone-detalhamento-conexoes.svg'
import iconeConsumoVerdeAlgar from '../icones/iconesVersaoVerde/icone-detalhamento-consumo-algar.svg'
import iconeConsumoVerde from '../icones/iconesVersaoVerde/icone-detalhamento-consumo.svg'
import iconeDescricaoVerdeAlgar from '../icones/iconesVersaoVerde/icone-detalhamento-descricao-algar.svg'
import iconeDescricaoVerde from '../icones/iconesVersaoVerde/icone-detalhamento-descricao.svg'
import iconeLocalizacaoVerdeAlgar from '../icones/iconesVersaoVerde/icone-detalhamento-localizacao-algar.svg'
import iconeLocalizacaoVerde from '../icones/iconesVersaoVerde/icone-detalhamento-localizacao.svg'
//CALENDARIO
import setaPassarCalendarioDireitaClara from '../icones/iconesVersaoClara/passar_calendario_direta.svg'
import setaPassarCalendarioEsquerdaClara from '../icones/iconesVersaoClara/passar_calendario_esquerda.svg'
import setaPassarCalendarioDireitaEscura from '../icones/iconesVersaoEscura/passar_calendario_direta.svg'
import setaPassarCalendarioEsquerdaEscura from '../icones/iconesVersaoEscura/passar_calendario_esquerda.svg'
import calendarioVerde from '../icones/iconesVersaoVerde/calendario.svg'
import calendarioVerdeAlgar from '../icones/iconesVersaoVerde/calendario_algar.svg'
//CHECKBOX
import checkboxClaro from '../icones/iconesVersaoClara/checkbox_vclara.svg'
import checkedClaro from '../icones/iconesVersaoClara/checked_vclara.svg'
import checkboxEscuro from '../icones/iconesVersaoEscura/checkbox.svg'
import checkedEscuro from '../icones/iconesVersaoEscura/checked.svg'
//PERFIL
import perfilClaro from '../icones/iconesVersaoClara/icone-perfil.svg'
import perfilEscuro from '../icones/iconesVersaoEscura/icone-perfil.svg'
import perfilVerde from '../icones/iconesVersaoVerde/icone-perfil.svg'
//VISÃO GERAL
import visaoGeralClaro from '../icones/iconesVersaoClara/icone-visao-geral.svg'
import visaoGeralEscuro from '../icones/iconesVersaoEscura/icone-visao-geral.svg'
import visaoGeralVerde from '../icones/iconesVersaoVerde/icone-visao-geral.svg'
//BARRA DE FILTRAGEM
import divisorFiltragemAlgar from '../icones/iconesVersaoVerde/icone-maior-algar.svg'
import divisorFiltragem from '../icones/iconesVersaoVerde/icone-maior.svg'
//SETA MENU LATERAL
import setaMenuLateralBranca from '../icones/iconesVersaoClara/seta_menu_claro.svg'
import setaMenuLateralEscuro from '../icones/iconesVersaoEscura/seta_menu_escuro.svg'
//CANCELAR CONFIRMAR
import iconeConfirmarDisabled from '../icones/confirmar_desativado.svg'
import iconeConfirmar from '../icones/iconesVersaoVerde/confirma.svg'
import iconeCancelar from '../icones/iconesVersaoVermelha/cancela.svg'
import iconeNaoPermitido from '../icones/iconesVersaoVermelha/nao-permitido.png'
//MENU DE INFOMAÇOES DE MAPAS DA ANATEL
import menuInformacaoBranco from '../icones/informacoesBranco.svg'
import menuInformacaoPreto from '../icones/informacoesPreto.svg'
//RETICENCIAS
import reticenciaVerde from '../icones/iconesVersaoVerde/opcoes.svg'
import reticenciaVerdeAlgar from '../icones/iconesVersaoVerde/opcoes_algar.svg'
//MENU FINANCEIRO
import menuFinanceiroBranco from '../icones/iconesVersaoClara/financeiro.svg'
import menuFinanceiroPreto from '../icones/iconesVersaoEscura/financeiro.svg'
import menuFinanceiroVerde from '../icones/iconesVersaoVerde/financeiro_verde.svg'
import menuFinanceiroVerdeAlgar from '../icones/iconesVersaoVerde/financeiro_verde_algar.svg'
// MENU DE PEDIDOS
import menuPedidoBranco from '../icones/iconesVersaoClara/pedidos.svg'
import menuPedidoPreto from '../icones/iconesVersaoEscura/pedidos.svg'
import menuPedidoVerde from '../icones/iconesVersaoVerde/pedidos_verde.svg'
import menuPedidoVerdeAlgar from '../icones/iconesVersaoVerde/pedidos_verde_algar.svg'
// MENU DE SMS
import menuSmsBranco from '../icones/iconesVersaoClara/sms.svg'
import menuSmsPreto from '../icones/iconesVersaoEscura/sms.svg'
import menuSmsVerde from '../icones/iconesVersaoVerde/sms_verde.svg'
import menuSmsVerdeAlgar from '../icones/iconesVersaoVerde/sms_verde_algar.svg'

import filtroArquivoBranco from '../icones/iconesVersaoClara/filtro_arquivo.svg'
import limparFiltros from '../icones/iconesVersaoClara/limpar_filtros.svg'
import filtroArquivoPreto from '../icones/iconesVersaoEscura/filtro_arquivo.svg'

//FINANCEIRO
import financeiroPreta from '../icones/iconesVersaoClara/financeiro.svg'
import financeiroBranca from '../icones/iconesVersaoEscura/financeiro.svg'

//SMS
import smsPreta from '../icones/iconesVersaoClara/sms.svg'
import smsBranca from '../icones/iconesVersaoEscura/sms.svg'

//PEDIDOS
import pedidosPreta from '../icones/iconesVersaoClara/pedidos.svg'
import pedidosBranca from '../icones/iconesVersaoEscura/pedidos.svg'

//CLIENTES
import clientesPreta from '../icones/iconesVersaoClara/clientes.svg'
import clientesBranca from '../icones/iconesVersaoEscura/clientes.svg'

//DASHBOARD
import dashboardPreta from '../icones/iconesVersaoClara/dashboard.svg'
import dashboardBranca from '../icones/iconesVersaoEscura/dashboard.svg'
import dashboardVerde from '../icones/iconesVersaoVerde/dashboard_verde.svg'
import dashboardVerdeAlgar from '../icones/iconesVersaoVerde/dashboard_verde_algar.svg'

//AUTOMACAO
import automacaoPreta from '../icones/iconesVersaoClara/automacao.svg'
import automacaoBranca from '../icones/iconesVersaoEscura/automacao.svg'
import automacaoVerde from '../icones/iconesVersaoVerde/automacao_verde.svg'
import automacaoVerdeAlgar from '../icones/iconesVersaoVerde/automacao_verde_algar.svg'

//MANUTENÇÃO
import manutencaoPreta from '../icones/iconesVersaoClara/manutencao.svg'
import manutencaoBranca from '../icones/iconesVersaoEscura/manutencao_branco.svg'
import manutencaoVerde from '../icones/iconesVersaoVerde/manutencao_verde.svg'
import manutencaoVerdeAlgar from '../icones/iconesVersaoVerde/manutencao_verde_algar.svg'

//RELATORIOS
import relatoriosPreta from '../icones/iconesVersaoClara/relatorios.svg'
import relatoriosBranca from '../icones/iconesVersaoEscura/relatorios.svg'
import relatoriosVerde from '../icones/iconesVersaoVerde/relatorios.svg'
import relatoriosVerdeAlgar from '../icones/iconesVersaoVerde/relatoriosAlgar.svg'

//RELOGIO
import relogioVerde from '../icones/iconesVersaoVerde/tempo_tutorial.svg'
//LUPAS ZOOM
import lupaMaisVerde from '../icones/iconesVersaoVerde/lupa_aumenta.svg'
import lupaMaisCinza from '../icones/iconesVersaoVerde/lupa_aumenta_desativado.svg'
import lupaMenosVerde from '../icones/iconesVersaoVerde/lupa_diminui.svg'
import lupaMenosCinza from '../icones/iconesVersaoVerde/lupa_diminui_desativado.svg'
//CAMERA
import cameraBranca from '../icones/iconesVersaoClara/camera.svg'
import cameraPreta from '../icones/iconesVersaoEscura/camera.svg'
//ICONES CABEÇALHO DESATIVADO
import lupaCinza from '../icones/iconesDesativados/busca_branca.svg'
import limparCinza from '../icones/iconesDesativados/icone-fechar-vermelho.svg'
import filtroCinza from '../icones/iconesDesativados/icone-filtro-verde.svg'
import dropdownCinza from '../icones/iconesDesativados/icone-seta-dropdown-verde.svg'
//usuarios
import usuariosBranco from '../icones/iconesVersaoClara/usuarios.svg'
import usuariosPreto from '../icones/iconesVersaoEscura/usuarios.svg'
import usuariosVerde from '../icones/iconesVersaoVerde/usuarios_verde.svg'
import usuariosVerdeAlgar from '../icones/iconesVersaoVerde/usuarios_verde_algar.svg'
//OUTROS
import iconeOutrosClara from '../icones/iconesVersaoClara/icone-outros.svg'
import iconeOutrosEscura from '../icones/iconesVersaoEscura/icone-outros.svg'
import iconeOutrosVerdeAlgar from '../icones/iconesVersaoVerde/icone-outros-algar.svg'
import iconeOutrosVerde from '../icones/iconesVersaoVerde/icone-outros.svg'
// faturas
import iconeFaturasBranco from '../icones/iconesVersaoClara/Icone-fatura-branco.svg'
import iconeFaturasPreto from '../icones/iconesVersaoEscura/Icone-fatura-preto.svg'
import iconeFaturasVerdeAlgar from '../icones/iconesVersaoVerde/Icone-fatura-verde-algar.svg'
import iconeFaturasVerde from '../icones/iconesVersaoVerde/Icone-fatura-verde.svg'

//Cadastros
import iconeCadastroBranco from '../icones/iconesVersaoClara/cadastros.svg'
import iconeCadastroPreto from '../icones/iconesVersaoEscura/cadastros.svg'
import iconeCadastroVerde from '../icones/iconesVersaoVerde/cadastros.svg'
import iconeCadastroVerdeAlgar from '../icones/iconesVersaoVerde/cadastros_algar.svg'

//Solicitações
import iconeSolicitacoesBranco from '../icones/iconesVersaoClara/solicitacoes.svg'
import iconeSolicitacoesPreto from '../icones/iconesVersaoEscura/solicitacoes.svg'
import iconeSolicitacoesVerde from '../icones/iconesVersaoVerde/solicitacoes.svg'
import iconeSolicitacoesVerdeAlgar from '../icones/iconesVersaoVerde/solicitacoes_algar.svg'

//Atualizar
import iconeAtualizarBranco from '../icones/iconesVersaoClara/atualizar.svg'
import iconeAtualizarPreto from '../icones/iconesVersaoEscura/atualizar.svg'
import iconeAtualizarVerde from '../icones/iconesVersaoVerde/atualizar.svg'
import iconeAtualizarVerdeAlgar from '../icones/iconesVersaoVerde/atualizar_algar.svg'

import iconeTrial from '../icones/imagemTral.jpg'
import iconeLogoVeye from '../icones/veye_logo.svg'

import iconeHistoricoBranco from '../icones/iconesVersaoClara/historico.svg'
import iconeHistoricoPreto from '../icones/iconesVersaoEscura/historico.svg'
import iconeHistoricoVerde from '../icones/iconesVersaoVerde/historico_verde.svg'

import iconeNotasBranco from '../icones/iconesVersaoClara/notas.svg'
import iconeNotasPreto from '../icones/iconesVersaoEscura/notas.svg'
import iconeNotasVerde from '../icones/iconesVersaoVerde/notas_verde.svg'

//Fornecedor
import iconeFornecedorPreto from '../icones/iconesVersaoClara/fornecedor_preto.svg'
import iconeFornecedorBranco from '../icones/iconesVersaoEscura/fornecedor_branco.svg'
import iconeFornecedorVerde from '../icones/iconesVersaoVerde/fornecedor_verde.svg'
//Cliente
import iconeClientePreto from '../icones/iconesVersaoClara/cliente_preto.svg'
import iconeClienteBranco from '../icones/iconesVersaoEscura/cliente_branco.svg'
import iconeClienteVerde from '../icones/iconesVersaoVerde/cliente_verde.svg'

import outlinedCircleBranco from '../icones/outlined-circle-2.svg'
import outlinedCircleEscuro from '../icones/outlined-circle.svg'

//Atribuir Linhas
import erroVermelhoBright from '../icones/erro-vermelho.svg'
import envelopeAbertoBranco from '../icones/icone-envelope-aberto-branco.svg'
import envelopeAbertoPreto from '../icones/icone-envelope-aberto-preto.svg'
import envelopeFechadoBranco from '../icones/icone-envelope-fechado-branco.svg'
import IconeSimcardVirgemBranco from '../icones/simcardVigemBranco.svg'
import sucessoBright from '../icones/sucesso-verde.svg'

import IconeCellTowerBranco from '../icones/iconesVersaoClara/cellTower.svg'
import IconeCellTowerPreto from '../icones/iconesVersaoEscura/cellTower.svg'

import IconeLocalizacaoPreenchidoBranco from '../icones/iconesVersaoClara/localizacao.svg'
import IconeLocalizacaoPreenchidoPreto from '../icones/iconesVersaoEscura/localizacao.svg'

import IconeSimcardBranco from '../icones/iconesVersaoClara/simcard.svg'
import IconeSimcardPreto from '../icones/iconesVersaoEscura/simcard.svg'

import IconeClienteBranco2 from '../icones/iconesVersaoClara/cliente.svg'
import IconeClientePreto2 from '../icones/iconesVersaoEscura/cliente.svg'

import FecharBranco from '../icones/icone-fechar-branco.svg'
import FecharPreto from '../icones/icone-fechar-preto.svg'
import IconeSucessoClaro from '../icones/iconesMensagens/alerta_sucesso_verde_claro.svg'
import IconeSucessoEscuro from '../icones/iconesMensagens/alerta_sucesso_verde_escuro.svg'
import dividerVerticalBranca from '../icones/iconesVersaoClara/breadCrumbs/divider_vertical.svg'
import divisaDireitaBranca from '../icones/iconesVersaoClara/breadCrumbs/divisa_direita.svg'
import setaVoltarBranca from '../icones/iconesVersaoClara/breadCrumbs/seta_voltar.svg'
import configuracoesBranca from '../icones/iconesVersaoClara/configuracoes_preta.svg'
import defaultImageBranca from '../icones/iconesVersaoClara/defaultImage_branco.svg'
import dotsVerticalBranco from '../icones/iconesVersaoClara/dotsVertical.svg'
import editarBranca from '../icones/iconesVersaoClara/editar_branca.svg'
import iconeHistoricoBranco2 from '../icones/iconesVersaoClara/historico-2.svg'
import lixeiraBranca from '../icones/iconesVersaoClara/lixeira_branca.svg'
import passarPaguinaDireitaBranca from '../icones/iconesVersaoClara/passar_pagina_versao_direita_clara.svg'
import passarPaguinaEsquerdaBranca from '../icones/iconesVersaoClara/passar_pagina_versao_esquerda_clara.svg'
import SetaBranca from '../icones/iconesVersaoClara/setaDown.svg'
import SetaEsquerdaBranca from '../icones/iconesVersaoClara/setaEsquerda.svg'
import adicionarCinza from '../icones/iconesVersaoEscura/add_novo_grupo.svg'
import dividerVerticalPreta from '../icones/iconesVersaoEscura/breadCrumbs/divider_vertical.svg'
import divisaDireitaPreta from '../icones/iconesVersaoEscura/breadCrumbs/divisa_direita.svg'
import setaVoltarPreta from '../icones/iconesVersaoEscura/breadCrumbs/seta_voltar.svg'
import configuracoesPreta from '../icones/iconesVersaoEscura/configuracoes_preto.svg'
import defaultImageEscuro from '../icones/iconesVersaoEscura/defaultImage_escuro.svg'
import dotsVerticalPreto from '../icones/iconesVersaoEscura/dotsVertical.svg'
import editarCorCinza from '../icones/iconesVersaoEscura/editar_cinza.svg'
import editarPreta from '../icones/iconesVersaoEscura/editar_preta.svg'
import iconeHistoricoPreto2 from '../icones/iconesVersaoEscura/historico-2.svg'
import lixeiraPreta from '../icones/iconesVersaoEscura/lixeira_preta.svg'
import passarPaguinaDireitaPreta from '../icones/iconesVersaoEscura/passar_pagina_versao_direita_escura.svg'
import passarPaguinaEsquerdaPreta from '../icones/iconesVersaoEscura/passar_pagina_versao_esquerda_escura.svg'
import SetaPreta from '../icones/iconesVersaoEscura/setaDown.svg'
import SetaEsquerdaPreta from '../icones/iconesVersaoEscura/setaEsquerda.svg'
import adicionarVerde from '../icones/iconesVersaoVerde/add_novo_grupo.svg'
import adicionarVerdeAlgar from '../icones/iconesVersaoVerde/add_novo_grupo_algar.svg'
import configuracoesVerde from '../icones/iconesVersaoVerde/configuracoes_verde.svg'
import editarCorVerde from '../icones/iconesVersaoVerde/editar_verde.svg'
import editarCorVerdeAlgar from '../icones/iconesVersaoVerde/editar_verde_algar.svg'
import adicionarSemEllipseVerde from '../icones/iconesVersaoVerde/icone-adicionar.svg'
import lixeiraVermelha from '../icones/iconesVersaoVermelha/lixeira_vermelho.svg'
import IconeSimcardVirgemPreto from '../icones/simcardVigemPreto.svg'

export const setaVoltar = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return setaVoltarBranca
  } else {
    return setaVoltarPreta
  }
}

export const dividerVertical = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return dividerVerticalBranca
  } else {
    return dividerVerticalPreta
  }
}

export const divisaDireita = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return divisaDireitaBranca
  } else {
    return divisaDireitaPreta
  }
}

export const telaTrial = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return iconeTrial
  } else {
    return iconeTrial
  }
}

export const logoVeye = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return iconeLogoVeye
  } else {
    return iconeLogoVeye
  }
}

export const outrosVerde = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return iconeOutrosVerdeAlgar
  } else {
    return iconeOutrosVerde
  }
}

export const IconeFaturas = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return iconeFaturasPreto
  } else {
    return iconeFaturasBranco
  }
}

export const IconeCadastros = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return iconeCadastroBranco
  } else {
    return iconeCadastroPreto
  }
}

export const IconeCadastroVerde = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return iconeCadastroVerdeAlgar
  } else {
    return iconeCadastroVerde
  }
}

export const IconeSolicitacoes = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return iconeSolicitacoesBranco
  } else {
    return iconeSolicitacoesPreto
  }
}

export const IconeSolicitacoesVerde = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return iconeSolicitacoesVerdeAlgar
  } else {
    return iconeSolicitacoesVerde
  }
}

export const IconeFaturasVerde = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return iconeFaturasVerdeAlgar
  } else {
    return iconeFaturasVerde
  }
}

export const outros = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return iconeOutrosEscura
  } else {
    return iconeOutrosClara
  }
}

export const filtroDesativado = () => {
  return filtroCinza
}

export const lupaDesativado = () => {
  return lupaCinza
}

export const limparDesativado = () => {
  return limparCinza
}

export const dropdownDesativado = () => {
  return dropdownCinza
}

export const lupaMaisZoomAtivada = () => {
  return lupaMaisVerde
}

export const lupaMenosZoomAtivada = () => {
  return lupaMenosVerde
}

export const lupaMaisZoomDesativada = () => {
  return lupaMaisCinza
}

export const lupaMenosZoomDesativada = () => {
  return lupaMenosCinza
}

export const relogio = () => {
  return relogioVerde
}

export const reticenciasVerde = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return reticenciaVerdeAlgar
  } else {
    return reticenciaVerde
  }
}

export const cancelar = () => {
  return iconeCancelar
}

export const confirmar = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return iconeConfirmar
  } else {
    return iconeConfirmar
  }
}

export const confirmarDisabled = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return iconeConfirmarDisabled
  } else {
    return iconeConfirmarDisabled
  }
}

export const naoPermitido = () => {
  return iconeNaoPermitido
}

export const setaBranca = () => {
  return setaDropdownBranca
}

//LIXERIA
export const lixeira = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return lixeiraPreta
  } else {
    return lixeiraBranca
  }
}

export const lixeiraBranco = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return lixeiraPreta
  } else {
    return lixeiraBranca
  }
}
export const lixeiraVermelho = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return lixeiraVermelha
  } else {
    return lixeiraVermelha
  }
}
//EDITAR
export const editar = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return editarPreta
  } else {
    return editarBranca
  }
}

export const editarVerde = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return editarCorVerdeAlgar
  } else {
    return editarCorVerde
  }
}

export const editarCinza = () => {
  return editarCorCinza
}

//CONFIGURACOES
export const configuracoes = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return configuracoesPreta
  } else {
    return configuracoesBranca
  }
}
export const configVerde = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return configuracoesVerde
  } else {
    return configuracoesVerde
  }
}
//ADICIONAR
export const adicionarMaisVerde = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return adicionarVerdeAlgar
  } else {
    return adicionarVerde
  }
}
export const adicionarMaisCinza = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return adicionarCinza
  } else {
    return adicionarCinza
  }
}
//ADICIONAR
export const adicionarMaisVerdeSemEllipse = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return adicionarSemEllipseVerde
  } else {
    return adicionarSemEllipseVerde
  }
}
//PASSAR PAGUINA
export const passarPaguinaDireita = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return passarPaguinaDireitaBranca
  } else {
    return passarPaguinaDireitaPreta
  }
}
export const passarPaguinaEsquerda = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return passarPaguinaEsquerdaBranca
  } else {
    return passarPaguinaEsquerdaPreta
  }
}
//EMAIL
export const email = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return emailPreta
  } else {
    return emailBranca
  }
}
export const envelopeFechado = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return envelopeFechadoBranco
  } else {
    return envelopeFechadoPreto
  }
}

export const envelopeAberto = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return envelopeAbertoBranco
  } else {
    return envelopeAbertoPreto
  }
}

// IMPORTAR ARQUIVO
export const importarArquivo = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return importarPreto
  } else {
    return importarBranco
  }
}

export const importarLogo = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return importarBranco
  } else {
    return importarPreto
  }
}

export const uploadVerde = () => importarVerdeSvg

export const downloadVerde = () => downloadVerdeSvg
export const downloadBranco = () => downloadBrancoSvg

export const iconeDownload = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return downloadBrancoSvg
  } else {
    return downloadPretoSvg
  }
}

export const importarRelatorio = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return importarBranco
  } else {
    return importarPreto
  }
}

export const importarDefault = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return importarCinza
  } else {
    return importarCinza
  }
}

export const iconeExportarCinza = () => {
  return exportarCinza
}

export const iconeImportarCinza = () => {
  return importarCinza
}

export const iconeErroVermelhoBright = () => {
  return erroVermelhoBright
}

export const iconeSucessoVerdeBright = () => {
  return sucessoBright
}

export const exportarDescricao = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return exportarPreto
  } else {
    return exportarBranco
  }
}

export const exportarDescricaoVerde = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return exportarVerde
  } else {
    return exportarVerdeAlgar
  }
}

//ALERTA
export const alerta = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return alertaPreta
  } else {
    return alertaBranca
  }
}
//DISPOSITIVO
export const dispositivo = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return dispositivoPreta
  } else {
    return dispositivoBranca
  }
}

export const dispositivoVerdeAtivo = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return dispositivoVerdeAlgar
  } else {
    return dispositivoVerde
  }
}
//FINANCEIRO
export const financeiro = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return financeiroPreta
  } else {
    return financeiroBranca
  }
}

//SMS
export const sms = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return smsPreta
  } else {
    return smsBranca
  }
}

//PEDIDOS
export const pedidos = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return pedidosPreta
  } else {
    return pedidosBranca
  }
}

//CLIENTES
export const clientes = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return clientesPreta
  } else {
    return clientesBranca
  }
}

//DASHBOARD
export const dashboard = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return dashboardPreta
  } else {
    return dashboardBranca
  }
}

export const dashboardVerdeAtivo = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return dashboardVerdeAlgar
  } else {
    return dashboardVerde
  }
}

//USUARIOS
export const usuarios = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return usuariosPreto
  } else {
    return usuariosBranco
  }
}

export const usuariosVerdeAtivo = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return usuariosVerdeAlgar
  } else {
    return usuariosVerde
  }
}

//AUTOMACAO
export const automacao = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return automacaoPreta
  } else {
    return automacaoBranca
  }
}

export const automacaoVerdeAtivo = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return automacaoVerdeAlgar
  } else {
    return automacaoVerde
  }
}

//MANUTECAO
export const manutencao = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return manutencaoPreta
  } else {
    return manutencaoBranca
  }
}

export const manutencaoVerdeAtivo = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return manutencaoVerdeAlgar
  } else {
    return manutencaoVerde
  }
}

//RELATORIOS
export const relatorios = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return relatoriosPreta
  } else {
    return relatoriosBranca
  }
}
export const relatoriosAtivo = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return relatoriosVerdeAlgar
  } else {
    return relatoriosVerde
  }
}

//ADMIN
export const admin = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return adminPreta
  } else {
    return adminBranca
  }
}

export const adminVerdeAtivo = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return adminVerdeAlgar
  } else {
    return adminVerde
  }
}

//OLHO
export const olho = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return olhoLigadoBranca
  } else {
    return olhoLigadoPreta
  }
}

export const olhoVerdeAtivo = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return olhoVerdeAlgar
  } else {
    return olhoVerde
  }
}
//LOGO VEYE-EYE
export const logoVirtueyes = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return logoVirtueyesBranco
  } else {
    return logoVirtueyesPreto
  }
}

export const veyeEye = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return veyeEyeBranca
  } else {
    return veyeEyePreta
  }
}
//LOGO VEYE-V
export const veyeV = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return veyeVPreta
  } else {
    return veyeVBranca
  }
}
export const logoAlgar = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return AlgarPreta
  } else {
    return AlgarBranca
  }
}

export const logoAlgarMin = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return AlgarPretaMin
  } else {
    return AlgarBrancaMin
  }
}

//TOGGLE MENU
export const menu = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return toggleMenuAlgar
  } else {
    return toggleMenu
  }
}
//TOGGLE MENU RECOLHIDO
export const menuRecolhido = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return toggleMenuAlgar
  } else {
    return toggleMenuRecolhido
  }
}
//LUPA
export const lupa = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return buscaPreta
  } else {
    return buscaBranca
  }
}
//FECHAR JANELA
export const fecharJanela = () => {
  return fecharAba
}

export const fecharBarraFiltro = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return fecharFiltroBarra
  } else {
    return fecharFiltroBarraClara
  }
}

export const limparFiltr = () => {
  return limparFiltros
}

//BOTÃO BUSCAR POR ARQUIVO
export const iconeBotaoBuscarPorArquivo = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return buscaArquivoPreta
  } else {
    return buscaArquivoBranca
  }
}

//LIMPAR
export const limpar = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return limparVermelho
  } else {
    return limparVermelho
  }
}

//SETA DROPDOWN
export const setaDropdown = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return setaDropdownVerdeAlgar
  } else {
    return setaDropdownVerde
  }
}

export const filtrarVerde = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return filtroVerdeAlgar
  } else {
    return filtroVerde
  }
}

export const setaUp = () => {
  return setaUpVerde
}

export const setaLado = () => {
  return setaLadoVerde
}

//ELIPSE
export const elipseClaro = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return ellipsePreto
  } else {
    return ellipseClaro
  }
}

export const elipseEscuro = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return ellipseClaro
  } else {
    return ellipsePreto
  }
}
export const elipseVerde = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return ellipseVerdeAlgar
  } else {
    return ellipseVerde
  }
}

export const elipseVermelho = () => {
  return ellipseVermelho
}

export const elipseAzul = () => {
  return ellipseAzul
}

export const elipseRoxo = () => {
  return ellipseRoxo
}

export const elipseLaranja = () => {
  return ellipseLaranja
}

export const elipseAmarelo = () => {
  return ellipseAmarelo
}

export const elipseCinza = () => {
  return ellipseCinza
}

//POWER
export const power = () => {
  return powerVerde
}

//OLHO DESLIGADO
export const olhoDesligado = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return olhoDeslidadoBranca
  } else {
    return olhoDeslidadoPreta
  }
}

//OLHO LIGADO
export const olhoLigado = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return olhoLigadoBranca
  } else {
    return olhoLigadoPreta
  }
}

//Olho Grupo
export const olhoLigadoGrupo = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return olhoLigadoBranca
  } else {
    return olhoLigadoPreta
  }
}

//EXCLAMACAO
export const exclamacao = () => {
  return exclamacaoVermelha
}
//EXCLAMACAO
export const teste = () => {
  return powerVerde
}

//SCROLL
export const scroll = () => {
  return iconeScroll
}

//MENSAGENS
export const alertaSucesso = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return iconeAlertaSucessoAlgar
  } else {
    return iconeAlertaSucesso
  }
}
export const alertaAtencao = () => {
  return iconeAlertaAtencao
}
export const alertaErro = () => {
  return iconeAlertaErro
}
export const alertaInformacao = () => {
  return iconeAlertaInformacao
}

export const telaInicialVeye = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return logoTelaInicialClara
  } else {
    return logoTelaInicialEscura
  }
}

export const telaIniciaAlgar = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return logoTelaInicialClaraAlgar
  } else {
    return logoTelaInicialEscuraAlgar
  }
}

export const poweredVeye = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'escuro' || localTema === 'algarClaro') {
    return poweredBranco
  } else {
    return poweredPreto
  }
}

export const iconeLocalizacao = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return iconeLocalizacaoEscuro
  } else {
    return iconeLocalizacaoClaro
  }
}

export const iconeLocalizacaoAtivado = () => {
  const localTema = window.localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return iconeLocalizacaoVerdeAlgar
  } else {
    return iconeLocalizacaoVerde
  }
}

export const iconeAntenaAtivado = () => {
  return iconeAntenaVerde
}

export const iconeDescricao = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return iconeDescricaoEscuro
  } else {
    return iconeDescricaoClaro
  }
}

export const iconeDescricaoAtivado = () => {
  const localTema = window.localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return iconeDescricaoVerdeAlgar
  } else {
    return iconeDescricaoVerde
  }
}

export const iconeConexoes = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return iconeConexoesEscuro
  } else {
    return iconeConexoesClaro
  }
}

export const iconeConexoesAtivado = () => {
  const localTema = window.localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return iconeConexoesVerdeAlgar
  } else {
    return iconeConexoesVerde
  }
}

export const iconeConsumo = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return iconeConsumoEscuro
  } else {
    return iconeConsumoClaro
  }
}

export const iconeConsumoAtivado = () => {
  const localTema = window.localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return iconeConsumoVerdeAlgar
  } else {
    return iconeConsumoVerde
  }
}

//CALENDARIO
export const calendario = () => {
  const localTema = window.localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return calendarioVerdeAlgar
  } else {
    return calendarioVerde
  }
}

export const googleBrands = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return iconeGoogleBrandsBranco
  } else {
    return iconeGoogleBrandsEscuro
  }
}

export const mapMarked = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return iconeMapMarkedBranco
  } else {
    return iconeMapMarkedEscuro
  }
}

export const setaPassarCalendarioDireita = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return setaPassarCalendarioDireitaEscura
  } else {
    return setaPassarCalendarioDireitaClara
  }
}

export const setaPassarCalendarioEsquerda = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return setaPassarCalendarioEsquerdaEscura
  } else {
    return setaPassarCalendarioEsquerdaClara
  }
}

export const checkboxSimcard = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return checkboxClaro
  } else {
    return checkboxEscuro
  }
}

export const checkedSimcard = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return checkedClaro
  } else {
    return checkedEscuro
  }
}

export const perfil = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return perfilEscuro
  } else {
    return perfilClaro
  }
}

export const filtroArq = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return filtroArquivoBranco
  } else {
    return filtroArquivoPreto
  }
}

export const perfilAtivado = () => {
  return perfilVerde
}

export const visaoGeral = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return visaoGeralEscuro
  } else {
    return visaoGeralClaro
  }
}

export const visaoGeralAtivado = () => {
  return visaoGeralVerde
}

export const sinalMaiorQueVerde = () => {
  const localTema = window.localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return divisorFiltragemAlgar
  } else {
    return divisorFiltragem
  }
}

//SETA MENU LATERAL
export const setaMenuLateral = () => {
  const localTema = window.localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return setaMenuLateralEscuro
  } else {
    return setaMenuLateralBranca
  }
}

export const menuInformacao = () => {
  const localTema = window.localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return menuInformacaoPreto
  } else {
    return menuInformacaoBranco
  }
}

export const menuFinanceiro = () => {
  const localTema = window.localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return menuFinanceiroBranco
  } else {
    return menuFinanceiroPreto
  }
}

export const menuFinanceiroVerdeAtivo = () => {
  const localTema = window.localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return menuFinanceiroVerdeAlgar
  } else {
    return menuFinanceiroVerde
  }
}

export const menuPedidos = () => {
  const localTema = window.localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return menuPedidoBranco
  } else {
    return menuPedidoPreto
  }
}

export const menuPedidosVerdeAtivo = () => {
  const localTema = window.localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return menuPedidoVerdeAlgar
  } else {
    return menuPedidoVerde
  }
}

export const menuSMS = () => {
  const localTema = window.localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return menuSmsBranco
  } else {
    return menuSmsPreto
  }
}

export const menuSMSVerdeAtivo = () => {
  const localTema = window.localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return menuSmsVerdeAlgar
  } else {
    return menuSmsVerde
  }
}

// LOGO VEYE
export const logoRedondo = () => {
  return iconeLogoRedondo
}

//CAMERA
export const camera = () => {
  const localTema = window.localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return cameraBranca
  } else {
    return cameraPreta
  }
}

export const historicoVerde = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return iconeHistoricoVerde
  } else {
    return iconeHistoricoVerde
  }
}

export const iconeHistorico = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return iconeHistoricoPreto
  } else {
    return iconeHistoricoBranco
  }
}

export const notaVerde = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return iconeNotasVerde
  } else {
    return iconeNotasVerde
  }
}

export const iconeNota = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return iconeNotasPreto
  } else {
    return iconeNotasBranco
  }
}

export const atualizar = () => {
  const localTema = window.localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return iconeAtualizarPreto
  } else {
    return iconeAtualizarBranco
  }
}

export const atualizarVerde = () => {
  const localTema = window.localStorage.getItem('tema')
  if (localTema === 'algarClaro' || localTema === 'algarEscuro') {
    return iconeAtualizarVerde
  } else {
    return iconeAtualizarVerdeAlgar
  }
}

export const fornecedor = () => {
  const localTema = window.localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return iconeFornecedorPreto
  } else {
    return iconeFornecedorBranco
  }
}

export const fornecedorVerde = () => {
  const localTema = window.localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return iconeFornecedorVerde
  } else {
    return iconeFornecedorVerde
  }
}

export const cliente_2 = () => {
  const localTema = window.localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return iconeClientePreto
  } else {
    return iconeClienteBranco
  }
}

export const clienteVerde_2 = () => {
  const localTema = window.localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return iconeClienteVerde
  } else {
    return iconeClienteVerde
  }
}

//CADASTRO PARCEIROS

export const defaultImage = () => {
  const localTema = window.localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return defaultImageBranca
  } else {
    return defaultImageEscuro
  }
}

export const alertaAmarelo = () => alertaAmareloSvg

export const alertaVermelho = () => alertaVermelhoSvg

export const fecharFiltroVerde = () => {
  return fecharVerde
}

export const outlineCircle = () => {
  const localTema = window.localStorage.getItem('tema')
  if (localTema === 'claro') {
    return outlinedCircleEscuro
  } else {
    return outlinedCircleBranco
  }
}

export const verSenhaFechado = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro') {
    return olhoDesligadoCinza
  } else {
    return olhoDeslidadoPreta
  }
}

export const verSenhaAberto = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro') {
    return olhoLigadoCinza
  } else {
    return olhoLigadoPreta
  }
}

export const iconeOpcaoDesabilitada = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro') {
    return disableCircleValidationPreto
  } else {
    return disableCircleValidationBranco
  }
}

export const iconeMensagemSucesso = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro') {
    return IconeSucessoEscuro
  } else {
    return IconeSucessoClaro
  }
}

//DISPOSITIVO
export const iconeSimcardVirgem = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro') {
    return IconeSimcardVirgemPreto
  } else {
    return IconeSimcardVirgemBranco
  }
}

//ERB
export const iconePortadora = () => {
  const localTema = localStorage.getItem('tema')
  return localTema === 'claro' ? IconeCellTowerPreto : IconeCellTowerBranco
}

export const iconeLocalizacaoPreenchido = () => {
  const localTema = localStorage.getItem('tema')
  return localTema === 'claro'
    ? IconeLocalizacaoPreenchidoPreto
    : IconeLocalizacaoPreenchidoBranco
}

export const iconeSimcard = () => {
  const localTema = localStorage.getItem('tema')
  return localTema === 'claro' ? IconeSimcardPreto : IconeSimcardBranco
}

export const iconeCliente = () => {
  const localTema = localStorage.getItem('tema')
  return localTema === 'claro' ? IconeClientePreto2 : IconeClienteBranco2
}

export const iconeHistorico_2 = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro' || localTema === 'algarClaro') {
    return iconeHistoricoPreto2
  } else {
    return iconeHistoricoBranco2
  }
}

export const setaParaBaixo = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro') {
    return SetaPreta
  } else {
    return SetaBranca
  }
}

export const setaParaEsquerda = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro') {
    return SetaEsquerdaPreta
  } else {
    return SetaEsquerdaBranca
  }
}

export const dropdownDotsVertical = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro') {
    return dotsVerticalPreto
  } else {
    return dotsVerticalBranco
  }
}

export const fechar = () => {
  const localTema = localStorage.getItem('tema')
  if (localTema === 'claro') {
    return FecharPreto
  } else {
    return FecharBranco
  }
}
