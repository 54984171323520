import styled from "styled-components";

export const ModalAlert = styled.div`
  .modal-alert {
    margin-top: 15px;
    font-size: 19px;
    text-align: center;
    color: ${({ theme }) => theme.cor41}!important;
  }
 
  .mantine-Group-root {
    justify-content: center !important;
  }
`;
