//IMPORTAÇÕES
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { api } from '../../../conexoes/api.js'
import checkCircle from '../../../icones/check-circle.svg'
import disableCircle from '../../../icones/disable-circle.svg'
import erroCircle from '../../../icones/erro-cirle.svg'
import fechar from '../../../icones/iconesVersaoEscura/fechar_janela.svg'
import ellipseVerde from '../../../icones/iconesVersaoVerde/ellipse.svg'
import { revelarAlterarSenha } from '../../../redux/actions/index'
import {
  outlineCircle,
  verSenhaAberto,
  verSenhaFechado
} from '../../../utils/alternanciaIcones.js'
import decryptJWT from '../../../utils/decryptJWT.js'
import MensagemAtencao from '../../componentesModais/mensagens/mensagemAtencao.js'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro.js'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso.js'
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'

import * as S from './styles.js'

const REGEX_MIN_CARACTERES = /^.{8,}$/
const REGEX_MAIUSCULAS = /[A-Z]/
const REGEX_MINUSCULAS = /[a-z]/
const REGEX_NUMEROS = /[0-9]/

const REGEX_CARACTERES_ESPECIAIS = /[!@#$%^&*()_+{}[\]:;,.?~\-|<>=]/

function validarSenhaComGrupos(senha) {
  let gruposValidos = 0

  if (REGEX_MAIUSCULAS.test(senha)) gruposValidos++
  if (REGEX_MINUSCULAS.test(senha)) gruposValidos++
  if (REGEX_NUMEROS.test(senha)) gruposValidos++
  if (REGEX_CARACTERES_ESPECIAIS.test(senha)) gruposValidos++

  return gruposValidos >= 3
}

export default function AlterarSenha() {
  const [spinAtivo, setSpinAtivo] = useState(false)
  const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
  const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
  const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
  const [alertaSenhaInvalida, setAlertaSenhaInvalida] = useState(false)
  const [mensagemErro, setMensagemErro] = useState(
    'Ocorreu um erro ao executar esta ação'
  )

  const [mostrarSenha, setMostrarSenha] = useState({
    novaSenha: 'password',
    confirmarSenha: 'password',
    senhaAtual: 'password'
  })

  const [formValues, setFormValues] = useState({
    senha: '',
    confirmarSenha: '',
    senhaAtual: ''
  })

  const [erro, setErro] = useState({
    faltaMaiuscula: false,
    faltaMinuscula: false,
    faltaNumero: false,
    faltaMinChar: false,
    faltaEspecial: false,
    faltaMinGrupo: false,
    senhaDiferente: false,
    senhaIgualAnterior: false
  })

  const disableConfirm =
    !formValues.senhaAtual ||
    erro.faltaMinGrupo ||
    erro.faltaMinChar ||
    erro.senhaIgualAnterior ||
    erro.senhaDiferente

  const inputErro =
    formValues.senha.length > 0 &&
    formValues.confirmarSenha.length > 0 &&
    formValues.senha !== formValues.confirmarSenha

  const despacho = useDispatch()

  const handleCancelar = useCallback(() => {
    setSpinAtivo(false)
    despacho(revelarAlterarSenha(false))
  }, [despacho])

  useEffect(() => {
    const validarSenha = () => {
      const faltaMinChar = !REGEX_MIN_CARACTERES.test(formValues.senha)
      const faltaMaiuscula = !REGEX_MAIUSCULAS.test(formValues.senha)
      const faltaMinuscula = !REGEX_MINUSCULAS.test(formValues.senha)
      const faltaNumero = !REGEX_NUMEROS.test(formValues.senha)
      const faltaEspecial = !REGEX_CARACTERES_ESPECIAIS.test(formValues.senha)

      const senhaDiferente = !(
        formValues.senha &&
        formValues.confirmarSenha &&
        formValues.senha === formValues.confirmarSenha
      )

      const faltaMinGrupo = !validarSenhaComGrupos(formValues.senha)

      const senhaIgualAnterior =
        formValues.senha === '' || formValues.senha === formValues.senhaAtual

      setErro({
        faltaMinChar,
        faltaMaiuscula,
        faltaMinuscula,
        faltaNumero,
        faltaEspecial,
        senhaDiferente,
        faltaMinGrupo,
        senhaIgualAnterior
      })
    }

    validarSenha()
  }, [formValues.senhaAtual, formValues.senha, formValues.confirmarSenha])

  useEffect(() => {
    async function handleCarregarDados() {
      await api
        .get(
          '/m1/consultar/cliente?cd_pav_cliente=' + decryptJWT('codigoCliente')
        )
        .then((resolve) => {
          if (resolve.data.status === 200 && resolve.data.dados.length != 0) {
          }
        })
    }
    handleCarregarDados()
  }, [])

  async function handleSalvar() {
    if (
      !erro.senhaInvalida &&
      !erro.senhaDiferente &&
      !erro.senhaIgualAnterior
    ) {
      setSpinAtivo(true)
      await api
        .get(
          '/m1/consultar/usuario_senha?cd_pav_usuario=' +
            decryptJWT('codigo') +
            '&ds_senha=' +
            encodeURIComponent(formValues.senhaAtual),
          { timeout: 20000 }
        )
        .then(async (response) => {
          if (response.data.status == 200 && response.data.dados.length != 0) {
            await api
              .post(
                '/m1/alterar/usuario',
                {
                  dados: {
                    ds_senha: formValues.senha
                  },
                  condicao: {
                    ds_email: decryptJWT('email')
                  }
                },
                { timeout: 20000 }
              )
              .then(function (response) {
                handleMensagemSucesso(false)
              })
              .catch(function () {
                setMensagemErro('Erro ao alterar senha')
                handleMensagemErro(false)
              })
              .finally(() => {
                setSpinAtivo(false)
              })
          } else if (response.data.dados.length === 0) {
            setSpinAtivo(false)
            setAlertaSenhaInvalida(true)
          } else {
            setMensagemErro(response.data.dados)
            handleMensagemErro(false)
          }
        })
    }
  }

  function handleMensagemAtencao(confirmacao) {
    if (revelarMensagemAtencao === false) {
      setRevelarMensagemAtencao(true)
    } else {
      setRevelarMensagemAtencao(false)
      if (confirmacao === true) {
      }
    }
  }

  function handleMensagemSucesso(confirmacao) {
    if (revelarMensagemSucesso === false) {
      setRevelarMensagemSucesso(true)
    } else {
      setRevelarMensagemSucesso(false)
      handleCancelar()
    }
    if (confirmacao) {
    }
  }

  function handleAlertaSenhaIvalida() {
    if (alertaSenhaInvalida === false) {
      setAlertaSenhaInvalida(true)
    } else {
      setAlertaSenhaInvalida(false)
    }
  }

  function handleMensagemErro() {
    if (revelarMensagemErro === false) {
      setRevelarMensagemErro(true)
    } else {
      setRevelarMensagemErro(false)
    }
  }

  const handleChangeSenhaAtual = (e) => {
    const { name, value } = e.target

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }))
  }

  const handleChange = (e) => {
    const caracteresProibidos = ["'", '"', '/', '\\', '`', '´', '¨', ' ']

    const { name, value } = e.target

    const valorFormatado = value
      .split('')
      .filter((char) => !caracteresProibidos.includes(char))
      .join('')

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: valorFormatado
    }))
  }

  const handleMostrarSenha = useCallback((campo) => {
    setMostrarSenha((prevState) => ({
      ...prevState,
      [campo]: prevState[campo] === 'password' ? 'text' : 'password'
    }))
  }, [])

  const exibirIcone = useCallback(
    (campo) => {
      return mostrarSenha[campo] === 'password' ? (
        <S.VerSenhaImg alt="olhoDesativo" src={verSenhaFechado()} />
      ) : (
        <S.VerSenhaImg alt="olhoAtivo" src={verSenhaAberto()} />
      )
    },
    [mostrarSenha]
  )

  const getIcon = (type) => {
    const campos = [
      'faltaMaiuscula',
      'faltaMinuscula',
      'faltaNumero',
      'faltaEspecial'
    ]

    const isAllErro =
      erro.faltaMinGrupo &&
      erro.faltaMaiuscula &&
      erro.faltaMinuscula &&
      erro.faltaNumero &&
      erro.faltaEspecial

    if (isAllErro && campos.includes(type)) return outlineCircle()

    if (erro[type]) {
      if (!erro.faltaMinGrupo && campos.includes(type)) {
        return disableCircle
      }

      return erroCircle
    }

    return checkCircle
  }

  return (
    <S.Overaly>
      <S.Modal>
        <S.ConteudoModal>
          {spinAtivo && (
            <div className="componente-spinner-overlay">
              <div className="componente-spinner-container">
                <SpinerVeye />
              </div>
            </div>
          )}
          <S.TitleWrapper>
            <S.Elipse src={ellipseVerde} alt="elipse" />
            <S.TituloModal>Alterar senha</S.TituloModal>
            <S.BotaoFechar onClick={handleCancelar}>
              <img
                className="botao-fechar-janela-icone"
                src={fechar}
                alt="fechar-janela"
              />
            </S.BotaoFechar>
          </S.TitleWrapper>
          <S.SenhasWrapper>
            <S.InputGroup>
              <S.InputLabel>Sua senha</S.InputLabel>
              <S.InputWrapper>
                <S.Input
                  autoComplete="off"
                  placeholder="********"
                  type={mostrarSenha.senhaAtual}
                  maxLength="40"
                  name="senhaAtual"
                  value={formValues.senhaAtual}
                  onChange={handleChangeSenhaAtual}
                />
                <S.BotaoVerSenha
                  onClick={() => handleMostrarSenha('senhaAtual')}
                >
                  {exibirIcone('senhaAtual')}
                </S.BotaoVerSenha>
              </S.InputWrapper>
            </S.InputGroup>
            <S.InputGroup>
              <S.InputLabel>Nova senha</S.InputLabel>
              <S.InputWrapper erro={inputErro}>
                <S.Input
                  autoComplete="off"
                  placeholder="********"
                  type={mostrarSenha.novaSenha}
                  maxLength="20"
                  minLength="8"
                  name="senha"
                  value={formValues.senha}
                  onChange={handleChange}
                />
                <S.BotaoVerSenha
                  onClick={() => handleMostrarSenha('novaSenha')}
                >
                  {exibirIcone('novaSenha')}
                </S.BotaoVerSenha>
              </S.InputWrapper>
            </S.InputGroup>
            <S.InputGroup>
              <S.InputLabel>Confirmar senha</S.InputLabel>
              <S.InputWrapper erro={inputErro}>
                <S.Input
                  autoComplete="new-password"
                  placeholder="********"
                  type={mostrarSenha.confirmarSenha}
                  maxLength="20"
                  minLength="8"
                  name="confirmarSenha"
                  value={formValues.confirmarSenha}
                  onChange={handleChange}
                />
                <S.BotaoVerSenha
                  onClick={() => handleMostrarSenha('confirmarSenha')}
                >
                  {exibirIcone('confirmarSenha')}
                </S.BotaoVerSenha>
              </S.InputWrapper>
            </S.InputGroup>
          </S.SenhasWrapper>
          <S.MensagensErroWrapper>
            <S.TituloMensagem>A nova senha deve conter:</S.TituloMensagem>
            <S.ValidacoesWrapper>
              <S.GrupoValidacao>
                <img alt="min_char" src={getIcon('faltaMinChar')} />
                Entre 8 e 20 caracteres;
              </S.GrupoValidacao>
              <S.GrupoValidacao>
                <img alt="grupos" src={getIcon('faltaMinGrupo')} />
                <p> Uso de pelo menos três dos quatro grupos de caracteres:</p>
              </S.GrupoValidacao>
              <S.GrupoValidacao subGrupo>
                <img alt="maiusculo" src={getIcon('faltaMaiuscula')} />
                <p>Letras maiúsculas (A-Z)</p>
              </S.GrupoValidacao>
              <S.GrupoValidacao subGrupo>
                <img alt="minusculo" src={getIcon('faltaMinuscula')} />
                <p>Letras minúsculas (a-z)</p>
              </S.GrupoValidacao>
              <S.GrupoValidacao subGrupo>
                <img alt="numero" src={getIcon('faltaNumero')} />
                <p>Números (0-9)</p>
              </S.GrupoValidacao>
              <S.GrupoValidacao subGrupo>
                <img alt="char_especiais" src={getIcon('faltaEspecial')} />
                <p>Caracteres especiais (ex.: !@#$%^&*)</p>
              </S.GrupoValidacao>
              <S.GrupoValidacao>
                <img alt="senha_iguais" src={getIcon('senhaDiferente')} />
                <p>As senhas devem ser iguais.</p>
              </S.GrupoValidacao>
              <S.GrupoValidacao>
                <img
                  alt="senhas_diferente"
                  src={getIcon('senhaIgualAnterior')}
                />
                <p> A nova senha deve ser diferente da anterior</p>
              </S.GrupoValidacao>
            </S.ValidacoesWrapper>
          </S.MensagensErroWrapper>
          <S.BotoesWrapper>
            <S.BotaoCancelar onClick={() => handleCancelar()}>
              CANCELAR
            </S.BotaoCancelar>
            <S.BotaoConfirmar disabled={disableConfirm} onClick={handleSalvar}>
              CONFIRMAR
            </S.BotaoConfirmar>
          </S.BotoesWrapper>
        </S.ConteudoModal>
      </S.Modal>
      {revelarMensagemSucesso && (
        <div className="componente-modal-overlay">
          <div className="componente-modal">
            <MensagemSucesso
              handleMensagemSucesso={handleMensagemSucesso}
              mensagemTitulo={'Sucesso'}
              mensagemParagrafo1={'Sua senha foi alterada.'}
              mensagemBotao={'Continuar'}
            />
          </div>
        </div>
      )}
      {revelarMensagemAtencao && (
        <div className="componente-modal-overlay">
          <div className="componente-modal">
            <MensagemAtencao
              handleMensagemAtencao={handleMensagemAtencao}
              mensagemTitulo={'Remover linha?'}
              mensagemBotao={'Remover'}
            />
          </div>
        </div>
      )}
      {revelarMensagemErro && (
        <div className="componente-modal-overlay">
          <div className="componente-modal">
            <MensagemErro
              handleMensagemErro={handleMensagemErro}
              mensagemTitulo={'Erro'}
              mensagemParagrafo1={mensagemErro}
              mensagemBotao={'Retornar'}
            />
          </div>
        </div>
      )}
      {alertaSenhaInvalida && (
        <div className="componente-modal-overlay">
          <div className="componente-modal">
            <MensagemErro
              handleMensagemErro={handleAlertaSenhaIvalida}
              mensagemTitulo={'Erro'}
              mensagemParagrafo1={'Senha atual inválida'}
              mensagemBotao={'Retornar'}
            />
          </div>
        </div>
      )}
    </S.Overaly>
  )
}
