import { useDisclosure } from '@mantine/hooks'
import { format, parse } from 'date-fns'
import React, { useEffect, useRef, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as XLSX from 'xlsx'

import BarraDadosCliente from '../../../components/componentesModais/barraDadosCliente/barraDadosCliente'
import BarraFiltragem from '../../../components/componentesModais/barraFiltragem/barraFiltragem'
import MensagemInformacao from '../../../components/componentesModais/mensagens/mensagemInformacao'
import Botao from '../../../components/Mantine/Botao'
import Group from '../../../components/Mantine/Group'
import Modal from '../../../components/Mantine/Modal'
import BarraTabelaLegenda from '../../../components/tabelaSelecao/barraTabelaLegenda'
import { desselecionarTodosCheckboxes } from '../../../components/tabelaSelecao/checkboxSelecao'
import '../../../css/painelDispositivos/dspSimcard/dspAcoes.css'
import {
  desabilitarAcoes,
  desabilitarAcoesCliente,
  filtrosArrayBarra,
  limpadorFiltros,
  mudarFiltros,
  mudarManejador,
  recarregarBarraFiltragem,
  revelarBarraFiltragem
} from '../../../redux/actions/index'
import { SimcardsVirgensService } from '../../../services/http/simcardVirgens.service'
import { useGlobal } from '../../../stores/useGlobal'
import decryptJWT from '../../../utils/decryptJWT'
import PaginacaoTabela from '../../../utils/paginacaoTabela'
import Provisionamento from '../Provisionamento'

import * as S from './styles'

let valorLimite = 50

let totalItens = 50
let contador = 0
let totalPaginas = 0

let auxDados = []

const TableprimaryKey = 'CdSimcardVirgem'

const COUNT_ITEMS = true

const COLUNAS = [
  {
    dataField: 'Iccid',
    text: 'ICCID',
    formatter: (cell) => cell || '-'
  },
  {
    dataField: 'Operadora',
    text: 'Operadora',
    formatter: (cell) => cell || '-'
  },
  {
    dataField: 'Plano',
    text: 'Plano',
    formatter: (cell) => cell || '-'
  },
  {
    dataField: 'TipoDeSimcard',
    text: 'Tipo de SIM Card',
    formatter: (cell) => cell || '-'
  },
  {
    dataField: 'DataAquisicao',
    text: 'Data da aquisição',
    formatter: (cell) => <div>{cell ? formatDate(cell) : '-'}</div>
  },
  {
    dataField: 'PreProvisionamento',
    text: 'Pré-provisionamento',
    formatter: (cell) => (
      <div>{cell ? formatDate(cell) : 'Sem data limite'}</div>
    )
  },
  {
    dataField: 'PrazoPreAtivacao',
    text: 'Prazo pré-ativação',
    formatter: (cell) => (cell ? `${cell} Dias` : '-')
  },
  {
    dataField: 'DataDeProvisionamento',
    text: 'Data de provisionamento',
    formatter: (cell) => <div>{cell ? formatDate(cell) : '-'}</div>
  },
  {
    dataField: 'Status',
    text: 'Status',
    formatter: (cell) => {
      return (
        <S.StatusWrapper>
          <S.StatusElipse status={cell} />
          {cell || '-'}
        </S.StatusWrapper>
      )
    }
  }
]

const formatDate = (date) => {
  const formattedDate = format(
    parse(date, 'yyyy-MM-dd', new Date()),
    'dd/MM/yyyy'
  )
  return formattedDate
}

export default function TabelaSimcardsVirgens() {
  const [opened, { open, close }] = useDisclosure(false)
  const timeoutRef = useRef(null)
  const [
    openedProvisionamentoConcluido,
    { open: openProvisionamentoConcluido, close: closeProvisionamentoConcluido }
  ] = useDisclosure(false)
  let recarregarBarraFiltragemValor = useSelector(
    (state) => state.filtrosState.recarregarBarraFiltragem
  )

  const {
    busca,
    buscaArquivo,
    filtros,
    setFiltros,
    revelarBarraFiltro,
    setRevelarBarraFiltro,
    revelarDadosCliente,
    separadorLinhaBusca,
    exportarRelatorio,
    setExportarRelatorio,
    selecionarParqueInteiro,
    setSelecionarParqueInteiro,
    mostrarAcao,
    setMostrarAcao,
    isLoadingGlobal,
    setIsLoadingGlobal
  } = useGlobal()

  const history = useHistory()

  const { getWhitechip, createReport, getDevices, getNotFoundIccids } =
    SimcardsVirgensService

  const [showCaption, setShowCaption] = useState(false)
  const [paginaAtual, setPaginaAtual] = useState(1)
  const [limpandoSelecao, setLimpandoSelecao] = useState(false)
  const [mostrarSelecao, setMostrarSelecao] = useState()
  const [mostrarAlertaRelatorio, setMostrarAlertaRelatorio] = useState(false)
  const [mostrarProvisionamentoConcluido, setMostrarProvisionamentoConcluido] =
    useState(false)
  const [alertaMostrarEmDispositivos, setAlertaMostrarEmDispositivos] =
    useState({
      alerta: false,
      mensagem: ''
    })
  const [dados, setDados] = useState([])
  const [simcardsSelecionados, setSimcardsSelecionados] = useState([])

  const [relatorioBaixado, setRelatorioBaixado] = useState(false)
  const despacho = useDispatch()

  const auxExportarRelatorio =
    exportarRelatorio &&
    exportarRelatorio?.acao === 'exportarLinhasSimcardsEmBranco'

  const mostrarEmDispositivos =
    mostrarAcao === 'revelarMostrarEmDispositivosSimcardsEmBranco'

  const mostrarProvisionamento =
    mostrarAcao === 'revelarMostrarProvisionamentoSimcardsEmBranco'

  useEffect(() => {
    setSelecionarParqueInteiro(false)
    if (!filtros.vazio) {
      setFiltros({ vazio: 'vazio' })
      setRevelarBarraFiltro(false)
      despacho(mudarFiltros({ vazio: 'vazio' }))
      despacho(revelarBarraFiltragem(false))
    }
  }, [])

  useEffect(() => {
    if (!mostrarEmDispositivos) return
    if (!selecionarParqueInteiro) {
      verificarEExibirAlerta()

      timeoutRef.current = setTimeout(() => {
        fetchDevices(
          simcardsSelecionados?.map((simcard) => simcard.Iccid).join(';')
        )
      }, 6000)
    } else {
      fetchDevices()
    }
  }, [mostrarEmDispositivos])

  useEffect(() => {
    if (mostrarProvisionamento) open()
  }, [mostrarProvisionamento])

  useEffect(() => {
    setPaginaAtual(1)
  }, [filtros])

  useEffect(() => {
    if (auxExportarRelatorio) {
      const handleExportarLinhas = async () => {
        setIsLoadingGlobal(true)

        const nomeRelatorio = 'Relatório de SIM Cards em Branco'
        const bodyBase = {
          tipo_arquivo: exportarRelatorio.tipo,
          tipo_relatorio: 31,
          filtros,
          buscaArquivo,
          buscar: busca,
          separador: separadorLinhaBusca,
          contar: false
        }

        try {
          if (selecionarParqueInteiro) {
            const bodyParqueTodo = {
              ...bodyBase,
              descricao: nomeRelatorio
            }
            createReport(bodyParqueTodo)
          } else {
            const auxArrayCdSimcard = simcardsSelecionados
              .map((simcard) => simcard[TableprimaryKey])
              .filter((cdSimcard) => cdSimcard)

            const bodySelecaoNormal = {
              ...bodyBase,
              descricao: nomeRelatorio,
              arrayCdSimcard: auxArrayCdSimcard
            }
            await createReport(bodySelecaoNormal)
          }
          setMostrarAlertaRelatorio(true)
        } catch (erro) {
          console.error(erro)
        } finally {
          setExportarRelatorio({})
          setIsLoadingGlobal(false)
        }
      }

      handleExportarLinhas()
    }
  }, [auxExportarRelatorio])

  useEffect(() => {
    despacho(mudarManejador('simcardsVirgens'))
  }, [despacho])

  useQuery(
    [
      `/whitechip/iccids?pagina=${paginaAtual}`,
      paginaAtual,
      valorLimite,
      filtros,
      busca,
      buscaArquivo,
      COUNT_ITEMS
    ],
    async () => {
      try {
        setIsLoadingGlobal(true)

        return await getWhitechip(
          paginaAtual,
          valorLimite,
          filtros,
          busca,
          buscaArquivo,
          separadorLinhaBusca,
          COUNT_ITEMS
        )
      } catch (err) {
        console.error(err)
      } finally {
        setIsLoadingGlobal(false)
      }
    },
    {
      onSuccess: async (data) => {
        contador = (paginaAtual - 1) * valorLimite
        totalItens = data?.item_count
        totalPaginas = Math.ceil(totalItens / valorLimite)

        const novosItens = [...auxDados]

        data.iccids?.forEach((iccid, index) => {
          novosItens[contador + index] = iccid
        })

        if (buscaArquivo?.chave && !relatorioBaixado) {
          const dados = {
            key: buscaArquivo.chave,
            user: decryptJWT('codigo'),
            customer: decryptJWT('codigoCliente')
          }
          const fileFilterResults = await getNotFoundIccids(dados)
          if (fileFilterResults) {
            const notFoundItems = fileFilterResults
              .filter((item) => item.cd_dsp_whitechip === null)
              .map((item) => ({ iccid: item.iccid, msisdn: item.msisdn }))

            if (notFoundItems?.length) {
              downloadXLSX(notFoundItems)
              setRelatorioBaixado(true)
            }
          }
        }

        setDados(novosItens)
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false
    }
  )

  const fetchDevices = async (iccids = []) => {
    const naoPossuiSimcardsProvisionados =
      !selecionarParqueInteiro &&
      simcardsSelecionados.every((item) => item.Status !== 'Provisionado')

    if (naoPossuiSimcardsProvisionados) return
    try {
      const auxBusca = iccids.length ? iccids : busca
      const data = await getDevices(
        filtros,
        auxBusca,
        buscaArquivo,
        separadorLinhaBusca
      )
      if (data.length === 0) {
        exibirAlerta(
          'Das opções selecionadas, não há ICCIDS provisionados a serem mostrados em dispositivos.'
        )
        return
      } else {
        if (selecionarParqueInteiro && data.length != totalItens) {
          if (data.length != totalItens) {
            exibirAlerta(
              'Dentre os ICCIDS selecionados, alguns foram removidos pois ainda não estão provisionados.'
            )
            timeoutRef.current = setTimeout(() => {
              handleMostrarEmDispositivos(data)
            }, 4000)
          }
        } else handleMostrarEmDispositivos(data)
      }
    } catch (error) {
      console.error('Erro ao buscar parque inteiro:', error)
    }
  }

  const verificarEExibirAlerta = () => {
    const possuiFalhaOuEmBranco = simcardsSelecionados.some(
      (item) => item.Status !== 'Provisionado'
    )
    if (
      possuiFalhaOuEmBranco &&
      possuiFalhaOuEmBranco.length !== simcardsSelecionados.length
    ) {
      exibirAlerta(
        simcardsSelecionados.every((item) => item.Status !== 'Provisionado')
          ? 'Das opções selecionadas, não há ICCIDS provisionados a serem mostrados em dispositivos'
          : 'Dentre os ICCIDS selecionados, alguns foram removidos pois ainda não estão provisionados.'
      )
      return
    }
  }

  const exibirAlerta = (mensagem) => {
    setAlertaMostrarEmDispositivos({ alerta: true, mensagem })
  }

  const closeProvisionamento = () => {
    setMostrarAcao(undefined)
    close()
  }

  const trocarPagina = (novaPagina) => {
    if (totalPaginas <= 1) return

    setPaginaAtual(novaPagina)
  }

  const opcoesPaginacao = {
    page: paginaAtual,
    showTotal: true,
    sizePerPage: valorLimite,
    firstPageTitle: 'Primeira',
    lastPageTitle: 'Última',
    nextPageTitle: 'Proxima',
    prePageTitle: 'Anterior',
    custom: true,
    onPageChange: (newPage) => {
      trocarPagina(newPage)
    }
  }

  const onClickSelecaoParqueTodo = (novoValor) => {
    if (novoValor === false) {
      desselecionarTodosCheckboxes('limparSelecao')

      setLimpandoSelecao(true)
      setShowCaption(false)
    }
    handleAlternarSelecao(novoValor)
  }

  const handleAlternarSelecao = (valor) => {
    setSelecionarParqueInteiro(valor)
    setMostrarSelecao(valor)
    if (valor) {
      despacho(desabilitarAcoes(false))
      despacho(desabilitarAcoesCliente(true))
    } else {
      despacho(desabilitarAcoes(true))
      despacho(desabilitarAcoesCliente(false))
    }
    setSimcardsSelecionados([])
  }

  const getLimiteNaPagina = () =>
    contador + valorLimite <= totalItens ? contador + valorLimite : totalItens
  const getTotalItensNaPagina = () => getLimiteNaPagina() - contador

  const handleSelecionarLinha = (simcard) => {
    const selecionado = dados
      .filter((item) => item)
      .find((item) => item[TableprimaryKey] === simcard)

    if (selecionado) setSimcardsSelecionados((prev) => [...prev, selecionado])
    despacho(desabilitarAcoes(false))
    despacho(desabilitarAcoesCliente(true))
  }

  const handleDesselecionarLinha = (simcard) => {
    setSimcardsSelecionados((prev) =>
      prev.filter((item) => item[TableprimaryKey] !== simcard)
    )
    if (simcardsSelecionados.length === 1) {
      despacho(desabilitarAcoes(true))
    }
  }

  const handleSelecionarTodasLinhas = (rows) => {
    setSimcardsSelecionados((prev) => [...prev, ...rows])
    despacho(desabilitarAcoes(false))
    despacho(desabilitarAcoesCliente(true))
  }

  const handleDesselecionarTodosLinhas = (rows) => {
    setSimcardsSelecionados((prev) =>
      prev.filter(
        (item) =>
          !rows.some((row) => row[TableprimaryKey] === item[TableprimaryKey])
      )
    )
    if (simcardsSelecionados.length === rows.length) {
      despacho(desabilitarAcoes(true))
    }
  }

  const handleFecharMensagem = () => {
    if (!selecionarParqueInteiro && timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null

      // Chama o fetch imediatamente
      if (!selecionarParqueInteiro)
        fetchDevices(
          simcardsSelecionados?.map((simcard) => simcard.Iccid).join(';')
        )
    }

    setAlertaMostrarEmDispositivos((prev) => ({
      ...prev,
      alerta: false
    }))
    setMostrarAcao(undefined)
  }

  async function downloadXLSX(dados) {
    if (dados && dados.length > 0) {
      const dadosFormatados = dados.flat().map((item) => {
        return {
          ICCID: item.iccid,
          MSISDN: item.msisdn
        }
      })

      const ws = XLSX.utils.json_to_sheet(dadosFormatados)

      ws['!cols'] = [{ wch: 20 }, { wch: 20 }]
      ws['A1'] = { t: 's', v: 'ICCID' }
      ws['B1'] = { t: 's', v: 'MSISDN' }

      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Itens Não Encontrados')

      const xlsxFile = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const blob = new Blob([xlsxFile], { type: 'application/octet-stream' })

      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = 'itens_nao_encontrados.xlsx'
      link.click()
    }
  }

  const handleMostrarEmDispositivos = (simcards) => {
    const iccidList = simcards || simcardsSelecionados.map((item) => item.Iccid)

    //! setFiltros É SÓ PARA CONTROLE DENTRO USEEFFECT QUE LIMPA OS FILTROS AO ENTRAR NESSA TELA
    setFiltros({ ICCID: iccidList })
    despacho(mudarFiltros({ ICCID: iccidList }))
    despacho(filtrosArrayBarra(['ICCIDS Provisionados?ICCID']))
    despacho(revelarBarraFiltragem(true))
    despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    despacho(limpadorFiltros(false))

    history.push('/veye/dispositivos')

    setMostrarAcao(undefined)
    setSimcardsSelecionados([])
  }

  const selecaoLinhas = {
    mode: 'checkbox',
    hideSelectColumn: mostrarSelecao,
    hideSelectAll: mostrarSelecao,
    onSelect: (row, isSelect) => {
      if (limpandoSelecao) setLimpandoSelecao(false)

      if (isSelect) {
        handleSelecionarLinha(row[TableprimaryKey])
      } else {
        handleDesselecionarLinha(row[TableprimaryKey])
        if (showCaption) setShowCaption(false)
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (limpandoSelecao) setLimpandoSelecao(false)

      if (isSelect) {
        handleSelecionarTodasLinhas(rows)
        setShowCaption(true)
      } else {
        handleDesselecionarTodosLinhas(rows)
        if (showCaption) setShowCaption(false)
      }
    },
    selectionRenderer: ({ mode, ...rest }) => {
      return (
        <>
          <input type={mode} className="input-checkbox-simcard" {...rest} />
          <label className="label-checkbox-simcard" />
        </>
      )
    },
    selectionHeaderRenderer: ({ mode, ...rest }) => {
      if (rest.checked && !showCaption) {
        setShowCaption(true)
      }

      return (
        <>
          <input
            type={mode}
            className="input-checkbox-header-simcard"
            {...rest}
          />
          <label className="label-checkbox-header-simcard" />
        </>
      )
    }
  }

  return (
    <div>
      <Modal
        opened={alertaMostrarEmDispositivos.alerta}
        width="444px"
        minHeight="116px"
        type="alerta"
      >
        <S.ContainerAlerta>
          <S.MensagemAlerta>
            {alertaMostrarEmDispositivos.mensagem}
          </S.MensagemAlerta>
          <Botao label="Fechar" onClick={handleFecharMensagem} />
        </S.ContainerAlerta>
      </Modal>
      {revelarDadosCliente && <BarraDadosCliente />}
      {revelarBarraFiltro && <BarraFiltragem />}
      <S.tabelaWrapper>
        <PaginationProvider pagination={Paginador(opcoesPaginacao)}>
          {({ paginationProps, paginationTableProps }) => (
            <>
              {showCaption && (
                <BarraTabelaLegenda
                  setShowCaption={setShowCaption}
                  onClick={onClickSelecaoParqueTodo}
                  parqueTodoSelecionado={selecionarParqueInteiro}
                  filtros={revelarBarraFiltro}
                  totalItens={totalItens}
                  getTotalItensNaPagina={getTotalItensNaPagina}
                />
              )}
              <BootstrapTable
                classes="tabela"
                condensed
                keyField={TableprimaryKey}
                data={dados}
                columns={COLUNAS}
                selectRow={selecaoLinhas}
                bootstrap4
                bordered={false}
                noDataIndication={!isLoadingGlobal && 'Nenhum item encontrado'}
                {...paginationTableProps}
              />
              <PaginacaoTabela
                setPaginaAtual={trocarPagina}
                totalPaginas={totalPaginas}
                totalItens={totalItens}
                valorLimite={valorLimite}
                paginaAtual={paginaAtual}
                page={paginationProps.page}
                onPageChange={paginationProps.onPageChange}
              />
            </>
          )}
        </PaginationProvider>
        <div className="itens-exibidos-tabela fonte-cor-1">
          Mostrando
          <span className="label-tabela-itens-exibidos">{contador + 1}</span>-
          <span className="label-tabela-itens-exibidos">
            {getLimiteNaPagina()}
          </span>
          de
          <span className="label-tabela-itens-exibidos">{totalItens}</span>
        </div>
      </S.tabelaWrapper>
      {mostrarAlertaRelatorio && (
        <div id="mensagem-relatorio" className="componente-modal-overlay">
          <div className="componente-modal">
            <MensagemInformacao
              handleMensagemInformacao={() => setMostrarAlertaRelatorio(false)}
              mensagemTitulo={
                selecionarParqueInteiro
                  ? 'O arquivo está sendo gerado'
                  : 'O arquivo foi gerado'
              }
              mensagemParagrafo1={
                selecionarParqueInteiro
                  ? 'Esse processo levará alguns minutos, você poderá fazer o download do seu arquivo na tela de Relatórios assim que o link do mesmo estiver disponível'
                  : 'Faça o download do seu arquivo na tela de Relatórios'
              }
              mensagemBotao="Voltar"
              marginTop={40}
            />
          </div>
        </div>
      )}

      {mostrarProvisionamento && (
        <Provisionamento
          opened={opened}
          close={closeProvisionamento}
          ids={simcardsSelecionados}
          params={
            selecionarParqueInteiro
              ? {
                  fl_parque_todo: selecionarParqueInteiro,
                  filtros: filtros,
                  separadorLinhaBusca: separadorLinhaBusca,
                  busca: busca,
                  buscaArquivo: buscaArquivo
                }
              : undefined
          }
          onDone={(done) => {
            setMostrarProvisionamentoConcluido(done)
            openProvisionamentoConcluido()
          }}
        />
      )}
      {mostrarProvisionamentoConcluido && (
        <Modal
          width="444px"
          type="alerta"
          opened={openedProvisionamentoConcluido}
        >
          <span className="fonte-cor-1">
            Solicitação de provisionamento criada com sucesso.
          </span>
          <div>
            <Group position="rigth">
              <Botao
                variant="outline"
                label="Fechar"
                onClick={() => closeProvisionamentoConcluido()}
              />
            </Group>
          </div>
        </Modal>
      )}
    </div>
  )
}
