import React from 'react'
import { Col, Form } from 'react-bootstrap'

import './checkbox.css'

export default function CheckboxAutorizar({
  texto,
  checked,
  onClick,
  className
}) {
  return (
    <Form.Row className={className} id="div-checkbox-autorizar">
      <Col>
        <div>
          <input
            checked={checked}
            type="checkbox"
            className="input-checkbox-simcard"
          />
          <label
            className={`label-checkbox-simcard label-checkbox-sms-${checked ? 'checked' : 'unchecked'}`}
            onClick={onClick}
           />
        </div>
        <div>
          <p className="fonte-cor-1">{texto}</p>
        </div>
      </Col>
    </Form.Row>
  )
}
