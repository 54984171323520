import React, { useEffect, useState } from 'react';

import { Button, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../conexoes/api';
import '../../css/pavFiltros/filtros.css';
import { filtrosArrayBarra, recarregarBarraFiltragem, revelarBarraFiltragem } from '../../redux/actions';
import { setaDropdown } from '../../utils/alternanciaIcones'; /*eslint-disable*/
import decrypt from '../../utils/decryptJWT';
import SelectEmpresa from '../componentesModais/selects/selectEmpresa';
import SelectConta from '../componentesModais/selects/selectFatConta';
import SelectOperadoraGeral from '../componentesModais/selects/selectFatOperadoraGeral';
import SelectFaturaStatus from '../componentesModais/selects/selectFatStatus';
import SelectFornecedor from '../componentesModais/selects/selectFornecedor';
// COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js';
        
function Filtros(props) {

    const [dsEmpresa,setDsEmpresa] = useState('Selecione')
    const [cdEmpresa,setCdEmpresa] = useState('')
    const [dsFornecedor,setDsFornecedor] = useState('Selecione')
    const [cdFornecedor,setCdFornecedor] = useState('')
    const [dsOperadora,setDsOperadora] = useState('Selecione')
    const [cdOperadora,setCdOperadora] = useState('')
    const [dsConta,setDsConta] = useState('Selecione')
    const [cdConta,setCdConta] = useState('')
    const [diaInicio,setDiaInicio] = useState('')
    const [diaVencimento,setDiaVencimento] = useState('')
    const [dsStatus,setDsStatus] = useState('Selecione')
    const [cdStatus,setCdStatus] = useState('')
    
    
    
    
    
    const [revelarSelectEmpresa,setRevelarSelectEmpresa] = useState(false)
    const [revelarSelectFornecedor,setRevelarSelectFornecedor] = useState(false)
    const [revelarSelectOperadora,setRevelarSelectOperadora] = useState(false)
    const [revelarSelectFatConta,setRevelarSelectFatConta] = useState(false)
    const [revelarSelectFatStatus,setRevelarSelectFatStatus] = useState(false)
            
    const despacho = useDispatch();
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let filtros = useSelector(state => state.filtrosState.filtros);
    let arrayFiltrosSimcard = []


    async function hendlerGetdadosFiltros(){
        if(!filtros.vazio){
            //seta os valores dos inputs
            

            if(filtros.cd_fat_empresa){
                const {data} = await  api.get('/m1/consultar/fat_empresa?fatemp.cd_fat_empresa='+filtros.cd_fat_empresa+' &cd_pav_usuario='+decrypt('codigo'))
                setCdEmpresa(filtros.cd_fat_empresa)
                if(data.status == 200){
                    setDsEmpresa(data.dados[0].ds_nome_fantasia)
                }
            }

            if(filtros.cd_fat_fornecedor){
                const {data} = await  api.get('/m1/consultar/fat_fornecedor_geral?fatforn.cd_fat_fornecedor='+
                                                filtros.cd_fat_fornecedor+'&cd_pav_usuario='+decrypt('codigo'))
                setCdFornecedor(filtros.cd_fat_fornecedor)
                if(data.status == 200){
                    setDsFornecedor(data.dados[0].ds_fat_nome_fantasia)
                }
            }

            if(filtros.cd_dsp_operadora){
                const {data} = await  api.get('/m1/consultar/fat_operadora_geral?dsp2.cd_dsp_operadora='+
                filtros.cd_dsp_operadora+' &cd_pav_usuario='+decrypt('codigo'))
                setCdOperadora(filtros.cd_dsp_operadora)
                if(data.status == 200){
                    setDsOperadora(data.dados[0].ds_apelido)
                }
            }
            if(filtros.cd_fat_conta){
                const {data} = await  api.get('/m1/consultar/fat_conta?fatconta.cd_fat_conta='+
                filtros.cd_fat_conta+' &cd_pav_usuario='+decrypt('codigo'))
                setCdConta(filtros.cd_fat_conta)
                if(data.status == 200){
                    setDsConta(data.dados[0].ds_conta)
                }
            }
            if(filtros.cd_fat_fatura_status){
                const {data} = await  api.get('/m1/consultar/fat_fatura_status?cd_fat_fatura_status='+filtros.cd_fat_fatura_status)
                setCdStatus(filtros.cd_fat_fatura_status)
                if(data.status == 200){
                    setDsStatus(data.dados[0].ds_fatura_status)
                }
            }
        }
    }
    useEffect(() => {
        hendlerGetdadosFiltros()
    }, []);

    //verifica se os radios butons estao ativos
    function handlerCheck(element,nome){
        if(filtros[element] == nome){
            return true
        }
        else{
            return false
        }
    }
    

    function handleFuncaoEstadosRedux(){

        if(cdEmpresa !== '' && cdEmpresa){
            arrayFiltrosSimcard.push("Empresa: "+dsEmpresa+"?cd_fat_empresa")
        }
        if(cdOperadora !== '' && cdOperadora){
            arrayFiltrosSimcard.push("Operadora: "+dsOperadora+"?cd_dsp_operadora")
        }
        if(cdFornecedor !== '' && cdFornecedor){
            arrayFiltrosSimcard.push("Fornecedor: "+dsFornecedor+"?cd_fat_fornecedor")
        }
        if(cdConta !== '' && cdConta){
            arrayFiltrosSimcard.push("Conta : "+dsConta+"?cd_fat_conta")
        }
        if(cdStatus !== '' && cdStatus){
            arrayFiltrosSimcard.push("Fatura status : "+dsStatus+"?cd_fat_fatura_status")
        }

        despacho(filtrosArrayBarra(arrayFiltrosSimcard))
        despacho(revelarBarraFiltragem(true))
        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    }

    const [dadoSelectGrupo, setDadoSelectGrupo] = useState([])

    useEffect(() => {
        async function carregarGrupos(){
            let todosGrupos = [];

            //carrega os 3 grupos padroes
            // const gruposPadroes = await api.get('/m1/consultar/simcard_grupo?fl_padrao=1')
            // todosGrupos = gruposPadroes.data.dados;
            
            // // carrega o grupos do cliente
            // const {data} = await api.get('/m1/consultar/simcard_grupo?fl_padrao=0&&cd_pav_cliente='+parseInt(decryptJWT('codigoCliente'))) 
            // await data.dados.map((item,i)=>{
            //     todosGrupos.push(item);
            // })
            // setDadoSelectGrupo(todosGrupos)

        }
        carregarGrupos();
    }, []);

    //HOOKS DOS VALORES DOS FILTROS

    const [cdSolicitacaoTipo, setCdSolicitacaoTipo] = useState()
    const [DsSolicitacaoTipo, setDsSolicitacaoTipo] = useState("Selecione")
    const [revelarSelectSolicitacaoTipo, setRevelarSelectSolicitacaoTipo] = useState(false)


    const [filtroOperadora, setFiltroOperadora] = useState('')
    const [filtroTecnologia, setFiltroTecnologia] = useState('')
    const [filtroPlanoTipo, setFiltroPlanoTipo] = useState('')
    const [filtroPlanoMinimo, setFiltroPlanoMinimo] = useState('')
    const [filtroPlanoMaximo, setFiltroPlanoMaximo] = useState('')
    const [filtroGrupo, setFiltroGrupo] = useState('')

    useEffect(() => {
        
        //Check se todas as variaveis estao limpas
        function handlerCheckarTudoLimpo(verificador = []){


            if(cdEmpresa=='') verificador.push(1)
            if(cdFornecedor=='') verificador.push(1) 
            if(cdOperadora=='') verificador.push(1) 
            if(cdConta=='') verificador.push(1) 
            if(cdStatus=='') verificador.push(1) 
            //tamanho igual a quantidade de variaveis
            if(verificador.length === 5) return false
            return true
        }

        async function handlerAtivarBotoes(){
            //se for alterado algo vai abilitar os botoes
            if(props.primeiraVez && !props.foiLimpado && await handlerCheckarTudoLimpo()){
                props.setFoiSelecionado(true);
            }
        }
        
        //limpa os dados de todas as variaveis
        if(props.foiLimpado){
            setDsEmpresa('Selecione')
            setCdEmpresa('')
            setDsFornecedor('Selecione')
            setCdFornecedor('')
            setDsOperadora('Selecione')
            setCdOperadora('')
            setDsConta('Selecione')
            setCdConta('')
            setDiaInicio('')
            setDiaVencimento('')
            setDsStatus('Selecione')
            setCdStatus('')
            props.setFoiLimpado(false)

             
        }
        
        handlerAtivarBotoes()
        props.setPrimeiraVez(true)
    }, [cdEmpresa,cdFornecedor,cdOperadora,cdConta,cdStatus,props.foiLimpado]);

    function validarNumero(id, valor){
        const filtro = /^[0-9]+$/;
        if (valor === '' || filtro.test(valor)) {
            if(id === "campo-texto-plano-minimo"){
                setFiltroPlanoMinimo(valor)
            }
            if(id === "campo-texto-plano-maximo"){
                setFiltroPlanoMaximo(valor)
            }
        }
    }

    return (
        <Form ref={props.modal} onSubmit={props.enviarFiltros} className="drop-acoes-container">
            <div className="campo-select-triangulo-menor-cor-2"></div>
            <div className="drop-acoes-corpo fundo-cor-7">
                <div className="drop-acoes-lista-menu">
                <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-empresa", 'imagem-filtro-empresa'); ColetaClickstream(1199) /*dashDropFiltrosEmpresa*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta"> Empresa </Form.Label>
                        <img id={"imagem-filtro-empresa"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-empresa"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                        <Form.Control id="ds_vendedor" name="cd_fat_empresa" type="text" value={cdEmpresa} className="d-none"/>
                        <Form.Group className="container-select-filtro">
                            { revelarSelectEmpresa === false &&
                                <Button name="cdResponsavel" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectEmpresa(!revelarSelectEmpresa)}>
                                    <div className="fonte-cor-1 label-campo-select">{dsEmpresa}</div>
                                    <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                </Button>
                            } 
                            { revelarSelectEmpresa === true &&
                                <SelectEmpresa
                                    setRevelarSelectEmpresa={setRevelarSelectEmpresa} //Hook para fechar a modal
                                    setCdEmpresa={setCdEmpresa} //Código do responsavel
                                    setDsEmpresa={setDsEmpresa} //Nome do responsavel
                                    phResponsavel={dsEmpresa} //Placeholder para mostrar o valor original em caso de edição
                                >
                                </SelectEmpresa>
                            }
                        </Form.Group>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-fornecedor", 'imagem-filtro-fornecedor'); ColetaClickstream(1200) /*dashDropFiltrosFornecedor*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Fornecedor </Form.Label>
                        <img id={"imagem-filtro-fornecedor"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-fornecedor"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                        <Form.Control id="ds_vendedor" name="cd_fat_fornecedor" type="text" value={cdFornecedor} className="d-none"/>
                        <Form.Group className="container-select-filtro">
                            { revelarSelectFornecedor === false &&
                                <Button name="cdResponsavel" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectFornecedor(!revelarSelectFornecedor)}>
                                    <div className="fonte-cor-1 label-campo-select">{dsFornecedor}</div>
                                    <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                </Button>
                            } 
                            { revelarSelectFornecedor === true &&
                                <SelectFornecedor
                                    setRevelarSelectFornecedor={setRevelarSelectFornecedor} //Hook para fechar a modal
                                    setCdFornecedor={setCdFornecedor} //Código do responsavel
                                    setDsFornecedor={setDsFornecedor} //Nome do responsavel
                                    phResponsavel={dsFornecedor} //Placeholder para mostrar o valor original em caso de edição
                                >
                                </SelectFornecedor>
                            }
                        </Form.Group>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-operadora","imagem-filtro-operadora"); ColetaClickstream(1201) /*dashDropFiltrosOperadora*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Operadora </Form.Label>
                        <img id={"imagem-filtro-operadora"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-operadora"}  className="drop-filtros-lista-submenu-simcard dropdown-desativado-filtros">
                        <Form.Control id="ds_vendedor" name="cd_dsp_operadora" type="text" value={cdOperadora} className="d-none"/>
                        <Form.Group className="container-select-filtro">
                            { revelarSelectOperadora === false &&
                                <Button name="cdResponsavel" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectOperadora(!revelarSelectOperadora)}>
                                    <div className="fonte-cor-1 label-campo-select">{dsOperadora}</div>
                                    <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                </Button>
                            } 
                            { revelarSelectOperadora === true &&
                                <SelectOperadoraGeral
                                    setRevelarSelectOperadora={setRevelarSelectOperadora} //Hook para fechar a modal
                                    setCdOperadora={setCdOperadora} //Código do responsavel
                                    setDsOperadora={setDsOperadora} //Nome do responsavel
                                    phResponsavel={dsOperadora} //Placeholder para mostrar o valor original em caso de edição
                                >
                                </SelectOperadoraGeral>
                            }
                        </Form.Group>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-conta", 'imagem-filtro-conta'); ColetaClickstream(1202) /*dashDropFiltrosConta*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Conta </Form.Label>
                        <img id={"imagem-filtro-conta"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-conta"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                        <Form.Control id="ds_vendedor" name="cd_fat_conta" type="text" value={cdConta} className="d-none"/>
                        <Form.Group className="container-select-filtro">
                            { revelarSelectFatConta === false &&
                                <Button name="cdResponsavel" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectFatConta(!revelarSelectFatConta)}>
                                    <div className="fonte-cor-1 label-campo-select">{dsConta}</div>
                                    <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                </Button>
                            } 
                            { revelarSelectFatConta === true &&
                                <SelectConta
                                    setRevelarSelectFatConta={setRevelarSelectFatConta} //Hook para fechar a modal
                                    setCdConta={setCdConta} //Código do responsavel
                                    setDsConta={setDsConta} //Nome do responsavel
                                    setDiaVencimento={setDiaVencimento} //Nome do responsavel
                                    setDiaInicio={setDiaInicio} //Nome do responsavel
                                    dsConta={dsConta} //Placeholder para mostrar o valor original em caso de edição
                                >
                                </SelectConta>
                            }
                        </Form.Group>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-status-operadora", 'imagem-filtro-status-operadora'); ColetaClickstream(1203) /*dashDropFiltrosStatusDaFatura*/}}>
                        <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Status da fatura </Form.Label>
                        <img id={"imagem-filtro-status-operadora"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                    </div>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <div id={"filtro-status-operadora"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                        <Form.Control id="ds_vendedor" name="cd_fat_fatura_status" type="text" value={cdStatus} className="d-none"/>
                        <Form.Group className="container-select-filtro">
                            { revelarSelectFatStatus === false &&
                                <Button name="cdResponsavel" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectFatStatus(!revelarSelectFatStatus)}>
                                    <div className="fonte-cor-1 label-campo-select">{dsStatus}</div>
                                    <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                </Button>
                            } 
                            { revelarSelectFatStatus === true &&
                                <SelectFaturaStatus
                                    setRevelarSelectFatStatus={setRevelarSelectFatStatus} //Hook para fechar a modal
                                    setCdStatus={setCdStatus} //Código do responsavel
                                    setDsStatus={setDsStatus} //Nome do responsavel
                                    dsStatus={dsStatus} //Placeholder para mostrar o valor original em caso de edição
                                >
                                </SelectFaturaStatus>
                            }
                        </Form.Group>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    </div>
                    {/* ------------------- */}
                    <div className="drop-filtros-divisora-final campo-select-divisor-cor-1"></div>
                    <div className="botao-central-filtros">
                        <Form.Row>
                            <Col>
                                <Button disabled={props.foiSelecionado?false:true} type="submit" onClick={() => handleFuncaoEstadosRedux()} className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                    Filtrar
                                </Button>
                                <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                    Limpar
                                </Button>
                            </Col>
                        </Form.Row>
                    </div>
                </div>
            </div>
        </Form>
    );
}

export default Filtros;
