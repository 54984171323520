import { Select as SelectMantine } from '@mantine/core'
import styled, { createGlobalStyle } from 'styled-components'

export const Img = styled.img`
  transition: ease-in 200ms;
  transform: ${({ isOpen }) => isOpen && 'rotate(180deg)'};
`

export const Select = styled(SelectMantine)`
  .mantine-Select-input {
    border: none !important;
    color: ${({ theme }) => theme.cor9}!important;
    background-color: ${({ theme }) => theme.cor27}!important;
    height: 38px;
    font-size: 16px;
    line-height: 3rem;

    ::placeholder {
      color: ${({ theme }) => theme.cor39}!important;
      font-weight: 400 !important;
    }
  }

  .mantine-Input-wrapper {
    width: ${({ width }) => width} !important;
  }

  .mantine-Input-rightSection {
    margin-right: 8px;
  }

  .mantine-Select-label {
    color: ${({ theme }) => theme.cor9} !important;
    font-size: 20px !important;
    background-color: transparent !important;
  }
`

export const GlobalStyles = createGlobalStyle`
  .mantine-Select-dropdown {
    background-color: ${({ theme }) => theme.cor27} !important;
    border:none !important;
  }

  .mantine-Select-item {
    color: ${({ theme }) => theme.cor9} !important;
    background-color: transparent !important;
    font-size: 16px !important;

    :hover{
      background-color: ${({ theme }) => theme.cor57}!important;
    }
  }

  .mantine-Select-item[data-selected]  {
    background-color: ${({ theme }) => theme.cor57}!important;
  }
`
