import { api, api_svdispositivos } from '../../conexoes/api'
import { decrypt, formatBusca } from '../../utils/genericFormatters'
import { requestResolver } from '../../utils/requestResolver'

const getParams = (busca, separadorLinha) => {
  if (!busca) return ''
  return formatBusca(busca, separadorLinha)
}

export const PortadorasService = {
  //#region GET
  getPortadoras: (
    pagina,
    linhasPorPagina,
    filtros = {},
    busca,
    separadorLinhaBusca,
    count
  ) => {
    const params = {
      params: {
        page: pagina,
        pageRows: linhasPorPagina,
        count,
        filter:
          filtros.vazio || Object.keys(filtros).length === 0
            ? undefined
            : filtros,
        fetch: busca ? getParams(busca, separadorLinhaBusca) : undefined
      }
    }

    Object.keys(params.params).forEach(
      (key) => params.params[key] === undefined && delete params.params[key]
    )

    return requestResolver(
      api_svdispositivos.get(`/api/v2/carriers/fetchAll`, params)
    )
  },
  getCarrierFilter: () => {
    return requestResolver(
      api_svdispositivos.get(`/api/v2/carriers/filterCarriers`)
    )
  },
  getTechnologiesFilter: () => {
    return requestResolver(
      api_svdispositivos.get(`/api/v2/carriers/filterTechnologies`)
    )
  },
  getRadioCustomers: (
    pagina,
    linhasPorPagina,
    filtros = {},
    count,
    cdPortadora
  ) => {
    const params = {
      params: {
        page: pagina,
        pageRows: linhasPorPagina,
        count,
        filter: filtros.vazio ? undefined : filtros,
        id: cdPortadora
      }
    }

    Object.keys(params.params).forEach(
      (key) => params.params[key] === undefined && delete params.params[key]
    )

    return requestResolver(
      api_svdispositivos.get(`/api/v2/carriers/details/radioCustomers`, params)
    )
  },
  getSimcards: (pagina, linhasPorPagina, count, cdPortadora) => {
    const params = {
      params: {
        page: pagina,
        pageRows: linhasPorPagina,
        count
      }
    }

    Object.keys(params.params).forEach(
      (key) => params.params[key] === undefined && delete params.params[key]
    )

    return requestResolver(
      api_svdispositivos.get(`/api/v2/carriers/${cdPortadora}/devices`, params)
    )
  },
  getFatContas: () => {
    return requestResolver(
      api.get(
        `/m1/consultar/fat_conta/contas_usuario?cd_pav_usuario=${
          decrypt(['codigo'])?.codigo
        }`
      )
    )
  },
  getAlterarToken: async ({ codigo, codigoErp }) => {
    const fatContas = await PortadorasService.getFatContas().then(
      (response) => {
        return response?.dados?.map((item) => item.cd_fat_conta).join(',')
      }
    )

    const decryptedParams = decrypt([
      'login',
      'email',
      'codigo',
      'nomeDeUsuario',
      'perfil',
      'cdSmsContaUsuario',
      'id',
      'tipoPerfil',
      'clientePrimario',
      'clienteOriginal',
      'clienteNivel'
    ])

    const params = {
      ...decryptedParams,
      fatContas,
      codigoCliente: codigo,
      codigoClienteTexto: codigoErp,
      codigoClienteErpAntigo: codigoErp
    }

    return api.get('/m1/servicos/alterar_token', { params })
  },
  getAtualizarLogin: () => {
    const params = {
      cd_pav_usuario: decrypt(['codigo'])
    }

    return api.get('/m1/servicos/token/atualizarLogin', { params })
  },
  getAddressFilter: (ids) => {
    return requestResolver(
      api_svdispositivos.post(`/api/v2/carriers/filterAddress`, {
        stateID: ids
      })
    )
  },
  getMapInformation: (filtros) => {
    const params = {
      params: {
        cd_pav_cliente: decrypt(['codigoCliente']),
        filter: filtros.vazio ? undefined : filtros
      }
    }

    return requestResolver(
      api_svdispositivos.get('/api/v2/carriers/retrieveMapInformation', params)
    )
  },

  getDevicesPortadora: (filtros = {}, busca) => {
    const params = {
      params: {
        filtro:
          filtros.vazio || Object.keys(filtros).length === 0
            ? undefined
            : filtros,
        busca: busca ? getParams(busca, separadorLinhaBusca) : undefined
      }
    }

    return requestResolver(
      api_svdispositivos.get('/api/v2/devices/portadora', params)
    )
  },

  //#endregion GET

  //#region CREATE
  createReport: (body) => {
    return requestResolver(
      api_svdispositivos.post(`/api/v2/carriers/exportData`, body)
    )
  },

  //#endregion CREATE

  //#region UPDATE
  updateCarrierName: (cdPortadora, nome) => {
    const params = {
      cd_pav_cliente: decrypt(['codigoCliente']),
      carrierID: cdPortadora,
      carrierName: nome
    }

    return api_svdispositivos.post('/api/v2/carriers/updateCarrierName', params)
  }
  //#endregion UPDATE
}
