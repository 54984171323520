import React from "react";
import MapaGoogle from "../../../googleMap/googleMap";
import * as S from "./styles";

export default function Localizacao({ row }) {
  if (!row.latitude && !row.longitude) {
    return (
      <S.SemLocalizacao>
        Não foram encontrados dados para a localização da antena
      </S.SemLocalizacao>
    );
  }

  return <MapaGoogle array={[row]} tipo={"localizacao"} />;
}
