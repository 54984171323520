//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import {api} from '../../../../conexoes/api';/*eslint-disable*/
import { useForm } from "react-hook-form";/*eslint-disable*/
import { Button, Form, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select'
import { useSelector } from 'react-redux'
import Calendario from '../../../calendario/calendario'
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import PaginacaoTabela from '../../../../utils/paginacaoTabela'
//ICONES
import {elipseVerde, fecharJanela, exclamacao, setaDropdown, alertaAtencao, calendario} from '../../../../utils/alternanciaIcones';
//REDUX
import { useDispatch } from 'react-redux';
//MODAIS
import MensagemAtencao from '../../../componentesModais/mensagens/mensagemAtencao'
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'
import MensagemInformacao from '../../../componentesModais/mensagens/mensagemInformacao'
import decryptJWT from '../../../../utils/decryptJWT'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../clickstreamAnalise/clickstreamColeta.js'
//SELECT
import SelectGenerico from '../../../componentesModais/selects/selectGenerico'
// SPINNER
import SpinerVeye from '../../../spinnerVeye/spinnerVeye.js'
//CSS
import '../../../../css/painelFaturas/fatSolicitacoes/tabela.css'

import { revelarEditarSolicitacao } from '../../../../redux/actions/index';
import { set } from 'date-fns';

let arrayStatus = [
    {value: 'Aberta', label: 'Aberta', codigo: 1},
    {value: 'Concluída', label: 'Concluída', codigo: 2},
    {value: 'Cancelada', label: 'Cancelada', codigo: 3},
    {value: 'Em Andamento', label: 'Em Andamento', codigo: 4} 
]

let contador = 0
let controladorPagina = 1
let totalPaginas = 1
let totalItens = 10
let simcardsSelecionados = [];
let operadorasSelecionadas = [];

export default function EditarSolicitacao(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    const [itens, setItens] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [valorLimite, setValorLimite] = useState(10);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    //HOOKS PRINCIPAIS
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [cdSolicitacao, setCdSolicitacao]= useState(props.cdSolicitacao)
    const [dataMin, setDataMin] = useState(new Date(props.dataAlteracao))
    const [dataMax, setDataMax] = useState()
    const [previsaoConclusao, setPrevisaoConclusao] = useState("")
    const [dataConclusao, setDataConclusao] = useState("")
    const [protocoloOperadora, setProtocoloOperadora] = useState("")
    const [motivoAlteracao, setMotivoAlteracao] = useState()
    const [refazerChamadas, setRefazerChamadas] = useState(true);
    const [cdStatus, setCdStatus] = useState();
    const [dsStatus, setDsStatus] = useState('Selecione');
    const [revelarSelecaoLinhas, setRevelarSelecaoLinhas] = useState(false)
    const [faturasEmAberto, setFaturasEmAberto] = useState([])
    const [destinoHook, setDestinoHook] = useState();

    //HOOKS MODAIS
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemAtencaoRecalculo, setRevelarMensagemAtencaoRecalculo] = useState(false)
    const [revelarMensagemPrazoCicloVida, setRevelarMensagemPrazoCicloVida] = useState(false)
    const [itensPrazo, setItensPrazo] = useState([]);
    //HOOKS SELECTS
    const [dummy, setDummy] = useState('')
    const [revelarSelectCicloDeVida, setRevelarSelectCicloDeVida] = useState(false)
    const [revelarMensagemInformacao, setRevelarMensagemInformacao] = useState(false)
    //OUTROS HOOKS
    const [clickFora, setClickFora] = useState(false);
    const despacho = useDispatch();
    const modal = useRef();
    let itensVar = []

    const cd_pav_usuario = decryptJWT('codigo')

    const temPaginacao = itens.length > 0

    const iconeAlerta = <img className="icone-alerta" src={exclamacao()} alt='exclamacao'/>;

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };
    
    const handleClickDentro = () => setClickFora(false);

    useEffect(() => {
        setSpinAtivo(true)
            setCdSolicitacao(props.cdSolicitacao)

            if(props.protocoloOperadora === 'undefined') {
                setProtocoloOperadora("")
            } else {
                setProtocoloOperadora(props.protocoloOperadora)
            }

            if(props.dataPrazoConclusao!== null && props.dataPrazoConclusao !== undefined && props.dataPrazoConclusao !== '' && props.dataPrazoConclusao !== '0000-00-00 00:00:00'){
                setPrevisaoConclusao(new Date(props.dataPrazoConclusao))
            }

            if(props.dataFim!== null && props.dataFim !== undefined && props.dataFim !== '' && props.dataFim !== '0000-00-00 00:00:00'){
                setDataConclusao(new Date(props.dataFim))
            }
            
            // props.dataPrazoConclusao === "0000-00-00 00:00:00"? setPrevisaoConclusao(""):setPrevisaoConclusao(new Date(props.dataPrazoConclusao))
            // props.dataAlteracao === "0000-00-00 00:00:00"? setDataConclusao(""):setDataConclusao(new Date(props.dataPrazoConclusao))
            setMotivoAlteracao(props.motivo)
            setCdStatus(props.cdStatus)
            setDsStatus(props.dsStatus)
        
       
        document.addEventListener('mousedown', handleClickFora);
        setSpinAtivo(false)
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])
    
    useEffect(()=>{
        setSpinAtivo(true)
        async function carregarSolicitacao(){
            if(refazerChamadas === true){
                const contagem = await api.post('/m1/consultar/fat_solicitacao_alteracao/fat_solicitacao_simcard?cd_fat_solicitacao',{
                    "cd_fat_solicitacao":cdSolicitacao,
                    "contar":true
                })
                if(contagem.data.status != 200){
                    setMensagemErro("Não foi possivel carregar os sim cards")
                    setRevelarMensagemErro(true)
                    return null;
                }
                totalPaginas = Math.ceil(contagem.data.dados[0].CONTADOR / valorLimite)
                totalItens = contagem.data.dados[0].CONTADOR;
                setRefazerChamadas(false)
            }
            contador = (paginaAtual - 1) * valorLimite;
            const preview = await api.post('/m1/consultar/fat_solicitacao_alteracao/fat_solicitacao_simcard?cd_fat_solicitacao',{
                "cd_fat_solicitacao":cdSolicitacao,
                "carregarLimit":valorLimite,
                "paginaAtual":contador,
                "contar":false
            })
            if(preview.data.status != 200){
                setMensagemErro("Não foi possivel carregar os sim cards")
                setRevelarMensagemErro(true)
                return null;
            }
            inserirData(preview.data.dados)
            setSpinAtivo(false)
        }
        carregarSolicitacao()
    },[paginaAtual])
    
    function inserirData(data){
        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){
                    itensVar[k] = data[j]
                    k++
                }
            }
            if(i == (totalItens-1)){
                setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }
        setSpinAtivo(false)
    }

    const selecaoLinhas = {
        mode: 'checkbox',  
        // hideSelectColumn: reduxModoSelecaoSimcard,
        // hideSelectAll: reduxModoSelecaoSimcard,
        onSelect: (row, isSelect, rowIndex, e) => {
            if(isSelect){
                handleSelecionarSimcard(row)
            }else{
                handleDesselecionarSimcard(row)
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <>
                    <input type={mode} class="input-checkbox-simcard" { ...rest }/>
                    <label class="label-checkbox-simcard"></label>
                </>
            )
        },
        selectionHeaderRenderer: ({ mode, ...rest }) => {
            return (
                <>
                    <input type={mode} class="input-checkbox-header-simcard" { ...rest }/>
                    <label class="label-checkbox-header-simcard"></label>
                </>
            )
        },
        bgColor: { backgroundColor: 'gray' },
        color: 'red',
        hideSelectColumn: !revelarSelecaoLinhas,
    };

    function handleSelecionarSimcard(simcard){
        for (let i = 0; i < itens.length; i++) {
            if(itens[i]){
                if(itens[i].CD_SIMCARD === simcard.CD_SIMCARD){
                    simcardsSelecionados.push(itens[i]);
                    operadorasSelecionadas.push(itens[i].DS_PEDIDO_OPERADORA);
                    break;
                }
            }
        }
    }

    function handleDesselecionarSimcard(simcard){
        for (let i = 0; i < simcardsSelecionados.length; i++) {
            if (simcardsSelecionados[i].CD_SIMCARD === simcard.CD_SIMCARD){
                simcardsSelecionados.splice(i, 1);
                operadorasSelecionadas.splice(i, 1);
                break;
            }
        }
        if(simcardsSelecionados.length == 0){
        }
    }

    const colunasPrazo = [
        {
            dataField: 'LINHA',
            headerClasses: 'nao-selecionavel',
            text: 'Linha',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'ICCID',
            headerClasses: 'nao-selecionavel',
            text: 'ICCID',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'DATA',
            headerClasses: 'nao-selecionavel',
            text: 'Data',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{moment(cell).format('DD/MM/YYYY')}</p>;
            }
        }
    ]

    const colunas = [
        {
            dataField: 'CALLERID',
            headerClasses: 'nao-selecionavel',
            text: 'Linha',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'ICCID',
            headerClasses: 'nao-selecionavel',
            text: 'ICCID',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'DS_CONTA',
            headerClasses: 'nao-selecionavel',
            text: "Conta",
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'DS_PLANO',
            headerClasses: 'nao-selecionavel',
            text: "Plano",
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'DS_CICLO_VIDA',
            headerClasses: 'nao-selecionavel',
            text: "Ciclo de vida",
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
    ]
    
    function handleSalvar(){
        ColetaClickstream(1229) /*dropAcoesRevelarEditarSolicitacaoConfirmar*/
        if(props.cdTipo === 13 && cdStatus === 2){
            itens.map((item,i) => {
                if(moment(item.DT_PRAZO_PERMANENCIA) > moment(dataConclusao)){
                    itensPrazo.push({LINHA: item.LINHA, ICCID: item.ICCID, DATA: item.DT_PRAZO_PERMANENCIA})
                }else if(moment(item.DT_DESBLOQUEIO_120) > moment(dataConclusao)){
                    itensPrazo.push({LINHA: item.LINHA, ICCID: item.ICCID, DATA: item.DT_DESBLOQUEIO_120})
                }
            })
            if(itensPrazo.length > 0){
                setRevelarMensagemPrazoCicloVida(true)
            }else{
                if(simcardsSelecionados.length > 0){
                    setRevelarMensagemInformacao(true)
                }else{
                    handleRealizarAlteracao(0)
                }
            }
        }else{
            if(simcardsSelecionados.length > 0){
                setRevelarMensagemInformacao(true)
            }else{
                handleRealizarAlteracao(0)
            }
        }
    }

    async function enviarAlteracao(){
        dataConclusao === "" ? setDataConclusao('00-00-0000 00:00:00') : dataConclusao
        setSpinAtivo(true)
        //STEP 1: SALVAR ALTERACAO DE SOLICITACAO
        await api.post('/m1/alterar/fat_solicitacao', {
            "dados":{
                "cd_fat_solicitacao_status": cdStatus,
                "ds_protocolo_operadora":protocoloOperadora,
                "ds_solicitacao":props.dsSolicitacao,
                "cd_fat_solicitacao_tipo": props.cdTipo,
                "dt_inicio":moment().format("YYYY-MM-DD hh:mm:ss"),
                "dt_fim":dataConclusao === "" ? setDataConclusao('00-00-0000 00:00:00') : moment(dataConclusao).format("YYYY-MM-DD hh:mm:ss"),
                "ds_protocolo_operadora":protocoloOperadora,
                "ds_motivo": motivoAlteracao,
                "dt_prazo_conclusao":moment(previsaoConclusao).format("YYYY-MM-DD"),
                "simcards": revelarSelecaoLinhas ? simcardsSelecionados : [],
                "cd_pav_usuario_created": decryptJWT("codigo"),
                "cd_pav_cliente": decryptJWT('codigoCliente'),
                "fl_destino": destinoHook
            },
            "condicao":{
                'cd_fat_solicitacao': cdSolicitacao,
            }
        })
        .then(function (response) {
            setSpinAtivo(false)
            handleMensagemSucesso(false)
            // if(recalcular){
            //     handleRecalcularFaturas()
            // }
            // else{
            //     setSpinAtivo(false)
            //     handleMensagemSucesso(false)
            // }
        })
        .catch(function (error) {
            setSpinAtivo(false)
            setMensagemErro("Erro na solicitação!")
            handleMensagemErro(false)
        });
    }

    async function handleRecalcularFaturas(){
        const recalculos = await api.post('/m1/cadastrar/fat_faturas/fatura_preview', {
            "arrayFaturas": faturasEmAberto
        })
        if(recalculos.data.status == 200){
            api.post('/m1/cadastrar/fat_faturas_historico', {
                'arrayFaturas':recalculos.data.dados.sucesso,
                'cd_pav_usuario':cd_pav_usuario,
                'cd_fat_fatura_historico_tipo':4,
            })
            setSpinAtivo(false)
            handleMensagemSucesso(false)
        }
        else{
            setSpinAtivo(false)
            setMensagemErro("Erro ao recalcular faturas, favor realizar recalculo de faturas manualmente!")
            setRevelarMensagemErro(true)
        }
    }

    async function handleRealizarAlteracao(destino){
        setSpinAtivo(true)

        setDestinoHook(destino)
        /*  NOVO STATUS DA SOLICITACAO SELECIONADO NA TELA DE EDICAO
        *   STATUS POSSIVEIS
        *   STATUS 1 = ABERTO  
        *   STATUS 2 = CONCLUIDO
        *   STATUS 3 = CANCELADO
        *   STATUS 4 = EM ANDAMENTO
        */
        if(cdStatus == 1){
            enviarAlteracao(false)
        }
        // else if(cdStatus == 2){
        //     /*  QUANDO RECEBE COMO NOVO STATUS 2, REALIZA-SE A EXECUCAO DA SOLICITACAO
        //     *   VERIFICA SE O TIPO DE SOLICITACAO RECEBIDO NECESSITA DE RECALCULO DE VALOR DA FATURA
        //     *   0 NAO RECALCULA, 1 RECALCULA
        //     */
        //     let verificarNecessidadeRecalculoFatura = await api.get("m1/consultar/fat_solicitacao/recalculo?cd_fat_solicitacao_tipo="+props.cdTipo)
        //     if(verificarNecessidadeRecalculoFatura.data.dados[0].fl_realiza_alteracao == 1)
        //     {
        //         /** CONTAS DISTINTAS ENCONTRA TODAS AS CONTAS
        //          *  RELACIONADAS AOS SIMCARDS DA SOLICITACAO
        //          */
        //         const contasDistintas = await api.get("m1/consultar/fat_conta/contas_distintas?cd_fat_solicitacao="+cdSolicitacao)
        //         if(contasDistintas.data.status == 200){
        //             /** A PARTIR DAS CONTAS, VERIFICA SE EXISTEM FATURAS EM ABERTO
        //              *  CASO EXISTAM, REALIZA RECALCULO DE FATURA, CASO CONTRARIO
        //              *  CONCLUI A ALTERACAO NORMALMENTE
        //              */
        //             const faturasAbertas = await api.post("m1/consultar/fat_faturas/faturas_nao_pagas", {
        //                 "arrayContas":contasDistintas.data.dados,
        //                 "dt_condicao": props.dataFim
        //             })
        //             if(faturasAbertas.data.dados.length > 0){
        //                 setFaturasEmAberto(faturasAbertas.data.dados)
        //                 setRevelarMensagemAtencaoRecalculo(true)
        //             }
        //             else{
        //                 enviarAlteracao(false)                        
        //             }
        //         }
        //         else{
        //             setMensagemErro("Não foi possivel validar a alteração")
        //             setRevelarMensagemErro(true)
        //         }
        //     }
        //     else{
        //         enviarAlteracao(false)
        //     }
        // }
        else if(cdStatus == 3){
            enviarAlteracao(false)
        }
        else if(cdStatus == 2){
            enviarAlteracao(false)
        }
        else if(cdStatus == 4){
            enviarAlteracao(false)
        }
        
    }

    async function handleCancelar() {
        ColetaClickstream(1228) /*dropAcoesRevelarEditarSolicitacaoCancelar*/
        setSpinAtivo(false)
        despacho(revelarEditarSolicitacao(false))
        props.setRenderizar(!props.renderizar)
        simcardsSelecionados = []
        operadorasSelecionadas = []
        itensVar = []
    }

    function handleMensagemAtencaoRecalculo(confirmacao){
        if(revelarMensagemAtencaoRecalculo === false){
            setRevelarMensagemAtencaoRecalculo(true)
        }else{
            if(confirmacao === true){
                enviarAlteracao(true)
            }
            setRevelarMensagemAtencaoRecalculo(false)
        }
    }
    
    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            if(confirmacao ===true){
            }
        }
    }

    function handleMensagemSucesso(){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            handleCancelar()
        }
    }

    function handleMensagemInformacao(confirmacao){
        if(revelarMensagemInformacao === false){
            setRevelarMensagemInformacao(true)
        }else{
            setRevelarMensagemInformacao(false)
        }
        if(confirmacao){
            handleRealizarAlteracao(1)
        }else{
            handleRealizarAlteracao(0)
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
        }
        if(confirmacao ===true){
        }
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    function handleFecharMensagemPrazoCicloVida(valor){
        setRevelarMensagemPrazoCicloVida(!revelarMensagemPrazoCicloVida)
        if(valor === true){
            if(simcardsSelecionados.length > 0){
                setRevelarMensagemInformacao(true)
            }else{
                handleRealizarAlteracao(0)
            }
        }
    }
    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            <div className="modal-acao-desativar-demonstracao fundo-cor-6">
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Editar solicitação</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                {/* CORPO */}
                <Form.Row className="usuario-dados-corpo-linha">
                    <div className="corpo-dados-solicitacao">
                        <Form.Label>
                            <div className="fonte-cor-1 label-campo">Status</div>
                        </Form.Label>
                        {revelarSelectCicloDeVida === false &&
                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectCicloDeVida(!revelarSelectCicloDeVida)}>
                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsStatus}</div>
                                <img className={revelarSelectCicloDeVida?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                            </Button>
                        }

                        {revelarSelectCicloDeVida === true &&
                            <SelectGenerico
                                paramRota={"fat_solicitacao_status"}
                                paramCampo1={null}
                                paramCampo2={null}
                                paramCampo3={null}
                                paramCampo4={null}
                                setCodigo={setCdStatus}
                                setDescricao1={setDsStatus}
                                setDescricao2={setDummy}
                                setDescricao3={setDummy}
                                setDescricao4={setDummy}
                                setRevelarSelect={setRevelarSelectCicloDeVida}
                                placeholder={dsStatus}
                                sufixo={""}
                            >
                            </SelectGenerico>
                        }
                    </div>
                    <Col>
                        <div>
                            <Form.Label> <div className="fonte-cor-1 label-campo">Protocolo da operadora</div> </Form.Label>
                            {/* <OverlayTrigger key="top" delay={{show: 500, hide: 250}}placement="top"> */}
                                <Form.Control
                                    className="campo-texto-cor-3 campo-texto"
                                    type="text"
                                    maxLength="30"
                                    value={protocoloOperadora}
                                    name="ds_protocolo_operadora"
                                    onChange={e => setProtocoloOperadora(e.target.value)}
                                    placeholder="Digite o protocolo da operadora"
                                    // ref={register({ required: true, min: 3})}
                                />
                            {/* </OverlayTrigger> */}
                            {/* {errors.ds_protocolo_operadora && <p className="fonte-cor-3 label-alerta">{iconeAlerta} O campo é obrigatorio.</p>} */}
                        </div>
                    </Col>
                </Form.Row>

                <Form.Row className="usuario-dados-corpo-linha">
                    <div className="corpo-dados-solicitacao">
                        <Form.Label> <div className="fonte-cor-1 label-campo">Previsão de conclusão</div> </Form.Label>
                        {/* <OverlayTrigger key="top" delay={{show: 500, hide: 250}}placement="top" overlay={<Tooltip className={'tooltip-campos-solicitacao'} id={`tooltip-top`}>Selecione a previsão de conclusão</Tooltip>}> */}
                            <div className="calendario-z-index">
                                <Calendario imputCustomisado='De:' formato="DD/MM/YYYY" data={previsaoConclusao} setData={setPrevisaoConclusao} dataMax={dataMax} dataMin={dataMin} rota={"editarSolicitacao"} ></Calendario>
                            </div>
                        {/* </OverlayTrigger> */}
                        {/* {errors.ds_email_empresa  && <p className="fonte-cor-3 label-alerta">{iconeAlerta} O campo é obrigatorio.</p>} */}
                    </div>
                    <Col>
                        <div>
                            <Form.Label> <div className="fonte-cor-1 label-campo">Data de conclusão </div> </Form.Label>
                            {dsStatus === 'Concluída' ?
                                <>
                                    {/* <OverlayTrigger key="top" delay={{show: 500, hide: 250}}placement="top" overlay={<Tooltip className={'tooltip-campos-solicitacao'} id={`tooltip-top`}>Selecione a data de alteração</Tooltip>}> */}
                                        <Calendario imputCustomisado='De:' formato="DD/MM/YYYY" data={dataConclusao} setData={setDataConclusao} dataMax={dataMax} dataMin={dataMin} ></Calendario>
                                    {/* </OverlayTrigger> */}
                                </>
                            :
                                <>
                                    <OverlayTrigger key="top" delay={{show: 500, hide: 250}}placement="top" overlay={<Tooltip className={'tooltip-campos-solicitacao'} id={`tooltip-top`}>Campo liberado somente para o status concluída</Tooltip>}>
                                        <Form.Label className='campo-texto-cor-3 campo-texto-calendario-default'/>
                                    </OverlayTrigger>
                                </>
                            }
                        </div>
                    </Col>
                </Form.Row>

                <Form.Row className="usuario-dados-corpo-linha">
                    <div className="corpo-textarea-solicitacao">
                        <Form.Label> <div className="fonte-cor-1 label-campo">Motivo da alteração</div> </Form.Label>
                        {/* <OverlayTrigger key="top" delay={{show: 500, hide: 250}}placement="top" overlay={<Tooltip className={'tooltip-campos-solicitacao'} id={`tooltip-top`}>Digite o motivo da alteração</Tooltip>}> */}
                            <Form.Control
                                className="campo-texto-cor-3 campo-texto-area"
                                as="textarea"
                                rows="3" 
                                maxlength="500"
                                value={motivoAlteracao}
                                name="ds_telefone_empresa"
                                onChange={e => setMotivoAlteracao(e.target.value)}
                                placeholder="Motivo da alteração"
                                ref={register({ required: true, min: 3})}
                            />
                        {/* </OverlayTrigger> */}
                    </div>
                </Form.Row>
                <Form.Row className="usuario-dados-corpo-linha">
                    <div className='container-tabela-solicitacao-simcard'>
                        <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                            {
                                ({
                                    paginationProps,
                                    paginationTableProps
                                }) => (
                                    <div className="tabela-transparente-header-fixo-solicitacao">
                                        <BootstrapTable
                                            hover={true}
                                            condensed={true}
                                            data={itens}
                                            keyField={"CD_SIMCARD"}
                                            selectRow={selecaoLinhas}
                                            columns={colunas} 
                                            bootstrap4={true}
                                            bordered={false}
                                            noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                            { ...paginationTableProps }
                                        />
                                        {temPaginacao &&
                                            <PaginacaoTabela
                                                solicitacao={true}
                                                setPaginaAtual={setPaginaAtual}
                                                totalPaginas={totalPaginas}
                                                totalItens={totalItens}
                                                valorLimite={valorLimite}
                                                paginaAtual={paginaAtual}
                                                page={paginationProps.page}
                                                onPageChange={paginationProps.onPageChange}
                                            /> 
                                        }                                      
                                    </div>
                                )
                            }
                        </PaginationProvider>
                        {temPaginacao && 
                            <text className="itens-exibidos-tabela-faturas fonte-cor-1">
                                Mostrando 
                                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                                    - 
                                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                                    de 
                                <span className="label-tabela-itens-exibidos">{totalItens}</span>
                            </text>
                        }
                    </div>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="label-atencao-pacote-extra">
                            <div class="switchDiv">
                                <div  class="flipswitch-grupo">
                                    <input checked={revelarSelecaoLinhas} id="switchIgnorarLinhas" type="checkbox" name="flipswitch" class="flipswitch-cb" onChange={(e) => setRevelarSelecaoLinhas(e.target.checked)}/>
                                    <label for="switchIgnorarLinhas" class="flipswitch-label">
                                        <div class="flipswitch-inner flipswitch-cor-1"></div>
                                        <div class="flipswitch-switch"></div>
                                    </label>
                                </div>
                                { !revelarSelecaoLinhas ?
                                    <span><span className="fonte-cor-1 label-normal"> Remover linhas.</span></span>
                                :
                                    <span><span className="fonte-cor-3 label-normal negrito">Atenção:</span><span className="fonte-cor-1 label-normal"> Os simcards selecionados acima serão removidos da solicitação.</span></span>
                                }
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-corpo"/>
                <Form.Row className="espaco-reativar-linha">
                    <Col>
                        <>
                            {(cdStatus !== "" && (previsaoConclusao != "" && previsaoConclusao != null) && (dsStatus === "Aberta" || dsStatus === "Cancelada" || dsStatus ==="Concluída") && simcardsSelecionados != []) ?
                                <Button  type="submit" className={"botao-confirmar"} variant="success" size="sm">
                                    <div className="fonte-cor-4">Confirmar </div>
                                </Button>
                            :
                               <>
                                    <Button className="botao-confirmar-desabilitado" size="sm">
                                        <div className="fonte-cor-4">Confirmar </div>
                                    </Button> 
                                    {/* {dsStatus ==="Concluída" && dataConclusao !=="" ?
                                        <Button  type="submit" className={"botao-confirmar"} variant="success" size="sm">
                                            <div className="fonte-cor-4">Confirmar </div>
                                        </Button>
                                    :
                                        <Button className="botao-confirmar-desabilitado" size="sm">
                                            <div className="fonte-cor-4">Confirmar </div>
                                        </Button>
                                    }  */}
                               </>
                            }

                            <Button className={"botao-cancelar"} variant="success" size="sm" onClick={() => handleCancelar()}>
                                <div className="fonte-cor-2" >Cancelar</div>
                            </Button>
                        </>
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Sua solicitação foi enviada."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencao}
                            mensagemTitulo={"Remover linha?"}
                            mensagemBotao={"Remover"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            {/* { revelarMensagemAtencaoRecalculo &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencaoRecalculo}
                            mensagemTitulo={"Atenção"}
                            mensagemParagrafo1={"Você possui faturas em aberto"}
                            mensagemParagrafo2={"para contas desta solicitação."}
                            mensagemParagrafo3={"As mesmas serão recalculadas."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            } */}
            { revelarMensagemInformacao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemInformacao}
                            mensagemTitulo={"Atenção"}
                            mensagemParagrafo1={"Deseja abrir uma nova solicitação com os Simcards removidos?"}
                            mensagemBotao={"Abrir Nova Solicitação"}
                        />
                    </div>
                </div>
            }
            
            { revelarMensagemPrazoCicloVida &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <div className="modal-mensagem-atencao-acoes-simcards fundo-cor-6">
                            <div className="mensagem-container-icone">
                                <img className="mensagem-icone" src={alertaAtencao()} alt="sucesso"/>
                            </div>
                            <div className="fonte-cor-1 label-janela">Os seguintes Simcards constam dentro do prazo de permanencia:</div>
                            <div className="container-tabela-modal-itens-nao-permitidos">
                                <BootstrapTable
                                    classes="tabela-transparente-modais"
                                    keyField='ds_linha'
                                    condensed={true}
                                    data={itensPrazo}
                                    columns={colunasPrazo}
                                    noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                    bootstrap4 = {true}
                                />
                            </div>
                            <div className="mensagem-container-botao">
                                <Button className="mensagem-botao-cancelar" variant="success" size="sm" onClick={() => handleFecharMensagemPrazoCicloVida(false)}>
                                    <div className="fonte-cor-2">Cancelar</div>
                                </Button>
                                <Button className="mensagem-botao-confirmar" variant="success" size="sm" onClick={() => handleFecharMensagemPrazoCicloVida(true)}>
                                    <div className="fonte-cor-4">Continuar</div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Form>
    )
}